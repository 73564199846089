<mat-card class="mt-3">
    <div class="form-group">
        <div class="heading-3 mb-3">
            Projected Land Use Rights - Land Parcel {{ landParcelIndex + 1 | numberToWords }}
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Size Of Land Parcel (ha)</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="landParcel.size" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Zoning/Right</label>
                <mat-select class="col-12 mt-1" [(value)]="selectedProposedZoning"
                    (selectionChange)="onProposedZoningChange($event)">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let zoning of zoningData" [value]="zoning.id">
                        {{ zoning.description }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Land Use(s)</label>
                <mat-select multiple class="col-12 mt-1" [(value)]="selectedProposedLandUses"
                    (selectionChange)="onProposedLandUsesChange($event)" [disabled]="selectedProposedZoning === null">
                    <ng-container *ngIf="selectedProposedZoning !== null">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let landUse of proposedLandUseData" [value]="landUse.id">
                            {{ landUse.descriptionLong }}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="selectedProposedZoning === null">Please select a Zoning Right first.</mat-error>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Approved Additional Use(s)</label>
                <mat-select multiple class="col-12 mt-1" [(value)]="selectedApprovedAdditionalUses"
                    (selectionChange)="onApprovedAdditionalUsesChange($event)" [disabled]="selectedProposedZoning === null">
                    <ng-container *ngIf="selectedProposedZoning !== null">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let landUse of proposedLandUseData" [value]="landUse.id">
                            {{ landUse.descriptionLong }}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="selectedProposedZoning === null">Please select a Zoning Right first.</mat-error>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Density (units/ha)</label>
                <input class="col-12 mt-1" type="number" [(ngModel)]="landParcel.proposedDensity" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Coverage (%)</label>
                <input class="col-12 mt-1" type="number" [(ngModel)]="landParcel.proposedCoverage" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Height (storeys)</label>
                <input class="col-12 mt-1" type="number" [(ngModel)]="landParcel.proposedHeight" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Proposed Floor Area Ratio</label>
                <input class="col-12 mt-1" type="number" [(ngModel)]="landParcel.proposedFloorAreaRatio" />
            </div>
        </div>
    </div>
</mat-card>