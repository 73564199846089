import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-property-for-sale-offer-to-purchase-with-conditions',
  templateUrl: './property-for-sale-offer-to-purchase-with-conditions.component.html',
  styleUrls: ['./property-for-sale-offer-to-purchase-with-conditions.component.scss']
})
export class PropertyForSaleOfferToPurchaseWithConditionsComponent implements OnInit {

  confirmOne: boolean = false;
  confirmTwo: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  gotoForm(){
    sessionStorage.removeItem('offerType');
    sessionStorage.setItem('offerType', 'offerWithConditions');
    GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/property-for-sale/offer-to-purchase/capture', this.router)
  }
}