/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyMatrixConfiguration, PropertyMatrixConfigurationParams } from './property-matrix-configuration';
import { AdditionalServeService } from './services/additional-serve.service';
import { AdminService } from './services/admin.service';
import { AuthenticateService } from './services/authenticate.service';
import { ContactUsService } from './services/contact-us.service';
import { ConveyancerService } from './services/conveyancer.service';
import { EstateAgentService } from './services/estate-agent.service';
import { FileService } from './services/file.service';
import { MapsService } from './services/maps.service';
import { PropertyForSaleService } from './services/property-for-sale.service';
import { PropertyListingService } from './services/property-listing.service';
import { SellingMyPropertyService } from './services/selling-my-property.service';
import { ShareInOurDatabaseService } from './services/share-in-our-database.service';
import { UrbanPlannerService } from './services/urban-planner.service';
import { UserService } from './services/user.service';
import { ValuerService } from './services/valuer.service';
import { ZoningService } from './services/zoning.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdditionalServeService,
    AdminService,
    AuthenticateService,
    ContactUsService,
    ConveyancerService,
    EstateAgentService,
    FileService,
    MapsService,
    PropertyForSaleService,
    PropertyListingService,
    SellingMyPropertyService,
    ShareInOurDatabaseService,
    UrbanPlannerService,
    UserService,
    ValuerService,
    ZoningService,
    PropertyMatrixConfiguration
  ],
})
export class PropertyMatrixApiModule {
  static forRoot(params: PropertyMatrixConfigurationParams): ModuleWithProviders<PropertyMatrixApiModule> {
    return {
      ngModule: PropertyMatrixApiModule,
      providers: [
        {
          provide: PropertyMatrixConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor(
    @Optional() @SkipSelf() parentModule: PropertyMatrixApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PropertyMatrixApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
