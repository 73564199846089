/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfessionDto } from '../models/profession-dto';
import { UploadFileDto } from '../models/upload-file-dto';
import { UrbanPlannerProfessionalDto } from '../models/urban-planner-professional-dto';
import { UserDto } from '../models/user-dto';

@Injectable({
  providedIn: 'root',
})
export class UrbanPlannerService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1UrbanPlannerAddProfessionAsyncPost
   */
  static readonly ApiV1UrbanPlannerAddProfessionAsyncPostPath = '/api/v1/UrbanPlanner/AddProfessionAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerAddProfessionAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddProfessionAsyncPost$Response(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ProfessionDto>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerAddProfessionAsyncPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ProfessionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerAddProfessionAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddProfessionAsyncPost(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<ProfessionDto> {

    return this.apiV1UrbanPlannerAddProfessionAsyncPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ProfessionDto>) => r.body as ProfessionDto)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet
   */
  static readonly ApiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGetPath = '/api/v1/UrbanPlanner/GetUrbanPlannerProfessionalAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UrbanPlannerProfessionalDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UrbanPlannerProfessionalDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UrbanPlannerProfessionalDto>> {

    return this.apiV1UrbanPlannerGetUrbanPlannerProfessionalAsyncGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UrbanPlannerProfessionalDto>>) => r.body as Array<UrbanPlannerProfessionalDto>)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete
   */
  static readonly ApiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDeletePath = '/api/v1/UrbanPlanner/DeleteUrbanPlannerProfessionalAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete$Response(params?: {
    context?: HttpContext
    body?: UrbanPlannerProfessionalDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete(params?: {
    context?: HttpContext
    body?: UrbanPlannerProfessionalDto
  }
): Observable<string> {

    return this.apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost
   */
  static readonly ApiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPostPath = '/api/v1/UrbanPlanner/AddUrbanPlannerProfessionalAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost$Response(params?: {
    context?: HttpContext
    body?: UrbanPlannerProfessionalDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost(params?: {
    context?: HttpContext
    body?: UrbanPlannerProfessionalDto
  }
): Observable<string> {

    return this.apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost
   */
  static readonly ApiV1UrbanPlannerAddUrbanPlannerUserAsyncPostPath = '/api/v1/UrbanPlanner/AddUrbanPlannerUserAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost$Response(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerAddUrbanPlannerUserAsyncPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<string> {

    return this.apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerUpdateProfessionAsyncPut
   */
  static readonly ApiV1UrbanPlannerUpdateProfessionAsyncPutPath = '/api/v1/UrbanPlanner/UpdateProfessionAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerUpdateProfessionAsyncPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerUpdateProfessionAsyncPut$Response(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerUpdateProfessionAsyncPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerUpdateProfessionAsyncPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerUpdateProfessionAsyncPut(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<string> {

    return this.apiV1UrbanPlannerUpdateProfessionAsyncPut$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerDeleteProfessionAsyncDelete
   */
  static readonly ApiV1UrbanPlannerDeleteProfessionAsyncDeletePath = '/api/v1/UrbanPlanner/DeleteProfessionAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerDeleteProfessionAsyncDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerDeleteProfessionAsyncDelete$Response(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerDeleteProfessionAsyncDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerDeleteProfessionAsyncDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UrbanPlannerDeleteProfessionAsyncDelete(params?: {
    context?: HttpContext
    body?: ProfessionDto
  }
): Observable<string> {

    return this.apiV1UrbanPlannerDeleteProfessionAsyncDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerUploadUrbanPlannerFilesPost
   */
  static readonly ApiV1UrbanPlannerUploadUrbanPlannerFilesPostPath = '/api/v1/UrbanPlanner/UploadUrbanPlannerFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerUploadUrbanPlannerFilesPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerUploadUrbanPlannerFilesPost$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerUploadUrbanPlannerFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerUploadUrbanPlannerFilesPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerUploadUrbanPlannerFilesPost(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UrbanPlannerUploadUrbanPlannerFilesPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerLoadRequiredDocumentsGet
   */
  static readonly ApiV1UrbanPlannerLoadRequiredDocumentsGetPath = '/api/v1/UrbanPlanner/LoadRequiredDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerLoadRequiredDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerLoadRequiredDocumentsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerLoadRequiredDocumentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerLoadRequiredDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerLoadRequiredDocumentsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UploadFileDto>> {

    return this.apiV1UrbanPlannerLoadRequiredDocumentsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>) => r.body as Array<UploadFileDto>)
    );
  }

  /**
   * Path part for operation apiV1UrbanPlannerLoadRequiredCadastreGet
   */
  static readonly ApiV1UrbanPlannerLoadRequiredCadastreGetPath = '/api/v1/UrbanPlanner/LoadRequiredCadastre';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UrbanPlannerLoadRequiredCadastreGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerLoadRequiredCadastreGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UploadFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, UrbanPlannerService.ApiV1UrbanPlannerLoadRequiredCadastreGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UploadFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UrbanPlannerLoadRequiredCadastreGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UrbanPlannerLoadRequiredCadastreGet(params?: {
    context?: HttpContext
  }
): Observable<UploadFileDto> {

    return this.apiV1UrbanPlannerLoadRequiredCadastreGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UploadFileDto>) => r.body as UploadFileDto)
    );
  }

}
