import { PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingStateService {
  private selectedListingSource = new BehaviorSubject<PropertyListingDto | undefined>(this.loadInitialListing());
  selectedListing$ = this.selectedListingSource.asObservable();

  constructor() { }

  setSelectedListing(listing: PropertyListingDto): void {
    sessionStorage.setItem('selectedListing', JSON.stringify(listing));
    this.selectedListingSource.next(listing);
  }

  clearSelectedListing(): void {
    sessionStorage.removeItem('selectedListing');
    this.selectedListingSource.next(undefined);
  }

  private loadInitialListing(): PropertyListingDto | undefined {
    const listing = sessionStorage.getItem('selectedListing');
    return listing ? JSON.parse(listing) : undefined;
  }
}
