import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { NaturalPersonDialogDto } from 'src/app/models/sell-your-property/natural-person-dialog-dto';
import { MaritalStatusDto } from 'src/app/services/property-matrixV2/models';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-natural-person-dialog',
  templateUrl: './natural-person-dialog.component.html',
  styleUrls: ['./natural-person-dialog.component.scss', '../../../../../css/2-modules/_forms.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class NaturalPersonDialogComponent implements OnInit {

  maritalStatusDto: MaritalStatusDto[] = [];

  emailPattern: string = GlobalConstants.emailPattern;
  counter: number = 0;
  maritalStatusValue: string = '';
  maritalStatusId: string = '';
  maritalIntValue: any;

  maritalStatuses: KeyValuePair[] = [
    { key: 1, value: 'Unmarried' },
    { key: 2, value: 'Married out of community' },
    { key: 3, value: 'Married in community' }
  ];

  constructor(
    public dialogRef: MatDialogRef<NaturalPersonDialogComponent>,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private _lookupService: LookupService,
    @Inject(MAT_DIALOG_DATA) public data: NaturalPersonDialogDto
  ) { }

  ngOnInit(): void {
    this.GetMaritalStatus();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  GetMaritalStatus() {
    this._lookupService.apiV1LookupGetMaritalStatusGet()
      .pipe(
        catchError((err: { message: any; }) =>
          of(
            this.notificationService.showErrorMessage('Error', err.message || `An error has occurred while getting the marital status`)
          )
        )
      )
      .subscribe((result: MaritalStatusDto[] | null) => {
        if (result != null) {
          this.maritalStatusDto = result as MaritalStatusDto[];
          this.maritalStatusDto.sort((a, b) => a.intValue - b.intValue);
        }
      });
  }

  resetLegalEntityFields(value: any, id: string) {
    this.maritalIntValue = value
    this.maritalStatusId = id;
  }

  saveNaturalPerson(formIsValid: Boolean) {
    if (formIsValid == false) {
      GlobalMethods.tinyErrorAlert("Error", 'Please fill in all required fields');
      return;
    }
    if (formIsValid == true) {
      this.data.coOwner.confirmedEmail = true;
      this.data.coOwner.spouseDetail.confirmedEmail = true;
    }
    this.data.coOwner.maritalStatus = this.maritalStatusDto.find(x => x.id == this.maritalStatusId)
    this.dialogRef.close(this.data.coOwner);
  }

  deleteNaturalPerson() {
    this.data.coOwner.isDeleted = true;
    this.data.deleteCoOwner = true;
    this.dialogRef.close(this.data);
  }

  scroll(elementId: string) {
    GlobalMethods.scrollToId(elementId);
  }

  onRsaResidentChange(event: any) {
    event.value == 'true' ? this.data.coOwner.isRsaResident = true : this.data.coOwner.isRsaResident = false;
  }

  onSpouseRsaResidentChange(event: any) {
    event.value == 'true' ? this.data.coOwner.isSpouseRsaResident = true : this.data.coOwner.isSpouseRsaResident = false;
  }
}
