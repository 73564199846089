<main class="main-container">
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col heading-1 pb-3">
                ABOUT US
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 pb-3 paragraph">
                Welcome to Property Matrix, a <b>nationwide network</b> of distinguished professionals in
            </div>
            <div class="col-12 pb-4 paragraph">
                Urban Planning
                <span class="burnt-red">|</span> Development Economics
                <span class="burnt-red">|</span> Property Valuation
                <span class="burnt-red">|</span> Real Estates
                <span class="burnt-red">|</span> Bond Origination
                <span class="burnt-red">|</span> Conveyancing
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-12 pb-5">
                <b>"Matrix"</b> is defined as something that constitutes the place or point from which something else
                originates, takes form, or develops: Partners of Property Matrix will experience a revolutionary
                platform that elevates the property industry and their own network to new heights of professionalism,
                connectivity, and empowerment. Our professional approach will redefine the real estate industry.
            </div>
            <div class="row col-12 pb-5">
                <div class="col-1 number">1</div>
                <div class="col-11">
                    We offer <b>reliable data</b>. Each property advertised on our platform is backed by a planning
                    report, endorsed by a professional town planner - registered with the South African Council of
                    Planners (SACPLAN). This report provides valuable property data, including
                    <b>development potential</b>: ways to develop, extend or enhance the value of the interpretation of
                    the municipality's land-use schemes, strategic plans and development policies. The report is
                    substantiated with essential planning data such as land use rights (“zoning”), Surveyor General data
                    (diagrams for boundaries and servitudes) and deeds data.
                </div>
            </div>
            <div class="row col-12 pb-5">
                <div class="col-1 number">2</div>
                <div class="col-11">
                    Our database establishes unparalleled connections between the supply and demand of property. The
                    extensive range of <b>unique property types</b> (land uses) sets us apart from existing property
                    platforms. From business to beauty, retail to religion, education to sports facilities, and more:
                    we offer an unmatched selection for buyers to choose from. Best of all: sharing in this database
                    is <b>entirely free</b>: up to 15 distinct property 'orders,' can be placed and edited at any time.
                </div>
            </div>
            <div class="row col-12 pb-5">
                <div class="col-1 number">3</div>
                <div class="col-11">
                    <b>Our mission</b> is to establish a collaborative network of multi-disciplined professionals that
                    offers reliable property data, resulting in expedited property sales and informed decision-making.
                    We treat all our clients with an ethos of high standards and professionalism, making everything
                    about us truly about you.
                </div>
            </div>
        </div>
        <div class="row pb-4">
            <div class="heading-2 pb-5 text-center">
                HERE'S HOW WE CAN SERVE YOU:
            </div>
            <br>
            <div class="row extend-container">
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            BUY IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            We conduct a thorough planning investigation on your property and its potential, advertise
                            your property (including the report) on our website, free of charge, and instantly notify
                            all buyers in our network who desire what your property offers.
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            SELL IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            We help you find the ideal property that suits your needs or business requirements. We offer
                            reliable property data, ensuring a discerning and transparent deal. We can also assist in
                            structuring suitable conditions to add to your offer to purchase contract.
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            SPECULATE WITH IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Property Matrix provides a planning investigation on the property and its potential,
                            advising you on optimal enhancement or development strategies to maximize your return on
                            investment. We can also conduct a market gap analysis to identify your target market.
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            DEVELOP IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Our team inspects possible zoning, height, density, and coverage for the site, along with
                            council s strategic frameworks and plans, to advise you on the expected processes,
                            time-frames, and figures required for legal rights.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row pb-4" style="width: 100%;">
            <div class="heading-2 pb-4 text-center">
                PARTNERSHIP AND COLLABORATION
            </div>
            <div class="row text-center">
                <div class="col-12 pb-4 paragraph">
                    <b>Property Matrix</b> is an all-inclusive network. Select your profession below for further details
                    and benefits.
                </div>
            </div>
            <div class="row extend-container text-center justify-content-center partnership-and-collaboration">
                <div class="col-3 my-5 text-center align-items-center justify-content-center urban-planners"
                    style="padding:0">
                    <img src="/assets/images/about-us/johannesburg-01.jpg" alt="johannesburg-01">
                    <div class="heading-3 text-center partnership-and-collaboration-heading justify-content-center">
                        URBAN PLANNERS
                    </div>
                </div>
                <div class="col-3 my-5 text-center align-items-center justify-content-center estate-agents">
                    <img src="/assets/images/about-us/johannesburg-02.jpg" alt="johannesburg-02">
                    <div class="heading-3 text-center partnership-and-collaboration-heading justify-content-center">
                        ESTATE AGENTS
                    </div>
                </div>
                <div class="col-3 my-5 text-center align-items-center justify-content-center property-valuers">
                    <img src="/assets/images/about-us/johannesburg-03.jpg" alt="johannesburg-03">
                    <div class="heading-3 text-center partnership-and-collaboration-heading justify-content-center">
                        PROPERTY VALUERS
                    </div>
                </div>
                <div class="col-3 my-5 text-center align-items-center justify-content-center conveyancers">
                    <img src="/assets/images/about-us/cape-town-01.jpg" alt="cape-town-01">
                    <div class="heading-3 text-center partnership-and-collaboration-heading justify-content-center">
                        CONVEYANCERS
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>