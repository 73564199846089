<div class="value-assessment-factors">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10 header">
            <div class="text-center heading-2-light">
                Factors considered for Value Assessment:
            </div>
        </div>
        <div class="col-1">
            <button class="close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="paragraph light col-12">
            <p>
                Be assured that your property will be objectively assessed and valued in terms of a thorough
                investigation. The list below is by no means exhaustive.
            </p>
            <p>
                Our value assessment team generally considers the following factors to determine your property's
                open market value:
            </p>
        </div>
    </div>
    <div class="row">
        <div class="paragraph light col-12">
            <ul>
                <li>
                    <b>Property price</b>, including previous purchase prices and dates, as well as the current
                    municipal valuation.
                </li>
                <li>
                    <b>Location factors</b> such as macro- and micro-location, accessibility, area developments,
                    nearby properties and amenities.
                </li>
                <li>
                    <b>Site particulars</b>, including property size and shape, planning specifications (current and
                    potential zoning, density, height, coverage, FAR, building lines), and servitudes.
                </li>
                <li>
                    <b>Property improvements</b> like building type, quality, size, grade, age, parking, and
                    security.
                </li>
                <li>
                    <b>Building tenancy</b>, whether owner-occupied or tenanted, tenant details, lease terms,
                    renewals or tenant defaults.
                </li>
                <li>
                    <b>Overall market conditions</b> encompassing macro-economic factors, supply and demand
                    dynamics, and if relevant, area rental rates and vacancy trends.
                </li>
                <li>
                    <b>Comparative Market Analysis</b> (if required), featuring nearby comparable sales or sales in
                    similar investor nodes, similar rentals, and property saleability.
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p class="paragraph light">
                If your property's land use is uncommon or specialized in nature, you might be requested to
                provide us with a formal valuation.
            </p>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12">
            <button class="heading-3 button-light" (click)="closeDialog()">GOT IT</button>
        </div>
    </div>
</div>