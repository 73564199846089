import { ListingLifeCycleState, PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss']
})
export class ListingCardComponent implements OnInit {

  @Input() listing: PropertyListingDto;
  @Input() currentUserIndex: number = 0;
  @Input() users: Array<{ key: number, name: string, role: string, roleInitial: string, roleColor: string }> = [];

  @Output() listingClicked = new EventEmitter<PropertyListingDto>();
  @Output() listingCardSelected = new EventEmitter<string>();

  selected: boolean = false;
  surveyorGeneralKey: string = '';

  constructor() { }

  ngOnInit(): void {
    this.surveyorGeneralKey = this.listing.surveyorGeneralKey;
  }

  selectListingForMultiple() {
    this.selected = !this.selected;
    this.listingCardSelected.emit(this.listing.id);
  }

  archiveListing() {
    this.listing.lifeCycleState = ListingLifeCycleState.Archived;
  }

  unarchiveListing() {
    this.listing.lifeCycleState = ListingLifeCycleState.Assigned;
  }

  toggleSelectListing() {
    this.selected = !this.selected;
    this.listingCardSelected.emit(this.listing.id);
  }

  getArchiveButtonText() {
    return this.listing.lifeCycleState === ListingLifeCycleState.Archived ? 'Unarchive Listing' : 'Archive Listing';
  }

  getSelectButtonText() {
    return this.selected ? 'Deselect Listing' : 'Select Listing';
  }

  goToListingOverview(): void {
    this.listingClicked.emit(this.listing);
  }

  getCurrentUser(): any {
    let adjustedIndex = (this.currentUserIndex - 1) % this.users.length;
    return this.users[adjustedIndex];
  }
}
