import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { OfferToPurchaseConditionsVm } from 'src/app/services/property-matrix/models';

@Component({
  selector: 'app-property-for-sale-form-conditions',
  templateUrl: './property-for-sale-form-conditions.component.html',
  styleUrls: ['../../../../../css/2-modules/_forms.scss']
})
export class PropertyForSaleFormConditionsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() offerToPurchaseConditions = new EventEmitter<OfferToPurchaseConditionsVm>();

  awaitingCurrentPropertySale: number = 0;
  pm_AssistWithSellingOtherProperty: number = 0;
  knowConditionsToInclude: number = 0;

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  knowConditionsToIncludeRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I need professional input/advice to formulate conditions.' }
  ];

  propertySaleTimeframeRadioOptions: KeyValuePair[] = [
    { key: 1, value: '30' },
    { key: 2, value: '45' },
    { key: 3, value: '60' },
    { key: 4, value: '90' },
    { key: 5, value: 'If more than 90 days – please specify and explain reason:' },
  ]

  form: OfferToPurchaseConditionsVm = {
    assistWithSale: false,
    awaitingCurrentPropertySale: false,
    conditionConcernsDescription: '',
    conditionsDespcription: '',
    timeFrame: null,
    timeFrameReason: '',
    knowConditionsToInclude: false,
    physicalAddress: {
      city: '',
      complexName: '',
      postalCode: '',
      streetName: '',
      suburb: '',
      unitNumber: '',
      streetNumber: '',
    }
  }

  formWithConditions: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.getFormType();
  }

  submitForm() {
    this.form.awaitingCurrentPropertySale = this.awaitingCurrentPropertySale == 1
    this.form.knowConditionsToInclude = this.knowConditionsToInclude == 1
    this.form.assistWithSale = this.pm_AssistWithSellingOtherProperty == 1
    this.offerToPurchaseConditions.emit(this.form);
    this.pageNumberEvent.emit(3);
    GlobalMethods.scrollToTop();
  }

  getFormType() {
    this.formWithConditions = sessionStorage.getItem('offerType') == 'offerWithConditions';
  }
}
