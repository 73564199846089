import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { SacplanRegistrationDto, UrbanPlannerDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, EventEmitter, Output, ChangeDetectorRef, Inject } from '@angular/core';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, catchError, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PlannerComponent {

  passwordPattern: string = GlobalConstants.passwordPattern;
  emailPattern: string = GlobalConstants.emailPattern;
  loading: boolean = false;
  registrationTypes: SacplanRegistrationDto[] = [];
  sacPlanRegNumber: FormControl;
  registrationSub: Subscription;
  isProfessional: boolean = null;
  form: UserDto = {
    urbanPlanner: {
      sacPlanRegNumber: '',
      registrationType: null,
    }
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public lookupService: LookupService,
    private notificationService: NotificationService,
    private registrationService: RegistrationService,
    private sharedService: PropertyMatrixSharedService
  ) { }

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() UrbanPlannerDetail = new EventEmitter<UrbanPlannerDto>();

  ngOnInit(): void {
    const user = this.sharedService.getUserModel();
    this.loadRegistrationTypes();
    if (user) {
      this.router.navigateByUrl('/collaboration-agreement')
    }
    this.sacPlanRegNumber = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Z]\d{4}\/(19|20)\d{2}$/)
    ]);
  }

  loadRegistrationTypes() {
    this.lookupService.apiV1LookupGetUrbanPlannerRegistrationTypesGet()
      .pipe(
        catchError((err) => {
          return of(
            this.notificationService.showErrorMessage(
              'Error',
              'Error loading registration types'
            )
          );
        })
      )
      .subscribe((res) => {
        if (res != null) {
          this.registrationTypes = res as SacplanRegistrationDto[]
          this.registrationTypes.sort((a, b) => a.intValue - b.intValue);
        }
      });
  }

  SelectRegistrationType(event: any) {
    this.form.urbanPlanner.registrationType = this.registrationTypes.find(x => x.id == event);
    if (this.form.urbanPlanner.registrationType.value == 'Professional Planner') {
      this.isProfessional = true;
    } else {
      this.isProfessional = false;
    }
  }

  ngAfterViewInit(): void { }

  Proceed() {
    this.form.urbanPlanner.sacPlanRegNumber = this.sacPlanRegNumber.value;
    if (this.form.urbanPlanner.registrationType == null || this.sacPlanRegNumber.value == '') {
      GlobalMethods.tinyErrorAlert('Error', 'Please ensure all required fields are completed');
      return;
    }
    if (this.isProfessional == false) {
      GlobalMethods.tinyErrorAlert('Error', 'Sacplan registration must be professional');
      return;
    }
    const dialogRef = this.dialog.open(CollaborationDialog, {
      data: {
        form: this.form,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.registrationService.setUserEnrollmentData(this.form);
      this.router.navigate(['register']);
    });
  }
}

@Component({
  selector: 'collaboration-dialog',
  templateUrl: './collaboration-agreement.html',
  styleUrls: ['./planner.component.scss', '../../../../css/2-modules/_forms.scss'],
})

export class CollaborationDialog {
  constructor(
    public dialogRef: MatDialogRef<CollaborationDialog>,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService,
    @Inject(MAT_DIALOG_DATA) public data: CollaborationDialog,
  ) {
  }

  Agree() {
    this.dialogRef.close();
  }
}