import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteAccessDto } from 'src/app/services/property-matrixV2/models';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-site-access',
  templateUrl: './site-access.component.html',
  styleUrls: ['./site-access.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class SiteAccessComponent implements OnInit {

  @Input() addendumOneId: string;
  @Output() siteAccessChange = new EventEmitter<SiteAccessDto[]>();

  siteAccessData: SiteAccessDto[] = [];

  file: any;
  fileId: string = null;
  fileUrls: string[] = [];
  hideButtons: boolean = false;

  maxSiteAccesses: number = 9;

  constructor(
    private reportingService: ReportingService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadSiteAccessDetails();
  }

  async setFile(event: { target: { files: any[]; }; }, index: number): Promise<void> {
    try {
      this.fileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.siteAccessData[index].imageUrl = this.fileId;
      this.emitChanges();

      this.file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrls[index] = event.target.result;
        this.hideButtons = true;
      }

      reader.readAsDataURL(this.file);
    } catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  async loadFile(index: number): Promise<void> {
    const siteAccess = this.siteAccessData[index];
    if (siteAccess.imageUrl) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(siteAccess.imageUrl);
        this.fileUrls[index] = fileUrl;
        this.hideButtons = !!fileUrl;
        this.emitChanges();
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async loadAllFiles(): Promise<void> {
    for (let i = 0; i < this.siteAccessData.length; i++) {
      await this.loadFile(i);
    }
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createSiteAccess(): void {
    const newSiteAccess: SiteAccessDto = {
      id: uuidv4(),
      imageDescription: '',
      imageName: '',
      imageUrl: '',
      name: ''
    };
    this.addItemToArray(this.siteAccessData, newSiteAccess);
  }

  addSiteAccess(): void {
    if (this.siteAccessData.length < this.maxSiteAccesses) {
      this.createSiteAccess();
    } else {
      this.notificationService.showWarningMessage('Site Access Limit Reached', 'You cannot add more than ' + this.maxSiteAccesses + ' site accesses.');
    }
  }

  removeSiteAccess(index: number): void {
    this.removeItemFromArray(this.siteAccessData, index);
    this.fileUrls.splice(index, 1);
  }

  loadSiteAccessDetails(): void {
    this.planningReportService.apiV1PlanningReportGetSiteAccessesGet({
      addendumOneId: this.addendumOneId
    }).subscribe({
      next: async (response) => {
        this.siteAccessData = response;
        if (this.siteAccessData === null || this.siteAccessData.length === 0) {
          this.createSiteAccess();
        } else {
          await this.loadAllFiles();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load site accesses.');
      }
    });
  }

  emitChanges(): void {
    this.siteAccessChange.emit(this.siteAccessData);
  }
}
