import Swal from "sweetalert2";
import { FileModel } from "../../app/models/FileModel";
import { GlobalMethods } from "../common/global-methods";
import { FileTypeDto } from "../services/property-matrixV2/models";

export class FileUploader {

    files: FileModel[] = [];

    /**
 * Convert Files list to normal array list
 * @param files (Files List)
 */
    prepareFilesList(files: Array<any>, maxUpload?: number, fileId?: number, propertyNumber?: number, fileType?: FileTypeDto) {
        if (maxUpload != null && files.length > maxUpload) {
            GlobalMethods.tinyErrorAlert("File Upload Exceeded", "Maximum of two files can be uploaded");
        } else {
            for (const item of files) {
                if (files.length > 1) {
                    GlobalMethods.tinyErrorAlert("File Upload Exceeded", "Maximum of two files can be uploaded");
                }
                else {
                    const file: FileModel = {
                        file: item,
                        id: fileId,
                        progress: 0,
                        propertyNumber: propertyNumber,
                        fileType: fileType
                    }
                    this.files.push(file);
                }
            }
        }
    }

    /**
   * on file drop handler
   */
    specificOnFileDropped($event, id, propertyNumber?: number, fileType?: FileTypeDto) {
        this.prepareFilesList($event, null, id, propertyNumber);
    }

    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    specificFileBrowseHandler(files, id, propertyNumber?: number, fileType?: FileTypeDto) {
        this.prepareFilesList(files, null, id, propertyNumber);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
 * Simulate the upload process
 */
    uploadFilesSimulator(index: number) {
        setTimeout(() => {
            if (index === this.files.length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (this.files[index].progress === 100) {
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1);
                    } else {
                        this.files[index].progress += 5;
                    }
                }, 25);
            }
        }, 125);
    }

    /**
   * Delete file from files list
   * @param index (File index)
   */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    /**
* format bytes
* @param bytes (File size in bytes)
* @param decimals (Decimals point)
*/
    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}