<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<form id="uploadDocumentsForm" novalidate #uploadDocuments="ngForm"
    class="submission-form">
    <div class="heading-2">
        STEP 4: UPLOAD DOCUMENTS
    </div>
    <div class="heading-3">
        Please upload copies of the following, in PDF format:
    </div>
    <div class="block-wrapper text-center heading-5" *ngIf="loadUploadDocumentBlocks">
        <ng-container *ngFor="let block of uploadBlocks; let i = index">
            <ng-container *ngIf="ownershipDetails.ownershipPersonalDetail.length != 0">
                <div *ngIf="block.fileType.heading == 'Non RSA residents' && !ownershipDetails.ownershipPersonalDetail[0].isRsaResident" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'RSA residents' && ownershipDetails.ownershipPersonalDetail[0].isRsaResident" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'Married in community' && ownershipDetails.ownershipPersonalDetail[0].maritalStatus.value == 'Married in community' && ownershipDetails.ownershipPersonalDetail[0].isSpouseRsaResident" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'If Multiple Properties' && isMultipleProperty" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'If Multiple Tenants' && isMultipleTenants" class="grey-block">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="ownershipDetails.juristicPersonDetail.length != 0">
                <div *ngIf="block.fileType.heading == 'If Juristic Person'" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'If Multiple Properties' && isMultipleProperty" class="grey-block invalid-input">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="block.fileType.heading == 'If Multiple Tenants' && isMultipleTenants" class="grey-block">
                    <ng-container *ngIf="block.file == null">
                        <input type="file" #fileDropRef id="fileDropRef" multiple
                            (change)="selectUpload($event.target.files, block.key)" />
                        <div class="row">
                            <div class="col-12">
                                {{ block.fileType.value }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="block.file != null">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{ block.file.name }}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(block.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="block.file?.progress">
                                </app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(block, i)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="heading-3 mt-3">ADDITIONAL PROPERTY INFORMATION</div>
    <strong class="paragraph">Important note:</strong>
    <ul class="montserrat-bullets paragraph">
        <li>
            It will be beneficial to your investigation if you provide us with
            as much information as possible.
        </li>
        <li>
            Click
            <a class="here" (click)="openDocumentsExampleDialog()">here</a>
            for examples of what we require.
        </li>
        <li>
            Please upload all plans/documents/reports available in PDF format
            and all pictures in PNG or JPEG format.
        </li>
    </ul>
    <div>
        <app-file-upload-multiple></app-file-upload-multiple>
    </div>
    <div class="col-12">
        <div class="checkbox-container">
            <label class="paragraph checkbox-label">
                <strong class="paragraph">
                    I have copies available, but I have trouble scanning and/or
                    uploading it
                </strong>
                <input type="checkbox" name="troubleUploadingCopies"
                    [(ngModel)]="form.troubleUploadingCopies" />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
    <div class="heading-3">
        Consent from industry professionals:
    </div>
    <span class="paragraph">
        Property Matrix respects the intellectual property of other related
        professionals. It is therefore essential
        that you have consent from any professionals you have consulted to:
    </span>
    <span class="paragraph">
        a) sharing their report(s) with Property Matrix,
        <br />
        b) make their report(s) available on our website alongside your property
        advert
        <br />
        c) allow Property Matrix to charge an administrative fee for users to
        download the report
    </span>
    <span class="paragraph">
        Our partnering agent will assist you with correspondence that can be
        forwarded to the relevant professional(s),
        asking for their consent and offering this vehicle (our website) to also
        promote their company and services -
        free of charge.
    </span>
    <section>
        <div class="row text-center">
            <div class="col-12">
                <button id="documentSubmit" [disabled]="uploadDocuments.invalid"
                    class="button heading-4"
                    (click)="submitForm()">
                    SUBMIT
                </button>
            </div>
        </div>
    </section>
</form>