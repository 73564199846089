<main class="dialog-container">
    <div class="dialog-content">
        <div>
            <span class="paragraph bold user-dialog-heading">Tags Key</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">
                    This key explains how website users are tagged
                </span>
            </div>
            <div class="table-container">
                <table class="tags-key-table">
                    <tr *ngFor="let tag of tags">
                        <th class="paragraph">
                            <div [ngStyle]="{'background-color': tag.backgroundColor}">{{ tag.name }}</div>
                        </th>
                        <td class="paragraph">{{ tag.description }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="button-group">
        <button class="footer-button background-coral" (click)="closeDialog()">Confirm</button>
    </div>
</main>