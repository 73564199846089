<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<form id="submitDocumentsForm" novalidate #submitDocumentsForm="ngForm" class="submission-form">
    <section>
        <div class="heading-2 mt-4">STEP 3: SUBMIT DOCUMENTS</div>
        <div class="block-wrapper text-center heading-5 mt-5">
            <div *ngFor="let block of uploadBlocks; let i = index">
                <div class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, block.key)"
                    *ngIf="block.file == null" [ngClass]="{'grey-block-required': block.required}">
                    <input type="file" #fileDropRef id="fileDropRef" multiple
                        (change)="selectUpload($event.target.files, block.key)" />
                    <div class="row">
                        <div class="col-12">{{block.value}}</div>
                        <div class="col-12 paragraph">(Click or drop a file)</div>
                        <div class="col-12">
                            <mat-icon class="file-icon">cloud_upload</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="block.file != null" class="grey-block">
                    <div class="row paragraph">
                        <div class="col-12">
                            <mat-icon class="file-icon"> insert_drive_file</mat-icon>
                        </div>
                        <h4 class="col-12 paragraph bold">
                            {{block.file.name}}
                        </h4>
                        <p class="col-12 heading-5">
                            {{ fileUploader.formatBytes(block.file.size) }}
                        </p>
                        <div class="col-12">
                            <app-progress [progress]="block.file?.progress"></app-progress>
                        </div>
                        <div class="delete col-12" (click)="deleteFile(block, i)">
                            <mat-icon class="delete delete-icon">delete</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="row text-center">
            <div class="col-12 mt-5">
                <button id="btnSaveFinal" [disabled]="checkRequiredItemsUploaded()" class="button heading-4"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>