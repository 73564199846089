<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Predominant Land Use</label>
            <mat-select class="col-12 mt-1" [(value)]="selectedPredominantLandUse"
                (selectionChange)="onPredominantLandUseChange($event)">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let landUse of predominantLandUseData" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Additional Land Use(s)</label>
            <mat-select class="col-12 mt-1" [(value)]="selectedAdditionalLandUse"
                (selectionChange)="onAdditionalLandUseChange($event)">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let landUse of additionalLandUseData" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Surveyor General Erf Extent (m²)</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.surveyorGeneralErfExtent" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Building Footprint Estimate (m²)</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.buildingFootprintEstimate" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Gross Floor Area Estimate</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.grossFloorAreaEstimate" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage Estimate</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.coverageEstimate" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height Estimate: Highest Building</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.heightEstimateHighestBuilding" />
        </div>
    </div>
</main>