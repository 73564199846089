import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-users',
  templateUrl: './listing-users.component.html',
  styleUrls: ['./listing-users.component.scss']
})
export class ListingUsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
