<main>
    <section>
        <div class="row mt-3 no-gutters" *ngIf="hideTopButtonsFlag == false">
            <div class="col-3 px-1" *ngFor="let header of formHeaders">
                <button class="form-section-button active-form-section"
                    [ngClass]="{ 'active-form-section': pageNumber >= header.key }" (click)="selectPage(header.key)"
                    [disabled]="pageNumber <= header.key">
                    <div class="heading-3-light">{{header.value}}</div>
                </button>
            </div>
        </div>
    </section>
    <section>
        <div [hidden]="pageNumber != 1">
            <app-siod-register (pageNumberEvent)="changePage($event)">
            </app-siod-register>
        </div>
        <div [hidden]="pageNumber != 2">
            <app-siod-subscribe (hideTopButtonsEvent)="hideTopButtons($event)" (pageNumberEvent)="changePage($event)">
            </app-siod-subscribe>
        </div>
        <div [hidden]="pageNumber != 3">
            <app-siod-confirm (pageNumberEvent)="changePage($event)">
            </app-siod-confirm>
        </div>
        <div [hidden]="pageNumber != 4">
            <app-siod-add (pageNumberEvent)="changePage($event)"></app-siod-add>
        </div>
    </section>
</main>