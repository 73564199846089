import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor() { }

  showSuccessMessage(title: string, message: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: message,
      confirmButtonColor: '#a5dc86',
    });
  }

  showInfoMessage(title: string, message: string) {
    Swal.fire({
      icon: 'info',
      title: title,
      text: message,
      confirmButtonColor: '#3fc3ee',
    });
  }

  showErrorMessage(title: string, message: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
      confirmButtonColor: '#f27474',
    });
  }

  showWarningMessage(title: string, message: string) {
    Swal.fire({
      icon: 'warning',
      title: title,
      text: message,
      confirmButtonColor: '#f8bb86',
    });
  }

  showConfirmMessage(title: string, message: string, confirmButtonText: string = "Yes", cancelButtonText: string = "No"): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Swal.fire({
        title: title,
        text: message,
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: cancelButtonText,
        cancelButtonColor: "#f8bb86",
        confirmButtonText: confirmButtonText,
        confirmButtonColor: "#87adbd",
      }).then((result) => {
        resolve(result.isConfirmed);
      });
    });
  }
}
