import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaboration-agreement',
  templateUrl: './collaboration-agreement.component.html',
  styleUrls: ['./collaboration-agreement.component.scss']
})
export class CollaborationAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
