<main class="background-image container-fluid">
    <section class="content">
        <form (ngSubmit)="forgotPassword(forgotPasswordForm.value)" [formGroup]="forgotPasswordForm" novalidate
            autocomplete="off">
            <div class="container">
                <div class="row">
                    <h1 class="heading-1">Forget Passord</h1>
                </div>
                <div class="paragraph">
                    <label for="recoveryEmail"></label>
                    <input formControlName="email" type="email" id="recoveryEmail" placeholder="Email Address"
                        class="textbox" name="email">
                    <small class="form-control-feedback">
                        *Must be a valid email address
                    </small>
                </div>
                <div class="row"><button class="button heading-3" type="submit"
                        [disabled]="forgotPasswordForm.invalid">Email me a reset link</button> </div>
            </div>
        </form>
    </section>
</main>