<div *ngFor="let type of billingDetails" class="mt-4">
    <mat-card>
        <mat-card-content>
            <div>
                <div class="row">
                    <div class="col-11">
                        <div class="heading-2">{{type.title}}</div>
                    </div>
                    <div class="col-1 edit-button-margin"><button (click)="editInfo(type.key)"
                            mat-icon-button><mat-icon>edit</mat-icon></button></div>
                </div>
                <div *ngIf="type.key == 1">
                <div class="row pt-3" *ngIf="type.value">
                    <div *ngIf="type.value.unitNo" class="col-12"><b>Building/unit no:</b> {{type.value.unitNo}}
                    </div>
                    <div *ngIf="type.value.complexName" class="col-12"><b>Building/complex name:</b>
                        {{type.value.complexName}}</div>
                    <div class="col-12"><b>Street no:</b> {{type.value.streetNo}}</div>
                    <div class="col-12"><b>Street name:</b> {{type.value.streetName}}</div>
                    <div class="col-12"><b>Suburb:</b> {{type.value.suburb}}</div>
                    <div class="col-12"><b>City:</b> {{type.value.city}}</div>
                </div>
            </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<mat-card class="mt-4">
    <mat-card-content>
        <div>
            <div class="row">
                <div class="col-11">
                    <div class="heading-2">Order History</div>
                </div>
            </div>
            <table mat-table [dataSource]="orderHistory">
                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef>Order Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                </ng-container>
                <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef>Items</th>
                    <td mat-cell *matCellDef="let element"> {{element.item}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="orderHistoryColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: orderHistoryColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>