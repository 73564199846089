<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Scheme Name</label>
            <mat-select class="col-12 mt-1" [(value)]="selectedSchemeName"
                (selectionChange)="onSchemeNameChange($event)">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let scheme of schemeData" [value]="scheme.id">
                    {{ scheme.description }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Current Zoning</label>
            <mat-select class="col-12 mt-1" [(value)]="selectedCurrentZoning"
                (selectionChange)="onCurrentZoningChange($event)" [disabled]="selectedSchemeName === null">
                <ng-container *ngIf="selectedSchemeName !== null">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let zoning of zoningData" [value]="zoning.id">
                        {{ zoning.description }}
                    </mat-option>
                </ng-container>
            </mat-select>
            <mat-error *ngIf="selectedSchemeName === null">Please select a Scheme Name first.</mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Use(s) Permitted On Site</label>
            <mat-select multiple class="col-12 mt-1" [(value)]="selectedUsesPermittedOnSite"
                (selectionChange)="onUsesPermittedOnSiteChange($event)" [disabled]="selectedSchemeName === null">
                <ng-container *ngIf="selectedSchemeName !== null">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let landUse of usesPermittedOnSiteData" [value]="landUse.id">
                        {{ landUse.descriptionLong }}
                    </mat-option>
                </ng-container>
            </mat-select>
            <mat-error *ngIf="selectedSchemeName === null">Please select a Scheme Name first.</mat-error>
        </div>
    </div>
    <div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
        <div>
            <label class="col-12 bold">Approved Additional Use(s)</label>
            <mat-select multiple class="col-12 mt-1" [(value)]="selectedApprovedAdditionalUses"
                (selectionChange)="onApprovedAdditionalUsesChange($event)" [disabled]="selectedSchemeName === null">
                <ng-container *ngIf="selectedSchemeName !== null">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let landUse of approvedAdditionalUseData" [value]="landUse.id">
                        {{ landUse.descriptionLong }}
                    </mat-option>
                </ng-container>
            </mat-select>
            <mat-error *ngIf="selectedSchemeName === null">Please select a Scheme Name first.</mat-error>
        </div>
        <div>
            <label class="col-12 bold">Addendum</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendum 5"
                [(ngModel)]="existingLandUseRightData.additionalRightAddendum" />
        </div>
    </div>
    <div class="paragraph mt-3 gx-2 d-flex flex-row flex-double-first">
        <div>
            <label class="col-12 bold">Current Planning Application</label>
            <input class="col-12 mt-1" type="text" placeholder="General Plan Approved"
                [(ngModel)]="existingLandUseRightData.currentPlanningApplication" />
        </div>
        <div>
            <label class="col-12 bold">Addendum</label>
            <input class="col-12 mt-1" type="text" placeholder="See Addendum 6"
                [(ngModel)]="existingLandUseRightData.currentPlanningApplicationAddendum" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage* Permitted (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="60"
                [(ngModel)]="existingLandUseRightData.coveragePermitted" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height Permitted (storeys)</label>
            <input class="col-12 mt-1" type="number" placeholder="3"
                [(ngModel)]="existingLandUseRightData.heightPermitted" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Floor Area Ratio**</label>
            <input class="col-12 mt-1" type="number" placeholder="1,5"
                [(ngModel)]="existingLandUseRightData.floorAreaRatio" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Density (units/ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="20" [(ngModel)]="existingLandUseRightData.density" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Minimum Erf Size (m²)</label>
            <input class="col-12 mt-1" type="number" placeholder="950"
                [(ngModel)]="existingLandUseRightData.minimumErfSize" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Current Permitted Bulk (m²)</label>
            <input class="col-12 mt-1" type="number" placeholder="800"
                [(ngModel)]="existingLandUseRightData.currentPermittedBulk" />
        </div>
    </div>
</main>