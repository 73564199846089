import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { GenericValidator } from 'src/app/services/generic-validator';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-login-valuers',
  templateUrl: './login-valuers.component.html',
  styleUrls: ['./login-valuers.component.scss']
})
export class LoginValuersComponent implements OnInit, AfterViewInit {
  // Access every form input fields in our login html file
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  loginForm: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder, private authService: PropertyMatrixSharedService, private router: Router) {
    if (this.authService.userValue) {
      this.router.navigate(['/profile']);
    }
    // Defines all of the validation messages for the form.
    this.validationMessages = {
      email: {
        required: 'Email address is required',
        email: 'This email is invalid'
      },
      password: {
        required: 'Password is required'
      }
    };
    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      password: ['', [Validators.nullValidator]]
    });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });

  }

  login() {
    const val = this.loginForm.value;
    if (val.email && val.password) {
      this.authService.authenticateCreds(val.email, val.password)
        .pipe(first())
        .subscribe({
          next: () => {
            GlobalMethods.tinySuccessAlert("Success", 'Welcome ' + this.authService.userValue.userName + 'You have successfully logged in')
          },
          error: err => {
            GlobalMethods.tinyErrorAlert("Login failed", err);
            this.isLoginFailed = true;
          }
        });
    }

  }
}
