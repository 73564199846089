<img class="home-page-video w-100" src="/assets/images/home/cover-image.png" alt="VideoLoop">
<div class="container-fluid">
    <footer class="row justify-content-between align-items-center">
        <div class="col-12 d-flex justify-content-center p-3">
            <span class="heading-2-light">
                Development and Commercial Property Specialists
            </span>
        </div>
    </footer>
</div>
