export class UrbanPlannerDetail {
    constructor(
        public firstName:string = '',
        public lastName:string = '',
        public companyName:string = '',
        public mobileNumber:string = '',
        public confirmMobileNumber:string = '',
        public emailAddress:string = '',
        public confirmEmail:string = '',
        public identitiyPassportNumber:string = '',
        public createPassword:string = '',
        public confirmPassword:string = '',
        public registrationType: string = '',

    ){}
}
