<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main>
    <div class="container-fluid">
        <div class="row ">
            <div class="col-12 heading-1-light text-center py-4">
                GET IN TOUCH
            </div>
            <p class="please-note pb-5 heading-3-light">
                PLEASE NOTE: This is for <u>general enquiries</u>. If you have a specific query regarding a property
                advertised on this website, please submit your
                query/request where the property is advertised.
            </p>
        </div>
        <hr>
        <div class="content">
            <div class="row heading-2 cloud py-4">
                <div class="col-3">SUBMIT QUERY</div>
            </div>
            <div class="row">
                <div class="col-8">
                    <form class="paragraph light" (ngSubmit)="submitPersonalDetails()" id="contactUsForm" novalidate #contactUsForm="ngForm">
                        <section>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblfirstName" for="txtfirstName" class="label">Name:</label>
                                </div>
                                <div class="col-7">
                                    <input id="txtfirstName" required [(ngModel)]="personalDetails.name" name="name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblmobile" for="txtmobile" class="label">Mobile:</label>
                                </div>
                                <div class="col-7">
                                    <input id="txtmobile" required [(ngModel)]="personalDetails.phoneNumber" name="phoneNumber">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblmobileConfirm" for="txtmobileConfirm" class="label">Confirm
                                        Mobile:</label>
                                </div>
                                <div class="col-7">
                                    <input id="txtmobileConfirm" required [(ngModel)]="personalDetails.confirmPhoneNumber"
                                        name="confirmPhoneNumber">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblemailAddress" for="txtemailaddress" class="label">Email
                                        Address:</label>
                                </div>
                                <div class="col-7">
                                    <input id="txtemailaddress" type="email" required [(ngModel)]="personalDetails.emailAddress"
                                        name="email">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblConfirmEmail" for="txtConfirmEmail" class="label">Confirm
                                        Email:</label>
                                </div>
                                <div class="col-7">
                                    <input id="txtConfirmEmail" required type="email" [(ngModel)]="personalDetails.confirmEmail"
                                        name="confirmEmail">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label id="lblQuery" for="drpQuery" class="label">Query to:</label>
                                </div>
                                <div class="col-7">
                                    <div class="drop-down" appearance="fill">
                                        <mat-select placeholder="Click to select" class="mySelectClass"
                                            panelClass="myPanelClass" [(ngModel)]="selectedValue" name="selectQueryTo">
                                            <mat-option *ngFor="let query of queries" [value]="query.key" id="drpQuery">
                                                {{query.value}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 display-flex">
                                    <label id="lblMessage" for="txtMessage" class="label">Message:</label>
                                </div>
                                <div class="col-7">
                                    <textarea id="txtMessage" required type="text" [(ngModel)]="queryBody.message" name="message"
                                        rows="2"></textarea>
                                </div>
                            </div>
                            <div class="row lark-neue-font">
                                <div class="col-3">
                                    Upload file:
                                </div>
                                <div class="col-7">
                                    <p>
                                        If you have any document/map available which relates to or can clarify your
                                        query, please upload it <span class="here"><a
                                                (click)="showDiv.upload = !showDiv.upload">here</a></span>.
                                        Two uploads are allowed.
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="showDiv.upload">
                                <div class="col-3"></div>
                                <div class="col-7 border-dashed">
                                    <div *ngIf="files.length < 2">
                                        <div class="text-center" appDragAndDrop
                                            (fileDropped)="onFileDropped($event)">
                                            <input class="remove-choose-file-button" type="file" #fileDropRef
                                                id="fileDropRef" multiple onclick="this.value=null;"
                                                (input)="fileBrowseHandler($event.target.files)" />
                                            <div class="heading-2-light">DRAG & DROP <br>FILE HERE</div>
                                            <div class="paragraph light">or</div>
                                            <label class="button-light heading-3-light" for="fileDropRef">CHOOSE
                                                FILE</label>
                                        </div>
                                    </div>
                                    <div class="files-list" *ngIf="files.length > 0">
                                        <div class="single-file"
                                            *ngFor="let file of files; let i = index">
                                            <div class="row info paragraph light pb-2">
                                                <div class="col-10">
                                                    <div class="name">
                                                        {{ file.name }}
                                                    </div>
                                                    <div class="size">
                                                        {{ fileUploader.formatBytes(file?.size) }}
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="delete" (click)="deleteFile(i)">
                                                        <mat-icon class="delete-icon">delete</mat-icon>
                                                    </div>
                                                </div>
                                                <app-progress [progress]="file?.progress"></app-progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-7 text-center">
                                    <button type="submit" id="btnSave" [disabled]="contactUsForm.invalid"
                                        class="button-light small-button heading-4-light">SEND</button>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
                <div class="col-4 px-5">
                    <div class="mb-5">
                        <div class="heading-3-light text-center">OUR PLEDGE:</div>
                        <p class="text-center cloud paragraph light">
                            We will not spam you with emails or
                            call
                            you non-stop. After you have filled out the form,
                            a <b>Property Matrix</b> professional will reach out to you within the next
                            business
                            day.
                        </p>
                        <br>
                        <p class="text-center cloud paragraph light">
                            Our first objective will be to understand your specific needs. There are no further
                            obligations.
                            Be assured that all information provided will be protected in terms of our privacy policy.
                        </p>
                    </div>
                    <br>
                    <p class="address-border py-3 text-center paragraph light">
                        239 Bronkhorst Street <br> Nieuw Muckleneuk <br> Pretoria, South Africa
                    </p>
                </div>
            </div>
        </div>
    </div>
</main>