import { UploadDocumentsFileReturnVm, ValuerFormVm, ValuerInformationVm } from 'src/app/services/property-matrix/models';
import { UserService, ValuerService } from 'src/app/services/property-matrix/services';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { GlobalMethods } from 'src/app/common/global-methods';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-valuer-register-form',
  templateUrl: './valuer-register-form.component.html',
  styleUrls: ['./valuer-register-form.component.scss']
})
export class ValuerRegisterFormComponent implements OnInit {

  pageNumber: number = 1;
  loading: boolean = false;
  user: any = null;

  form: ValuerFormVm = {
    paths: null,
    username: '',
    valuerInformation: null
  }

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'INFORMATION' },
    { key: 2, value: 'SUBMIT DOCUMENTS' },
    { key: 3, value: 'SIGN CONTRACT' },
  ];

  constructor(
    private router: Router,
    private userService: UserService,
    private valuerService: ValuerService,
    private sharedService: PropertyMatrixSharedService
  ) { }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
    GlobalMethods.scrollToTop();
    sessionStorage.removeItem('originalUrl');
    this.user = sessionStorage.getItem('user');
    if (!this.user) {
      this.router.navigateByUrl('/login')
    } else {
      this.isNormalUser();
    }
  }

  submitForm() {
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.valuerService.apiV1ValuerSaveValuerDetailsPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all require fields are completed')
              .then(result => {
                this.pageNumber = 2;
              });
          }
          this.loading = false;
          this.addOrRemoveBackgroundImage();
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all require fields are completed')
            .then(result => {
              this.pageNumber = 2;
            });
          this.loading = false;
        }
      });
  }

  assignValuerInformation($event: ValuerInformationVm) {
    this.form.valuerInformation = $event;
  }

  assignFiles($event: UploadDocumentsFileReturnVm[]) {
    this.form.paths = $event;
    this.addOrRemoveBackgroundImage();
    this.submitForm();
  }

  selectPage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  changePage($event: number) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
    this.addOrRemoveBackgroundImage();
  }

  addOrRemoveBackgroundImage() {
    if (this.pageNumber == 3) {
      document.getElementById('main').classList.add('background-image');
    } else {
      document.getElementById('main').classList.remove('background-image');
    }
  }

  isNormalUser() {
    const userModel = JSON.parse(JSON.parse(this.user));

    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Valuer')
              .then(result => {
                this.router.navigateByUrl('');
              });
          }
          this.loading = false;
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Valuer')
            .then(result => {
              this.router.navigateByUrl('');
            });
          this.loading = false;
        }
      });
  }
}
