<div class="heading-2">
    <div class="row">
        <div class="col-10">Edit {{data.data.title}}</div>
        <div class="col-2 text-end"><button mat-icon-button
                (click)="closeDialogWithNoChanges()"><mat-icon>close</mat-icon></button></div>
    </div>
</div>
<section>
<div class="text-center heading-5 mt-2">
    <div class="row">
    <div *ngFor="let block of uploadBlocks; let i = index" class="col-2 mb-3">
        <div class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, block.key)"
            *ngIf="block.file == null" [ngClass]="{'grey-block-required': block.required}">
            <input type="file" #fileDropRef id="fileDropRef" multiple
                (change)="selectUpload($event.target.files, block.key)" />
            <div class="row">
                <div class="col-12">{{block.value}}</div>
                <div class="col-12 paragraph">(Click or drop a file)</div>
                <div class="col-12">
                    <mat-icon class="file-icon">cloud_upload</mat-icon>
                </div>
            </div>
        </div>
        <div *ngIf="block.file != null" class="grey-block">
            <div class="row paragraph">
                <div class="col-12">
                    <mat-icon class="file-icon">insert_drive_file</mat-icon>
                </div>
                <h4 class="col-12 paragraph bold">
                    {{block.file.name}}
                </h4>
                <p class="col-12 heading-5">
                    {{ fileUploader.formatBytes(block.file.size) }}
                </p>
                <div class="col-12">
                    <app-progress [progress]="block.file?.progress"></app-progress>
                </div>
                <div class="delete col-12" (click)="deleteFile(block, i)">
                    <mat-icon class="delete delete-icon">delete</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="text-center mt-4">
    <button *ngIf="!loading" (click)="saveInfo()" class="button">Save</button>
    <button style="text-align: -webkit-center" class="button-loader" *ngIf="loading" class="button"><mat-spinner diameter="35"></mat-spinner></button>
</div>
</section>