<div class="p-3 popup-colors">
    <h1 class="heading-1-light text-center mt-2">
        Disclaimer
    </h1>
    <div class="m-4 mt-2">
        <div class="row">
            <div class="col-12 paragraph light text-center">
                In the event that you decide to appoint your own conveyancers, please be aware that Property Matrix
                cannot guarantee the quality of conveyancing work or the estimated turnaround times. In the absence of
                an established relationship, conveyancers may not have knowledge of our platform, workflow, and network
                partners.
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button id="btnAgree" class="button-light heading-4-light" (click)="agree()">GOT IT</button>
            </div>
        </div>
    </div>
</div>