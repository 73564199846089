<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="container">
        <mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.id }}. {{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="!section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
                <app-servitude [addendumTwoId]="addendumTwoId" (servitudeChange)="handleServitudeChange($event)">
                </app-servitude>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Extent: Servitudes</label>
                        <input class="col-12 mt-1" type="number" [(ngModel)]="addendumTwoData.totalServitudeExtent" />
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
                <app-sg-diagram-and-plan [addendumTwoId]="addendumTwoId"
                    (diagramAndPlanChange)="handleDiagramAndPlanChange($event)">
                </app-sg-diagram-and-plan>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
                <app-addendum-two-reference [addendumTwoId]="addendumTwoId"
                    (referenceChange)="handleReferenceChange($event)">
                </app-addendum-two-reference>
            </div>
        </mat-card>
    </div>
    <div class="button-group mb-3">
        <button (click)="goToAddendumOne()" class="footer-button background-cloud">Previous</button>
        <button (click)="saveAddendumTwoDetails()" class="footer-button background-coral">Save</button>
        <button (click)="goToAddendumThree()" class="footer-button background-green">Completed</button>
    </div>
    <mat-card class="floating-card">
        <button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
            <mat-icon class="material-icons-outlined">info</mat-icon>
        </button>
        <button class="icon-button" matTooltip="Save Addendum Two" (click)="saveAddendumTwoDetails()">
            <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
    </mat-card>
</main>