import { PotentialConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-potential-construction-scope',
  templateUrl: './potential-construction-scope.component.html',
  styleUrls: ['./potential-construction-scope.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class PotentialConstructionScopeComponent implements OnInit, OnChanges {

  @Input() potentialConstructionScopeData: PotentialConstructionScopeDto;
  @Input() potentialUsableProperty: number;

  squareMetersConversion: number = 10000;

  constructor() { }

  ngOnInit(): void {
    this.calculateValues();
  }

  ngOnChanges(): void {
    this.calculateValues();
  }

  calculateValues(): void {
    if (this.potentialConstructionScopeData) {

      /**
       * Calcs Section 6: Maximum Potential Development
       */

      // Open space per hectare of usable space required (potentialUsableProperty * potentialOpenSpaceRequired)
      this.potentialConstructionScopeData.potentialMaximumOpenSpaceRequired = Math.max(0, parseFloat((this.potentialUsableProperty * this.potentialConstructionScopeData.potentialOpenSpaceRequired).toFixed(2)));

      // Maximum units calculated on usable space (rounded) (potentialUsableProperty * potentialDensity)
      this.potentialConstructionScopeData.potentialMaximumUnitsOnUsableSpace = Math.max(0, Math.round(this.potentialUsableProperty * this.potentialConstructionScopeData.potentialDensity));

      // Maximum m² based on FAR ((potentialUsableProperty * potentialFloorAreaRatio) * 10000)
      this.potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, parseFloat(((this.potentialUsableProperty * this.potentialConstructionScopeData.potentialFloorAreaRatio) * this.squareMetersConversion).toFixed(2)));

      // Maximum m² based on coverage & height ((potentialUsableProperty * potentialHeight *  potentialCoverage) * 10000)
      this.potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, parseFloat(((this.potentialUsableProperty * this.potentialConstructionScopeData.potentialHeight * this.potentialConstructionScopeData.potentialCoverage) * this.squareMetersConversion).toFixed(2)));

      // Average size if maximum units are built (potentialMaximumSquareMetersBasedOnCoverageAndHeight / potentialMaximumUnitsOnUsableSpace)
      this.potentialConstructionScopeData.potentialMaximumUnitsAverageSize = Math.max(0, parseFloat((this.potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnCoverageAndHeight / this.potentialConstructionScopeData.potentialMaximumUnitsOnUsableSpace).toFixed(2)));

      /**
       * Calcs Section 7: Space For Parking
       */

      // Parking: per unit (potentialMaximumUnitsOnUsableSpace * potentialParkingSpaces)
      this.potentialConstructionScopeData.potentialParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.potentialConstructionScopeData.potentialParkingSpaces * this.potentialConstructionScopeData.potentialMaximumUnitsOnUsableSpace).toFixed(2)));

      // Visitors parking/unit (potentialVisitorsParkingSpaces * potentialMaximumUnitsOnUsableSpace)
      this.potentialConstructionScopeData.potentialVisitorsParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.potentialConstructionScopeData.potentialVisitorsParkingSpaces * this.potentialConstructionScopeData.potentialMaximumUnitsOnUsableSpace).toFixed(2)));

      // Parking: per 100m² ((potentialMaximumSquareMetersBasedOnFloorAreaRatio / 100) * potentialParkingSpacesPerHundredSquareMeters)
      this.potentialConstructionScopeData.potentialParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, parseFloat(((this.potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnFloorAreaRatio / 100) * this.potentialConstructionScopeData.potentialParkingSpacesPerHundredSquareMeters).toFixed(2)));

      // Total parking requirement for maximum development (potentialParkingSpacesWithMaxRights + potentialParkingSpacesPerHundredSquareMetersWithMaxRights)
      this.potentialConstructionScopeData.potentialTotalParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.potentialConstructionScopeData.potentialParkingSpacesWithMaxRights + this.potentialConstructionScopeData.potentialParkingSpacesPerHundredSquareMetersWithMaxRights).toFixed(2)));

      // Potential parking spaces available on vacant land with max rights ((100% - potentialCoverage) * ((potentialUsableProperty - potentialMaximumOpenSpaceRequired) * 10000) / 23)
      this.potentialConstructionScopeData.potentialParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, parseFloat(((1 - this.potentialConstructionScopeData.potentialCoverage) * ((this.potentialUsableProperty - this.potentialConstructionScopeData.potentialMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23).toFixed(2)));
    }
  }
}
