import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Router } from '@angular/router';

import { ValueAssessmentFactorsDialogComponent } from './sell-your-property-dialogs/value-assessment-factors-dialog/value-assessment-factors-dialog.component';
import { FormalValuationReportDialogComponent } from './sell-your-property-dialogs/formal-valuation-report-dialog/formal-valuation-report-dialog.component';

export interface CommissionModel {
  priceRange: string;
  percentage: string;
}

@Component({
  selector: 'app-selling-my-property',
  templateUrl: './selling-my-property.component.html',
  styleUrls: ['./selling-my-property.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SellingMyPropertyComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  showButtons: boolean = false;

  officeArray = [
    { header: 'Investigation & Report*', description: 'A professional town planning investigation on your property to generate an advanced planning report**, indicating ways to develop, extend, or improve it.', worthAmount: '(worth 9k)', id: 0, number: 1 },
    { header: 'Value Assessment***', description: 'Inspection and objective assessment of your property, according to property specific parameters, to determine the worth (open market value) thereof.', worthAmount: '(worth 2k - 5k)', id: 1, number: 2 },
    { header: 'Basic Deeds Report', description: 'Title deed investigation and basic deeds report by our expert conveyancers, informing proposed buyers and allowing faster sales.', worthAmount: '(worth 8k)', id: 2, number: 3 },
    { header: 'Advert on Website', description: 'Your property will be advertised on our website and the property report (highlighting the potential) will be made available to all buyers - free of charge.', worthAmount: '(worth 2k - 4k)', id: 3, number: 4 },
    { header: 'Trace Targeted Buyers', description: 'Our database will trace all buyers who indicated an “appetite” for what your property offers. Each will be personally notified of your property being in the market.', worthAmount: '(worth 2k)', id: 4, number: 5 },
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _focusMonitor: FocusMonitor
  ) { }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore1'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore2'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore3'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore4'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore5'));
    GlobalMethods.scrollToTop();
  }

  addOverflow = false;
  isMenuOpen = false;
  showLevel1 = false;
  showLevel2 = false;
  showLevel3 = false;
  showLevel4 = false;
  showLevel5 = false;
  showLevel6 = false;
  hasReadPages = false;
  open = false;
  tabIndex = 0;
  isShow = true;
  page = 1;

  openSideNav(index: number, showButtons: boolean) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = index == 0;
    this.showLevel2 = index == 1;
    this.showLevel3 = index == 2;
    this.showLevel4 = index == 3;
    this.showLevel5 = index == 4;
    this.showLevel6 = false;
    this.addOverflow = false;
    this.sidenav.open();
    this.tabIndex = index;
    this.showButtons = showButtons;
  }

  openSideOtherNav(index: number, showButtons: boolean) {
    this.open = true;
    this.isShow = true;
    this.showLevel1 = false;
    this.showLevel2 = false;
    this.showLevel3 = false;
    this.showLevel4 = false;
    this.showLevel5 = false;
    this.showLevel6 = index == 5;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
    this.showButtons = showButtons;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    this.addOverflow = false
  }

  closeNav() {
    this.close();
  }

  continueToForms() {
    this.router.navigate(['selling-my-property/capture']);
  }

  continue(page: number) {
    this.page = page;
    GlobalMethods.scrollToTop();
  }

  openValueAssessmentFactorsDialog() {
    const dialogRef = this.dialog.open(ValueAssessmentFactorsDialogComponent, {
      width: '50vw',
      height: '85vh'
    })
  }

  openFormalValuationReportDialog() {
    const dialogRef = this.dialog.open(FormalValuationReportDialogComponent, {
      width: '50vw',
      height: '85vh'
    });
  }
}
