<div class="table-popup-color">
    <section *ngIf="data.showDelete == true">
        <main class="dialog-container">
            <div class="dialog-content">
                <div>
                    <span class="paragraph bold user-dialog-heading">Remove Owner/Co-Owner</span>
                    <div class="row-container pt-1 pb-1">
                        <span class="paragraph user-dialog-subheading">                           
                            Are you sure you want to delete Co-owner
                            {{ data.coOwner.orderNumber }}
                            ({{ data.coOwner.fullNames }}
                            {{ data.coOwner.surname }})?
                        </span>
                    </div>
                </div>
            </div>
            <div class="button-group">
                <button class="footer-button background-cloud" (click)="onNoClick()">Cancel</button>
                <button class="footer-button background-coral" (click)="deleteNaturalPerson()">Confirm</button>
            </div>
        </main>
    </section>
    <section *ngIf="data.showDelete == false" class="co-owner-details">
        <form id="ownershipForm" novalidate #ownershipForm="ngForm">
            <section class="submission-form">
                <div class="heading-2">
                    <div class="row">
                        <div class="col-12 pb-3 text-center">
                            ADD OWNER
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblCoOwnerPersonalName"
                            for="txtCoOwnerPersonalName" class="paragraph">
                            Full names:
                        </label>
                    </div>
                    <div class="col-9">
                        <input type="text" placeholder="Mandatory"
                            id="txtCoOwnerPersonalName" class="paragraph"
                            name="coOwnerName"
                            [(ngModel)]="data.coOwner.fullNames" required
                            #coOwnerName="ngModel"
                            [ngClass]="{'invalid-input': coOwnerName.invalid, 'valid-input': coOwnerName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblCoOwnerPersonalSurname"
                            for="txtCoOwnerPersonalSurname"
                            class="paragraph">
                            Surname:
                        </label>
                    </div>
                    <div class="col-9">
                        <input type="text" placeholder="Mandatory"
                            id="txtCoOwnerPersonalSurname" class="paragraph"
                            name="coOwnerSurname"
                            [(ngModel)]="data.coOwner.surname" required
                            #coOwnerSurname="ngModel"
                            [ngClass]="{'invalid-input': coOwnerSurname.invalid, 'valid-input': coOwnerSurname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblCoOwnerPersonalMobile"
                            for="txtCoOwnerPersonalMobile"
                            class="paragraph">
                            Mobile:
                        </label>
                    </div>
                    <div class="col-9">
                        <input type="text" placeholder="Mandatory"
                            id="txtCoOwnerPersonalMobile"
                            name="coOwnerPhoneNumber" class="paragraph"
                            [(ngModel)]="data.coOwner.mobileNumber" required
                            #coOwnerPhoneNumber="ngModel" minlength="10"
                            [ngClass]="{'invalid-input': coOwnerPhoneNumber.invalid, 'valid-input': coOwnerPhoneNumber.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(coOwnerPhoneNumber.errors && (coOwnerPhoneNumber.dirty || coOwnerPhoneNumber.touched)) && coOwnerPhoneNumber.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblCoOwnerPersonalEmailAddress"
                            for="txtCoOwnerPersonalEmailaddress"
                            class="paragraph">
                            Email Address:
                        </label>
                    </div>
                    <div class="col-9">
                        <input type="text" placeholder="Mandatory"
                            id="txtCoOwnerPersonalEmailaddress"
                            name="coOwnerEmailAddress" class="paragraph"
                            [(ngModel)]="data.coOwner.emailAddress"
                            required #coOwnerEmailAddress="ngModel"
                            [pattern]="emailPattern"
                            [ngClass]="{'invalid-input': coOwnerEmailAddress.invalid, 'valid-input': coOwnerEmailAddress.valid}">
                        <small class="form-control-feedback"
                            *ngIf="coOwnerEmailAddress.errors && (coOwnerEmailAddress.dirty || coOwnerEmailAddress.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblCoOwnerPersonalConfirmEmail"
                            for="txtCoOwnerPersonalConfirmEmail"
                            class="paragraph">
                            Confirm Email:
                        </label>
                    </div>
                    <div class="col-9">
                        <input type="text" placeholder="Mandatory"
                            id="txtCoOwnerPersonalConfirmEmail"
                            name="coOwnerConfirmEmail" class="paragraph"
                            required #coOwnerConfirmEmail="ngModel"
                            [pattern]="emailPattern"
                            [(ngModel)]="data.coOwner.confirmEmail"
                            [ngClass]="{'invalid-input': (coOwnerEmailAddress.value != coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched), 'valid-input': (coOwnerEmailAddress.value == coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched)}">
                        <small class="form-control-feedback"
                            [ngClass]="{'confirmEmail-invalid': (coOwnerEmailAddress.value != coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched), 'confirmEmail-valid': coOwnerEmailAddress.value == coOwnerConfirmEmail.value}">
                            *Confirm Email does not match Email Address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="heading-5">
                        <span class="burnt-red">|</span>
                        <span>
                            Are you, as owner/co-owner, a resident of the
                            Republic of South Africa?
                        </span>
                    </div>
                    <div>
                        <mat-radio-group (change)="onRsaResidentChange($event)">
                            <mat-radio-button class="paragraph" value="true">
                                Yes
                            </mat-radio-button>
                            <mat-radio-button class="paragraph" value="false">
                                No
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">
                            <span *ngIf="data.coOwner.isRsaResident == true">
                                Identity Number:
                            </span>
                            <span *ngIf="data.coOwner.isRsaResident == false">
                                Passport Number:
                            </span>
                        </label>
                    </div>
                    <div class="col-9">
                        <input required class="paragraph"
                            type="text"
                            name="IdNumber"
                            minlength="9"
                            placeholder="Mandatory"
                            #IdNumber="ngModel"
                            [(ngModel)]="data.coOwner.idNumber"
                            [ngClass]="{'invalid-input': IdNumber.invalid, 'valid-input': IdNumber.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(IdNumber.errors && (IdNumber.dirty || IdNumber.touched)) && IdNumber.errors.minlength">
                            *Must must contain at least 9 characters
                        </small>
                    </div>
                </div>
                <p class="paragraph">
                    <strong>
                        Note: Please check spelling and digits carefully.
                    </strong>
                </p>
                <div class="row pb-3">
                    <div class="col-12">
                        <div class="heading-5">
                            <span class="burnt-red">| </span>
                            Marital status of this co-owner?
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-radio-group name="radioCoOwnerMaritalStatus"
                            [(ngModel)]="data.coOwner.maritalStatus">
                            <mat-radio-button class="paragraph"
                                *ngFor="let selectionValue of maritalStatusDto"
                                (change)="resetLegalEntityFields(selectionValue.intValue, selectionValue.id)"
                                [value]="selectionValue.id">
                                {{ selectionValue.value }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </section>
            <section class="spouse-details" *ngIf="maritalIntValue == 3">
                <div class="submission-form">
                    <div class="heading-5">
                        <span>
                            Details of
                        </span>
                        <span class="coral">
                            your spouse
                        </span>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblCoOwnerSpousePersonalName"
                                for="txtCoOwnerSpousePersonalName"
                                class="paragraph">
                                Full names:
                            </label>
                        </div>
                        <div class="col-9">
                            <input type="text" placeholder="Mandatory"
                                id="txtCoOwnerSpousePersonalName"
                                class="paragraph" name="coOwnerSpouseName"
                                [(ngModel)]="data.coOwner.spouseDetail.fullNames"
                                required #coOwnerSpouseName="ngModel"
                                [ngClass]="{'invalid-input': coOwnerSpouseName.invalid, 'valid-input': coOwnerSpouseName.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblCoOwnerSpousePersonalSurname"
                                for="txtCoOwnerSpousePersonalSurname"
                                class="paragraph">
                                Surname:
                            </label>
                        </div>
                        <div class="col-9">
                            <input type="text" placeholder="Mandatory"
                                id="txtCoOwnerSpousePersonalSurname"
                                class="paragraph" name="coOwnerSpouseSurname"
                                [(ngModel)]="data.coOwner.spouseDetail.surname"
                                required #coOwnerSpouseSurname="ngModel"
                                [ngClass]="{'invalid-input': coOwnerSpouseSurname.invalid, 'valid-input': coOwnerSpouseSurname.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblCoOwnerSpousePersonalMobile"
                                for="txtCoOwnerSpousePersonalMobile"
                                class="paragraph">
                                Mobile:
                            </label>
                        </div>
                        <div class="col-9">
                            <input type="text" placeholder="Mandatory"
                                id="txtCoOwnerSpousePersonalMobile"
                                name="coOwnerSpousePhoneNumber"
                                class="paragraph"
                                [(ngModel)]="data.coOwner.spouseDetail.mobileNumber"
                                required
                                #coOwnerSpousePhoneNumber="ngModel"
                                minlength="10"
                                [ngClass]="{'invalid-input': coOwnerSpousePhoneNumber.invalid, 'valid-input': coOwnerSpousePhoneNumber.valid}">
                            <small class="form-control-feedback"
                                *ngIf="(coOwnerSpousePhoneNumber.errors && (coOwnerSpousePhoneNumber.dirty || coOwnerSpousePhoneNumber.touched)) && coOwnerSpousePhoneNumber.errors.minlength">
                                *Mobile number must contain at least the 10
                                numbers
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblCoOwnerSpousePersonalEmailAddress"
                                for="txtCoOwnerSpousePersonalEmailaddress"
                                class="paragraph">
                                Email Address:
                            </label>
                        </div>
                        <div class="col-9">
                            <input type="text" placeholder="Mandatory"
                                id="txtCoOwnerSpousePersonalEmailaddress"
                                name="coOwnerSpouseEmailAddress"
                                class="paragraph"
                                [(ngModel)]="data.coOwner.spouseDetail.emailAddress"
                                required
                                #coOwnerSpouseEmailAddress="ngModel"
                                [pattern]="emailPattern"
                                [ngClass]="{'invalid-input': coOwnerSpouseEmailAddress.invalid, 'valid-input': coOwnerSpouseEmailAddress.valid}">
                            <small class="form-control-feedback"
                                *ngIf="coOwnerSpouseEmailAddress.errors && (coOwnerSpouseEmailAddress.dirty || coOwnerSpouseEmailAddress.touched)">
                                *Must be a valid email address
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblCoOwnerSpousePersonalEmailAddress"
                                for="txtCoOwnerSpousePersonalEmailaddress"
                                class="paragraph">
                                Confirm Email:
                            </label>
                        </div>
                        <div class="col-9">
                            <input type="text" placeholder="Mandatory"
                                id="txtCoOwnerSpousePersonalEmailaddress"
                                [(ngModel)]="data.coOwner.spouseDetail.confirmEmail"
                                name="coOwnerSpouseConfirmEmailAddress"
                                class="paragraph" required
                                #coOwnerSpouseConfirmEmailAddress="ngModel"
                                [pattern]="emailPattern"
                                [ngClass]="{'invalid-input': coOwnerSpouseConfirmEmailAddress.invalid, 'valid-input': coOwnerSpouseConfirmEmailAddress.valid}">
                            <small class="form-control-feedback"
                                *ngIf="coOwnerSpouseConfirmEmailAddress.errors && (coOwnerSpouseConfirmEmailAddress.dirty || coOwnerSpouseConfirmEmailAddress.touched)">
                                *Must be a valid email address
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="heading-5">
                            <span class="burnt-red">|</span>
                            <span>
                                Is your spouse a resident of the Republic of
                                South Africa?
                            </span>
                        </div>
                        <div>
                            <mat-radio-group
                                (change)="onSpouseRsaResidentChange($event)">
                                <mat-radio-button class="paragraph"
                                    value="true">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button class="paragraph"
                                    value="false">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label class="paragraph">
                                <span
                                    *ngIf="data.coOwner.isSpouseRsaResident == true">
                                    Identity Number:
                                </span>
                                <span
                                    *ngIf="data.coOwner.isSpouseRsaResident == false">
                                    Passport Number:
                                </span>
                            </label>
                        </div>
                        <div class="col-9">
                            <input required class="paragraph"
                                type="text"
                                name="IdNumber"
                                minlength="9"
                                placeholder="Mandatory"
                                #IdNumber="ngModel"
                                [(ngModel)]="data.coOwner.idNumber"
                                [ngClass]="{'invalid-input': IdNumber.invalid, 'valid-input': IdNumber.valid}">
                            <small class="form-control-feedback"
                                *ngIf="(IdNumber.errors && (IdNumber.dirty || IdNumber.touched)) && IdNumber.errors.minlength">
                                *Must must contain at least 9 characters
                            </small>
                        </div>
                    </div>
                    <p class="paragraph">
                        <strong>
                            Note: Please check spelling and digits carefully.
                        </strong>
                    </p>
                </div>
            </section>
            <section
                *ngIf="maritalIntValue == 1 || maritalIntValue == 2 || maritalIntValue == 3"
                class="submission-form pb-3" style="width: 50vw;">
                <div class="heading-5"><u>Please confirm</u></div>
                <div class="row">
                    <div class="col-12">
                        <label class="checkbox-container">
                            <span class="paragraph">
                                I have consent from this owner to fill in this
                                form and to provide Property Matrix with
                                information on their behalf. I can confirm that
                                this owner agrees and give permission to sell
                                this property
                            </span>
                            <input type="checkbox"
                                [(ngModel)]="data.coOwner.confirmedConsent"
                                name="coOwnerConfirmPermission"
                                #coOwnerConfirmPermission="ngModel"
                                id="cbCoOwnerConfirmPermission"
                                data-toggle="toggle"
                                (click)="scroll('btnSaveCoOwner')" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="row text-center mt-3">
                        <div class="col-12">
                            <button id="btnSaveCoOwner" mat-button
                                class="button heading-4 mt-2"
                                [disabled]="!(data.coOwner.confirmedConsent && ownershipForm.valid)"
                                [mat-dialog-close]="data"
                                (click)="saveNaturalPerson(ownershipForm.valid)">
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </section>
</div>