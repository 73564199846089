import { ListingGroupStateService } from 'src/app/shared/services/listing-group-state-service/listing-group-state.service';
import { MultiplePropertyListingDto, PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent implements OnInit {

  selectedListing: PropertyListingDto;
  selectedListingGroup: MultiplePropertyListingDto;

  taskNumber: number;
  taskName: string;
  listingType: string;
  listingAddress: string;
  createdDate: string;
  isMultiple: boolean = false;
  showReferenceAndGroup: boolean = false;
  showReportOverview: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listingStateService: ListingStateService,
    private listingGroupStateService: ListingGroupStateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.taskNumber = params['taskNumber'];
      this.taskName = params['taskName'];
      this.listingType = params['listingType'];

      if (this.taskNumber == 1) {
        this.showReferenceAndGroup = true;
      }
      if (this.taskNumber == 8 || this.taskNumber == 13 || this.taskNumber == 14) {
        this.showReportOverview = true;
      }
    });

    if (this.listingType === 'single') {
      this.listingStateService.selectedListing$.subscribe((listing: PropertyListingDto) => {
        this.selectedListing = listing;
        this.listingAddress = listing.addressTitle;
        this.createdDate = listing.createdDate;
        this.isMultiple = false;
      });
    }
    else if (this.listingType === 'multiple') {
      this.listingGroupStateService.selectedListingGroup$.subscribe((group: any) => {
        this.selectedListingGroup = group.value;
        this.listingAddress = group.value.groupName;
        this.createdDate = group.value.createdDate;
        this.isMultiple = true;
      });
    }
    else {
      this.notificationService.showErrorMessage('Error', 'Selected listing/group or ID is undefined.');
    }
  }

  goToListingOverview() {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }
}
