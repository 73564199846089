import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-market-gap-analysis',
  templateUrl: './market-gap-analysis.component.html',
  styleUrls: ['./market-gap-analysis.component.scss']
})
export class MarketGapAnalysisComponent implements OnInit {
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
