import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-one-time-pass-code',
  templateUrl: './one-time-pass-code.component.html',
  styleUrls: ['./one-time-pass-code.component.scss']
})
export class OneTimePassCodeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  register() {
    this.router.navigateByUrl('/estate-agent-register-form');
    GlobalMethods.scrollToTop();
  }
}
