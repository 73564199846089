<main>
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                One Time Passcode
                            </h1>
                        </div>
                    </div>
                    <div class="heading-5 text-center mt-5">
                        Please provide the passcode received via email:
                    </div>
                    <div class="row mt-1 input-container">
                        <div class="text-center">
                            <div class="row">
                                <input type="password" />
                            </div>
                        </div>
                    </div>
                    <div class="button-container mt-5">
                        <button mat-button (click)="register()" class="button-proceed heading-4">Register</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>