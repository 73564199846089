import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPropertyInvestigationLevel3Vm, AddressVm, CheckboxVm, PersonalDetailsVm, TimePreferenceVm } from 'src/app/services/property-matrix/models';
import { GlobalConstants } from 'src/app/common/global-constants';
import { DatePipe } from '@angular/common';
import { NgModel } from '@angular/forms';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdditionalServeService } from 'src/app/services/property-matrix/services';

@Component({
  selector: 'app-level3-investigations',
  templateUrl: './level3-investigations.component.html',
  styleUrls: ['../../../../css/2-modules/_forms.scss']
})

export class Level3InvestigationsComponent {

  emailPattern: string = GlobalConstants.emailPattern;

  loading: boolean = false;

  today: Date = new Date();
  checkDate: CheckboxVm[] = [
    { id: 1, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 2, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 3, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
  ]

  timePreferences: TimePreferenceVm[] = [
    { date: this.checkDate.find(f => f.id == 1), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 2), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 3), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' }
  ]

  personalDetails: PersonalDetailsVm = {
    name: '',
    surname: '',
    landline: '',
    phoneNumber: '',
    alternativeNumber: '',
    emailAddress: '',
    confirmEmail: '',
    emailConfirmed: false,
    id_Number: ''
  }

  propertyDetails: AddressVm = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    propertyDescription: '',
    currentLandUse: ''
  }

  queryBody: AddPropertyInvestigationLevel3Vm = {
    personalDetails: this.personalDetails,
    propertyDetails: this.propertyDetails,
    timePreferenceVMs: this.timePreferences
  }

  constructor(public dialog: MatDialog, private datePipe: DatePipe, private addService: AdditionalServeService) { }

  emailConfirmed: boolean;
  checkValidation(email: NgModel, confirmEmail: NgModel) {
    this.emailConfirmed = GlobalMethods.checkValidation(email, confirmEmail);
  }

  validateTimePreference(): boolean {
    return (this.checkDate.filter(f => f.checked == true).length == 0);
  }

  submit({ value, valid }: { value: AddPropertyInvestigationLevel3Vm, valid: boolean }) {
    this.loading = true
    this.queryBody.timePreferenceVMs = this.timePreferences.filter(f => f.date.checked == true);
    if (valid && this.emailConfirmed) {
      this.addService.apiV1AdditionalServeCapturePropertyInvestigationLevel3Post$Response({ body: this.queryBody }).pipe()
        .subscribe(Response => {
          this.loading = false
          GlobalMethods.tinySuccessAlert("Saved!", "Your details have been submitted!");
        },
          error => {
            this.loading = false
            GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.");
          }
        );
    } else {
      this.loading = false
      GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.")
    }
  }

  clicked: boolean = false;
  openDialog(id: number) {
    if (this.clicked == true) {
      this.clicked = false;
      return;
    }
    this.clicked = true;
    if (this.checkDate[id - 1].checked == false) {
      const dialogRef = this.dialog.open(TimePickerDialogInvestigation, {
        width: '25vw',
        height: '30vh',
        data: { timePreferences: this.timePreferences }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result == null || result.startTime == null || result.endTime == null) {
          GlobalMethods.tinyErrorAlert("Error", "Please select your preferred time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime > result.endTime) {
          GlobalMethods.tinyErrorAlert("Error", "Make sure your start time is before your end time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime < "09:00" || result.endTime > "16:00") {
          GlobalMethods.tinyErrorAlert("Error", "Please choose times between 09:00 and 16:00");
          this.checkDate[(id - 1)].checked = false;
        }
        else if (result) {
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).startTime = this.setDateForAPI(id, result.startTime);
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).endTime = this.setDateForAPI(id, result.endTime);
        }
      });
    }
  }

  setDateForAPI(id: number, time: string): string {
    return ((this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).date).value + 'T' + time + ':00.000Z');
  }
}

@Component({
  selector: 'time-picker-dialog-investigation',
  templateUrl: './time-picker-dialog-investigation.html'
})
export class TimePickerDialogInvestigation {

  constructor(public dialogRef: MatDialogRef<TimePickerDialogInvestigation>,
    @Inject(MAT_DIALOG_DATA) public data: TimePreferenceVm) { }

  closeDialog() {
    if (this.data.startTime == null) { this.dialogRef.close(null) }
    else { this.dialogRef.close(this.data); }
  }
}