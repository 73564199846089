import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planner-register-form-contract',
  templateUrl: './planner-register-form-contract.component.html',
  styleUrls: ['./planner-register-form-contract.component.scss', '../planner-register-form-documents/planner-register-form-documents.component.scss']
})
export class PlannerRegisterFormContractComponent implements OnInit {

  constructor(private router: Router) { }

  submitForm() {
    this.router.navigateByUrl('');
  }

  ngOnInit(): void { }
}
