import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminPortalExtendedNetworkDialogModel } from 'src/app/models/admin-portal-en-dialog-model';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { UrbanPlannerProfessionalVm } from 'src/app/services/property-matrix/models';
import { AdminService } from 'src/app/services/property-matrix/services';
import { AdminMyProfileExtendedNetworkDialogComponent } from './admin-my-profile-extended-network-dialog/admin-my-profile-extended-network-dialog.component';

@Component({
  selector: 'app-admin-my-profile-extended-network',
  templateUrl: './admin-my-profile-extended-network.component.html',
  styleUrls: ['./admin-my-profile-extended-network.component.scss']
})
export class AdminMyProfileExtendedNetworkComponent implements OnInit {

  professionals: UrbanPlannerProfessionalVm[] = [];
  formId: number;

  constructor(private adminService: AdminService, private sharedService: PropertyMatrixSharedService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getExtendedNetworkDetails();
  }

  getExtendedNetworkDetails() {
    let userName = this.sharedService.getUsername();

    this.adminService.apiV1AdminGetExtendedNetworkDetailsGet$Response({ username: userName })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          let body = JSON.parse(result.body);
          this.professionals = body.professionals;
          this.formId = body.formId;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }

  goToUrl() {
    window.open('www.google.co.za', '_blank')
  }

  addProfessional() {
    const dialogRef = this.dialog.open(AdminMyProfileExtendedNetworkDialogComponent, {
      width: '60vw',
      maxHeight: '80vh',
      data: this.getEmptyDataModel(false),
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.professionals.push(result.data);
      }
    });
  }

  deleteProfessional(id: number) {
    this.adminService.apiV1AdminDeleteExtendedNetworkProfessionalPost$Response({ professionalId: id })
      .pipe(map(m => { return m })).subscribe({
        next: () => {
          let idx = this.professionals.findIndex(f => f.id == id);
          this.professionals.splice(idx, 1);
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error deleting data");
        }
      })
  }

  editProfessional(professional: UrbanPlannerProfessionalVm) {
    const dialogRef = this.dialog.open(AdminMyProfileExtendedNetworkDialogComponent, {
      width: '60vw',
      maxHeight: '80vh',
      data: this.getEmptyDataModel(true, professional),
    },);

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getExtendedNetworkDetails();
      }
    });
  }

  getEmptyDataModel(isEdit: boolean, professional: UrbanPlannerProfessionalVm = null): AdminPortalExtendedNetworkDialogModel {
    let model: AdminPortalExtendedNetworkDialogModel;
    if (isEdit == true) {
      model = {
        data: professional,
        edit: isEdit
      }
    } else {
      model = {
        data: {
          linkToWebsite: '',
          profession: '',
          formId: this.formId,
          personalDetails: {
            name: '',
            surname: '',
            companyName: '',
            phoneNumber: '',
            emailAddress: ''
          }
        },
        edit: isEdit
      }
    }

    return model
  }
}