<mat-card class="mt-3">
    <div class="form-group">
        <div class="heading-3 mb-3">
            Option {{ improvementOptionIndex + 1 | numberToWords }}
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Possible Development/Elevation</label>
                <textarea class="col-12 mt-1 text-area" [(ngModel)]="improvementOption.possibleDevelopmentEnhancement"
                    placeholder="Retain existing house and subdivide into full title property, creating 7 additional properties.">
                </textarea>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Planning Application</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="improvementOption.landUseApplication"
                    placeholder="Subdivision & Rezoning" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Time-Frame (±)</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="improvementOption.timeFrame"
                    placeholder="12 Months" />
            </div>
        </div>
    </div>
</mat-card>