<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
    integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14=" crossorigin="" />
<div class="row g-0">
    <div class="col-2 px-2 py-3">
        <div class="row">
            <div class="col-12">
                <div class="heading-3"><u>Refine your search</u></div>
            </div>
            <div class="col-12 mt-1">
                <div *ngFor="let item of searchFilters">
                    <button type="button" class="collapsible mt-2" [id]="item.id" (click)="openSearchPanel(item)">
                        <div class="row">
                            <div class="col-9">
                                <div class="heading-5">{{item.name}}</div>
                            </div>
                            <div class="col-3">
                                <mat-icon class="expansion-icon">
                                    <b>{{item.expanded ? 'remove' : 'add'}}</b>
                                </mat-icon>
                            </div>
                        </div>
                    </button>
                    <div *ngIf="item.expanded == true" class="mt-2">
                        <!-- Location -->
                        <p *ngIf="item.id == 1" class="paragraph filter-text">Select Location on the map</p>
                        <!-- Sector -->
                        <div *ngIf="item.id == 2">
                            <div *ngFor="let option of sectorOptions; let index = i" class="remove-row-gap">
                                <label class="checkbox-container">
                                    <strong class="heading-5 coral-text">{{option.value}}</strong>
                                    <input type="checkbox" [(ngModel)]="option.checked" [name]="mainPurpose"
                                        #sectorOptions="ngModel" required />
                                    <span class="checkmark"></span>
                                    <div class="sector-text ">
                                        <div *ngIf="option.id == 1">
                                            <span class="heading-5">Property with existing
                                                buildings/facilities:</span><span class="paragraph">: ready
                                                to be fitted out, occupied,
                                                renovated, or expanded upon.</span>
                                        </div>
                                        <div *ngIf="option.id == 2">
                                            <span class="heading-5">Land/erven with development
                                                potential:</span><span class="paragraph">: generally vacant
                                                OR with structures that can be
                                                demolished.</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <!-- Type -->
                        <div *ngIf="item.id == 3">
                            <div class="property-type-block">
                                <div class="row g-0">
                                    <div class="col-6">
                                        <div *ngFor="let majorType of propertyTypes.slice(0, 3); let majorIndex = index"
                                            class="paragraph m-1">
                                            <table class="table-border">
                                                <tr>
                                                    <th class="heading-5 text-center">{{majorType.name}}</th>
                                                </tr>
                                                <div class="bottom-border"
                                                    *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                                    <table>
                                                        <tr>
                                                            <td class="d-flex justify-content-between" (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;">
                                                                <b>{{minorType.name}}</b>
                                                                <a>
                                                                    <mat-icon class="expaned-false" *ngIf="minorType.expanded === false">expand_more
                                                                    </mat-icon>
                                                                    <mat-icon class="expaned-true" *ngIf="minorType.expanded === true">expand_less
                                                                    </mat-icon>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                            <td *ngIf="minorType.expanded === true">
                                                                <div class="d-flex justify-content-between">
                                                                    <label [for]="value.id"
                                                                        class="value-name">{{value.name}}</label>
                                                                    <input type="checkbox" [(ngModel)]="value.checked"
                                                                        [id]="value.id" class="property-type-checkbox">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div *ngFor="let majorType of propertyTypes.slice(3); let majorIndex = index"
                                            class="paragraph m-1">
                                            <table class="table-border">
                                                <tr>
                                                    <th class="heading-5 text-center">{{majorType.name}}</th>
                                                </tr>
                                                <div class="bottom-border"
                                                    *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                                    <table>
                                                        <tr>
                                                            <td class="d-flex justify-content-between">
                                                                <b>{{minorType.name}}</b>
                                                                <a *ngIf="minorType.propertyTypeValues.length >= 5"
                                                                    (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;"
                                                                    class="grey-type">
                                                                    <mat-icon *ngIf="minorType.expanded === false">expand_more
                                                                    </mat-icon>
                                                                    <mat-icon *ngIf="minorType.expanded === true">expand_less
                                                                    </mat-icon>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                            <td [hidden]="minorType.expanded === false">
                                                                <div class="d-flex justify-content-between">
                                                                    <label [for]="value.id"
                                                                        class="value-name">{{value.name}}</label>
                                                                    <input type="checkbox" [(ngModel)]="value.checked"
                                                                        [id]="value.id" class="property-type-checkbox">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Price -->
                        <div *ngIf="item.id == 4">
                            <div *ngFor="let option of priceOptions; let index = i">
                                <label class="checkbox-container paragraph mt-1">
                                    <span class="paragraph">
                                        {{option.value}}
                                    </span>
                                    <input type="checkbox" [(ngModel)]="option.checked" [name]="priceOptions"
                                        #priceOptions="ngModel" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <!-- Size: stand -->
                        <div *ngIf="item.id == 5">
                            <div *ngFor="let option of standSizeOptions; let index = i">
                                <label class="checkbox-container paragraph mt-1">
                                    <span class="paragraph">
                                        {{option.value}}
                                    </span>
                                    <input type="checkbox" [(ngModel)]="option.checked" [name]="standSizeOptions"
                                        #standSizeOptions="ngModel" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <!-- Size: bulk -->
                        <div *ngIf="item.id == 6">
                            <div *ngFor="let option of blukSizeOptions; let index = i">
                                <label class="checkbox-container paragraph mt-1">
                                    <span class="paragraph">
                                        {{option.value}}
                                    </span>
                                    <input type="checkbox" [(ngModel)]="option.checked" [name]="blukSizeOptions"
                                        #blukSizeOptions="ngModel" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <!-- More Options -->
                        <div *ngIf="item.id == 7">
                            <span class="paragraph">You can indicate your exact
                                property needs and your selections
                                will enable us to notify you
                                immediately once such a property
                                enters the market. You will also
                                have access to a full planning
                                report, setting out the potential
                                (development options) of the
                                property. Click on <span class="coral">“share in data
                                    base”</span> to place your order via our
                                unique and extensive menu – over
                                100 property types to choose from!</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row pt-3">
                    <div class="col-12 pt-2">This is just for development purposes. Once we have all the flows, this will work as intended</div>
                    <div class="col-12 pt-2"><button class="button" (click)="gotoOptions()">Options</button></div>
                    <div class="col-12 pt-2"><button class="button" (click)="gotoCheckout()">Checkout</button></div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-10">
        <div class="map-container">
                <div id="pfsMap"></div>
            <div id="searchBlock">
                <input type="text" placeholder="Search Suburb" aria-label="Text" [(ngModel)]="mapSearch"
                    (input)="searchSuburbs()" id="searchInput" class="paragraph p-1" [matAutocomplete]="auto">
            </div>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="selectedPlacesOfLoading($event)">
                <mat-option *ngFor="let suburb of suburbs" [value]="suburb.SUBURB" [id]="suburb.id"
                    (click)="selectSuburb(suburb)" class="suburb-list">
                    <span class="paragraph">{{suburb.SUBURB}}</span>
                </mat-option>
            </mat-autocomplete>
            <div id="backButtonBlock">
                <button (click)="backButtonPress()" class="button" id="backButton">
                    <mat-icon>arrow_back_ios</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>