<main>
    <div class="container pt-3 pb-3">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-sm-8 col-xs-6">
                <mat-card>
                    <div class="d-flex justify-content-center align-items-center pb-2">
                        <div class="float-left">
                            <button class="center-content back-button" (click)="goToListingOverview()">
                                <mat-icon class="circle-button">arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="paragraph report-title">
                            {{ taskName ?? 'No task name available' }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center pb-2">
                        <span class="paragraph report-address">
                            {{ listingAddress ?? 'No address available' }}
                        </span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-sm-8 col-xs-6">
                <app-reference-and-group *ngIf="showReferenceAndGroup" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup" [isMultiple]="isMultiple" [createdDate]="createdDate">
                </app-reference-and-group>
                <app-report-overview *ngIf="showReportOverview" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup" [isMultiple]="isMultiple">
                </app-report-overview>
            </div>
        </div>
    </div>
</main>