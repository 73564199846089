import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractDetailDto } from 'src/app/services/property-matrixV2/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';

import { CommissionModel } from '../selling-my-property.component';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class ContractDetailsComponent {

  @Input() ownershipDetailsId: number = 0;

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() contractDetailsEvent = new EventEmitter<ContractDetailDto>();
  @Output() loadUploadBlocksEvent = new EventEmitter<boolean>();

  emailPattern: string = GlobalConstants.emailPattern;
  tableData: CommissionModel[] = GlobalConstants.commissionStructureTableData;

  propertyGovernedByAssociation: number = 0;
  leviesUpToDate: number = 0;
  useInHouseLegalTeam: number = 0;
  recommendedByPartnerAgent: number = 0;
  connectedToUrbanPlanner: number = 0;
  loadUploadDocumentBlocks: boolean = false;

  form: ContractDetailDto = {
    recommendedByPartnerAgent: false,
    connectedToUrbanPlanner: false,
    acceptCommissionStructure: false,
    usePropertyMatrixLegalTeam: false,
    conveyancerDetail: {
      companyName: '',
      contactPerson: '',
      emailAddress: '',
      mobileNumber: '',
      officeNumber: ''
    },
    urbanPlanner: {
      plannerEmail: '',
      plannerMobile: '',
      plannerName: '',
      plannerSurname: '',
    },
  };

  basicYesNoOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  useInHouseLegalTeamOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I wish to appoint my own conveyancer' }
  ];

  recommendedByPartnerAgentOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I discovered Property Matrix myself' }
  ];

  connectedToUrbanPlannerOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, Property Matrix can assign a planner to investigate my property' }
  ];

  constructor(public dialog: MatDialog) { }

  submitForm() {
    if (this.connectedToUrbanPlanner == 1) {
      this.form.connectedToUrbanPlanner == true
    } else {
      this.form.connectedToUrbanPlanner == false
    }

    if (this.recommendedByPartnerAgent == 1) {
      this.form.recommendedByPartnerAgent == true
    } else {
      this.form.recommendedByPartnerAgent == false
    }

    if (this.useInHouseLegalTeam == 1) {
      this.form.usePropertyMatrixLegalTeam == true
    } else {
      this.form.usePropertyMatrixLegalTeam == false
    }
    this.gotoUploadDocuments();
  }

  gotoUploadDocuments() {
    this.loadUploadBlocksEvent.emit(true);
    this.pageNumberEvent.emit(4);
    this.contractDetailsEvent.emit(this.form);
    GlobalMethods.scrollToTop();
  }

  openCollaborationDialog() {
    const dialogRef = this.dialog.open(CollaborationDialog, {
      width: '65vw',
      height: 'auto',
      panelClass: 'align-items',
      data: { agree: false }
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitForm();
      }
    });
  }

  openBenefitsOfLegalTeamDialog() {
    this.dialog.open(BenefitsOfLegalTeamDialog, {
      width: 'auto',
      height: 'auto',
      panelClass: 'mat-dialog-container-dark'
    },);
  }

  openDisclaimerDialog() {
    this.dialog.open(DisclaimerContractDetailsDialog, {
      width: '54vw',
      height: '35vh',
      panelClass: 'mat-dialog-container-dark'
    },);
  }
}

@Component({
  selector: 'contract-details-collaboration-dialog',
  templateUrl: './contract-details-collaboration-dialog.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class CollaborationDialog {
  constructor(
    public dialogRef: MatDialogRef<CollaborationDialog>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agree() {
    this.dialogRef.close({ agree: true });
  }
}

@Component({
  selector: 'contract-details-legalteambenefits-dialog',
  templateUrl: './contract-details-legalteambenefits-dialog.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class BenefitsOfLegalTeamDialog {
  constructor(
    public dialogRef: MatDialogRef<BenefitsOfLegalTeamDialog>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agree() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'contract-details-disclaimer-dialog',
  templateUrl: './contract-details-disclaimer-dialog.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class DisclaimerContractDetailsDialog {
  constructor(
    public dialogRef: MatDialogRef<DisclaimerContractDetailsDialog>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agree() {
    this.dialogRef.close();
  }
}