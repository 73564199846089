import { AuthenticateService, UserService } from 'src/app/services/property-matrix/services';
import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { GenericValidator } from 'src/app/services/generic-validator';
import { LoginVm } from 'src/app/services/property-matrix/models';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  // Access every form input fields in our login html file
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  loginForm: FormGroup;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(
    private sharedService: PropertyMatrixSharedService,
    private fb: FormBuilder,
    private authService: AuthenticateService,
    private router: Router,
    private userService: UserService
  ) {
    // Defines all of the validation messages for the form.
    this.validationMessages = {
      email: {
        required: 'Email address is required',
        email: 'This email is invalid'
      },
      password: {
        required: 'Password is required'
      }
    };
    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    let isLoggedIn = this.sharedService.isLoggedIn();
    if (isLoggedIn) {
      this.router.navigateByUrl('');
      return;
    }
    sessionStorage.setItem("adminNav", "false");
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(800)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });
  }

  login() {
    const val = this.loginForm.value;
    let loginModel: LoginVm = {
      email: val.email,
      password: val.password
    }
    this.isLoggedIn = true;
    this.routeToUrl();
    // if (val.email && val.password) {
    //   this.authService.apiV1AuthenticateLoginPost({ body: loginModel })
    //     .pipe(first())
    //     .subscribe({
    //       next: (result) => {
    //         this.isLoggedIn = true;
    //         sessionStorage.setItem("adminNav", "false");
    //         let userResultJson = JSON.stringify(result);
    //         sessionStorage.setItem('user', userResultJson);
    //         let user = this.sharedService.getUserModel();
    //         sessionStorage.setItem("userRole", user.userRole);
    //         this.routeToUrl();
    //       },
    //       error: err => {
    //         GlobalMethods.tinyErrorAlert('Error', 'Username and password does not match');
    //         this.isLoginFailed = true;
    //       }
    //     });
    // // }
  }

  routeToUrl() {
    let key = 'originalUrl';
    let originalUrl = sessionStorage.getItem(key);
    this.router.navigateByUrl(originalUrl);
    sessionStorage.removeItem(key);
    window.location.reload();
    // if(originalUrl){
    //   this.router.navigateByUrl(originalUrl);
    //   sessionStorage.removeItem(key);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 50);
    // }else{
    //   window.location.reload();
    // }
  }
}
