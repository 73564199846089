import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComingSoonDialogComponent } from '../../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-architectural-technologist',
  templateUrl: './architectural-technologist.component.html',
  styleUrls: ['./architectural-technologist.component.scss']
})
export class ArchitecturalTechnologistComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }
}
