import { ListingLifeCycleState } from 'src/app/services/property-matrixV2/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Output() change = new EventEmitter<{ heading: string, description: string, state: ListingLifeCycleState }>();

  sideMenuIndex: number = 1;

  sideMenuItems: Array<{ key: number, value: string, description: string, icon: string, state: ListingLifeCycleState }> = [
    {
      key: 1,
      value: 'My Tasks',
      description: "The task list is all the listed properties currently assigned to you.",
      icon: "assignment",
      state: ListingLifeCycleState.Assigned
    },
    {
      key: 2,
      value: 'Manage Listings',
      description: "The manage list is all the properties you are over-viewing.",
      icon: "edit",
      state: ListingLifeCycleState.Managed
    },
    {
      key: 3,
      value: 'Published Listings',
      description: "The published list is all properties currently for sale under “Property for Sale”.",
      icon: "cloud_upload",
      state: ListingLifeCycleState.Published
    },
    {
      key: 4,
      value: 'Under Offer',
      description: "The under offer list is all the properties that received an offer to purchase.",
      icon: "sell",
      state: ListingLifeCycleState.UnderOffer
    },
    {
      key: 5,
      value: 'Sold',
      description: "The sold list is all the properties that have been sold.",
      icon: "attach_money",
      state: ListingLifeCycleState.Sold
    },
    {
      key: 6,
      value: 'Archived',
      description: "This is all the properties that has not been processed for publication or that has an expired exclusive mandate.",
      icon: "archive",
      state: ListingLifeCycleState.Archived
    }
  ];

  constructor() { }

  ngOnInit(): void {
    const defaultItem = this.sideMenuItems.find(item => item.key === this.sideMenuIndex);

    if (defaultItem) {
      this.change.emit({ heading: defaultItem.value, description: defaultItem.description, state: defaultItem.state });
    }
  }

  sideMenuChange(item: number): void {
    this.sideMenuIndex = item;
    const selectedItem = this.sideMenuItems.find(itemKey => itemKey.key === item);

    if (selectedItem) {
      this.change.emit({ heading: selectedItem.value, description: selectedItem.description, state: selectedItem.state });
    }
  }
}
