import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { OtherUnusableSpaceDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-other-unusable-space',
  templateUrl: './other-unusable-space.component.html',
  styleUrls: ['./other-unusable-space.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class OtherUnusableSpaceComponent implements OnInit {

  @Input() addendumOneId: string;
  @Output() otherUnusableSpaceChange = new EventEmitter<OtherUnusableSpaceDto[]>();

  otherUnusableSpaceData: OtherUnusableSpaceDto[] = [];
  maxOtherUnusableSpaces: number = 9;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadOtherUnusableSpaceDetails();
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createOtherUnusableSpace(): void {
    const newOtherUnusableSpace: OtherUnusableSpaceDto = {
      id: uuidv4(),
      addendum: '',
      description: '',
      size: 0
    };
    this.addItemToArray(this.otherUnusableSpaceData, newOtherUnusableSpace);
  }

  addOtherUnusableSpace(): void {
    if (this.otherUnusableSpaceData.length < this.maxOtherUnusableSpaces) {
      this.createOtherUnusableSpace();
    } else {
      this.notificationService.showWarningMessage('Other Unusable Space Limit Reached', 'You cannot add more than ' + this.maxOtherUnusableSpaces + ' other unusable spaces.');
    }
  }

  removeOtherUnusableSpace(index: number): void {
    this.removeItemFromArray(this.otherUnusableSpaceData, index);
  }

  loadOtherUnusableSpaceDetails(): void {
    this.planningReportService.apiV1PlanningReportGetOtherUnusableSpacesGet({
      addendumOneId: this.addendumOneId
    }).subscribe({
      next: async (response) => {
        this.otherUnusableSpaceData = response;
        if (this.otherUnusableSpaceData === null || this.otherUnusableSpaceData.length === 0) {
          this.createOtherUnusableSpace();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load other unusable spaces.');
      }
    });
  }

  emitChanges(): void {
    this.otherUnusableSpaceChange.emit(this.otherUnusableSpaceData);
  }
}
