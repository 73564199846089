<div class="heading-2">
    <div class="row">
        <div class="col-10">Edit {{data.data.title}}</div>
        <div class="col-2 text-end"><button mat-icon-button
                (click)="closeDialogWithNoChanges()"><mat-icon>close</mat-icon></button></div>
    </div>
</div>
<!-- Personal Details -->
<div *ngIf="data.editType == 1">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                    [(ngModel)]="data.data.value.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                    [(ngModel)]="data.data.value.surname" required #surname="ngModel"
                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblWorkPhone" for="txtWorkPhone" class="paragraph">Work Phone:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtWorkPhone" name="workPhone" class="paragraph not-required"
                    [(ngModel)]="data.data.value.alternativeMobile">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber" class="paragraph"
                    [(ngModel)]="data.data.value.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                    class="paragraph" [(ngModel)]="data.data.value.emailAddress" required #emailAddress="ngModel"
                    [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                    <span>Identity Number/Passport Number:</span>
                </label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number" class="paragraph"
                    [(ngModel)]="data.data.value.id_Number" required #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                'valid-input': id_Number.valid}">
                <small class="form-control-feedback"
                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                    *Must must contain at least 9 characters
                </small>
            </div>
        </div>
    </section>
</div>
<!-- Professional Details -->
<div *ngIf="data.editType == 2">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Years of experience:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" type="number" class="paragraph" name="yearsOfExperience"
                    [(ngModel)]="data.data.value.yearsOfExperience" required #yearsOfExperience="ngModel"
                    [ngClass]="{'invalid-input': yearsOfExperience.invalid, 'valid-input': yearsOfExperience.valid}">
            </div>
        </div>
        <div *ngIf="userRole == userRoles.estateAgent">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Fidelity Fund Certificate number:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" class="paragraph" name="fidelityFundCertificateNumber"
                        [(ngModel)]="data.data.value.fidelityFundCertificateNumber" required
                        #fidelityFundCertificateNumber="ngModel"
                        [ngClass]="{'invalid-input': fidelityFundCertificateNumber.invalid, 'valid-input': fidelityFundCertificateNumber.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Individual reference number:</label>
                </div>
                <div class="col-7">
                    <input type="text" class="paragraph" name="individualReferenceNumber"
                        [(ngModel)]="data.data.value.individualReferenceNumber" #individualReferenceNumber="ngModel">
                </div>
            </div>
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>The property to be sold is registered:
            </div>
            <div>
                <mat-radio-group name="estateAgentStatus" [(ngModel)]="data.data.value.statusId">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of estateAgentStatusOptions"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>In which property sectors do you <span class="coral">specialise</span>
                (have extensive experience and knowledge)?
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngFor="let option of specialisedSectorsCheckBoxOptions; let index = i">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                {{option.value}}<span
                                    [ngClass]="{'specify-text': data.data.value.specialisingPropertySectorsOther.length == 0}"
                                    *ngIf="otherCheckboxSelected(option, 6)"> – Please specify</span>
                            </span>
                            <input type="checkbox" [(ngModel)]="option.checked"
                                [name]="specialisedSectorsCheckBoxOptions"
                                #specialisedSectorsCheckBoxOptions="ngModel" />
                            <span class="checkmark"></span>
                        </label>
                        <div class="mt-2" *ngIf="otherCheckboxSelected(option, 6)">
                            <div class="row mt-2">
                                <div class="col-10">
                                    <input name="specialisingPropertySectorsOther" type="text"
                                        class="paragraph mat-input-section mat-input-section-other"
                                        [(ngModel)]="data.data.value.specialisingPropertySectorsOther" required
                                        #specialisingPropertySectorsOther="ngModel">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="userRole == userRoles.conveyancer">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Legal Practice Council
                        (LPC) number:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtlpc_Number" class="paragraph" name="lpc_Number"
                        [(ngModel)]="data.data.value.legalPracticeCOuncilNumber" required #lpc_Number="ngModel"
                        [ngClass]="{'invalid-input': lpc_Number.invalid, 'valid-input': lpc_Number.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <div class="heading-5 mt-4">
                        <span class="burnt-red">| </span>If you/your company serves on the panel of any financial
                        institution, please indicate below
                        which institutions are applicable:
                    </div>
                </div>
            </div>
            <div class="row mat-input-section">
                <div class="col-12">
                    <div *ngFor="let option of financialInstitutionsPanelCheckBoxOptions; let index = i">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                {{option.value}}<span
                                    [ngClass]="{'specify-text': data.data.value.financialInstitutionForPanelAccredOther == 0}"
                                    *ngIf="otherCheckboxSelected(option, 8)"> – Please specify</span>
                            </span>
                            <input type="checkbox" [(ngModel)]="option.checked"
                                [name]="financialInstitutionsPanelCheckBoxOptions"
                                #financialInstitutionsPanelCheckBoxOptions="ngModel" />
                            <span class="checkmark"></span>
                        </label>
                        <div class="mt-2" *ngIf="otherCheckboxSelected(option, 8)">
                            <div class="row">
                                <div class="col-7 mx-4">
                                    <input type="text" placeholder="Mandatory" class="paragraph"
                                        name="financialInstituteOther"
                                        [(ngModel)]="data.data.value.financialInstitutionForPanelAccredOther" required
                                        #financialInstituteOther="ngModel"
                                        [ngClass]="{'invalid-input': financialInstituteOther.invalid, 'valid-input': financialInstituteOther.valid}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="userRole == userRoles.urbanPlanner">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Graduated as a planner:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" class="paragraph" name="plannerGraduationYear"
                        [(ngModel)]="data.data.value.plannerGraduationYear" required #plannerGraduationYear="ngModel"
                        [ngClass]="{'invalid-input': plannerGraduationYear.invalid, 'valid-input': plannerGraduationYear.valid}">
                </div>
            </div>
            <div class="submission-form">
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Institution:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" class="paragraph" name="institution"
                            [(ngModel)]="data.data.value.institution" required #institution="ngModel"
                            [ngClass]="{'invalid-input': institution.invalid, 'valid-input': institution.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Full SACPLAN registration number:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" class="paragraph" name="sacplan_RegistrationNumber"
                            [(ngModel)]="data.data.value.sacplan_RegistrationNumber" required
                            #sacplan_RegistrationNumber="ngModel"
                            [ngClass]="{'invalid-input': sacplan_RegistrationNumber.invalid, 'valid-input': sacplan_RegistrationNumber.valid}">
                    </div>
                </div>
                <mat-radio-group name="sacplan_RegistrationType"
                    [(ngModel)]="data.data.value.sacplan_RegistrationTypeId">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of sacplanRegistrationTypes"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="userRole == userRoles.valuer">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">SACPVP Number:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" type="number" class="paragraph" name="sacpvp_Number"
                        [(ngModel)]="data.data.value.sacpvp_Number" required #sacpvp_Number="ngModel"
                        [ngClass]="{'invalid-input': sacpvp_Number.invalid, 'valid-input': sacpvp_Number.valid}">
                </div>
            </div>

            <div class="row">
                <mat-radio-group name="valuerTypeRadioOptions" [(ngModel)]="data.data.value.registrationTypeId">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of valuerTypeRadioOptions"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="row">
                <div class="col-10">
                    <div class="heading-5 mt-2">
                        <span class="burnt-red">| </span>For which types of properties do you have extensive valuation
                        experience and knowledge:
                    </div>
                </div>
            </div>
            <div class="row mat-input-section">
                <div class="col-10">
                    <div *ngFor="let option of propertyExperienceTypeCheckBoxOptions; let index = i">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                {{option.value}}<span
                                    [ngClass]="{'specify-text': data.data.value.experienceOther.length == 0}"
                                    *ngIf="otherCheckboxSelected(option, 12)"> – Please specify</span>
                            </span>
                            <input type="checkbox" [(ngModel)]="option.checked"
                                [name]="propertyExperienceTypeCheckBoxOptions"
                                #propertyExperienceTypeCheckBoxOptions="ngModel" />
                            <span class="checkmark"></span>
                        </label>
                        <div class="mt-2" *ngIf="otherCheckboxSelected(option, 12)">
                            <div class="row mt-2">
                                <div class="col-10">
                                    <input name="experienceOther" type="text"
                                        class="paragraph mat-input-section mat-input-section-other"
                                        [(ngModel)]="data.data.value.experienceOther" required
                                        #experienceOther="ngModel">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <div class="heading-5 mt-4">
                        <span class="burnt-red">| </span>If you/your company serves on the panel of any financial
                        institution,
                        please indicate below
                        which institutions are applicable:
                    </div>
                </div>
            </div>
            <div class="row mat-input-section">
                <div class="col-12">
                    <div *ngFor="let option of financialInstitutionsPanelCheckBoxOptions; let index = i">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                {{option.value}}<span
                                    [ngClass]="{'specify-text': data.data.value.valuationPermittedOther == 0}"
                                    *ngIf="otherCheckboxSelected(option, 8)"> – Please specify</span>
                            </span>
                            <input type="checkbox" [(ngModel)]="option.checked"
                                [name]="financialInstitutionsPanelCheckBoxOptions"
                                #financialInstitutionsPanelCheckBoxOptions="ngModel" />
                            <span class="checkmark"></span>
                        </label>
                        <div class="mt-2" *ngIf="otherCheckboxSelected(option, 8)">
                            <div class="row">
                                <div class="col-7 mx-4">
                                    <input type="text" placeholder="Mandatory" class="paragraph"
                                        name="financialInstituteOther"
                                        [(ngModel)]="data.data.value.valuationPermittedOther" required
                                        #financialInstituteOther="ngModel"
                                        [ngClass]="{'invalid-input': financialInstituteOther.invalid, 'valid-input': financialInstituteOther.valid}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Qualitfications -->
<div *ngIf="data.editType == 3">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Qualification details:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="qualificationDetails"
                    [(ngModel)]="data.data.value.qualificationDetails" required #qualificationDetails="ngModel"
                    [ngClass]="{'invalid-input': qualificationDetails.invalid, 'valid-input': qualificationDetails.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Additional skills:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="additionalSkills"
                    [(ngModel)]="data.data.value.additionalSkills" required #additionalSkills="ngModel"
                    [ngClass]="{'invalid-input': additionalSkills.invalid, 'valid-input': additionalSkills.valid}">
            </div>
        </div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>What is the highest qualification you have?
        </div>
        <mat-radio-group name="highestQualificationId" [(ngModel)]="data.data.value.highestQualificationId">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of highestQualificationsOptions"
                [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
    </section>
</div>
<!-- Physical Address -->
<div *ngIf="data.editType == 4">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" name="buildingUnitNumber" class="paragraph not-required"
                    [(ngModel)]="data.data.value.unitNo" #buildingUnitNumber="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" name="estateComplexName" class="paragraph not-required"
                    [(ngModel)]="data.data.value.complexName" #estateComplexName="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetNumber" class="paragraph"
                    [(ngModel)]="data.data.value.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetName" class="paragraph"
                    [(ngModel)]="data.data.value.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="suburb" class="paragraph"
                    [(ngModel)]="data.data.value.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="city" class="paragraph"
                    [(ngModel)]="data.data.value.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
    </section>
</div>
<div class="text-center">
    <button (click)="saveInfo()" class="button">Save</button>
</div>