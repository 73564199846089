import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoles } from 'src/app/common/global-constants';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { AdminService, FileService } from 'src/app/services/property-matrix/services';

@Component({
  selector: 'app-admin-my-profile-documents-edit-dialog',
  templateUrl: './admin-my-profile-documents-edit-dialog.component.html',
  styleUrls: ['./admin-my-profile-documents-edit-dialog.component.scss']
})
export class AdminMyProfileDocumentsEditDialogComponent implements OnInit {

  userRole: string;
  userRoles = UserRoles;
  fileUploader = new FileUploader();
  loading: boolean = false;
  uploadBlocks: any[] = [];

  uploadBlockData: any[] = [
    { key: 1, value: 'Copy of your Identity Document', file: null, required: false, userRole: UserRoles.estateAgent },
    { key: 2, value: 'Up to date EAAB Fidelity Fund Certificate', file: null, required: false, userRole: UserRoles.estateAgent },
    { key: 3, value: 'Company VAT certificate (if applicable)', file: null, required: false, userRole: UserRoles.estateAgent },
    { key: 4, value: 'Qualifications and/or designations awarded to you', file: null, required: false, userRole: UserRoles.estateAgent },
    { key: 5, value: 'A photo  of yourself', file: null, required: false, userRole: UserRoles.estateAgent },
    { key: 1, value: 'Copy of your Identity Document', file: null, required: false, userRole: UserRoles.conveyancer },
    { key: 2, value: 'Proof of admission as conveyancer', file: null, required: false, userRole: UserRoles.conveyancer },
    { key: 3, value: 'Fidelity fund certificate of firm', file: null, required: false, userRole: UserRoles.conveyancer },
    { key: 4, value: 'A photo  of yourself', file: null, required: false, userRole: UserRoles.conveyancer },
    { key: 5, value: 'A logo of your company', file: null, required: false, userRole: UserRoles.conveyancer },
    { key: 1, value: 'Copy of your Identity Document', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 2, value: 'Up to date registration certificate with SACPLAN', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 3, value: 'Certified copy: Town and regional planning degree certificate', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 4, value: 'A logo of your company to be used for marketing purposes', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 5, value: 'Certified copy 1: additional professional qualification and/or designation', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 6, value: 'Certified copy 2: additional professional qualification and/or designation', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 7, value: 'Certified copy 3: additional professional qualification and/or designation', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 8, value: 'Photo of yourself to be used by Property Matrix for office & admin purposes only', file: null, required: false, userRole: UserRoles.urbanPlanner },
    { key: 1, value: 'Copy of your Identity Document', file: null, required: false, userRole: UserRoles.valuer },
    { key: 2, value: 'Up to date registration certificate with SACPVP', file: null, required: false, userRole: UserRoles.valuer },
    { key: 3, value: 'Qualifications and/or designations', file: null, required: false, userRole: UserRoles.valuer },
    { key: 4, value: 'A photo  of yourself', file: null, required: false, userRole: UserRoles.valuer },
    { key: 5, value: 'A logo of your company', file: null, required: false, userRole: UserRoles.valuer },
  ]

  constructor(public dialogRef: MatDialogRef<AdminMyProfileDocumentsEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminEditDialogModel,
    private adminService: AdminService,
    private sharedService: PropertyMatrixSharedService,
    private fileService: FileService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.getUserRole();
    this.setUploadBlocks();
  }

  setUploadBlocks() {
    this.uploadBlocks = this.uploadBlockData.filter(f => f.userRole == this.userRole);
  }

  async saveInfo() {
    const containsNewFile = this.uploadBlocks.find(f => f.file != null);
    if (!containsNewFile) {
      this.closeDialogWithNoChanges();
      return;
    }
    this.loading = true;
    let success = await this.sharedService.uploadFiles(this.fileUploader.files);
    if(success == true){
      this.dialogRef.close(true);
      this.fileUploader.files = [];
      this.loading = false;
    }
  }

  selectUpload(files, id) {
    this.fileUploader.specificFileBrowseHandler(files, id);
    this.updateShownBlocks(files[0], id);
  }

  dropUpload($event, id) {
    this.fileUploader.specificOnFileDropped($event, id);
    this.updateShownBlocks($event[0], id);
  }

  deleteFile(block, blockIndex) {
    this.uploadBlocks[blockIndex].file = null;
    let item = this.fileUploader.files.find(f => f.id == block.key);
    let fileIndex = this.fileUploader.files.indexOf(item);
    this.fileUploader.deleteFile(fileIndex);
  }

  updateShownBlocks(file, id) {
    let uploadBlock = this.uploadBlocks[id - 1]
    uploadBlock.show = this.fileUploader.files.filter(f => f.id == id).length > 0;
    uploadBlock.file = file;
  }

  closeDialogWithNoChanges() {
    this.dialogRef.close();
  }

  getUserRole() {
    this.userRole = this.sharedService.getUserRole();
  }
}
