import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { OfferToPurchasePurchaserDetailsVm } from 'src/app/services/property-matrix/models';

@Component({
  selector: 'app-property-for-sale-form-purchaser-details',
  templateUrl: './property-for-sale-form-purchaser-details.component.html',
  styleUrls: ['./property-for-sale-form-purchaser-details.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class PropertyForSaleFormPurchaserDetailsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() offerToPurchasePurchaserDetailsEvent = new EventEmitter<OfferToPurchasePurchaserDetailsVm>();

  purchaserSAResident: number = 0;
  spouseIsSouthAfricanResident: number = 0;
  moreThanOneOwner: number = 0;
  hasVatNumber: number = 0;
  isLegalRepresentative: number = 0;
  confirmLegalRepConcent: number = 0;

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];
  maritalStatuses: KeyValuePair[] = [
    { key: 1, value: 'Unmarried' },
    { key: 2, value: 'Married out of community' },
    { key: 3, value: 'Married in community' }
  ];
  multipleOwners: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, there are multiple owners' }
  ];

  form: OfferToPurchasePurchaserDetailsVm = {
    confirmConsent: false,
    moreThanOneOwner: false,
    legalEntityName: '',
    legalEntityRegistrationNumber: '',
    legalEntityVATNumber: '',
    maritalStatus: null,
    propertyOwnershipType: 0,
    address: {
      city: '',
      complexName: '',
      postalCode: '',
      propertyDescription: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      unitNumber: ''
    },
    personalDetails: {
      alternativeNumber: '',
      emailAddress: '',
      id_Number: '',
      phoneNumber: '',
      name: '',
      rsa_Resident: false,
      confirmEmail: '',
      confirmPhoneNumber: ''
    },
    spouseDetails: {
      alternativeNumber: '',
      emailAddress: '',
      id_Number: '',
      phoneNumber: '',
      name: '',
      rsa_Resident: false,
    },
  }

  propertyOwnershipType: KeyValuePair[] = [
    { key: 1, value: 'Natural person(s)' },
    { key: 2, value: 'Juristic person(s) - (company, cc, NPO, trust, etc.)' },
    { key: 3, value: 'Both natural person(s) and juristic person(s)'}
  ];

  constructor() { }

  ngOnInit(): void {
  }

  submitForm(){
    this.form.confirmConsent = this.confirmLegalRepConcent == 1
    this.form.moreThanOneOwner = this.moreThanOneOwner == 1
    this.form.personalDetails.rsa_Resident = this.purchaserSAResident == 1
    this.form.spouseDetails.rsa_Resident = this.spouseIsSouthAfricanResident == 1
    this.form.confirmConsent = this.confirmLegalRepConcent == 1
    this.offerToPurchasePurchaserDetailsEvent.emit(this.form);
    this.pageNumberEvent.emit(2);
    GlobalMethods.scrollToTop();
  }
}
