<main class="main-container">
    <div class="background-image container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container mt-3">
                    <div class="text-center paragraph">
                        <p>Thank you for your time.</p>
                        <p>
                            Please check your inbox for an email from one of our partnering agents where you can
                            indicate a suitable time for a visit to your property.
                        </p>
                        <p>
                            <strong>Property Matrix</strong> will kick off with the planning report and value assessment
                            as soon as the information is received from our agent.
                        </p>
                        <button class="button" (click)="goToHome()">
                            NOTED
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>