import { RoleBasedAccessDto } from 'src/app/services/property-matrixV2/models';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { MatMenuTrigger } from '@angular/material/menu';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { PropertyMatrixSharedService } from './property-matrix-shared.service';
import { AccountService } from './services/auth/account-service';
import { UserService } from './services/user.service';
import { User } from './models/User';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Property Pro Web';
  isLoggedIn: boolean;
  user: User;
  subscription: Subscription;
  navbar: HTMLElement;
  navPos: number;
  adminActive: boolean = false;
  adminPageTitle: string;
  mainMenuOpen = false;
  isSmallScreenMain = false;
  adminMenuOpen = false;
  isSmallScreenAdmin = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private pageTitle: Title,
    private userService: UserService,
    private accountService: AccountService,
    private keycloakService: KeycloakService,
    private sharedService: PropertyMatrixSharedService
  ) {
    keycloakService.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken(20);
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.sharedService.getUserModel();

    this.isSmallScreenMain = window.innerWidth < 1341;
    this.isSmallScreenAdmin = window.innerWidth < 801;

    this.keycloakService.isLoggedIn().then((loggedIn: boolean) => {
      this.isLoggedIn = loggedIn;
      if (this.isLoggedIn) {
        this.accountService.setUserToken().then(() => {
          this.getAdminNavStatus();
        });
      }
    });

    this.adminActive = sessionStorage.getItem("adminNav") === "true";
    if (this.router.url.startsWith('/admin')) {
      this.adminActive = true;
    }

    this.setTitle();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setTitle() {
    if (!this.pageTitle.getTitle()) {
      this.pageTitle.setTitle('Property Matrix')
    }
  }

  goToRegister() {
    this.router.navigate(['/register/choice']);
  }

  onClickLogin() {
    sessionStorage.clear();
    this.keycloakService.login();
  }

  onClickLogout() {
    sessionStorage.clear();
    this.keycloakService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmallScreenMain = window.innerWidth < 1341;
    if (!this.isSmallScreenMain) {
      this.mainMenuOpen = false;
    }

    this.isSmallScreenAdmin = window.innerWidth < 801;
    if (!this.isSmallScreenAdmin) {
      this.adminMenuOpen = false;
    }
  }

  toggleMainMenu() {
    this.mainMenuOpen = !this.mainMenuOpen;
  }

  toggleAdminMenu() {
    this.adminMenuOpen = !this.adminMenuOpen;
  }

  enableAdminNav() {
    sessionStorage.setItem("adminNav", "true");
    this.adminActive = true;
  }

  disableAdminNav() {
    sessionStorage.setItem("adminNav", "false");
    this.adminActive = false;
  }

  getAdminNavStatus(): void {
    let roleBasedAccessDto: RoleBasedAccessDto;
    this.accountService.getDecodedUserToken().then((token: any) => {
      if (token != null) {
        roleBasedAccessDto = JSON.parse(token);
        if (roleBasedAccessDto.roles.includes('Planner Portal Administrator')) {
          this.adminPageTitle = roleBasedAccessDto.userFullName;
        } else {
          this.adminActive = false;
        }
      }
    });
  }

  delayedClose(trigger: MatMenuTrigger) {
    setTimeout(() => {
      trigger.closeMenu();
    }, 500);
  }

  logout() {
    this.sharedService.logout();
  }
}
