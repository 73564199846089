import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UrbanPlannerProfessionalDto } from '../models';
import { UserDto } from '../../property-matrix/models';

@Injectable({
  providedIn: 'root'
})

export class WizardDataService {

  private _urbanPlannerData$ = new BehaviorSubject<UserDto>({});
  private _urbanPlannerExtendedNetworkData$ = new BehaviorSubject<UrbanPlannerProfessionalDto[]>([]);

  urbanPlannerExtendedNetwork = this._urbanPlannerExtendedNetworkData$.asObservable();
  urbanPlannerInfoData = this._urbanPlannerData$.asObservable();

  constructor() { }

  setUrbanPlannerInfoEnrollmentData(val: UserDto) {
    this._urbanPlannerData$.next(val);
  }

  setUrbanPlannerProfessionalData(val: UrbanPlannerProfessionalDto[]) {
    this._urbanPlannerExtendedNetworkData$.next(val);
  }
}
