<div class="background-image container-fluid">
  <section class="content">
    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate autocomplete="false">
      <div class="container text-center">
        <div class="row">
          <p class="heading-1">LOG IN</p>
        </div>
        <div class="row pb-4">
          <label class="heading-5" id="lblUsername" for="txtUsername">Username</label>
          <input class="textbox paragraph login-input" [ngClass]="{'is-danger': displayMessage.Username}"
            id="txtUsername" formControlName="email" type="email">
          <div class=" burnt-red paragraph" *ngIf="displayMessage.email">
            *{{ displayMessage.email }}
          </div>
        </div>
        <div class="row">
          <label class="heading-5" id="lblpassword" for="txtPassword">Password</label>
          <input class="textbox paragraph login-input" [ngClass]="{'is-danger': displayMessage.password}"
            id="txtPassword" formControlName="password" type="password">
          <div class=" burnt-red paragraph" *ngIf="displayMessage.password">
            *{{ displayMessage.password }}
          </div>
        </div>
        <div class="row paragraph">
          <div class="col-12">
            <a routerLink="/register/choice">Register</a>
            <div class="pipe"> | </div>
            <a [routerLink]="['/forgot-password']">Forgot Password</a>
          </div>
        </div>
        <div class="row">
          <button type="submit" class="button heading-3" >LOG IN</button>
        </div>
      </div>
    </form>
  </section>
</div>