<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Step 3: Submit Documents
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="upload-section" *ngFor="let doc of documents; let i = index">
            <mat-card class="upload-card" (click)="fileInput.click()">
                <div>
                    <mat-checkbox [checked]="isFileUploaded(i)" disabled></mat-checkbox>
                    <span class="paragraph ms-2">{{ doc.description }}</span>
                </div>
                <div class="upload-actions">
                    <span class="paragraph">Drag & Drop OR click</span>
                    <button mat-button color="warn" [disabled]="!isFileUploaded(i)" (click)="onRemoveFile(i, $event)">
                        Delete
                    </button>
                </div>
                <input type="file" class="file-input" (change)="onFileSelected($event, i)" hidden #fileInput>
            </mat-card>
        </div>
    </div>
    <div class="row paragraph mt-5">
        <div class="text-center">
            <button class="button" type="submit" (click)="onSubmitDocuments()">SUBMIT</button>
        </div>
    </div>
</main>