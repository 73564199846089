import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class AddEditUserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddEditUserDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
