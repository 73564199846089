<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Property Type</label>
            <mat-select class="col-12 mt-1" [(value)]="selectedPropertyType"
                (selectionChange)="onPropertyTypeChange($event)">
                <mat-option *ngFor="let item of propertyTypes" [value]="item.value">
                    {{ item.value }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Unit Number</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.sectionalTitleUnitNumber" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Erf Number</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.erfNumber" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Portion Number</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.portionNumber" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Township</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.subPlaceName" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Deeds Erf Extent</label>
            <input class="col-12 mt-1 riskscape-data" type="number" readonly
                [(ngModel)]="riskscapePropertyInfoData.deedsErfExtent" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Surveyor General Erf Extent</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapeExistingLandUseAndFeatureData.surveyorGeneralErfExtent" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">S.G Code</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.surveyorGeneralKey" />
        </div>
    </div>
</main>