<main class="dialog-container">
    <div>
        <span class="paragraph bold user-dialog-heading">Add new user</span>
        <div class="row-container pt-1 pb-1">
            <span class="paragraph user-dialog-subheading">Invite a new user to join the platform</span>
        </div>
        <form class="user-form">
            <div class="row paragraph">
                <div class="col-12 mt-2">
                    <label class="col-12 bold">First Name</label>
                    <input class="col-12 mt-1" type="text" placeholder="John" />
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Last Name</label>
                    <input class="col-12 mt-1" type="text" placeholder="Doe" />
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Email</label>
                    <input class="col-12 mt-1" type="text" placeholder="johndoe@gmail.com" />
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Role</label>
                    <mat-select class="col-12 mt-1">
                        <mat-option [value]="null">None</mat-option>
                    </mat-select>
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Profession</label>
                    <mat-select class="col-12 mt-1">
                        <mat-option [value]="null">None</mat-option>
                    </mat-select>
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Associated Network Planner</label>
                    <input class="col-12 mt-1" type="text" />
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">Password (Optional)</label>
                    <input class="col-12 mt-1" type="text" />
                </div>
            </div>
        </form>
        <div class="button-group mt-3">
            <button class="footer-button background-cloud" (click)="closeDialog()">
                Cancel
            </button>
            <button class="footer-button background-coral" (click)="closeDialog()">
                Confirm
            </button>
        </div>
    </div>
</main>