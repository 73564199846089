<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let reference of referenceData; let i = index">
        <button *ngIf="i > 0" (click)="removeReference(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Reference {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Name</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="reference.name"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Company</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="reference.company"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Professional</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="reference.professional"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Mobile Number/Email</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="reference.contactDetail"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Website</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="reference.website"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addReference()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Add reference</span>
        </button>
    </div>
</div>