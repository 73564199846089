import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-zoning-map',
  templateUrl: './zoning-map.component.html',
  styleUrls: ['./zoning-map.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ZoningMapComponent implements OnInit {

  @Input() zoningMap: any;
  @Input() zoningMapFileId: string;
  @Output() setZoningMapFileId = new EventEmitter<any>();

  file: any;
  fileUrl: string = null;
  hideButtons = false;

  constructor(
    private reportingService: ReportingService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.loadFile();
  }

  async setFile(event: { target: { files: any[]; }; }) {
    try {
      this.zoningMapFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.setZoningMapFileId.emit(this.zoningMapFileId);
      this.file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrl = event.target.result;
        this.hideButtons = true;
      }

      reader.readAsDataURL(this.file);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replace() {
    this.fileUrl = null;
    this.hideButtons = false;
  }

  async loadFile() {
    if (this.zoningMapFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.zoningMapFileId);
        this.fileUrl = fileUrl;
        this.hideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }
}
