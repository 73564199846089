import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

interface Step {
  label: string;
  link: number;
}

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss']
})
export class ProgressTrackerComponent {

  @Input() steps: Step[] = [];
  @Input() currentStep: number = 0;
  @Output() stepClick = new EventEmitter<number>();

  isMediumScreen = false;
  isSmallScreen = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMediumScreen = window.innerWidth <= 1140 && window.innerWidth > 920;
    this.isSmallScreen = window.innerWidth <= 920;
  }

  ngOnInit() {
    this.onResize();
  }

  onStepClick(link: number) {
    this.stepClick.emit(link);
  }
}
