import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminPortalExtendedNetworkDialogModel } from 'src/app/models/admin-portal-en-dialog-model';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { AdminService } from 'src/app/services/property-matrix/services';
import { AdminMyProfileWorkDetailsEditDialogComponent } from '../../admin-my-profile-work-details/admin-my-profile-work-details-edit-dialog/admin-my-profile-work-details-edit-dialog.component';

@Component({
  selector: 'app-admin-my-profile-extended-network-dialog',
  templateUrl: './admin-my-profile-extended-network-dialog.component.html',
  styleUrls: ['./admin-my-profile-extended-network-dialog.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AdminMyProfileExtendedNetworkDialogComponent implements OnInit {

  username: string;

  constructor(public dialogRef: MatDialogRef<AdminMyProfileWorkDetailsEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminPortalExtendedNetworkDialogModel, private adminService: AdminService, private sharedService: PropertyMatrixSharedService) { }

  ngOnInit(): void {
    this.username = this.sharedService.getUsername();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  saveInfo() {
    if (this.data.edit == true) {
      this.editProfessional();
    } else {
      this.addProfessional();
    }
  }

  editProfessional() {
    this.adminService.apiV1AdminUpdateExtendedNetworkProfessionalPost$Response({body: this.data.data})
    .pipe(map(m => { return m })).subscribe({
      next: (result: any) => {
        this.closeDialog(this.data);
      }
      , error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Error Adding Professional");
      }
    })
  }

  addProfessional() {
    this.adminService.apiV1AdminAddExtendedNetworkProfessionalPost$Response({ body: this.data.data })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          this.data.data.id = result
          this.closeDialog(this.data);
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error Adding Professional");
        }
      })
  }

  closeDialog(data: AdminPortalExtendedNetworkDialogModel) {
    this.dialogRef.close(data);
  }
}
