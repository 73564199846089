<div class="background-color popup-size">
    <div class="heading-2">
        <div class="row">
            <div class="col-12 text-center">
                Add profession
            </div>
        </div>
    </div>  
    <div class="row row-spacing">
        <div class="col-4">                 
            <label id="lblProfessionName" for="txtProfessionName" class="paragraph">
                Profession Name:
            </label>                
        </div>
        <div class="col-8">
            <input type="text" id="txtProfessionName" name="professionName" class="paragraph not-required input-sizing"
            [(ngModel)]="newProfession.value" #professionName="ngModel"
            [ngClass]="{'invalid-input': professionName.invalid, 'valid-input': professionName.valid}">
        </div>
    </div>
    <div class="row row-spacing">
        <div class="col-12 text-center ">
            <button class="button heading-4 mt-2" (click)="saveProfession()">Save Profession</button>
        </div>
    </div>
</div>