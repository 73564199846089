export class TownPlanningQueries {
    constructor(
        public name:string = '', 
        public surname: string = '',
        public position: string = '',
        public landline: string = '',
        public mobile: string = '',
        public email: string = ''
    ){}
}
