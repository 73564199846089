import { EstateAgentCompanyInformationDto, EstateAgentDocumentDto, EstateAgentDto, EstateAgentPersonalInformationDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstateAgentRegistrationStateService {

  private formState = new BehaviorSubject<EstateAgentDto>({
    personalInformation: {
      otherSectors: null,
      sectors: null,
      status: null,
      yearsInOperation: 0
    },
    companyInformation: {
      accountHolderName: null,
      accountNumber: null,
      accountType: null,
      agencyBranch: null,
      agencyName: null,
      agencyPinNumber: null,
      bankName: null,
      branchName: null,
      branchNumber: null,
      city: null,
      complexName: null,
      fidelityFundCertificateNumber: null,
      individualReferenceNumber: null,
      isAffiliatedWithAgency: false,
      isPrincipal: false,
      principalIdNumber: null,
      principalName: null,
      principalSurname: null,
      streetName: null,
      streetNumber: null,
      suburb: null,
      unitNumber: null,
      vatNumber: null
    },
    documents: []
  });

  formState$ = this.formState.asObservable();

  setUserId(userId: string) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      userId: userId
    });
  }

  updateIsPrincipal(isPrincipal: boolean) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      companyInformation: {
        ...currentState.companyInformation,
        isPrincipal: isPrincipal
      }
    });
  }

  updatePersonalInformation(personalInfo: EstateAgentPersonalInformationDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      personalInformation: personalInfo
    });
  }

  updateCompanyInformation(companyInfo: EstateAgentCompanyInformationDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      companyInformation: companyInfo
    });
  }

  updateDocuments(documents: EstateAgentDocumentDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      documents: documents
    });
  }

  updateDocument(index: number, document: EstateAgentDocumentDto) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = document;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  removeDocument(index: number) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = null;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  getFormState(): EstateAgentDto {
    return this.formState.value;
  }
}
