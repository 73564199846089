<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let otherUnusableSpace of otherUnusableSpaceData; let i = index">
        <button *ngIf="i > 0" (click)="removeOtherUnusableSpace(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Other Unusable Space {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Description</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="otherUnusableSpace.description"
                            placeholder="Environmental Sensitive Area; N/A; TBC: Level 3 Report Required"
                            (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-6">
                        <label class="col-12 bold">Size (m²)</label>
                        <input class="col-12 mt-1" type="number" [(ngModel)]="otherUnusableSpace.size" placeholder="75"
                            (ngModelChange)="emitChanges()">
                    </div>
                    <div class="col-6">
                        <label class="col-12 bold">Addendum</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="otherUnusableSpace.addendum"
                            placeholder="See Addendum 7" (ngModelChange)="emitChanges()">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addOtherUnusableSpace()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Unusable space</span>
        </button>
    </div>
</div>