<mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav #sidenav (keydown.escape)="closeNav()" position="end">
      <div class="container-side sidenav-container container" fxLayout="column" fxLayoutAlign="space-between start">
        <div class="row">
          <div class="col-11 heading-4-light py-3">
            Documents required for verification
          </div>
          <div class="col-1 btn-close-align pt-2">
            <button type="button" class="btn-close btn-close-white" (click)="closeNav()" aria-label="Close"></button>
          </div>
        </div>
        <div class="row">
            <div class="col-12">
              <ul class="montserrat-bullets paragraph light">
                <li>If a resident of RSA – copy of your ID (if ID card, then front and back)</li>
                <li>If not a resident of RSA – a copy of your passport</li>
                <li>Proof of residence (e.g. bank statement, salary slip, water & electricity account, telephone account)</li>
                <li>Proof of tax number (SARS income number)</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="paragraph light">
                If you make an offer on behalf of a natural person/s:
              </p>
              <ul class="montserrat-bullets paragraph light">
                <li>If married in community - Marriage certificate</li>
                <li>If married out of community - Antenuptial agreement</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="paragraph light">
                If you make an offer on behalf of a legal entity:
              </p>
              <ul class="montserrat-bullets paragraph light">
                <li>Company registration documents</li>
                <li>Company VAT certificate (if applicable)</li>
                <li>Resolution mandating you to act on behalf of the entity</li>
              </ul>
            </div>
          </div>
      </div>
    </mat-sidenav>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main style="background-color: white" id="main">
    <div class="content content-padding">
        <div class="container">
            <!--Form section block-->
            <section>
                <div class="row mt-3 no-gutters form-header">
                    <div class="col-4 px-1" *ngFor="let header of formHeaders">
                        <button class="form-section form-section-button active-form-section"
                            [ngClass]="{ 'active-form-section': pageNumber >= header.key }"
                              (click)="selectPage(header.key)" [disabled]="pageNumber <= header.key || pageNumber == 3">
                            <div class="heading-3-light">{{header.value}}</div>
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <div [hidden]="pageNumber != 1">
                    <app-property-for-sale-form-purchaser-details (pageNumberEvent)="changePage($event)" (offerToPurchasePurchaserDetailsEvent)="assignOfferToPurchasePurchaserDetails($event)"></app-property-for-sale-form-purchaser-details>
                </div>
                <div [hidden]="pageNumber != 2">
                  <app-property-for-sale-form-conditions (pageNumberEvent)="changePage($event)" (offerToPurchaseConditions)="assignOfferToPurchaseConditions($event)"></app-property-for-sale-form-conditions>
              </div>
              <div [hidden]="pageNumber != 3">
                <app-property-for-sale-form-purchase-price (openSideNavEvent)="openNav()" (offerToPurchasePriceEvent)="assignOfferToPurchasePrice($event)"></app-property-for-sale-form-purchase-price>
            </div>
              </section>
        </div>
    </div>
</main>
</mat-sidenav-container>