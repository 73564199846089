<div *ngFor="let type of documentDetails" class="mt-4">
    <mat-card *ngIf="type.value">
        <mat-card-content>
            <div>
                <div class="row">
                    <div class="col-11">
                        <div class="heading-2">{{type.title}}</div>
                    </div>
                    <div class="col-1 edit-button-margin"><button (click)="editInfo(type.key)"
                            mat-icon-button><mat-icon>edit</mat-icon></button></div>
                </div>
                <div class="row pt-3" *ngIf="type.key == 1">
                    <div class="col-12" *ngFor="let document of type.value">
                        <a (click)="downloadDocument(document)" class="clickable-link">
                            <span class="paragraph"><mat-icon>article</mat-icon>&nbsp;{{document.fileName}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>