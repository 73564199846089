/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EstateAgentDto } from '../models/estate-agent-dto';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1RegistrationRegisterEstateAgentPost
   */
  static readonly ApiV1RegistrationRegisterEstateAgentPostPath = '/api/v1/Registration/RegisterEstateAgent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RegistrationRegisterEstateAgentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterEstateAgentPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<EstateAgentDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegistrationService.ApiV1RegistrationRegisterEstateAgentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<EstateAgentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1RegistrationRegisterEstateAgentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RegistrationRegisterEstateAgentPost(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<EstateAgentDto> {

    return this.apiV1RegistrationRegisterEstateAgentPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<EstateAgentDto>) => r.body as EstateAgentDto)
    );
  }

}
