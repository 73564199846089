import { LandUseDto, SchemeDto, ZoningDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-land-parcel',
  templateUrl: './land-parcel.component.html',
  styleUrls: ['./land-parcel.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class LandParcelComponent implements OnInit {

  @Input() landParcel: any;
  @Input() landParcelIndex: number;
  @Input() schemeData: SchemeDto[] = [];
  @Input() zoningData: ZoningDto[] = [];
  @Input() proposedLandUseData: LandUseDto[] = [];

  @Output() schemeName = new EventEmitter<string>();
  @Output() proposedZoning = new EventEmitter<string>();
  @Output() proposedLandUses = new EventEmitter<string[]>();
  @Output() approvedAdditionalUses = new EventEmitter<string[]>();

  selectedSchemeName: string | null = null;
  selectedProposedZoning: string | null = null;
  selectedProposedLandUses: string[] = [];
  selectedApprovedAdditionalUses: string[] | null = [];

  constructor() { }

  ngOnInit(): void {

    this.setDefaultScheme();

    this.selectedProposedZoning = this.landParcel?.proposedZoningRight.id ?? null;

    if (this.selectedProposedZoning != null) {
      this.proposedZoning.emit(this.selectedProposedZoning);

      for (const right of this.landParcel?.proposedLandUses) {
        this.selectedProposedLandUses.push(right.id);
      }

      for (const use of this.landParcel?.proposedAdditionalRights) {
        this.selectedApprovedAdditionalUses.push(use.id);
      }
    }


  }

  setDefaultScheme() {
    if (this.schemeData.length > 0) {
      this.selectedSchemeName = this.schemeData[0].id;
      this.schemeName.emit(this.selectedSchemeName ?? "");
    }
  }

  onProposedZoningChange(event: MatSelectChange) {
    this.selectedProposedZoning = event.value === null ? null : event.value;
    this.proposedZoning.emit(this.selectedProposedZoning ?? "");

    if (this.selectedProposedZoning === null) {
      this.resetOtherSelects();
    }
  }

  onProposedLandUsesChange(event: MatSelectChange) {
    this.selectedProposedLandUses = event.value.includes(null) ? [] : event.value.filter((value: null) => value !== null);
    this.proposedLandUses.emit(this.selectedProposedLandUses);
  }

  onApprovedAdditionalUsesChange(event: MatSelectChange) {
    this.selectedApprovedAdditionalUses = event.value.includes(null) ? [] : event.value.filter((value: null) => value !== null);
    this.approvedAdditionalUses.emit(this.selectedApprovedAdditionalUses);
  }

  private resetOtherSelects() {
    this.selectedProposedLandUses = [];
    this.selectedApprovedAdditionalUses = [];
    this.proposedLandUses.emit([]);
    this.approvedAdditionalUses.emit([]);
  }
}
