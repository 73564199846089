<form id="extendedNetworkForm" novalidate  class="submission-form">
    <div class="heading-2 mt-4">STEP 2: EXTENDED NETWORK</div>
    <div class="paragraph mt-2">
     <p>To ensure comprehensive assessments, our property reports, often supported by specialist studies and information, require the involvement of extended 
        professionals. Nomination of specialists can lead to additional work opportunities stemming from technical queries on specific aspects.</p>

     <p>Our office will contact your nominated professionals individually if additional details are required. All communication to specialists regarding properties you 
        are involved in, will include you or copy you. We will respect your role as the coordinator.</p>

      <p><b>Important:</b> If the architect you work with does not provide drafting services (building plans or “as-built” plans), please also provide details of an Architectural 
        technologist</p>
    </div>
    <div class="heading-5 mt-5">
        Add and edit professionals
    </div>
    <div class="paragraph mt-2">
        Add professionals to the professions already listed. Additional professionals can also be added (top right).
    </div>
    <div class="col-12 searching-header">
        <div class="add-professional-pos col-12">
            <mat-button class="add-user-button" (click)="addProfessional()">
                <span style="padding-right: 10px;">Add professional</span>
                <mat-icon class="add-user-icon"
                    style="border: 1px solid black; border-radius: 15px;">add</mat-icon>
            </mat-button>
        </div>
    </div>
    <mat-table [dataSource]="dataSource" #professionalTable class="mat-elevation-z8 table-style">
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef class="header-cell"> Number </mat-header-cell>            
            <mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="header-cell">  NAME: PROFESSIONAL </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="profession">
            <mat-header-cell *matHeaderCellDef class="header-cell"> PROFESSION </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.profession.value}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef class="header-cell"> Edit </mat-header-cell>
            <mat-cell *matCellDef="let element">                
                <button mat-raised-button color="light"  (click)="editProfessionalItem(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef class="header-cell"> Delete </mat-header-cell>
            <mat-cell *matCellDef="let element"> 
                <button mat-raised-button color="light"  (click)="deleteProfessionalItem(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>       
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div class="row text-center mt-4">
        <div class="col-12">
            <button id="btnSaveFinal" class="button heading-4" (click)="submitForm()">SUBMIT</button>
        </div>
    </div>
</form>