/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OwnershipDetailDto } from '../models/ownership-detail-dto';

@Injectable({
  providedIn: 'root',
})
export class OwnershipDetailService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1OwnershipDetailGetAllOwnershipDetailsGet
   */
  static readonly ApiV1OwnershipDetailGetAllOwnershipDetailsGetPath = '/api/v1/OwnershipDetail/GetAllOwnershipDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1OwnershipDetailGetAllOwnershipDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1OwnershipDetailGetAllOwnershipDetailsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<OwnershipDetailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OwnershipDetailService.ApiV1OwnershipDetailGetAllOwnershipDetailsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<OwnershipDetailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1OwnershipDetailGetAllOwnershipDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1OwnershipDetailGetAllOwnershipDetailsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<OwnershipDetailDto>> {

    return this.apiV1OwnershipDetailGetAllOwnershipDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<OwnershipDetailDto>>) => r.body as Array<OwnershipDetailDto>)
    );
  }

  /**
   * Path part for operation apiV1OwnershipDetailAddOwnershipDetailsPost
   */
  static readonly ApiV1OwnershipDetailAddOwnershipDetailsPostPath = '/api/v1/OwnershipDetail/AddOwnershipDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1OwnershipDetailAddOwnershipDetailsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1OwnershipDetailAddOwnershipDetailsPost$Response(params?: {
    context?: HttpContext
    body?: OwnershipDetailDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<OwnershipDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, OwnershipDetailService.ApiV1OwnershipDetailAddOwnershipDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<OwnershipDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1OwnershipDetailAddOwnershipDetailsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1OwnershipDetailAddOwnershipDetailsPost(params?: {
    context?: HttpContext
    body?: OwnershipDetailDto
  }
): Observable<OwnershipDetailDto> {

    return this.apiV1OwnershipDetailAddOwnershipDetailsPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<OwnershipDetailDto>) => r.body as OwnershipDetailDto)
    );
  }

}
