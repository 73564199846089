import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ForgotPasswordInterface } from 'src/app/models/forgotPassword';
import { ValidationResults } from 'src/app/models/validation/validation-results';
import { ForgotPasswordVm } from 'src/app/services/property-matrix/models';
import { AuthenticateService } from 'src/app/services/property-matrix/services';
import Swal from 'sweetalert2';
import { formatWithOptions } from 'util';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(private authService: AuthenticateService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", [Validators.required])
    })
  }

  forgotPassword = (forgotPasswordFormValue) => {
    const forgotPass = { ...forgotPasswordFormValue };

    const forgotPasswordBody: ForgotPasswordVm = {
      email: forgotPass.email,
      clientURI: 'http://localhost:4200/change-password'
    }

    this.authService.apiV1AuthenticateForgotPasswordPost$Json({ body: forgotPasswordBody }).pipe()
      .subscribe(next => {
        GlobalMethods.tinySuccessAlert("Email sent!", "Check your inbox!");
      },
        error => {
          GlobalMethods.tinyErrorAlert("Error", error);
        })
  }
}