import { FormGroup } from '@angular/forms';

// Provide all set of validation messages here
const VALIDATION_MESSAGES = {
  graduateYear: {
    required: 'Graduate year is required'
  },
  institutionOfGraduation: {
    required: 'Institution is required'
  },
  yearsOfExperience: {
    required: 'Years of experience is required'
  },
  nameOfCompany: {
    required: 'Name of company is required'
  },
  mobileNumber: {
    required: 'Mobile number is required',
  },
  email: {
    required: 'Email Address is Required',
    email: 'This email is invalid'
  },
  password: {
    required: 'Password is Required',
    minlength: 'The password length must be greater than or equal to 8'
  },
  confirmPassword: {
    required: 'Re-enter your password',
    match: 'Password does not match'
  },
  firstName: {
    required: 'First Name is Required'
  },
  lastName: {
    required: 'Surname is Required'
  },
  confirmEmail: {
    required: 'Re-enter your email address',
    match: 'Email does not match'
  },
  idNumber: {
    required: 'Identity/Passport Number is Required',
  },
  streetNo: {
    required: 'Street Number is Required',
  },
  streetName: {
    required: 'Street Name is Required',
  },
  suburb: {
    required: 'suburb is Required',
  },
  city: {
    required: 'city is Required',
  },
};

export class GenericValidator {
  // By default the defined set of validation messages is pass but a custom message when the class is called can also be passed
  constructor(private validationMessages: { [key: string]: { [key: string]: string } } = VALIDATION_MESSAGES) { }

  // this will process each formcontrol in the form group
  // and then return the error message to display
  // the return value will be in this format `formControlName: 'error message'`;
  processMessages(container: FormGroup): { [key: string]: string } {
    const messages = {};
    // loop through all the formControls
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        // get the properties of each formControl
        const controlProperty = container.controls[controlKey];
        // If it is a FormGroup, process its child controls.
        if (controlProperty instanceof FormGroup) {
          const childMessages = this.processMessages(controlProperty);
          Object.assign(messages, childMessages);
        } else {
          // Only validate if there are validation messages for the control
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if ((controlProperty.dirty || controlProperty.touched) && controlProperty.errors) {
              // loop through the object of errors
              Object.keys(controlProperty.errors).map(messageKey => {
                if (this.validationMessages[controlKey][messageKey]) {
                  messages[controlKey] += this.validationMessages[controlKey][messageKey] + ' ';
                }
              });
            }
          }
        }
      }
    }
    return messages;
  }
}