<div>
    <form id="signContractForm" novalidate #signContractForm="ngForm" class="submission-form">
        <div class="heading-2 mt-4">STEP 4: SIGN CONTRACT</div>
        <div class="text-center mt-3 paragraph">
            <div class="row">
                <div class="col-12">
                    <p class="mt-4">Thank you for your time</p>
                    <p> An email containing a service level agreement contract will be sent to you upon successful application completion.                                            
                    </p>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 mt-3">
                <button id="btnSaveFinal" class="button heading-4" (click)="submitForm()">NOTED</button>
            </div>
        </div>
    </form>
</div>