<main>
    <div class="heading-3 mt-3 mb-2">
        Projected Development Controls
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="0,1" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialOpenSpaceRequired" #potentialOpenSpace="ngModel"
                name="potentialOpenSpace" min="0" />
            <mat-error *ngIf="potentialOpenSpace.invalid && (potentialOpenSpace.dirty || potentialOpenSpace.touched)">
                <span *ngIf="potentialOpenSpace.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Density/ha (units per ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="80" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialDensity" #density="ngModel" name="density"
                min="0" />
            <mat-error *ngIf="density.invalid && (density.dirty || density.touched)">
                <span *ngIf="density.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">F.A.R.</label>
            <input class="col-12 mt-1" type="number" placeholder="1" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialFloorAreaRatio" #far="ngModel" name="far"
                min="0" />
            <mat-error *ngIf="far.invalid && (far.dirty || far.touched)">
                <span *ngIf="far.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Height (storeys)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialHeight" #height="ngModel" name="height" min="0" />
            <mat-error *ngIf="height.invalid && (height.dirty || height.touched)">
                <span *ngIf="height.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Coverage (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="0,5" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialCoverage" #coverage="ngModel" name="coverage"
                min="0" />
            <mat-error *ngIf="coverage.invalid && (coverage.dirty || coverage.touched)">
                <span *ngIf="coverage.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="2" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialParkingSpaces" #parkingSpaces="ngModel"
                name="parkingSpaces" min="0" />
            <mat-error *ngIf="parkingSpaces.invalid && (parkingSpaces.dirty || parkingSpaces.touched)">
                <span *ngIf="parkingSpaces.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (visitors parking per unit)</label>
            <input class="col-12 mt-1" type="number" placeholder="0,33" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialVisitorsParkingSpaces"
                #visitorsParkingSpaces="ngModel" name="visitorsParkingSpaces" min="0" />
            <mat-error
                *ngIf="visitorsParkingSpaces.invalid && (visitorsParkingSpaces.dirty || visitorsParkingSpaces.touched)">
                <span *ngIf="visitorsParkingSpaces.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100 m² (spaces per 100m²)</label>
            <input class="col-12 mt-1" type="number" placeholder="0" (ngModelChange)="calculateValues()"
                [(ngModel)]="potentialConstructionScopeData.potentialParkingSpacesPerHundredSquareMeters"
                #parkingSpacesPerHundred="ngModel" name="parkingSpacesPerHundred" min="0" />
            <mat-error
                *ngIf="parkingSpacesPerHundred.invalid && (parkingSpacesPerHundred.dirty || parkingSpacesPerHundred.touched)">
                <span *ngIf="parkingSpacesPerHundred.errors?.min">Value must be positive.</span>
            </mat-error>
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Maximum Potential Development
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Open space per hectare of usable space required (ha)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0,09" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialMaximumOpenSpaceRequired" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum units calculated on usable space (units)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="72" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialMaximumUnitsOnUsableSpace" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on FAR (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnFloorAreaRatio" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Maximum m² based on coverage & height (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="9000" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialMaximumSquareMetersBasedOnCoverageAndHeight" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Average size if maximum units are built (m²)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="125" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialMaximumUnitsAverageSize" />
        </div>
    </div>
    <div class="heading-3 mt-4 mb-2">
        Space For Parking
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="2" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialParkingSpacesWithMaxRights" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Visitors parking/unit (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="0.33" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialVisitorsParkingSpacesWithMaxRights" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Parking: per 100m² (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="34" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialParkingSpacesPerHundredSquareMetersWithMaxRights" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Total parking requirement for maximum development (spaces)</label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="168" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialTotalParkingSpacesWithMaxRights" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">
                Maximum parking spaces available on vacant land if fully developed (spaces)
            </label>
            <input class="col-12 mt-1 matrix-data" type="number" placeholder="176" readonly
                [(ngModel)]="potentialConstructionScopeData.potentialParkingSpacesAvailableOnVacantLandWithMaxRights" />
        </div>
    </div>
</main>