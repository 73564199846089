<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
     integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
     crossorigin=""/>
     
<div class="map-container">
    <div class="map-frame">
      <div id="map"></div>
    </div>
    <div id="searchBlock">
      <input type="text"
      placeholder="Search Suburb"
      aria-label="Text"
      [(ngModel)]="mapSearch"
      (input)="searchSuburbs()"
      id="searchInput"
      class="paragraph p-1"
      [matAutocomplete]="auto">
    </div>
      <mat-autocomplete autoActiveFirstOption 
      #auto="matAutocomplete"
      (optionSelected)="selectedPlacesOfLoading($event)">
        <mat-option *ngFor="let suburb of suburbs" 
        [value]="suburb.SUBURB" 
        [id]="suburb.id"
        (click)="selectSuburb(suburb)"
        class="suburb-list">
          <span class="paragraph">{{suburb.SUBURB}}</span>
        </mat-option>
      </mat-autocomplete>
      
  </div>