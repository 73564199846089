import { Component, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var RiskscapeMap: any;
declare var RiskscapeSearch: any;

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, AfterViewInit {

  @Output() propertySelected: EventEmitter<any> = new EventEmitter();

  private apiKey: string = environment.riskscapeApiKey;

  constructor() { }

  ngOnInit(): void {
    this.loadScript('https://services.dev.cloud.riskscape.pro/js/plugins/map/map-0.1.umd.js').then(() => {
      this.loadScript('https://services.dev.cloud.riskscape.pro/js/plugins/search/search-0.1.umd.js').then(() => {
        this.initMapAndSearch();
      });
    });
  }

  ngAfterViewInit(): void { }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject();
      document.body.appendChild(script);
    });
  }

  private initMapAndSearch(): void {
    const map = new RiskscapeMap('map', {
      zoom: 16,
      center: [-33.9249, 18.4241],
    }, { apiKey: this.apiKey });

    const search = new RiskscapeSearch('search', { apiKey: this.apiKey });

    const showProperty = (latlng: any) => {
      map.showFeature('property_scheme', latlng, {
        style: {
          weight: 2,
          color: 'white',
          fillColor: 'orange',
          fillOpacity: 0.2,
        }
      }).then((data: any) => {
        map.fitBounds(map.feature.getBounds());

        if (data.type === 'scheme' || (data.type === 'property' && data.has_sectional_units)) {
          return;
        }

        map.getProperty(data.property_key, '00000').then((property: any) => {
          this.propertySelected.emit(property);
        });
      });
    };

    map.on('click', (e: any) => {
      if (e.originalEvent.ctrlKey) {
        showProperty(e.latlng);
      }
    });

    let marker: any;

    search.on('selected', (result: any) => {
      if (marker) {
        map.removeMarker(marker);
      }

      const { lat, lon } = result.position;

      if (['StreetAddress', 'Scheme'].includes(result.category)) {
        showProperty({ lat, lng: lon });
        return;
      }

      map.setView([lat, lon], 15);
    });
  }
}
