import { ImportantFactorsToConsiderDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-important-factors-to-consider',
  templateUrl: './important-factors-to-consider.component.html',
  styleUrls: ['./important-factors-to-consider.component.scss']
})
export class ImportantFactorsToConsiderComponent implements OnInit {

  @Input() importantFactorsToConsiderData: ImportantFactorsToConsiderDto;
  @Output() importantFactorsToConsiderDataChange = new EventEmitter<ImportantFactorsToConsiderDto>();

  constructor() { }

  ngOnInit(): void { }

  onInputChange() {
    this.importantFactorsToConsiderDataChange.emit(this.importantFactorsToConsiderData);
  }
}
