<main>
	<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
	<div class="container" *ngIf="!isMultiple">
		<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
			<div class="col-12 heading-2">
				<div class="row">
					<div class="col-11">{{ i + 1 }}. {{ section.value }}</div>
					<div class="col-1 collapse-expand">
						<button (click)="collapseOrExpandSection(section)" mat-icon-button>
							<mat-icon *ngIf="!section.checked">expand_less</mat-icon>
							<mat-icon *ngIf="section.checked">expand_more</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
				<app-property-detail [propertyTypes]="propertyTypes"
					[riskscapePropertyInfoData]="riskscapePropertyInfoData"
					[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
					(propertyType)="handlePropertyTypeChange($event)">
				</app-property-detail>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
				<app-address-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData">
				</app-address-detail>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
				<app-locality-map [localityMapFileId]="localityMapFileId"
					(setLocalityMapFileId)="handleSetLocalityMapFileId($event)">
				</app-locality-map>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
				<app-existing-land-use [predominantLandUseData]="predominantLandUseData"
					[additionalLandUseData]="additionalLandUseData"
					[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
					[existingLandUseRightData]="existingLandUseRightData"
					(predominantLandUse)="handlePredominantLandUseChange($event)"
					(additionalLandUse)="handleAdditionalLandUseChange($event)">
				</app-existing-land-use>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
				<app-existing-land-use-right [schemeData]="schemeData" [zoningData]="zoningData"
					[usesPermittedOnSiteData]="usesPermittedOnSiteData"
					[approvedAdditionalUseData]="approvedAdditionalUseData"
					[existingLandUseRightData]="existingLandUseRightData" (schemeName)="handleSchemeChange($event)"
					(currentZoning)="handleCurrentZoningChange($event)"
					(usesPermittedOnSite)="handleUsesPermittedOnSiteChange($event)"
					(approvedAdditionalUses)="handleApprovedAdditionalUsesChange($event)">
				</app-existing-land-use-right>
				<app-zoning-map [zoningMap]="existingLandUseRightData" [zoningMapFileId]="zoningMapFileId"
					(setZoningMapFileId)="handleSetZoningMapFileId($event)">
				</app-zoning-map>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 6" [hidden]="!section.checked">
				<app-spatial-framework-and-policy [planningReportId]="planningReportId"
					(spatialFrameworkChange)="handleSpatialFrameworkChange($event)">
				</app-spatial-framework-and-policy>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 7" [hidden]="!section.checked">
				<app-additional-policy-plan-figure [planningReportId]="planningReportId"
					(additionalPolicyChange)="handleAdditionalPolicyChange($event)">
				</app-additional-policy-plan-figure>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 8" [hidden]="!section.checked">
				<app-area-available [areaAvailableData]="areaAvailableData"
					(potentialUsableSpaceInHectaresChange)="handlePotentialUsableSpaceChange($event)">
				</app-area-available>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 9" [hidden]="!section.checked">
				<div class="form-group">
					<div class="col-12 mt-3" *ngFor="let improvementOption of improvementOptionData; let i = index">
						<button *ngIf="i > 0" (click)="removeImprovementOption(i)" type="button"
							class="mat-icon-button circle-button">
							<mat-icon>remove</mat-icon>
						</button>
						<app-improvement-option [improvementOption]="improvementOption" [improvementOptionIndex]="i">
						</app-improvement-option>
					</div>
					<div class="col-12 mt-3 center-content paragraph">
						<button (click)="addImprovementOption()" class="center-content add-button">
							<mat-icon class="circle-button">add</mat-icon>
							<span class="bold add-button-label">Add Option</span>
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 10" [hidden]="!section.checked">
				<div class="row paragraph mt-3">
					<div class="col-12">
						<label class="col-12 bold">How many parcels do you recommend?</label>
					</div>
				</div>
				<div class="row paragraph mt-2">
					<div class="col-12">
						<mat-radio-group (change)="onLandParcelSelectionChange($event.value)"
							[(ngModel)]="possibleDevelopmentScenarioData.amountOfParcels">
							<div class="radio-container">
								<span style="font-size: small;">
									<mat-radio-button class="radio" [value]="1">
										One
									</mat-radio-button>
								</span>
							</div>
							<div class="radio-container">
								<span style="font-size: small;">
									<mat-radio-button class="radio" [value]="2">
										Two
									</mat-radio-button>
								</span>
							</div>
							<div class="radio-container">
								<span style="font-size: small;">
									<mat-radio-button class="radio" [value]="3">
										Three
									</mat-radio-button>
								</span>
							</div>
							<div class="radio-container">
								<span style="font-size: small;">
									<mat-radio-button class="radio" [value]="4">
										More Than Three
									</mat-radio-button>
								</span>
							</div>
						</mat-radio-group>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 11" [hidden]="!section.checked">
				<div *ngIf="amountOfParcelsOption == 4">
					<div class="row paragraph mt-3">
						<div class="col-12">
							<label class="col-12 bold">
								According to our calculations, based on the interpretation of the relevant planning
								policies, the property appears to have the following development potential:
							</label>
							<textarea class="col-12 mt-1 text-area"
								placeholder="Copy chosen improvement option description."
								[(ngModel)]="possibleDevelopmentScenarioData.proposedPotentialDevelopmentParagraph">
							</textarea>
						</div>
					</div>
				</div>
				<div *ngIf="amountOfParcelsOption > 0 && amountOfParcelsOption <= 3">
					<div class="row paragraph mt-3">
						<div class="col-12">
							<label class="col-12 bold">
								According to our calculations, based on the interpretation of the relevant planning
								policies, the following potential development is proposed:
							</label>
							<input class="col-12 mt-1" type="text"
								placeholder="Copy chosen improvement option description."
								[(ngModel)]="landParcelData.proposedPotentialDevelopment" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-12 mt-3" *ngFor="let landParcel of landParcelData; let i = index">
							<app-land-parcel [landParcel]="landParcel" [landParcelIndex]="i" [schemeData]="schemeData"
								[zoningData]="zoningData" [proposedLandUseData]="proposedLandUseData"
								(schemeName)="handleSchemeChange($event)"
								(proposedZoning)="handleProposedZoningChange($event)"
								(proposedLandUses)="handleProposedLandUsesChange($event)"
								(approvedAdditionalUses)="handleApprovedAdditionalUsesLandParcelChange($event)">
							</app-land-parcel>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 12" [hidden]="!section.checked">
				<app-current-construction-scope [currentConstructionScopeData]="currentConstructionScopeData"
					[potentialUsableProperty]="potentialUsableProperty">
				</app-current-construction-scope>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 13" [hidden]="!section.checked">
				<app-potential-construction-scope [potentialConstructionScopeData]="potentialConstructionScopeData"
					[potentialUsableProperty]="potentialUsableProperty">
				</app-potential-construction-scope>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 14" [hidden]="!section.checked">
				<app-important-factors-to-consider [importantFactorsToConsiderData]="importantFactorsToConsiderData"
					(importantFactorsToConsiderDataChange)="updateImportantFactorsToConsider($event)">
				</app-important-factors-to-consider>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 15" [hidden]="!section.checked">
				<app-cost-estimates [costEstimatesData]="costEstimatesData"
					(costEstimatesDataChange)="updateCostEstimates($event)">
				</app-cost-estimates>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 16" [hidden]="!section.checked">
				<app-way-forward [wayForwardData]="wayForwardData" (wayForwardDataChange)="updateWayForward($event)">
				</app-way-forward>
			</div>
			<div class="col-12 pb-2" *ngIf="section.id == 17" [hidden]="!section.checked">
				<app-planning-report-reference [planningReportId]="planningReportId"
					(referenceChange)="handleReferenceChange($event)">
				</app-planning-report-reference>
			</div>
		</mat-card>
	</div>
	<div class="container" *ngIf="isMultiple">
		<div *ngIf="isGeneralInformation">
			<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
				<div class="col-12 heading-2">
					<div class="row">
						<div class="col-11">{{ section.value }}</div>
						<div class="col-1 collapse-expand">
							<button (click)="collapseOrExpandSection(section)" mat-icon-button>
								<mat-icon *ngIf="!section.checked">expand_less</mat-icon>
								<mat-icon *ngIf="section.checked">expand_more</mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
					<app-locality-map (setLocalityMapFileId)="handleSetLocalityMapFileId($event)"
						[localityMapFileId]="localityMapFileId">
					</app-locality-map>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
					<app-existing-land-use [predominantLandUseData]="predominantLandUseData"
						[additionalLandUseData]="additionalLandUseData"
						[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
						(predominantLandUse)="handlePredominantLandUseChange($event)"
						(additionalLandUse)="handleAdditionalLandUseChange($event)">
					</app-existing-land-use>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
					<app-spatial-framework-and-policy [planningReportId]="planningReportId"
						(spatialFrameworkChange)="handleSpatialFrameworkChange($event)">
					</app-spatial-framework-and-policy>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
					<app-additional-policy-plan-figure [planningReportId]="planningReportId"
						(additionalPolicyChange)="handleAdditionalPolicyChange($event)">
					</app-additional-policy-plan-figure>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
					<app-area-available [areaAvailableData]="areaAvailableData"
						(potentialUsableSpaceInHectaresChange)="handlePotentialUsableSpaceChange($event)">
					</app-area-available>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 6" [hidden]="!section.checked">
					<div class="form-group">
						<div class="col-12 mt-3" *ngFor="let improvementOption of improvementOptionData; let i = index">
							<button *ngIf="i > 0" (click)="removeImprovementOption(i)" type="button"
								class="mat-icon-button circle-button">
								<mat-icon>remove</mat-icon>
							</button>
							<app-improvement-option [improvementOption]="improvementOption"
								[improvementOptionIndex]="i">
							</app-improvement-option>
						</div>
						<div class="col-12 mt-3 center-content paragraph">
							<button (click)="addImprovementOption()" class="center-content add-button">
								<mat-icon class="circle-button">add</mat-icon>
								<span class="bold add-button-label">Add Option</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 7" [hidden]="!section.checked">
					<div class="row paragraph mt-3">
						<div class="col-12">
							<label class="col-12 bold">How many parcels do you recommend?</label>
						</div>
					</div>
					<div class="row paragraph mt-2">
						<div class="col-12">
							<mat-radio-group (change)="onLandParcelSelectionChange($event.value)"
								[(ngModel)]="possibleDevelopmentScenarioData.amountOfParcels">
								<div class="radio-container">
									<span style="font-size: small;">
										<mat-radio-button class="radio" [value]="1">
											One
										</mat-radio-button>
									</span>
								</div>
								<div class="radio-container">
									<span style="font-size: small;">
										<mat-radio-button class="radio" [value]="2">
											Two
										</mat-radio-button>
									</span>
								</div>
								<div class="radio-container">
									<span style="font-size: small;">
										<mat-radio-button class="radio" [value]="3">
											Three
										</mat-radio-button>
									</span>
								</div>
								<div class="radio-container">
									<span style="font-size: small;">
										<mat-radio-button class="radio" [value]="4">
											More Than Three
										</mat-radio-button>
									</span>
								</div>
							</mat-radio-group>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 8" [hidden]="!section.checked">
					<div *ngIf="amountOfParcelsOption == 4">
						<div class="row paragraph mt-3">
							<div class="col-12">
								<label class="col-12 bold">
									According to our calculations, based on the interpretation of the relevant planning
									policies, the property appears to have the following development potential:
								</label>
								<textarea class="col-12 mt-1 text-area"
									placeholder="Copy chosen improvement option description."
									[(ngModel)]="possibleDevelopmentScenarioData.proposedPotentialDevelopmentParagraph">
								</textarea>
							</div>
						</div>
					</div>
					<div *ngIf="amountOfParcelsOption > 0 && amountOfParcelsOption <= 3">
						<div class="row paragraph mt-3">
							<div class="col-12">
								<label class="col-12 bold">
									According to our calculations, based on the interpretation of the relevant planning
									policies, the following potential development is proposed:
								</label>
								<input class="col-12 mt-1" type="text"
									placeholder="Copy chosen improvement option description."
									[(ngModel)]="landParcelData.proposedPotentialDevelopment" />
							</div>
						</div>
						<div class="form-group">
							<div class="col-12 mt-3" *ngFor="let landParcel of landParcelData; let i = index">
								<app-land-parcel [landParcel]="landParcel" [landParcelIndex]="i"
									[schemeData]="schemeData" [zoningData]="zoningData"
									[proposedLandUseData]="proposedLandUseData"
									(schemeName)="handleSchemeChange($event)"
									(proposedZoning)="handleProposedZoningChange($event)"
									(proposedLandUses)="handleProposedLandUsesChange($event)"
									(approvedAdditionalUses)="handleApprovedAdditionalUsesLandParcelChange($event)">
								</app-land-parcel>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 9" [hidden]="!section.checked">
					<app-current-construction-scope [currentConstructionScopeData]="currentConstructionScopeData"
						[potentialUsableProperty]="potentialUsableProperty">
					</app-current-construction-scope>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 10" [hidden]="!section.checked">
					<app-potential-construction-scope [potentialConstructionScopeData]="potentialConstructionScopeData"
						[potentialUsableProperty]="potentialUsableProperty">
					</app-potential-construction-scope>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 11" [hidden]="!section.checked">
					<app-important-factors-to-consider [importantFactorsToConsiderData]="importantFactorsToConsiderData"
						(importantFactorsToConsiderDataChange)="updateImportantFactorsToConsider($event)">
					</app-important-factors-to-consider>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 12" [hidden]="!section.checked">
					<app-cost-estimates [costEstimatesData]="costEstimatesData"
						(costEstimatesDataChange)="updateCostEstimates($event)">
					</app-cost-estimates>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 13" [hidden]="!section.checked">
					<app-way-forward [wayForwardData]="wayForwardData">
					</app-way-forward>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 14" [hidden]="!section.checked">
					<app-planning-report-reference [planningReportId]="planningReportId"
						(referenceChange)="handleReferenceChange($event)">
					</app-planning-report-reference>
				</div>
			</mat-card>
		</div>
		<div *ngIf="!isGeneralInformation">
			<mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
				<div class="col-12 heading-2">
					<div class="row">
						<div class="col-11">{{ section.value }}</div>
						<div class="col-1 collapse-expand">
							<button (click)="collapseOrExpandSection(section)" mat-icon-button>
								<mat-icon *ngIf="!section.checked">expand_less</mat-icon>
								<mat-icon *ngIf="section.checked">expand_more</mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
					<app-property-detail [propertyTypes]="propertyTypes"
						[riskscapePropertyInfoData]="riskscapePropertyInfoData"
						[riskscapeExistingLandUseAndFeatureData]="riskscapeExistingLandUseAndFeatureData"
						(propertyType)="handlePropertyTypeChange($event)">
					</app-property-detail>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
					<app-address-detail [riskscapePropertyInfoData]="riskscapePropertyInfoData">
					</app-address-detail>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
					<app-existing-land-use-right [schemeData]="schemeData" [zoningData]="zoningData"
						[usesPermittedOnSiteData]="usesPermittedOnSiteData"
						[approvedAdditionalUseData]="approvedAdditionalUseData"
						[existingLandUseRightData]="existingLandUseRightData" (schemeName)="handleSchemeChange($event)"
						(currentZoning)="handleCurrentZoningChange($event)"
						(usesPermittedOnSite)="handleUsesPermittedOnSiteChange($event)"
						(approvedAdditionalUses)="handleApprovedAdditionalUsesChange($event)">
					</app-existing-land-use-right>
					<div class="form-group">
						<div class="col-12 mt-3" *ngIf="zoningMapVisible">
							<button (click)="removeZoningMap()" type="button" class="mat-icon-button circle-button">
								<mat-icon>remove</mat-icon>
							</button>
							<app-zoning-map [zoningMap]="existingLandUseRightData" [zoningMapFileId]="zoningMapFileId"
								(setZoningMapFileId)="handleSetZoningMapFileId($event)">
							</app-zoning-map>
						</div>
						<div class="col-12 mt-3 center-content paragraph" *ngIf="!zoningMapVisible">
							<button (click)="addZoningMap()" class="center-content add-button">
								<mat-icon class="circle-button">add</mat-icon>
								<span class="bold add-button-label">Add Zoning Map</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
					<app-planning-report-reference [planningReportId]="planningReportId"
						(referenceChange)="handleReferenceChange($event)">
					</app-planning-report-reference>
				</div>
			</mat-card>
		</div>
	</div>
	<div class="button-group mb-3">
		<button (click)="goToListingOverview()" class="footer-button background-cloud">Previous</button>
		<button (click)="saveReportDetails()" class="footer-button background-coral">Save</button>
		<button (click)="goToAddendumOne()" class="footer-button background-green">Completed</button>
	</div>
	<mat-card class="floating-card">
		<button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
			<mat-icon class="material-icons-outlined">info</mat-icon>
		</button>
		<button class="icon-button" matTooltip="Save Planning Report" (click)="saveReportDetails()">
			<mat-icon class="material-icons-outlined">save</mat-icon>
		</button>
	</mat-card>
</main>