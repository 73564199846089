<main class="main-container">
    <div class="close-button-container col-12 mb-3">
        <button class="close-button" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="row">
        <div class="col-4">
            <div class="heading-5-light mb-2">
                Possible documents/reports:
            </div>
            <ul class="montserrat-bullets paragraph light">
                <li>Title deed</li>
                <li>Property report (Windeed / Lightstone / Searchworks / etc.)</li>
                <li>Body corporate or homeowner association levy account</li>
                <li>Rent roll - for all spaces tenanted</li>
                <li>Planning Approval</li>
                <li>Cost estimates: Civil services</li>
                <li>Cost estimates: Sewer services</li>
                <li>Cost estimates: Electrical services</li>
                <li>Cost estimates: Bulk contributions</li>
                <li>Geotechnical report</li>
                <li>Environmental Impact Assessment</li>
                <li>Access Study</li>
                <li>Traffic Impact Assessment</li>
                <li>Flood line report</li>
                <li>Heritage building report</li>
                <li>Archaeological Study</li>
                <li>Demolition certificate</li>
                <li>Air pollution study</li>
                <li>Noise study</li>
            </ul>
        </div>
        <div class="col-4">
            <div class="heading-5-light mb-2">
                Possible maps/plans:
            </div>
            <ul class="montserrat-bullets paragraph light">
                <li>Locality Map</li>
                <li>Surveyor General (S.G.) Diagrams</li>
                <li>General Plan</li>
                <li>Compilation Plan</li>
                <li>Sectional Plans</li>
                <li>Spatial Development Framework (SDF) map, indicating the property/site</li>
                <li>Site plan</li>
                <li>Site development plan (SDP)</li>
                <li>Building plans</li>
                <li>Architectural designs</li>
                <li>Massing diagram</li>
                <li>Roads Map</li>
            </ul>
        </div>
        <div class="col-4">
            <div class="heading-5-light mb-2">
                Recent pictures of the property:
            </div>
            <ul class="montserrat-bullets paragraph light">
                <li>Street views</li>
                <li>Clear pictures of every building's exterior and interior</li>
                <li>Interior pictures to illustrate the type and quality of building finishes and fittings</li>
                <li>
                    If a building has more than one type of use, interior pictures of each type, e.g. offices, retail,
                    flats, etc.
                </li>
                <li>Any other site improvements or fixtures</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <p class="paragraph light">
                Any other documents/maps pertaining to the property that you feel may be of use to the investigation
            </p>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12">
            <button class="heading-3 button-light" (click)="closeDialog()">
                GOT IT
            </button>
        </div>
    </div>
</main>