import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-improvement-option',
  templateUrl: './improvement-option.component.html',
  styleUrls: ['./improvement-option.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ImprovementOptionComponent implements OnInit {

  @Input() improvementOption: any;
  @Input() improvementOptionIndex: number;

  constructor() { }

  ngOnInit(): void { }
}
