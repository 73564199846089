import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-for-sale',
  templateUrl: './property-for-sale.component.html',
  styleUrls: ['./property-for-sale.component.scss']
})
export class PropertyForSaleComponent implements OnInit {

  pageNumber: Number = 1;

  constructor() { }

  ngOnInit(): void {
  }

  changePage($event) {
    this.pageNumber = $event;
  }

}
