import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-for-sale-offer-to-purchase-form-end',
  templateUrl: './property-for-sale-offer-to-purchase-form-end.component.html',
  styleUrls: ['./property-for-sale-offer-to-purchase-form-end.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PropertyForSaleOfferToPurchaseFormEndComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  finish() {
    this.router.navigateByUrl('');
  }
}
