export class AddressOfCompanyOffice {
    constructor(
        public buildingUnitNo: string = '',
        public estateComplexName: string = '',
        public streetNo: string = '',
        public streetName: string = '',
        public suburb: string = '',
        public city: string = '',
        public registrationNumber: string = '',
        public companyVatNumber: string = '',
        public cityOfTshwane: boolean = false,
        public cityOfJohannesburg: boolean = false,
        public cityOfEkurhuleni:boolean = false,
        public cityOfCapeTown: boolean = false,
        public cityOfeThekwini: boolean = false,
        public municipalCouncilOther: boolean = false,
        public municipalCouncilReason: string = '',
        public townshipEstablishment: boolean = false,
        public fuelStations: boolean = false,
        public retailStoresCentres: boolean = false,
        public environmentsensitiveSites: boolean = false,
        public planningApplicationsOther: string = '',
        public planningApplicationReason: string = ''
    ){}
}
