<main class="main-background" id="main">
    <div class="pt-4">
        <button class="button-color" mat-flat-button (click)="backToOptions()">
            <mat-icon>arrow_back_ios</mat-icon>&nbsp; Continue Shopping
        </button>
    </div>
    <div class="content content-padding">
        <div class="container">
            <div class="md-stepper-horizontal orange text-center">
                <div *ngFor="let step of cartSteps; let i = index" class="md-step" [ngClass]="{'active': step.active}">
                    <button [disabled]="step.key > activePageNumber" (click)="gotoNextStep(step.key)"
                        class="md-step-circle heading-2">{{step.value}}</button>
                    <div class="md-step-bar-left"></div>
                    <div class="md-step-bar-right"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                    <div *ngIf="activePageNumber === 1">
                        <div class="heading-2">ORDER SUMMARY</div>
                        <div class="mt-3 description-border">
                            <div class="m-1">
                                <div class="row heading-5">
                                    <div class="col-6">
                                        Property
                                    </div>
                                    <div class="col-5">
                                        Documents
                                    </div>
                                    <div class="col-1">
                                        price
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let item of cart.cartItems; let cartItemIndex = index" class="item-border">
                            <div class="row">
                                <div class="col-6 heading-4 m-2">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="m-2">
                                <div class="row price-block">
                                    <div class="col-6">
                                        <div style="width: 10vw; border: 1px solid black; height: 10vw;"></div>
                                    </div>
                                    <div class="col-6 paragraph">
                                        <div *ngFor="let document of item.documents; let documentIndex = index">
                                            <div class="row">
                                                <div class="col-10" [ngClass]="{'archived-item': document.archived}">
                                                    <button mat-icon-button class="icon-button-small">
                                                        <mat-icon *ngIf="!document.archived">delete</mat-icon>
                                                        <mat-icon *ngIf="document.archived">add</mat-icon>
                                                        {{document.name}}
                                                    </button>
                                                </div>
                                                <div class="col-2 price-padding">R{{document.price}} -</div>
                                                <hr>
                                            </div>
                                            <div class="row total-top-spacing" *ngIf="cart.cartItems.length == (cartItemIndex + 1) && item.documents.length == (documentIndex + 1)">
                                                <hr class="total-divider">
                                                <div class="col-10">
                                                       Total
                                                </div>
                                                <div class="col-2 price-padding">R{{cart.total}}</div>
                                                <hr class="total-divider total-bottom-spacing">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col"></div>
                            <div class="col-3 text-end mt-4"><button class="button" (click)="gotoNextStep(3)">Check
                                    out</button></div>
                        </div>
                    </div>
                    <div *ngIf="activePageNumber === 3">
                        <form id="billingAddressForm" novalidate #billingAddressForm="ngForm">
                            <section class="submission-form">
                                <div class="submission-form">
                                    <div class="row">
                                        <div class="col-12 heading-5">
                                            Billing address:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo"
                                                class="paragraph">Building/
                                                unit
                                                no:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                                                class="paragraph not-required" [(ngModel)]="billingAddress.unitNo">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                                                class="paragraph">Estate/complex
                                                name:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" id="txtPropertyEstateComplexName"
                                                name="estateComplexName" class="paragraph not-required"
                                                [(ngModel)]="billingAddress.complexName">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertyStreetNo" for="txtPropertyStreetNo"
                                                class="paragraph">Street
                                                no:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo"
                                                name="streetNumber" class="paragraph"
                                                [(ngModel)]="billingAddress.streetNo" required #streetNumber="ngModel"
                                                [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertyStreetName" for="txtPropertyStreetName"
                                                class="paragraph">Street
                                                Name:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" placeholder="Mandatory" id="txtPropertyStreetName"
                                                name="streetName" class="paragraph"
                                                [(ngModel)]="billingAddress.streetName" required #streetName="ngModel"
                                                [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertySuburb" for="txtPropertySuburb"
                                                class="paragraph">Suburb:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" placeholder="Mandatory" id="txtPropertySuburb"
                                                name="suburb" class="paragraph" [(ngModel)]="billingAddress.suburb"
                                                required #suburb="ngModel"
                                                [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPropertyCity" for="txtPropertyCity"
                                                class="paragraph">City:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city"
                                                class="paragraph" [(ngModel)]="billingAddress.city" #city="ngModel"
                                                required
                                                [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Postal
                                                code:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" placeholder="Mandatory" id="txtPropertyCity"
                                                name="postalCode" class="paragraph"
                                                [(ngModel)]="billingAddress.postalCode" #postalCode="ngModel" required
                                                [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 heading-5">
                                            Company Information for invoice purposes:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label for="txtCompanyName" class="paragraph">Company Name:</label>
                                        </div>
                                        <div class="col-6">
                                            <input id="txtCompanyName" [(ngModel)]="companyInvoiceDetails.companyName"
                                                type="text" class="paragraph" name="companyName" #companyName="ngModel">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label for="txtVatNumber" class="paragraph">VAT number:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" id="txtVatNumber"
                                                [(ngModel)]="companyInvoiceDetails.vat_Number" class="paragraph" name="vat_Number" #vat_Number="ngModel">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label for="txtCompanyEmail" class="paragraph">Company email:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" id="txtCompanyEmail"
                                                [(ngModel)]="companyInvoiceDetails.emailAddress" class="paragraph" name="companyEmail" #companyEmail="ngModel">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <label for="txtCompanyMobile" class="paragraph">Company mobile:</label>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" id="txtCompanyMobile"
                                                [(ngModel)]="companyInvoiceDetails.phoneNumber" class="paragraph" name="companyMobile" #companyMobile="ngModel">
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="submission-form">
                                <div class="heading-5">
                                    You will receive the purchased documents via email.
                                    <br>
                                    Feel free to share the property information with prospected buyers once the
                                    following conditions are agreed to:
                                </div>
                                <div *ngFor="let option of conditions; let index = i" class="remove-row-gap">
                                    <label class="checkbox-container">
                                        <strong class="paragraph">{{option.value}}</strong>
                                        <input type="checkbox" [(ngModel)]="option.checked" [name]="conditions"
                                            #conditions="ngModel" required />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </section>
                            <section class="submission-form text-center mt-4">
                                <div class="heading-5">
                                    Thank you for your collaboration.
                                    <br>
                                    You will now be redirected to the secure PayFast engine to make your payment.
                                </div>
                                <button class="button heading-4" [disabled]="canPay() || billingAddressForm.invalid">
                                    PAY VIA PAYFAST
                                </button>
                            </section>
                        </form>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</main>