<form id="propertyDetailsForm" novalidate #propertyDetails="ngForm" class="submission-form" ngForm>
    <section class="submission-form">
        <div class="heading-2">STEP 2: PROPERTY DETAILS</div>
        <form id="searchPropertyForm" novalidate #searchPropertyForm="ngForm" class="submission-form">
            <div class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>I wish to sell:</span>
                </div>
                <mat-radio-group name="iWishToSell" [(ngModel)]="form.wishToSell">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of wishToSellLookup"
                        [value]="selectionValue.id" (change)="setValuesOfWishToSellRadioButtons(selectionValue.id)">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="wishToSellValue == 2" class="mt-3">
                <div class="heading-5">
                    Please note:
                </div>
                <div class="paragraph">
                    Separate properties (separate title deeds) require a different form. However, if you are logged in,
                    the information you have already provided will be auto filled when you list additional properties
                </div>
            </div>
            <div *ngIf="wishToSellValue == 3" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>The joint properties are owned:</span>
                </div>
                <mat-radio-group name="jointPropertiesAreOwned" [(ngModel)]="form.jointPropertiesAreOwned"
                    (change)="jointPropertiesAreOwned == 2? openSideNav(5) : null">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of jointPropertiesLookup"
                        [value]="selectionValue.id" (click)="multipleOwnersEntities(selectionValue.intValue)">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="radioButtonSelected" class="row text-center map-container">
                <div class="map-plugin-container col-12 col-md-8">
                    <app-map-search (propertySelected)="handlePropertySelected($event)"></app-map-search>
                </div>
                <div class="map-sidenav-container col-12 col-md-4">
                    <div class="sidenav-header">
                        <h2>Confirm Property</h2>
                    </div>
                    <div class="sidenav-body">
                        <div *ngIf="cadasterPicture != 1">
                            <div class="sidenav-sub-heading">
                                <h2>Property Boundary</h2>
                            </div>
                            <div id="sidebar-map" class="small-map">
                                <img [src]="cadasterPicture" class="cadastre-image" alt="Cadaster Picture">
                            </div>
                            <div class="button-container">
                                <button class="incorrect-button" [class.selected]="selectedBoundaryButton === 1"
                                    (click)="selectBoundaryButton(1)" confirmPropertyBoundary="1">
                                    Incorrect
                                </button>
                                <button class="unsure-button" [class.selected]="selectedBoundaryButton === 2"
                                    (click)="selectBoundaryButton(2)" confirmPropertyBoundary="2">
                                    Unsure
                                </button>
                                <button class="correct-button" [class.selected]="selectedBoundaryButton === 3"
                                    (click)="selectBoundaryButton(3)" confirmPropertyBoundary="3">
                                    Correct
                                </button>
                            </div>
                        </div>
                        <div class="sidenav-sub-heading">
                            <h2>Property Details</h2>
                        </div>
                        <div class="property-details-table">
                            <table>
                                <tr>
                                    <td>Unit Number</td>
                                    <td>{{ this.unitNumber }}</td>
                                </tr>
                                <tr>
                                    <td>Erf/Portion Nr</td>
                                    <td>{{ this.erfOrPortionNr }}</td>
                                </tr>
                                <tr>
                                    <td>Short Address</td>
                                    <td>{{ this.shortAddress }}</td>
                                </tr>
                                <tr>
                                    <td>Suburb Name</td>
                                    <td>{{ this.suburbName }}</td>
                                </tr>
                                <tr>
                                    <td>City/Town</td>
                                    <td>{{ this.cityTown }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="button-container">
                            <button class="incorrect-button" [class.selected]="selectedButton === 1"
                                (click)="selectButton(1)" confirmPropertyDetail="1">
                                Incorrect
                            </button>
                            <button class="unsure-button" [class.selected]="selectedButton === 2"
                                (click)="selectButton(2)" confirmPropertyDetail="2">
                                Unsure
                            </button>
                            <button class="correct-button" [class.selected]="selectedButton === 3"
                                (click)="selectButton(3)" confirmPropertyDetail="3">
                                Correct
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.confirmPropertyBoundary === 1 || this.confirmPropertyBoundary === 2">
                <div class="heading-3">
                    Property Boundary
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>Please explain your concern regarding the boundary indicated:</span>
                </div>
                <div class="row">
                    <div class="col-8">
                        <textarea style="height: 100px; resize: none;" [matTextareaAutosize]="true"
                            [(ngModel)]="form.propertyBoundaryConcern" id="txtPropertyBoundaryConcern"
                            name="propertyBoundaryConcern" class="paragraph">
                        </textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="confirmPropertyDetail == 1 || confirmPropertyDetail == 2">
                <div class="heading-3">
                    Property Details
                </div>
                <div class="heading-5">
                    Please provide the correct details:
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblPropertyAddress" for="txtPropertyAddress" class="paragraph">
                            Property address
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtPropertyAddress" name="propertyAddress" class="paragraph not-required"
                            [(ngModel)]="form.propertyAddress">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblPropertyDescription" for="txtPropertyDescription" class="paragraph">
                            Property description:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtPropertyDescription" name="propertyDescription"
                            class="paragraph not-required" [(ngModel)]="form.propertyDescription">
                    </div>
                </div>
            </div>
            <div *ngIf="wishToSellValue" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>What is the </span>
                    <span class="coral">current </span>
                    <span>main use of this property?</span>
                </div>
                <mat-radio-group name="mainPropertyUseOptionsRadio" [(ngModel)]="form.mainPropertyUse">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of mainPropertyUseLookup"
                        [value]="selectionValue.id"
                        (change)="setValuesOfMainPropertyUseRadioButtons(selectionValue.id)">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="mainPropertyValue == 6">
                <div class="row">
                    <div class="col-8">
                        <textarea id="txtOtherMainPropertyUse" name="otherMainPropertyUse" class="paragraph" rows="3"
                            [(ngModel)]="form.mainPropertyUseOther" #otherMainPropertyUse="ngModel">
                        </textarea>
                    </div>
                </div>
                <p class="paragraph">
                    <strong>Please note: </strong>
                    <span>
                        Additional information or documents might be required if the property is specialized/unique in
                        nature. If necessary, this will be requested by
                    </span>
                    <strong> Property Matrix </strong>
                    <span>upon receipt of the initial information.</span>
                </p>
            </div>
            <div *ngIf="mainPropertyValue > 1" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>What is the approximate age of the main building(s) on site?</span>
                </div>
                <mat-radio-group name="approximateBuildingAge" [(ngModel)]="form.approximateBuildingAge">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of approximateBuildingAgeLookup"
                        [value]="selectionValue.id"
                        (change)="setValuesOfApproximateBuildingAgeRadioButtons(selectionValue.value, selectionValue.id)">
                        {{ selectionValue.value }}
                        <div *ngIf="form.approximateBuildingAge == selectionValue.id && selectionValue.intValue == 8">
                            <input style="width: 3rem" type="number"
                                [disabled]="form.approximateBuildingAge != selectionValue.id"
                                [(ngModel)]="form.approximateBuildingAgeOther" name="approximateBuildingAgeOther"
                                #approximateBuildingAgeOther="ngModel">
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="approximateBuildingValue > 0 && mainPropertyValue != 1">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>What is the current occupation status of all buildings/units on this property?</span>
                </div>
                <div class="row">
                    <mat-radio-group name="occupationStatus" [(ngModel)]="form.occupationStatus">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of occupationStatusLookup"
                            [value]="selectionValue.id"
                            (change)="setValuesOfOccupationStatusRadioButtons(selectionValue.id)">
                            {{ selectionValue.value }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="occupationStatusValue > 0 && mainPropertyValue != 1 " class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>
                        Are all the buildings/structures which are erected on the property done with duly approved
                        building plans?
                    </span>
                </div>
                <mat-radio-group name="allBuildingsDoneWithApprovedBuildingPlans"
                    [(ngModel)]="form.allBuildingsDoneWithApprovedBuildingPlans">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of approvedBuildingPlansLookup"
                        [value]="selectionValue.intValue" (click)="openBuildingPlansOptionDialog(selectionValue)">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="approvedBuildingPlanValue && mainPropertyValue != 1" class="submission-form">
                <div *ngIf="approvedBuildingPlanValue == 1">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>
                        <span>Do you have copies available of the approved building plans?</span>
                    </div>
                    <div class="row">
                        <mat-radio-group name="hasCopiesOfApprovedBuildingPlans"
                            [(ngModel)]="form.hasCopiesOfApprovedBuildingPlans">
                            <mat-radio-button class="paragraph"
                                *ngFor="let selectionValue of copiesOfApprovedBuildingLookup"
                                [value]="selectionValue.id"
                                (click)="copiesOfApprovedBuildingPlansSelected(selectionValue)">
                                {{ selectionValue.value }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div *ngIf="(form.hasCopiesOfApprovedBuildingPlans || buildingPlansApproved == true) && mainPropertyValue != 1"
                class="mt-3">
                <div class="heading-3">
                    Details for invoice purpose
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>The invoice should be issued to:</span>
                </div>
                <mat-radio-group name="invoiceIssuedTo" [(ngModel)]="form.invoiceIssuedTo">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of invoiceIssuedToLookup"
                        [value]="selectionValue.id" (change)="setValuesOfIssueToRadioButtons(selectionValue.id)">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="issueToValue == 1 && mainPropertyValue != 1" class="mt-3">
                <div class="row">
                    <div class="col-4">
                        <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                            Individual's full names
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                            class="paragraph not-required" [(ngModel)]="individualInvoiceDetails.name">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblPropertyDescription" for="txtIndividualInvoiceSurname" class="paragraph">
                            Individual's surname:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtIndividualInvoiceSurname" name="individualInvoiceSurname"
                            class="paragraph not-required" [(ngModel)]="individualInvoiceDetails.surname">
                    </div>
                </div>
            </div>
            <div *ngIf="issueToValue == 2 && mainPropertyValue != 1" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>Is the entity/organization registered for VAT?</span>
                </div>
                <mat-radio-group name="isTheOrganizationRegisteredForVat"
                    [(ngModel)]="form.isTheOrganizationRegisteredForVat">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of isTheOrganizationRegisteredForVatRadio"
                        [value]="selectionValue.key">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                            Name of organization:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                            class="paragraph not-required" [(ngModel)]="organizationInvoiceDetails.organizationName">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                            Company registration nr
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                            class="paragraph not-required"
                            [(ngModel)]="organizationInvoiceDetails.companyRegistrationNumber">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput" *ngIf="form.isTheOrganizationRegisteredForVat">
                    <div class="col-4">
                        <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                            Vat number
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                            class="paragraph not-required" [(ngModel)]="organizationInvoiceDetails.vatNumber">
                    </div>
                </div>
            </div>
            <div *ngIf="issueToValue && mainPropertyValue != 1" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>
                        Is the address to appear on the invoice the same as the address provided for receiving legal
                        documents/notices?
                    </span>
                </div>
                <mat-radio-group name="isTheAddressToAppearOnInvoice" [(ngModel)]="form.isTheAddressToAppearOnInvoice">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of isTheAddressToAppearOnInvoiceRadio" [value]="selectionValue.key">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="form.isTheAddressToAppearOnInvoice == false && mainPropertyValue != 1" class="mt-3">
                <div class="row">
                    <div class="col-4">
                        <label id="lblOrganizationBuildingNumber" for="txtOrganizationBuildingNumber" class="paragraph">
                            Building/unit no
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationBuildingNumber" name="organizationBuildingNumber"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.unitNo">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblOrganizationComplexOrBuilding" for="txtOrganizationComplexOrBuilding"
                            class="paragraph">
                            Complex/Building:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationComplexOrBuilding" name="organizationcomplexOrBuilding"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.complexName">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblOrganizationStreetNumber" for="txtOrganizationStreetNumber" class="paragraph">
                            Street no:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationStreetNumber" name="organizationStreetNumber"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.streetNo">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblIndividualInvoiceName" for="txtOrganizationStreetName" class="paragraph">
                            Street name:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationStreetName" name="organizationStreetName"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.streetName">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblOrganizationSuburb" for="txtOrganizationSuburb" class="paragraph">
                            Suburb:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationSuburb" name="organizationSuburb"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.suburb">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblOrganizationCity" for="txtOrganizationCity" class="paragraph">
                            City:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtOrganizationCity" name="organizationCity"
                            class="paragraph not-required" [(ngModel)]="physicalAddress.city">
                    </div>
                </div>
            </div>
            <div *ngIf="form.isTheAddressToAppearOnInvoice == false" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>
                        Is the contact details to appear on the invoice the same as the details provided via
                        registration?
                    </span>
                </div>
                <mat-radio-group name="isTheContactDetailsToAppearOnInvoice"
                    [(ngModel)]="form.isTheContactDetailsToAppearOnInvoice">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of isTheContactDetailsToAppearOnInvoiceRadio"
                        [value]="selectionValue.key">
                        {{ selectionValue.value }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <form #contactDetailsForm="ngForm">
                <div *ngIf="form.isTheContactDetailsToAppearOnInvoice == false" class="mt-3">
                    <div class="row">
                        <div class="col-4">
                            <label id="lblOrganizationLandline" for="txtOrganizationLandline" class="paragraph">
                                Landline:
                            </label>
                        </div>
                        <div class="col-8">
                            <input type="text" id="txtOrganizationLandline" name="organizationLandline"
                                class="paragraph not-required" [(ngModel)]="contactInvoiceDetails.landline">
                        </div>
                    </div>
                    <div class="row propertyDetailsBoundaryInput">
                        <div class="col-4">
                            <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                                Mobile:
                            </label>
                        </div>
                        <div class="col-8">
                            <input type="text" id="txtIndividualInvoiceName" name="organizationMobile"
                                class="paragraph not-required" [(ngModel)]="contactInvoiceDetails.mobile">
                        </div>
                    </div>
                    <div class="row propertyDetailsBoundaryInput">
                        <div class="col-4">
                            <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                                Email:
                            </label>
                        </div>
                        <div class="col-8">
                            <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                                class="paragraph not-required" [(ngModel)]="contactInvoiceDetails.emailAddress">
                        </div>
                    </div>
                    <div class="row propertyDetailsBoundaryInput">
                        <div class="col-4">
                            <label id="lblIndividualInvoiceName" for="txtIndividualInvoiceName" class="paragraph">
                                Confirm email:
                            </label>
                        </div>
                        <div class="col-8">
                            <input type="text" id="txtIndividualInvoiceName" name="individualInvoiceName"
                                class="paragraph not-required" [(ngModel)]="contactInvoiceDetails.confirmEmailAddress">
                        </div>
                    </div>
                </div>
            </form>
            <div
                *ngIf="mainPropertyValue === 1 || form.reasonForNotInterested || form.isTheAddressToAppearOnInvoice == true || form.isTheAddressToAppearOnInvoice || form.isTheContactDetailsToAppearOnInvoice || form.isTheContactDetailsToAppearOnInvoice == false">
                <div class="heading-3">
                    Asking Price
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>
                        What amount would you like to receive “in your pocket” for this property (excluding commission
                        and VAT)?
                    </span>
                </div>
                <mat-radio-group name="askingPriceChoice" [(ngModel)]="form.hasAskingPrice">
                    <mat-radio-button class="paragraph" [value]="true" (click)="selectInput(1)">
                        My asking price should be
                        <div *ngIf="form.hasAskingPrice == true">
                            <div class="input-group" *ngFor="let i of [0, 3, 6]; let groupIndex = index">
                                <input class="asking-price-number-input"
                                    *ngFor="let priceNum of priceCombo.slice(i, i+3); let j = index" id="askingPrice"
                                    name="askingPrice" [(ngModel)]="priceCombo[i+j].val" [maxlength]="1" [min]="0"
                                    [max]="9" type="number" size="1" (keydown)="checkPriceInput($event)">
                                <div class="price-label">
                                    {{ getLabelForGroup(groupIndex) }}
                                </div>
                            </div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button class="paragraph" [value]="false">
                        I am uncertain. Property Matrix can conduct an objective value assessment to determine the
                        market value of my property.
                    </mat-radio-button>
                </mat-radio-group>
                <strong *ngIf="form.hasAskingPrice == true" class="paragraph">Please note: </strong>
                <span *ngIf="form.hasAskingPrice == true" class="paragraph">
                    It is essential that your asking price is realistic, thus in line with the current market trends in
                    your area.
                </span>
            </div>
        </form>
        <section
            *ngIf="(form.hasAskingPrice !== null) && (form.hasAskingPrice || form.hasAskingPrice === false || (form.hasAskingPrice === true && form.askingPrice > 0) || form.isTheAddressToAppearOnInvoice === true || !form.isTheContactDetailsToAppearOnInvoice) && form.mainPropertyUse">
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" [disabled]="propertyDetails.invalid" class="button heading-4"
                        (click)="submitForm()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </section>
    </section>
</form>