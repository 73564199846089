import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfessionDto, UrbanPlannerProfessionalDto } from 'src/app/services/property-matrixV2/models';
import { LookupService, UrbanPlannerService } from 'src/app/services/property-matrixV2/services';
import { ProfessionAddDialogComponent } from '../profession-add-dialog/profession-add-dialog.component';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-urban-planner-professional-dialog',
  templateUrl: './urban-planner-professional-dialog.component.html',
  styleUrls: ['./urban-planner-professional-dialog.component.scss']
})
export class UrbanPlannerProfessionalDialogComponent implements OnInit {  
  professions: ProfessionDto[] = [];
  professional : UrbanPlannerProfessionalDto = {
    companyName: '',
    email: '',
    mobile: '',
    name: '',
    profession: {
      value: '',
      order: 0,
    },
    surname: '',
    websiteLink: '',
  }
  dialogTitle: string;
  newProfession : ProfessionDto = {
   order: 0,
   value: '', 
  };
  pleaseConfirmCheckbox = new FormControl(false);

  constructor
  ( private professionalService : UrbanPlannerService,
    private lookupService:LookupService ,
    @Inject(MAT_DIALOG_DATA) public data: { dataKey: UrbanPlannerProfessionalDto, mode: string },
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<UrbanPlannerProfessionalDialogComponent>
  ) { }

  ngOnInit(): void {
    if (this.data.mode == 'Edit professional') {
      this.professional = this.data.dataKey;
      this.dialogTitle = 'Edit professional';
    } else if (this.data.mode == 'Add professional') {
      this.dialogTitle = 'Add extended professional';
    }
    this.loadExistingProfessions();
  }

  loadExistingProfessions() {
  this.lookupService.apiV1LookupGetProfessionsGet().subscribe((response) => {
    this.professions = response.sort((a, b) => a.order - b.order);
   });
  }

  openProfessionalsDialog() {
    const dialogRef = this.dialog.open(ProfessionAddDialogComponent, {
    },);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      this.professions.push(result);
      }
    });
  }

  saveProfessional() {
    let selectedProfession = this.professions.find(x => x.id === this.professional.profession.id);
    this.professional.profession = selectedProfession;
    this.professionalService.apiV1UrbanPlannerAddUrbanPlannerProfessionalAsyncPost({body:this.professional}).subscribe((response : any) => {
    if (response === true) 
    {
      this.dialogRef.close(this.professional);
      this.loadExistingProfessions();
    }
    else
    {
      GlobalMethods.tinyErrorAlert('Error', 'Problem saving professional details. Please try again.');
    }
  });
  }
}
