<div class="inner-block contract-details p-3">
    <div class="collaboration-border"> 
      <h1 class="heading-1 text-center mt-2 coral-text">
          COLLABORATION AGREEMENT
      </h1>
      <div class="m-3 mt-2">
          <div class="row">
              <div class="col-12">
                  <ul class="montserrat-bullets paragraph collaboration-agreement-text">
                        <li>Upon approval as a Property Matrix 'associate planner,' we will refer potential clients in your area/metro to you at no cost</li>
                        <li>Our in-house planners prepare a baseline report (70-80% completion) with deeds and planning data. Your role as a registered planner involves confirming existing land use rights and offering insights on potential land use and construction scope according to municipal policy</li>
                        <li>Any subsequent LUMS work from the client will be directed to you as an independent consultant, not as a Property Matrix associate. Planning applications can be lodged via your firm with your branding</li>
                        <li>Professional planning fee guidelines will be provided on our website. We encourage adherence to these rates to correct market discrepancies. Discussions may be initiated if rates are discounted more than 30% from our guidelines</li>
                        <li>For Property Matrix-listed properties, the investigation report is seen as a 'quote' and is mostly completed at risk. We remunerate R10,000 plus VAT for planning reports that facilitate successful property transactions</li>
                        <li>Clients not pursuing a property transaction can order Level 1, 2, or 3 investigation reports via our platform for a fee</li>
                        <li>Property Matrix refers potential clients to the most suitable planner in our network</li>
                        <li>You'll receive remuneration for completed investigation reports ordered by clients: R1,000 for Level 1 and R4,000 for Level 2 reports contributed to</li>
                        <li>Level 3 reports are project-specific, allowing collaboration with an extended network and providing cost and timeframe quotations to the client</li>
                  </ul>
              </div>
          </div>
          <div class="row">
              <div class="col-12">
                  <span class="paragraph collaboration-agreement-text">
                    Upon successful registration, an online session will delve deeper into the Property Matrix model, the summary above, and 
                    our workflow procedures
                  </span>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-12 text-center">
                  <button id="btnAgree" class="button heading-4" (click)="Agree()">Agree</button>
              </div>
          </div>
      </div>
  </div>
</div>