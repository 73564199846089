<div class="container inner-block paragraph dialog-background">
    <div class="row">
        <div class="col-10 justify-center text-center heading-1-light py-4"
            style="padding-left: 110px;">
            COMING SOON
        </div>
        <div class="col-2 btn-close-align pt-3">
            <button type="button" class="btn-close btn-close-white"
                (click)="closeDialog()" aria-label="Close">
            </button>
        </div>
    </div>
    <div class="text-center commission-table">
        <div class="col-12 pb-4 text-center paragraph light">
            This service will be made available soon.
        </div>
        <div class="text-center">
            <button class="button-light heading-3-light mt-4"
                (click)="closeDialog()">
                Got it
            </button>
        </div>
    </div>
</div>