import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-value-assessment-factors-dialog',
  templateUrl: './value-assessment-factors-dialog.component.html',
  styleUrls: ['./value-assessment-factors-dialog.component.scss']
})
export class ValueAssessmentFactorsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValueAssessmentFactorsDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
