import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { APP_INITIALIZER, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { RouterModule } from '@angular/router';

import { AdminMyProfilePersonalDetailsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-personal-details/admin-my-profile-personal-details-edit-dialog/admin-my-profile-personal-details-edit-dialog.component';
import { TownPlanningApplicationRequestComponent, TimePickerDialogPlanning } from './pages/town-planning-applications/town-planning-applications-request/town-planning-application-request/town-planning-application-request.component';
import { PlannerPropertyDetailsDialogComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-information/dialogs/planner-property-details-dialog/planner-property-details-dialog.component';
import { UrbanPlannerProfessionalDialogComponent } from './pages/register/planner/registration/planner-register-form/urban-planner-professional-dialog/urban-planner-professional-dialog/urban-planner-professional-dialog.component';
import { AdminMyProfileExtendedNetworkDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-extended-network/admin-my-profile-extended-network-dialog/admin-my-profile-extended-network-dialog.component';
import { AdminMyProfileWorkDetailsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-work-details/admin-my-profile-work-details-edit-dialog/admin-my-profile-work-details-edit-dialog.component';
import { PropertyForSaleFormPurchaserDetailsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-purchaser-details/property-for-sale-form-purchaser-details.component';
import { PropertyForSalePurchaseDocumentsDialogComponent } from './pages/property-for-sale/property-for-sale-options/property-for-sale-purchase-documents-dialog/property-for-sale-purchase-documents-dialog.component';
import { AdminMyProfileDocumentsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-documents/admin-my-profile-documents-edit-dialog/admin-my-profile-documents-edit-dialog.component';
import { PropertyForSaleFormPurchasePriceComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-purchase-price/property-for-sale-form-purchase-price.component';
import { PropertyForSaleOfferToPurchaseWithConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-with-conditions/property-for-sale-offer-to-purchase-with-conditions.component';
import { PlannerRegisterFormExtendedNetworkComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-extended-network/planner-register-form-extended-network.component';
import { AdminMyProfileBillingEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-billing/admin-my-profile-billing-edit-dialog/admin-my-profile-billing-edit-dialog.component';
import { AdditionalPolicyPlanFigureComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/additional-policy-plan-figure/additional-policy-plan-figure.component';
import { ImportantFactorsToConsiderComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/important-factors-to-consider/important-factors-to-consider.component';
import { PotentialConstructionScopeComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/potential-construction-scope/potential-construction-scope.component';
import { SpatialFrameworkAndPolicyComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/spatial-framework-and-policy/spatial-framework-and-policy.component';
import { PropertyForSaleFormConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-conditions/property-for-sale-form-conditions.component';
import { PropertyValuationRequestComponent, TimePickerDialogValuation } from './pages/property-valuations/property-valuation-request/property-valuation-request/property-valuation-request.component';
import { CurrentConstructionScopeComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/current-construction-scope/current-construction-scope.component';
import { BenefitsOfLegalTeamDialog, CollaborationDialog, ContractDetailsComponent, DisclaimerContractDetailsDialog } from './pages/selling-my-property/contract-details/contract-details.component';
import { PlannerRegisterFormInformationComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-information/planner-register-form-information.component';
import { PlanningReportReferenceComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/planning-report-reference/planning-report-reference.component';
import { ProfessionAddDialogComponent } from './pages/register/planner/registration/planner-register-form/urban-planner-professional-dialog/profession-add-dialog/profession-add-dialog.component';
import { EstateAgentPersonalInformationComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-personal-information/estate-agent-personal-information.component';
import { PropertyForSaleOfferToPurchaseFormEndComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form-end/property-for-sale-offer-to-purchase-form-end.component';
import { ConveyancerPersonalCompanyDetailsComponent } from './pages/register/conveyancer/option/option_B/conveyancer-personal-company-details/conveyancer-personal-company-details.component';
import { PlannerRegisterFormDocumentsComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-documents/planner-register-form-documents.component';
import { EstateAgentCompanyInformationComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-company-information/estate-agent-company-information.component';
import { ConveyancerPersonalInformationComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-personal-information/conveyancer-personal-information.component';
import { ExistingLandUseRightComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/existing-land-use-right/existing-land-use-right.component';
import { PlannerRegisterFormContractComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-contract/planner-register-form-contract.component';
import { ValueAssessmentFactorsDialogComponent } from './pages/selling-my-property/sell-your-property-dialogs/value-assessment-factors-dialog/value-assessment-factors-dialog.component';
import { AddendumOneReferenceComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-one/addendum-one-reference/addendum-one-reference.component';
import { AddendumTwoReferenceComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-two/addendum-two-reference/addendum-two-reference.component';
import { FormalValuationReportDialogComponent } from './pages/selling-my-property/sell-your-property-dialogs/formal-valuation-report-dialog/formal-valuation-report-dialog.component';
import { EstateAgentSubmitDocumentsComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-submit-documents/estate-agent-submit-documents.component';
import { PropertyForSaleOfferToPurchaseFormComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-offer-to-purchase-form.component';
import { UploadDocumentsExamplesDialogComponent } from './pages/selling-my-property/upload-documents/upload-documents-examples-dialog/upload-documents-examples-dialog.component';
import { ConveyancerFirmInformationComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-firm-information/conveyancer-firm-information.component';
import { ConveyancerSubmitDocumentsComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-submit-documents/conveyancer-submit-documents.component';
import { OtherUnusableSpaceComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-one/other-unusable-space/other-unusable-space.component';
import { FeasibilityStudiesRequestComponent } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { ImprovementOptionComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/improvement-option/improvement-option.component';
import { AdminMyProfilePersonalDetailsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-personal-details/admin-my-profile-personal-details.component';
import { AdminMyProfileExtendedNetworkComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-extended-network/admin-my-profile-extended-network.component';
import { SgDiagramAndPlanComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-two/sg-diagram-and-plan/sg-diagram-and-plan.component';
import { EstateAgentSignContractComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-sign-contract/estate-agent-sign-contract.component';
import { ExistingLandUseComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/existing-land-use/existing-land-use.component';
import { PropertyDetailsBuildingDialog, PropertyDetailsComponent, PropertyDetailsDialog } from './pages/selling-my-property/property-details/property-details.component';
import { ConveyancerSignContractComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-sign-contract/conveyancer-sign-contract.component';
import { PropertyForSaleOfferToPurchaseComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase/property-for-sale-offer-to-purchase.component';
import { PropertyDetailComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/property-detail/property-detail.component';
import { TitleConditionComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/title-condition/title-condition.component';
import { Level3InvestigationsComponent, TimePickerDialogInvestigation } from './pages/property-investigations/level3-investigations/level3-investigations.component';
import { ConveyancerPersonalInfoComponent } from './pages/register/conveyancer/option/option_B/steps/conveyancer-personal-info/conveyancer-personal-info.component';
import { AddressDetailComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/address-detail/address-detail.component';
import { AreaAvailableComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/area-available/area-available.component';
import { CostEstimatesComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/cost-estimates/cost-estimates.component';
import { AdminMyProfileWorkDetailsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-work-details/admin-my-profile-work-details.component';
import { TimePickerDialog } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { LocalityMapComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/locality-map/locality-map.component';
import { ReportKeyDialogComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/report-key-dialog/report-key-dialog.component';
import { LandParcelComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/land-parcel/land-parcel.component';
import { WayForwardComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/way-forward/way-forward.component';
import { AdminMyProfileFavoritesComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-favorites/admin-my-profile-favorites.component';
import { ConveyancerRegistrationComponent } from './pages/register/conveyancer/registration/conveyancer-registration/conveyancer-registration.component';
import { AdminMyProfileDocumentsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-documents/admin-my-profile-documents.component';
import { ValuerSubmitDocumentsComponent } from './pages/register/valuer/valuer-register-form/valuer-submit-documents/valuer-submit-documents.component';
import { ConveyancerRegisterDetailsComponent } from './pages/register/conveyancer/conveyancer-register-details/conveyancer-register-details.component';
import { ZoningMapComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/zoning-map/zoning-map.component';
import { SiteAccessComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-one/site-access/site-access.component';
import { PlanningReportComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/planning-report/planning-report.component';
import { JuristicPersonDialogComponent } from './pages/selling-my-property/ownership-details/juristic-person-dialog/juristic-person-dialog.component';
import { ListingUnderOfferComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-under-offer/listing-under-offer.component';
import { AdminMyProfileBillingComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-billing/admin-my-profile-billing.component';
import { AddendumThreeComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-three/addendum-three.component';
import { NaturalPersonDialogComponent } from './pages/selling-my-property/ownership-details/natural-person-dialog/natural-person-dialog.component';
import { ServitudeComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-two/servitude/servitude.component';
import { EstateAgentRegisterFormComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-register-form.component';
import { ReferenceAndGroupComponent } from './pages/admin-portal/admin-listings/task-overview/reference-and-group/reference-and-group.component';
import { ExportReportComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/export-report/export-report.component';
import { ValuerSignContractComponent } from './pages/register/valuer/valuer-register-form/valuer-sign-contract/valuer-sign-contract.component';
import { ListingDocumentsComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-documents/listing-documents.component';
import { EstateAgentRegistrationComponent } from './pages/register/estate-agent/estate-agent-registration/estate-agent-registration.component';
import { ConveyancerRegisterFormComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-register-form.component';
import { PropertyForSaleCheckoutComponent } from './pages/property-for-sale/property-for-sale-checkout/property-for-sale-checkout.component';
import { AddendumOneComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-one/addendum-one.component';
import { AddendumTwoComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/addendum-two/addendum-two.component';
import { PlannerRegisterFormComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form.component';
import { ValuerInformationComponent } from './pages/register/valuer/valuer-register-form/valuer-information/valuer-information.component';
import { PropertyForSaleOptionsComponent } from './pages/property-for-sale/property-for-sale-options/property-for-sale-options.component';
import { AdminMyProfileSiodComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-siod/admin-my-profile-siod.component';
import { PlannerRegistrationComponent } from './pages/register/planner/registration/planner-registration/planner-registration.component';
import { PropertyForSaleSearchComponent } from './pages/property-for-sale/property-for-sale-search/property-for-sale-search.component';
import { EstateAgentAgreementComponent } from './pages/register/estate-agent/estate-agent-agreement/estate-agent-agreement.component';
import { Level1InvestigationsComponent } from './pages/property-investigations/level1-investigations/level1-investigations.component';
import { Level2InvestigationsComponent } from './pages/property-investigations/level2-investigations/level2-investigations.component';
import { ReportOverviewComponent } from './pages/admin-portal/admin-listings/task-overview/report-overview/report-overview.component';
import { ArchitecturalTechnologistComponent } from './pages/register/architectural-technologist/architectural-technologist.component';
import { EstateAgentProcessesComponent } from './pages/register/estate-agent/estate-agent-processes/estate-agent-processes.component';
import { ListingTasksComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-tasks/listing-tasks.component';
import { ListingUsersComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-users/listing-users.component';
import { AddEditUserDialogComponent } from './pages/admin-portal/admin-users/add-edit-user-dialog/add-edit-user-dialog.component';
import { ConveyancerOptionComponent } from './pages/register/conveyancer/option/conveyancer-option/conveyancer-option.component';
import { SubmissionCompletedComponent } from './pages/selling-my-property/submission-completed/submission-completed.component';
import { UserActionDialogComponent } from './pages/admin-portal/admin-users/user-action-dialog/user-action-dialog.component';
import { TownPlanningApplicationsComponent } from './pages/town-planning-applications/town-planning-applications.component';
import { ListingOverviewComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-overview.component';
import { ValuerRegisterFormComponent } from './pages/register/valuer/valuer-register-form/valuer-register-form.component';
import { OwnershipDetailsComponent } from './pages/selling-my-property/ownership-details/ownership-details.component';
import { UploadDocumentsReportDialog } from './pages/selling-my-property/upload-documents/upload-documents.component';
import { FileUploadMultipleComponent } from './shared/components/file-upload-multiple/file-upload-multiple.component';
import { PropertyInvestigationsComponent } from './pages/property-investigations/property-investigations.component';
import { CollaborationAgreementComponent } from './pages/collaboration-agreement/collaboration-agreement.component';
import { TagsKeyDialogComponent } from './pages/admin-portal/admin-users/tags-key-dialog/tags-key-dialog.component';
import { UploadDocumentsComponent } from './pages/selling-my-property/upload-documents/upload-documents.component';
import { ContactEstateAgentComponent } from './pages/contact/contact-estate-agent/contact-estate-agent.component';
import { ContactTownPlannerComponent } from './pages/contact/contact-town-planner/contact-town-planner.component';
import { TaskOverviewComponent } from './pages/admin-portal/admin-listings/task-overview/task-overview.component';
import { ComingSoonDialogComponent } from './shared/components/coming-soon-dialog/coming-soon-dialog.component';
import { SiodSubscribeComponent } from './pages/share-in-our-database/siod-subscribe/siod-subscribe.component';
import { ListingCardComponent } from './pages/admin-portal/admin-listings/listing-card/listing-card.component';
import { CreateGroupComponent } from './pages/admin-portal/admin-listings/create-group/create-group.component';
import { ShareInOurDatabaseComponent } from './pages/share-in-our-database/share-in-our-database.component';
import { RegisterValuerComponent } from './pages/register/valuer/register-valuer/register-valuer.component';
import { SiodRegisterComponent } from './pages/share-in-our-database/siod-register/siod-register.component';
import { OneTimePassCodeComponent } from './pages/register/one-time-pass-code/one-time-pass-code.component';
import { AdminMyProfileComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile.component';
import { ProgressTrackerComponent } from './shared/components/progress-tracker/progress-tracker.component';
import { PropertyFormComponent } from './pages/selling-my-property/property-form/property-form.component';
import { SiodConfirmComponent } from './pages/share-in-our-database/siod-confirm/siod-confirm.component';
import { GroupCardComponent } from './pages/admin-portal/admin-listings/group-card/group-card.component';
import { PropertyValuationsComponent } from './pages/property-valuations/property-valuations.component';
import { FeasibilityStudiesComponent } from './pages/feasibility-studies/feasibility-studies.component';
import { RegisterChoicesComponent } from './pages/register/register-choices/register-choices.component';
import { PropertyMatrixApiModuleV2 } from './services/property-matrixV2/property-matrix-api-module-v-2';
import { MarketGapAnalysisComponent } from './pages/market-gap-analysis/market-gap-analysis.component';
import { SellingMyPropertyComponent } from './pages/selling-my-property/selling-my-property.component';
import { AdminListingsComponent } from './pages/admin-portal/admin-listings/admin-listings.component';
import { SideMenuComponent } from './pages/admin-portal/admin-listings/side-menu/side-menu.component';
import { BondOriginatorComponent } from './pages/register/bond-originator/bond-originator.component';
import { OptionAComponent } from './pages/register/conveyancer/option/option-a/option-a.component';
import { ValuerStepsComponent } from './pages/register/valuer/valuer-steps/valuer-steps.component';
import { PropertyForSaleComponent } from './pages/property-for-sale/property-for-sale.component';
import { PropertyMatrixApiModule } from './services/property-matrix/property-matrix-api.module';
import { SiodFormComponent } from './pages/share-in-our-database/siod-form/siod-form.component';
import { SiodAddComponent } from './pages/share-in-our-database/siod-add/siod-add.component';
import { AdminUsersComponent } from './pages/admin-portal/admin-users/admin-users.component';
import { EstateAgentComponent } from './pages/register/estate-agent/estate-agent.component';
import { LoginValuersComponent } from './pages/login/login-valuers/login-valuers.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AdminHomeComponent } from './pages/admin-portal/admin-home/admin-home.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import { DateInputComponent } from './shared/components/date-input/date-input.component';
import { MapSearchComponent } from './shared/components/map-search/map-search.component';
import { ContactUsComponent } from './pages/contact/contact-us/contact-us.component';
import { ProgressComponent } from './pages/progress/progress/progress.component';
import { PropertyMatrixSharedService } from './property-matrix-shared.service';
import { PlannerComponent } from './pages/register/planner/planner.component';
import { ValuerComponent } from './pages/register/valuer/valuer.component';
import { RemoveLeadingZerosPipe } from './pipes/remove-leading-zeros.pipe';
import { RegisterComponent } from './pages/register/register.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { InitializeKeycloak } from './services/auth/auth.config';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { MapComponent } from './map/map.component';
import { AppComponent } from './app.component';

@NgModule({
  imports: [
    MatDialogModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatExpansionModule,
    HttpClientModule,
    CommonModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatCardModule,
    ScrollingModule,
    RouterModule,
    MatMenuModule,
    PdfJsViewerModule,
    KeycloakAngularModule,
    MatAutocompleteModule,
    PropertyMatrixApiModule.forRoot({
      rootUrl: environment.api
    }),
    PropertyMatrixApiModuleV2.forRoot({
      rootUrl: environment.api
    })
  ],

  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AboutUsComponent,
    PropertyInvestigationsComponent,
    PropertyValuationsComponent,
    SellingMyPropertyComponent,
    FeasibilityStudiesComponent,
    TimePickerDialog,
    TimePickerDialogValuation,
    MarketGapAnalysisComponent,
    TownPlanningApplicationsComponent,
    Level1InvestigationsComponent,
    ShareInOurDatabaseComponent,
    Level2InvestigationsComponent,
    Level3InvestigationsComponent,
    TimePickerDialogInvestigation,
    PlannerComponent,
    EstateAgentComponent,
    ValuerComponent,
    FeasibilityStudiesRequestComponent,
    TownPlanningApplicationRequestComponent,
    TimePickerDialogPlanning,
    PropertyValuationRequestComponent,
    CollaborationAgreementComponent,
    PlannerRegistrationComponent,
    ContactUsComponent,
    ProgressComponent,
    DragAndDropDirective,
    LoginValuersComponent,
    RegisterValuerComponent,
    EstateAgentAgreementComponent,
    EstateAgentRegistrationComponent,
    ConveyancerOptionComponent,
    ConveyancerPersonalCompanyDetailsComponent,
    ConveyancerRegistrationComponent,
    ConveyancerPersonalInfoComponent,
    OptionAComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    CollaborationDialog,
    DisclaimerContractDetailsDialog,
    SellingMyPropertyComponent,
    BenefitsOfLegalTeamDialog,
    OwnershipDetailsComponent,
    PropertyDetailsComponent,
    PropertyFormComponent,
    ContractDetailsComponent,
    UploadDocumentsComponent,
    UploadDocumentsReportDialog,
    SubmissionCompletedComponent,
    PlannerRegisterFormComponent,
    PlannerRegisterFormInformationComponent,
    PlannerRegisterFormExtendedNetworkComponent,
    PlannerRegisterFormDocumentsComponent,
    PlannerRegisterFormContractComponent,
    EstateAgentRegisterFormComponent,
    EstateAgentSignContractComponent,
    EstateAgentPersonalInformationComponent,
    EstateAgentCompanyInformationComponent,
    EstateAgentSubmitDocumentsComponent,
    ConveyancerRegisterFormComponent,
    ConveyancerFirmInformationComponent,
    ConveyancerPersonalInformationComponent,
    ConveyancerSubmitDocumentsComponent,
    ConveyancerSignContractComponent,
    ConveyancerRegisterDetailsComponent,
    ValuerInformationComponent,
    ValuerSubmitDocumentsComponent,
    ValuerSignContractComponent,
    ValuerStepsComponent,
    ValuerRegisterFormComponent,
    SiodRegisterComponent,
    SiodFormComponent,
    SiodSubscribeComponent,
    MapComponent,
    PropertyForSaleComponent,
    PropertyForSaleSearchComponent,
    PropertyForSaleOptionsComponent,
    PropertyForSalePurchaseDocumentsDialogComponent,
    PropertyForSaleCheckoutComponent,
    ContactEstateAgentComponent,
    ContactTownPlannerComponent,
    PropertyForSaleOfferToPurchaseComponent,
    PropertyForSaleOfferToPurchaseWithConditionsComponent,
    PropertyForSaleOfferToPurchaseFormComponent,
    PropertyForSaleFormConditionsComponent,
    PropertyForSaleFormPurchasePriceComponent,
    PropertyForSaleFormPurchaserDetailsComponent,
    SiodConfirmComponent,
    SiodAddComponent,
    PropertyForSaleOfferToPurchaseFormEndComponent,
    AdminHomeComponent,
    AdminMyProfileComponent,
    AdminMyProfilePersonalDetailsComponent,
    AdminMyProfileWorkDetailsComponent,
    AdminMyProfileDocumentsComponent,
    AdminMyProfilePersonalDetailsEditDialogComponent,
    AdminMyProfileWorkDetailsEditDialogComponent,
    AdminMyProfileDocumentsEditDialogComponent,
    AdminMyProfileSiodComponent,
    AdminMyProfileFavoritesComponent,
    AdminMyProfileBillingComponent,
    AdminMyProfileBillingEditDialogComponent,
    AdminMyProfileExtendedNetworkComponent,
    AdminMyProfileExtendedNetworkDialogComponent,
    PropertyDetailsDialog,
    PropertyDetailsBuildingDialog,
    RegisterChoicesComponent,
    RemoveLeadingZerosPipe,
    NumberToWordsPipe,
    AdminListingsComponent,
    SideMenuComponent,
    ListingCardComponent,
    ListingOverviewComponent,
    ListingTasksComponent,
    TaskOverviewComponent,
    ReportOverviewComponent,
    PlanningReportComponent,
    PropertyDetailComponent,
    AddressDetailComponent,
    LocalityMapComponent,
    ExistingLandUseComponent,
    ExistingLandUseRightComponent,
    ZoningMapComponent,
    SpatialFrameworkAndPolicyComponent,
    AdditionalPolicyPlanFigureComponent,
    AreaAvailableComponent,
    ImprovementOptionComponent,
    LandParcelComponent,
    CurrentConstructionScopeComponent,
    PotentialConstructionScopeComponent,
    TitleConditionComponent,
    PlanningReportReferenceComponent,
    WayForwardComponent,
    AddendumOneComponent,
    AddendumOneReferenceComponent,
    OtherUnusableSpaceComponent,
    SiteAccessComponent,
    AddendumTwoComponent,
    AddendumTwoReferenceComponent,
    ServitudeComponent,
    SgDiagramAndPlanComponent,
    AddendumThreeComponent,
    ExportReportComponent,
    ReferenceAndGroupComponent,
    CreateGroupComponent,
    ListingUnderOfferComponent,
    ListingUsersComponent,
    ListingDocumentsComponent,
    PlannerPropertyDetailsDialogComponent,
    UrbanPlannerProfessionalDialogComponent,
    ProfessionAddDialogComponent,
    GroupCardComponent,
    ComingSoonDialogComponent,
    BondOriginatorComponent,
    ArchitecturalTechnologistComponent,
    NaturalPersonDialogComponent,
    JuristicPersonDialogComponent,
    FileUploadMultipleComponent,
    AdminUsersComponent,
    DataTableComponent,
    AddEditUserDialogComponent,
    UserActionDialogComponent,
    TagsKeyDialogComponent,
    EstateAgentProcessesComponent,
    OneTimePassCodeComponent,
    ProgressTrackerComponent,
    ReportKeyDialogComponent,
    FormalValuationReportDialogComponent,
    UploadDocumentsExamplesDialogComponent,
    ValueAssessmentFactorsDialogComponent,
    ImportantFactorsToConsiderComponent,
    CostEstimatesComponent,
    DateInputComponent,
    MapSearchComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    PropertyMatrixSharedService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  exports: []
})
export class AppModule { }
