import { AddendumTwoDto, AddendumTwoReferenceDto, DiagramAndPlanDto, ServitudeDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { MatDialog } from '@angular/material/dialog';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-addendum-two',
  templateUrl: './addendum-two.component.html',
  styleUrls: ['./addendum-two.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AddendumTwoComponent implements OnInit, OnChanges {

  @Input() addendumTwoData: AddendumTwoDto;
  @Input() listingId: string;

  @Output() tabNumberEvent = new EventEmitter<number>();

  loading: boolean = false;
  addendumTwoId: string = '';

  servitudeData: ServitudeDto[];
  diagramAndPlanData: DiagramAndPlanDto[];
  referenceData: AddendumTwoReferenceDto[];

  sections = [
    { id: 1, value: 'Servitudes', checked: false },
    { id: 2, value: 'SG Diagrams and Plans', checked: false },
    { id: 3, value: 'References', checked: false }
  ]

  constructor(
    public dialog: MatDialog,
    private planningReportService: PlanningReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.addendumTwoId = this.addendumTwoData?.id || '';
    this.loadAllAddendumTwoData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addendumTwoData) {
      this.loadAllAddendumTwoData();
    }
  }

  loadAllAddendumTwoData() {
    this.servitudeData = this.addendumTwoData?.servitudes || [];
    this.diagramAndPlanData = this.addendumTwoData?.diagramsAndPlans || [];
    this.referenceData = this.addendumTwoData?.references || [];
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  saveAddendumTwoDetails() {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdateAddendumTwoPost({
      propertyListingId: this.listingId,
      body: this.addendumTwoData
    }).subscribe({
      next: () => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Successfully saved addendum two details.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not save addendum two details.');
      }
    });
  }

  handleServitudeChange(event: ServitudeDto[]) {
    this.addendumTwoData.servitudes = event;
  }

  handleDiagramAndPlanChange(event: DiagramAndPlanDto[]) {
    this.addendumTwoData.diagramsAndPlans = event;
  }

  handleReferenceChange(event: AddendumTwoReferenceDto[]) {
    this.addendumTwoData.references = event;
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }

  goToAddendumOne() {
    this.tabNumberEvent.emit(2);
  }

  goToAddendumThree() {
    this.tabNumberEvent.emit(4);
  }
}
