import { CurrentConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-current-construction-scope',
  templateUrl: './current-construction-scope.component.html',
  styleUrls: ['./current-construction-scope.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class CurrentConstructionScopeComponent implements OnInit, OnChanges {

  @Input() currentConstructionScopeData: CurrentConstructionScopeDto;
  @Input() potentialUsableProperty: number;

  squareMetersConversion: number = 10000;

  constructor() { }

  ngOnInit(): void {
    this.calculateValues();
  }

  ngOnChanges(): void {
    this.calculateValues();
  }

  calculateValues(): void {
    if (this.currentConstructionScopeData) {

      /**
       * Calcs Section 3: Maximum Potential Development with Existing Rights
       */

      // Open space per hectare of usable space required (potentialUsableProperty * currentOpenSpaceRequired)
      this.currentConstructionScopeData.currentMaximumOpenSpaceRequired = Math.max(0, parseFloat((this.potentialUsableProperty * this.currentConstructionScopeData.currentOpenSpaceRequired).toFixed(2)));

      // Maximum units calculated on usable space (rounded) (potentialUsableProperty * currentDensity)
      this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace = Math.max(0, Math.round(this.potentialUsableProperty * this.currentConstructionScopeData.currentDensity));

      // Maximum m² based on FAR ((potentialUsableProperty * currentFloorAreaRatio) * 10000)
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, parseFloat(((this.potentialUsableProperty * this.currentConstructionScopeData.currentFloorAreaRatio) * this.squareMetersConversion).toFixed(2)));

      // Maximum m² based on coverage & height ((potentialUsableProperty * currentHeight *  currentCoverage) * 10000)
      this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, parseFloat(((this.potentialUsableProperty * this.currentConstructionScopeData.currentHeight * this.currentConstructionScopeData.currentCoverage) * this.squareMetersConversion).toFixed(2)));

      // Average size if maximum units are built (currentMaximumSquareMetersBasedOnCoverageAndHeight / currentMaximumUnitsOnUsableSpace)
      this.currentConstructionScopeData.currentMaximumUnitsAverageSize = Math.max(0, parseFloat((this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnCoverageAndHeight / this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace).toFixed(2)));

      /**
       * Calcs Section 4: Space For Parking When Implementing Existing Max. Rights
       */

      // Parking: per unit (currentMaximumUnitsOnUsableSpace * currentParkingSpaces)
      this.currentConstructionScopeData.currentParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace * this.currentConstructionScopeData.currentParkingSpaces).toFixed(2)));

      // Visitors parking/unit (currentVisitorsParkingSpaces * currentMaximumUnitsOnUsableSpace)
      this.currentConstructionScopeData.currentVisitorsParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.currentConstructionScopeData.currentVisitorsParkingSpaces * this.currentConstructionScopeData.currentMaximumUnitsOnUsableSpace).toFixed(2)));

      // Parking: per 100m² ((currentMaximumSquareMetersBasedOnFloorAreaRatio / 100) * currentParkingSpacesPerHundredSquareMeters)
      this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, parseFloat(((this.currentConstructionScopeData.currentMaximumSquareMetersBasedOnFloorAreaRatio / 100) * this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMeters).toFixed(2)));

      // Total parking requirement for maximum development (currentParkingSpacesWithMaxRights + currentParkingSpacesPerHundredSquareMetersWithMaxRights)
      this.currentConstructionScopeData.currentTotalParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.currentConstructionScopeData.currentParkingSpacesWithMaxRights + this.currentConstructionScopeData.currentParkingSpacesPerHundredSquareMetersWithMaxRights).toFixed(2)));

      // Maximum parking spaces available on vacant land if fully developed ((100% - currentCoverage) * ((potentialUsableProperty - currentMaximumOpenSpaceRequired) * 10000) / 23)
      this.currentConstructionScopeData.currentParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, parseFloat(((1 - this.currentConstructionScopeData.currentCoverage) * ((this.potentialUsableProperty - this.currentConstructionScopeData.currentMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23).toFixed(2)));
    }
  }
}
