<main class="main-background" id="main">
    <div class="content content-padding">
        <div class="container">
            <form id="contactEstateAgentForm" novalidate #contactEstateAgentForm="ngForm" class="submission-form">
                <div class="row">
                    <div class="col"></div>
                    <div class="col-6 heading-1 text-center mb-3 bottom-border">
                        CONTACT ESTATE AGENT
                    </div>
                    <div class="col"></div>
                </div>
                <div class="heading-3">QUERY RELATED TO: ERF 397 WATERKLOOF</div>
                <div class="paragraph">
                    <b>Please note:</b> It is extremely important that you have purchased and read the planning report
                    prior to any queries to our partnering estate agents, as
                    their time is valuable to us. We are keen to assist if your query is related to, or in addition to
                    the information provided in the report
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>I have purchased and read the planning report
                </div>
                <mat-radio-group name="purchasedAndReadPlanningReport" [(ngModel)]="purchasedAndReadPlanningReport">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
                <div class="heading-4">Describe query to planner</div>
                <div class="row paragraph">
                    <div class="col-2">Query 1:</div>
                    <div class="col-10"><textarea name="queryOne" #queryOne="ngModel" [(ngModel)]="contactForm.queryOne"
                            rows="4"></textarea></div>
                </div>
                <div class="row paragraph">
                    <div class="col-2">Query 2:</div>
                    <div class="col-10"><textarea name="queryTwo" #queryTwo="ngModel" [(ngModel)]="contactForm.queryTwo"
                            rows="4"></textarea></div>
                </div>
                <div class="heading-4">
                    Time preferences
                    <div class="paragraph"><b>Should it be required to discuss any uncertainties over the phone, which
                            time during working hours will be most suitable to give
                            you a call?</b></div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div *ngFor="let option of checkDate; let index = i" (click)="openDialog(option.id)">
                            <label class="checkbox-container">
                                <strong class="paragraph">{{option.value}}</strong>
                                <input type="checkbox" [checked]="option.checked" [(ngModel)]="option.checked"
                                    [name]="timePreferenceVMs" #timePreferenceVMs="ngModel" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button id="btnSaveFinal" class="button heading-4"
                            [disabled]="purchasedAndReadPlanningReport == 0 || !(contactForm.queryOne.length > 0 || contactForm.queryTwo.length > 0) || contactEstateAgentForm.invalid"
                            (click)="submitForm()">SUBMIT</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</main>