import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-documents',
  templateUrl: './listing-documents.component.html',
  styleUrls: ['./listing-documents.component.scss']
})
export class ListingDocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
