<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Step 4: Sign Contract
        </div>
    </div>
    <div class="section-container">
        <div class="row paragraph mt-4">
            <p>
                Thank you for your time
            </p>
            <p class="mt-3">
                Please keep an eye on your inbox.
            </p>
            <p>
                Once you are endorsed, your profile will be accessible to access training videos and listings/tasks
                assigned to you.
            </p>
        </div>
    </div>
    <div class="mt-5">
        <button class="button" (click)="submitForm()">NOTED</button>
    </div>
</main>