import { JuristicPersonDialogData } from 'src/app/models/sellingMyProperty/JuristicPersonDialogData';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-juristic-person-dialog',
  templateUrl: './juristic-person-dialog.component.html',
  styleUrls: ['./juristic-person-dialog.component.scss', '../../../../../css/2-modules/_forms.scss',  '../../../../../css/2-modules/_admin-portal.scss']
})
export class JuristicPersonDialogComponent implements OnInit {

  emailPattern: string = GlobalConstants.emailPattern;
  counter: number = 0;

  legalRepresentativeRadio: KeyValuePair[] = [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' }
  ];

  constructor(
    public dialogRef: MatDialogRef<JuristicPersonDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: JuristicPersonDialogData,
  ) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveJuristicPerson(formIsValid: Boolean) {
    if (formIsValid == false) {
      GlobalMethods.tinyErrorAlert("Error", 'Please fill in all required fields');
      return;
    }
    if (formIsValid == true) {
      this.data.juristicPerson.legalRepresentative.confirmedEmail = true;
    }
    this.dialogRef.close(this.data.juristicPerson);
  }

  deleteJuristicPerson() {
    this.data.deleteCoOwner = true;
    this.dialogRef.close(this.data);
  }

  changeLegalRepresentativeYesNo(value: any) {
    if (value == 1) {
      this.data.juristicPerson.areYouLegalRepresentative = true;
    } else {
      this.data.juristicPerson.areYouLegalRepresentative = false;
    }
  }

  scroll(elementId: string) {
    GlobalMethods.scrollToId(elementId);
  }
}
