<main style="background-color: white">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                REGISTRATION FOR PLANNER
                            </h1>
                        </div>
                    </div>
                    <div class="paragraph text-center">
                        <p><strong> This form can be completed in 15-20 minutes, following 4 straightforward steps:</strong></p>               
                    </div>
                    <div class="row">
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    1
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Personal and 
                                    company 
                                    information
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    2
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Details of your 
                                    extended 
                                    professional 
                                    network
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    3
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Submission of 
                                    documents
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    4
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Confirmation email 
                                    with contract upon 
                                    completion
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph m-5">
                        <strong>For STEP 3, please have the following ready to submit in PDF format:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li>Copy of your identification document</li>
                            <li>Updated SACPLAN registration certificat</li>
                            <li>Certified copy of your town and regional planning degree certificat</li>
                            <li>A photo of yourself (for office & admin purposes only)</li>
                            <li>Company logo for marketing purposes</li>
                        </ul>
                        <strong>Optional:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li> Certified copy(ies) of additional professional qualifications/designations</li>
                        </ul>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="paragraph text-center">
                                <button class="button heading-3" id="proceed" (click)="Proceed()" [routerLink]="['planner-register-form']">
                                    PROCEED
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>