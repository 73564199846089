<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="container">
        <mat-card class="row mb-3" *ngFor="let section of sections; let i = index">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.id }}. {{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="!section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row paragraph mt-3 gx-2"
                    *ngFor="let emergencyService of riskscapeEmergencyServiceData; let i = index">
                    <div class="col-12 col-md-6">
                        <label class="col-12 bold">Area</label>
                        <input class="col-12 mt-1 riskscape-data" type="text" readonly
                            [(ngModel)]="emergencyService.area" />
                    </div>
                    <div class="col-12 col-md-3">
                        <label class="col-12 bold">Crow</label>
                        <input class="col-12 mt-1 riskscape-data" type="number" readonly
                            [(ngModel)]="emergencyService.distanceCrow" />
                    </div>
                    <div class="col-12 col-md-3">
                        <label class="col-12 bold">Route</label>
                        <input class="col-12 mt-1 riskscape-data" type="number" readonly
                            [(ngModel)]="emergencyService.distanceRoute" />
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row paragraph mt-4">
                    <div class="heading-3">
                        Building Lines:
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Street Front</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="buildingRestrictionData.streetFront" />
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Other Building Lines</label>
                        <input class="col-12 mt-1" type="text"
                            [(ngModel)]="buildingRestrictionData.otherBuildingLines" />
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Building Lines Restriction Area</label>
                        <input class="col-12 mt-1" type="number"
                            [(ngModel)]="buildingRestrictionData.buildingLinesRestrictionArea" />
                    </div>
                </div>
                <div class="row paragraph mt-4">
                    <div class="heading-3">
                        Servitudes:
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Servitude Restriction Area</label>
                        <input class="col-12 mt-1" type="number"
                            [(ngModel)]="buildingRestrictionData.servitudeRestrictionArea" />
                    </div>
                </div>
                <app-other-unusable-space [addendumOneId]="addendumOneId"
                    (otherUnusableSpaceChange)="handleOtherUnusableSpaceChange($event)">
                </app-other-unusable-space>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Dolomite Risk Category</label>
                        <input class="col-12 mt-1 riskscape-data" type="text" readonly
                            [(ngModel)]="riskscapeSiteAttributeData.dolomiteRiskCategory" />
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
                <app-site-access [addendumOneId]="addendumOneId" (siteAccessChange)="handleSiteAccessChange($event)">
                </app-site-access>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
                <app-addendum-one-reference [addendumOneId]="addendumOneId"
                    (referenceChange)="handleReferenceChange($event)">
                </app-addendum-one-reference>
            </div>
        </mat-card>
    </div>
    <div class="button-group mb-3">
        <button (click)="goToPlanningReport()" class="footer-button background-cloud">Previous</button>
        <button (click)="saveAddendumOneDetails()" class="footer-button background-coral">Save</button>
        <button (click)="goToAddendumTwo()" class="footer-button background-green">Completed</button>
    </div>
    <mat-card class="floating-card">
        <button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
            <mat-icon class="material-icons-outlined">info</mat-icon>
        </button>
        <button class="icon-button" matTooltip="Save Addendum One" (click)="saveAddendumOneDetails()">
            <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
    </mat-card>
</main>