<div class="">
    <h1 class="heading-1-light text-center mt-2 ">
        Benefits of using the Property Matrix Legal Team
    </h1>
    <div class="m-4 mt-2">
        <div class="row">
            <div class="col-12">
                <ul class="montserrat-bullets paragraph light">
                    <li> 
                        Our conveyancers have a nationwide presence and extensive expertise in property development, spatial planning, and land 
                        use management. Their specialist services are backed by over a century of collective conveyancing experience.
                    </li>
                    <li>
                        Our team is trusted by all major financial institutions and investment houses and on the panel of most of the major South 
                        African commercial banks.
                    </li>
                    <li>
                        Our in-house services boast foreseeable faster timelines and estimated turnaround periods in attending to initial work due 
                        to our longstanding collaboration: our conveyancing legal team is familiar with the Property Matrix platform, workflow, and 
                        network partners (i.e. network planners/network agents/network valuers).
                    </li>
                    <li>
                        We attend to the initial due diligence work for your property, including drafting a basic deeds report and will therefore be 
                        well-versed regarding the legal aspects and potential of your property.
                    </li>
                    <li>
                        Our team has served numerous clients, including banks, developers, city councils, and property practitioners. Their 
                        expertise includes handling various types of developments (from residential to industrial and commercial), registration of 
                        mega sectional title schemes, and providing counsel on property development structures.
                    </li>
                    <li>
                        If appointed, they possess the expertise to take care of drafting conveyancing certificates and negotiations with the 
                        council, drafting commercial property sale agreements, and, if required, represent clients in hearings before the Municipal 
                        Planning Tribunal (MPT) in line with SPLUMA and applicable planning legislation/by-laws.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button id="btnAgree" class="button-light heading-4-light" (click)="agree()">GOT IT</button>
            </div>
        </div>
    </div>
</div>