<div class="heading-2">
    <div class="row">
        <div class="col-10">Edit Billing Address</div>
        <div class="col-2 text-end"><button mat-icon-button
                (click)="closeDialogWithNoChanges()"><mat-icon>close</mat-icon></button></div>
    </div>
</div>
<div>
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" name="buildingUnitNumber" class="paragraph not-required"
                    [(ngModel)]="data.data.value.unitNo" #buildingUnitNumber="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" name="estateComplexName" class="paragraph not-required"
                    [(ngModel)]="data.data.value.complexName" #estateComplexName="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetNumber" class="paragraph"
                    [(ngModel)]="data.data.value.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetName" class="paragraph"
                    [(ngModel)]="data.data.value.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="suburb" class="paragraph"
                    [(ngModel)]="data.data.value.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="city" class="paragraph"
                    [(ngModel)]="data.data.value.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
    </section>
</div>
<div class="text-center">
    <button (click)="saveInfo()" class="button">Save</button>
</div>