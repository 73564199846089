import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { FileManagementExtensionService } from 'src/app/shared/services/file-management-extension-service/file-management-extension.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { EstateAgentDocumentDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estate-agent-submit-documents',
  templateUrl: './estate-agent-submit-documents.component.html',
  styleUrls: ['./estate-agent-submit-documents.component.scss']
})
export class EstateAgentSubmitDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() documentsSubmitted = new EventEmitter<void>();

  documents = [
    { description: 'Identification Documents: ID (RSA resident) or Passport (non-resident)' },
    { description: 'Recent Fidelity Fund Certificate' },
    { description: 'Company VAT certificate' },
    { description: 'Qualifications & designations' },
    { description: 'A photo of yourself' },
    { description: 'The company logo' },
    { description: 'Signed Contract' }
  ];

  constructor(
    private _notificationService: NotificationService,
    private _fileManagementExtensionService: FileManagementExtensionService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void { }

  isFileUploaded(index: number): boolean {
    const state = this._estateAgentRegistrationStateService.getFormState();
    return !!state.documents[index];
  }

  onFileSelected(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadFile(file, index);
    }
  }

  async uploadFile(file: File, index: number): Promise<void> {
    try {
      const result = await this._fileManagementExtensionService.uploadFile(file);
      const document: EstateAgentDocumentDto = {
        id: result,
        documentInfo: {
          id: result,
          name: file.name,
          extension: file.type
        }
      };
      this._estateAgentRegistrationStateService.updateDocument(index, document);
    } catch (_error) {
      this._notificationService.showErrorMessage('Error', 'An error occurred while uploading the file.');
    }
  }

  onRemoveFile(index: number, event: Event): void {
    event.stopPropagation();
    this._estateAgentRegistrationStateService.removeDocument(index);
  }

  onSubmitDocuments(): void {
    this.documentsSubmitted.emit();
    this.pageNumberEvent.emit(4);
  }
}
