import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { MultiplePropertyListingDto, PropertyListingDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-listing-tasks',
  templateUrl: './listing-tasks.component.html',
  styleUrls: ['./listing-tasks.component.scss']
})
export class ListingTasksComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;
  @Input() listingType: string;

  phase: number = 1;
  receivedDate: string;

  users = [
    { 'name': 'Reinier Minny', 'role': 'Senior Planner', 'roleInitial': 'P', 'roleColor': '#E5277B' },
    { 'name': 'Storm Vezasie', 'role': 'Junior Planner', 'roleInitial': 'J', 'roleColor': '#E98642' },
    { 'name': 'Karen Minny', 'role': 'Senior Agent', 'roleInitial': 'A', 'roleColor': '#61C8D5' },
    { 'name': 'Amelia Amani', 'role': 'Network Agent', 'roleInitial': 'A', 'roleColor': '#51583D' },
    { 'name': 'Silvia Ankievicz', 'role': 'Network Planner', 'roleInitial': 'P', 'roleColor': '#5AA697' }
  ];

  tasks = [
    { 'number': 1, 'task': 'Reference & Group Properties', 'user': 'Reinier Minny', 'notes': "A note description", 'status': 'Done' },
    { 'number': 2, 'task': 'Allocate Senior Planner & Junior Planner', 'user': 'Reinier Minny', 'notes': "", 'status': 'In Progress' },
    { 'number': 3, 'task': 'Confirm Property & Seller Details', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 4, 'task': 'Confirm CCF/Referral Form', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 5, 'task': 'Allocate Network Agent', 'user': 'Karen Minny', 'notes': "", 'status': 'Awaiting' },
    { 'number': 6, 'task': 'Accept Appointment: NA', 'user': 'Amelia Amani', 'notes': "", 'status': 'Awaiting' },
    { 'number': 7, 'task': 'Agent Site Visit: VA & CMA', 'user': 'Amelia Amani', 'notes': "", 'status': 'Awaiting' },
    { 'number': 8, 'task': 'Planning Report (JP)', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 9, 'task': 'Matrix Value Assessment', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 10, 'task': 'Confirm Value Assessment', 'user': 'Silvia Ankievicz', 'notes': "", 'status': 'Awaiting' },
    { 'number': 11, 'task': 'First Negotiations', 'user': 'Karen Minny', 'notes': "", 'status': 'Awaiting' },
    { 'number': 12, 'task': 'Sort Additional Addendums', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 13, 'task': 'Planning Report (JP)', 'user': 'Storm Vezasie', 'notes': "", 'status': 'Awaiting' },
    { 'number': 14, 'task': 'Check Planning Report', 'user': 'Silvia Ankievicz', 'notes': "", 'status': 'Awaiting' }
  ];

  displayedColumns: string[] = ['number', 'task', 'user', 'notes', 'status'];

  dataSource = new MatTableDataSource(this.tasks);

  constructor(private router: Router) {
    this.tasks = this.tasks.map(task => {
      const user = this.users.find(u => u.name === task.user);
      return {
        ...task,
        role: user.role,
        roleInitial: user.roleInitial,
        roleColor: user.roleColor
      };
    });

    this.dataSource = new MatTableDataSource(this.tasks);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.receivedDate = (this.listingType === 'single') ? this.selectedListing.createdDate : this.selectedListingGroup.createdDate;
  }

  goToTaskOverview(taskNumber: number, taskName: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'taskNumber': taskNumber,
        'taskName': taskName,
        'listingType': this.listingType
      }
    };
    this.router.navigate(['admin/listings/task-overview'], navigationExtras);
  }

  onRowClicked(row: any) {
    this.goToTaskOverview(row.number, row.task);
  }
}
