import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ComingSoonDialogComponent } from '../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-property-investigations',
  templateUrl: './property-investigations.component.html',
  styleUrls: ['./property-investigations.component.scss']
})
export class PropertyInvestigationsComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.openDialog();
  }

  addOverflow = false;
  isMenuOpen = false;
  showLevel1 = false;
  showLevel2 = false;
  showLevel3 = false;
  open = false;
  tabIndex = -1;
  isShow = true;

  toggleColorButton1(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.showLevel3 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }

  toggleColorButton2(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.showLevel3 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorButton3(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevel3 = true;
    this.showLevel2 = false;
    this.showLevel1 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    this.addOverflow = false
  }
}
