/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseService } from '../property-matrix-base-service';
import { PropertyMatrixConfiguration } from '../property-matrix-configuration';
import { PropertyMatrixStrictHttpResponse } from '../property-matrix-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AddFeasibilityStudyVm } from '../models/add-feasibility-study-vm';
import { AddPropertyInvestigationVm } from '../models/add-property-investigation-vm';
import { AddPropertyValuationVm } from '../models/add-property-valuation-vm';
import { AddPropertyInvestigationLevel3Vm } from '../models/add-property-investigation-level-3-vm';
import { AddTownPlanningVm } from '../models/add-town-planning-vm';

@Injectable({
  providedIn: 'root',
})
export class AdditionalServeService extends PropertyMatrixBaseService {
  constructor(
    config: PropertyMatrixConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AdditionalServeCapturePropertyInvestigationPost
   */
  static readonly ApiV1AdditionalServeCapturePropertyInvestigationPostPath = '/api/v1/AdditionalServe/CapturePropertyInvestigation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServeCapturePropertyInvestigationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyInvestigationPost$Response(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationVm
  }
  ): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServeService.ApiV1AdditionalServeCapturePropertyInvestigationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServeCapturePropertyInvestigationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyInvestigationPost(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationVm
  }
  ): Observable<void> {

    return this.apiV1AdditionalServeCapturePropertyInvestigationPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServeCapturePropertyInvestigationLevel3Post
   */
  static readonly ApiV1AdditionalServeCapturePropertyInvestigationLevel3PostPath = '/api/v1/AdditionalServe/CapturePropertyInvestigationLevel3';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServeCapturePropertyInvestigationLevel3Post()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyInvestigationLevel3Post$Response(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationLevel3Vm
  }
  ): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServeService.ApiV1AdditionalServeCapturePropertyInvestigationLevel3PostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServeCapturePropertyInvestigationLevel3Post$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyInvestigationLevel3Post(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationLevel3Vm
  }
  ): Observable<void> {

    return this.apiV1AdditionalServeCapturePropertyInvestigationLevel3Post$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServeCaptureFeasibilityStudyPost
   */
  static readonly ApiV1AdditionalServeCaptureFeasibilityStudyPostPath = '/api/v1/AdditionalServe/CaptureFeasibilityStudy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServeCaptureFeasibilityStudyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCaptureFeasibilityStudyPost$Response(params?: {
    context?: HttpContext
    body?: AddFeasibilityStudyVm
  }
  ): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServeService.ApiV1AdditionalServeCaptureFeasibilityStudyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServeCaptureFeasibilityStudyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCaptureFeasibilityStudyPost(params?: {
    context?: HttpContext
    body?: AddFeasibilityStudyVm
  }
  ): Observable<void> {

    return this.apiV1AdditionalServeCaptureFeasibilityStudyPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServeCaptureTownPlanningPost
   */
  static readonly ApiV1AdditionalServeCaptureTownPlanningPostPath = '/api/v1/AdditionalServe/CaptureTownPlanning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServeCaptureTownPlanningPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCaptureTownPlanningPost$Response(params?: {
    context?: HttpContext
    body?: AddTownPlanningVm
  }
  ): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServeService.ApiV1AdditionalServeCaptureTownPlanningPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServeCaptureTownPlanningPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCaptureTownPlanningPost(params?: {
    context?: HttpContext
    body?: AddTownPlanningVm
  }
  ): Observable<void> {

    return this.apiV1AdditionalServeCaptureTownPlanningPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServeCapturePropertyValuationPost
   */
  static readonly ApiV1AdditionalServeCapturePropertyValuationPostPath = '/api/v1/AdditionalServe/CapturePropertyValuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServeCapturePropertyValuationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyValuationPost$Response(params?: {
    context?: HttpContext
    body?: AddPropertyValuationVm
  }
  ): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServeService.ApiV1AdditionalServeCapturePropertyValuationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServeCapturePropertyValuationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServeCapturePropertyValuationPost(params?: {
    context?: HttpContext
    body?: AddPropertyValuationVm
  }
  ): Observable<void> {

    return this.apiV1AdditionalServeCapturePropertyValuationPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }
}
