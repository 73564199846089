import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: User;

  constructor(private authService: PropertyMatrixSharedService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.userValue;
    this.authService.getById(this.currentUser.userID).pipe(first()).subscribe()
  }
}
