<main class="background-image">
    <div class="content">
        <div class="col-12 text-center">
            <div class="heading-1 mt-3">
                OPPORTUNITY FOR BROKERS & AGENTS
            </div>
            <div class="paragraph mt-4">
                Thank you for visiting our platform.
            </div>
        </div>
        <div class="col-12 mt-4 paragraph">
            <p>
                <b>Property Matrix</b> is a national network of professional town planners, estate
                agents/brokers, property valuers, urban economists, bond originators, and
                conveyancers. Our operations cover Tshwane (Pretoria), Johannesburg, Ekurhuleni,
                Cape Town, and eThekwini (Durban). We invite estate agents, commercial brokers, and
                agencies in these areas to join our network.
            </p>
            <p>
                Upon registration With <b>Property Matrix</b>, you gain instant access to network Of
                professional planners, to assist you in promoting your property listings. We will
                verify the property information gou already gathered and add a professional opinion
                on land use rights ("zoning") and the potential of the property: possible ways to
                develop, extend or enhance the lue of the property.
            </p>
        </div>
        <div class="paragraph mt-4">
            <span class="bold">OUR SERVICES FOR ESTATE AGENTS/BROKERS INCLUDE:</span>
            <div class="service-block-container mt-3">
                <div>
                    <span>PROFESSIONAL</span>
                    <span>SUPPORT NETWORK</span>
                    <mat-icon>school</mat-icon>
                </div>
                <div>
                    <span>TRUSTWORTHY</span>
                    <span>INFORMATION</span>
                    <mat-icon>info</mat-icon>
                </div>
                <div>
                    <span>MORE EXCLUSIVE</span>
                    <span>MANDATES</span>
                    <mat-icon>task</mat-icon>
                </div>
                <div>
                    <span>MARKET RELATED ASKING PRICE</span>
                    <mat-icon>price_check</mat-icon>
                </div>
                <div>
                    <span>FASTER</span>
                    <span>SALES</span>
                    <mat-icon>bolt</mat-icon>
                </div>
            </div>
            <div class="mt-3">
                <ul class="p-2">
                    <li>
                        <b>Professional Support Network:</b>
                        You will be connected to a range of industry experts.
                    </li>
                    <li>
                        <b>Trustworthy Information:</b>
                        Each property listing includes Property Matrix Level 2 town planning report,
                        outlining the property's potential. Click here to view a sample report.
                    </li>
                    <li>
                        <b>Exclusive Mandates:</b>
                        We encourage mandates to provide a range of professional services to sellers
                        at no cost, requiring a dual exclusive mandate with you as the "Agent" and
                        Property Matrix as the "Service Provider".
                    </li>
                    <li>
                        <b>Market Related Asking Price:</b>
                        Our value assessment system ensures that your listings have competitive,
                        market-related asking prices.
                    </li>
                    <li>
                        <b>Buyer Tracing:</b>
                        We email your listing to potential buyers in our national database.
                    </li>
                    <li>
                        <b>Diverse Property Types:</b>
                        Our extensive range of property types and land uses are exceptional, not
                        equalled by any other website you have used to market your listings, whether
                        it be properties relating to business, beauty, retail, religion, education,
                        animals, plants, vehicles, medical services and the list goes on.
                    </li>
                    <li>
                        <b>User-friendly Search Engine:</b>
                        Your listings are uniquely categorized, making it easy for buyers to find
                        exactly what they need.
                    </li>
                </ul>
            </div>
            <div class="mt-5">
                <span class="bold">OUR OFFER</span>
            </div>
            <div class="mt-3">
                <div>
                    Property Matrix offers a <b>free</b>, comprehensive package, including the
                    professional investigation, property valuation (where applicable), and placement
                    of your listing on our pioneering national network.
                </div>
                <div>
                    We collaborate seamlessly with a wide network of estate agents and agencies,
                    allowing you to remain with your current agency or franchise.
                </div>
                <div>
                    Partner with us to secure exclusive mandates, foster client trust, and expedite transparent
                    property sales. This innovative marketing approach promises significant increases in your
                    future sales.
                </div>
                <div>
                    Please proceed below for more information On our commission structure and
                    collaboration agreement. Following successful registration, you'll in access to
                    our FREE training courses, offering insights into the <b>Property Matrix</b>
                    model and our workflow procedures.
                </div>
            </div>
            <div class="button-container">
                <button mat-button (click)="proceed()" class="button-proceed heading-4">PROCEED</button>
            </div>
        </div>
    </div>
</main>