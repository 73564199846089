export class SpecificEmployee {
    constructor(
        public name: string = '',
        public surname: string = '',
        public companyName: string = '',
        public mobile: string = '',
        public email: string = '',
        public linkToWebsite: string = ''
    ) { }
}
