import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { GlobalConstants, UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { FileService } from 'src/app/services/property-matrix/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-my-profile',
  templateUrl: './admin-my-profile.component.html',
  styleUrls: ['./admin-my-profile.component.scss']
})
export class AdminMyProfileComponent implements OnInit {

  userRole: string;
  userRoles = UserRoles;
  fileUploader = new FileUploader();
  profilePicture: any = null;
  profilePictureLoading: boolean = false;
  tab: number = 1;

  tabList = [
    { id: 1, icon: 'person', isActive: false },
    { id: 2, icon: 'work', isActive: false },
    { id: 3, icon: 'description', isActive: false },
    { id: 4, icon: 'grid_view', isActive: false },
    { id: 5, icon: 'star', isActive: false },
    { id: 6, icon: 'shopping_cart', isActive: false },
  ];

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private sharedService: PropertyMatrixSharedService
  ) { }

  ngOnInit(): void {
    this.getProfilePicture();
    this.getUserRole();
    if (this.userRole == UserRoles.urbanPlanner) {
      this.tabList.splice(2, 0, { id: 7, icon: 'group', isActive: false });
    }
  }

  getUserRole() {
    this.userRole = this.sharedService.getUserRole();
  }

  selectTab(tabNumber: number) {
    this.tab = tabNumber;
  }

  onMouseEnter() {
    let editPicture = document.getElementById('edit-my-profile-picture')
    editPicture.style.display = 'block'
  }

  onMouseOut() {
    let editPicture = document.getElementById('edit-my-profile-picture')
    editPicture.style.display = 'none'
  }

  selectUpload(files) {
    this.fileUploader.files = [];
    this.fileUploader.fileBrowseHandler(files);
    let formData = new FormData();
    const username: string = this.sharedService.getUsername();
    this.fileUploader.files.forEach(fileObj => {
      formData.append(username, fileObj.file);
    });
    formData.append('username', username);
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey);

    this.http.post(environment.api + FileService.ApiV1FileUploadProfilePicturePostPath, formData, { headers: headers }).pipe(
      map((result) => {
        return result;
      })).subscribe({
        next: (result) => {
          this.getProfilePicture();
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Problem uploading picture");
        }
      });
  }


  async getProfilePicture() {
    this.profilePictureLoading = true;
    const username: string = this.sharedService.getUsername();
    this.fileService.apiV1FileGetProfilePictureGet$Response({ username: username })
      .pipe(map(m => { return m })).subscribe({
        next: async (result: any) => {
          if (result.body.includes('00000000-0000-0000')) {
            return;
          }
          let dataURI = result.body;
          this.profilePicture = await this.sharedService.getImageFromDataUri(dataURI);
          this.profilePictureLoading = false;
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }
}
