<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="pdf-viewer-container mt-2">
            <ng2-pdfjs-viewer [pdfSrc]="pdfSrc" [download]="false"></ng2-pdfjs-viewer>
        </div>
    </mat-sidenav>
    <main class="main-background" id="main">
        <div class="p-4">
            <button class="button-color" mat-flat-button (click)="backToMap()">
                <mat-icon>arrow_back_ios</mat-icon>&nbsp; Back to map
            </button>
        </div>
        <div class="content content-padding">
            <div class="row">
                <div class="col"></div>
                <div class="col-6 heading-4 text-center mb-3 heading-bottom-border">
                    OPTIONS FOR THIS PROPERTY
                </div>
                <div class="col"></div>
            </div>
            <div class="row">
                <div class="col-7">
                    <mat-card id="property-info">
                        <div class="row">
                            <div class="col-4">
                                <div class="m-1 property-image"></div>
                            </div>
                            <div class="col-8 paragraph">
                                <div class="row">
                                    <div class="col-4">
                                        <strong>Price:</strong>
                                    </div>
                                    <div class="col-8">
                                        <span>R4.5 m</span>
                                    </div>
                                    <div class="col-4">
                                        <strong>Parcel extent: </strong>
                                    </div>
                                    <div class="col-8">
                                        <span>4.3ha</span>
                                    </div>
                                    <div class="col-4">
                                        <strong>Current bulk (±):</strong>
                                    </div>
                                    <div class="col-8">
                                        <span>960m2</span>
                                    </div>
                                    <div class="col-4">
                                        <strong>Current zoning:</strong>
                                    </div>
                                    <div class="col-8">
                                        <span>Residential 2</span>
                                    </div>
                                    <div class="col-4">
                                        <strong>Current use:</strong>
                                    </div>
                                    <div class="col-8">
                                        <span>Multiple residential; Block of flats. Dae ma
                                            porporro dolo quam secat laborum orpostis.</span>
                                    </div>
                                    <div class="col-12">
                                        <strong>Development option 1:</strong>
                                    </div>
                                    <div class="col-12">
                                        <span>Rezone to Residential 3 and add 10 more units. Neque min
                                            nobisciam dolore evellauda net Ollupta volo.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-5">
                    <mat-card class="text-center" id="planning-report">
                        <div class="heading-2 mt-4">
                            PLANNING REPORT
                        </div>
                        <br>
                        <p class="paragraph">Preview an example of our detailed report by professional
                            planners, indicating possible uses, development potential
                            and improvement options of the property</p>
                        <button class="button" (click)="openNav()">PREVIEW</button>
                    </mat-card>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <mat-card class="mt-2">
                        <div class="heading-2 mt-4 text-center">PURCHASE DOCUMENTS</div>
                        <div class="row m-4">
                            <div *ngFor="let doc of purchasableDocuments; let i = index"
                                class="col-3 purchase-block-item">
                                <div class="purchase-doc-block">
                                    <div class="m-4 text-center">
                                        <div class="heading-5 upper-case">{{doc.name}}</div>
                                        <span class="paragraph">{{doc.description}}</span>
                                        <div *ngIf="doc.price" class="heading-5 purchase-doc-bottom purchase-doc-price">
                                            R{{doc.price}}
                                        </div>
                                        <div *ngIf="doc.information"
                                            class="heading-5 purchase-doc-bottom purchase-doc-info">
                                            <button mat-icon-button (click)="openDialog();">
                                                <mat-icon>info</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button class="add-to-cart-button button mt-2" [disabled]="doc.addedToCart">
                                    <span *ngIf="doc.addedToCart">
                                        <mat-icon *ngIf="doc.addedToCart" class="coral-text"
                                            style="vertical-align: middle;">done</mat-icon>
                                        ADDED
                                    </span>
                                    <span *ngIf="!doc.addedToCart">ADD TO CART</span>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-6">
                    <mat-card class="info-card">
                        <div class="heading-3 mt-3 text-center">CONTACT US</div>
                        <div class="row mt-3">
                            <div class="col-6 text-center">
                                <button mat-fab class="hover-icon" (click)="gotoContactEstateAgent()">
                                    <mat-icon>mail</mat-icon>
                                </button>
                                <div class="mt-4">
                                    <div class="heading-5">CONTACT ESTATE AGENT</div>
                                    <p class="paragraph mt-3">Queries regarding the physical condition, fixtures or
                                        to view
                                        <u>this</u> property
                                    </p>
                                </div>
                            </div>
                            <div class="col-6 text-center">
                                <button mat-fab class="hover-icon" (click)="gotoContactTownPlanner()">
                                    <mat-icon>mail</mat-icon>
                                </button>
                                <div class="mt-4">
                                    <div class="heading-5">CONTACT PLANNER</div>
                                    <p class="paragraph mt-3">Queries on the zoning or development potential of
                                        <u>this</u>
                                        property
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-6">
                    <mat-card class="info-card">
                        <div class="heading-3 mt-3 text-center">MAKE AN OFFER</div>
                        <div class="row mt-3">
                            <div class="col-6 text-center">
                                <button mat-fab class="hover-icon" (click)="gotoMakeOffer()">
                                    <mat-icon>assignment</mat-icon>
                                </button>
                                <div class="mt-4">
                                    <div class="heading-5">MAKE AN IMMEDIATE OFFER</div>
                                    <p class="paragraph mt-3">An offer on this property, as
                                        presented, with all defects known
                                        and unknown (thus “voetstoots”).</p>
                                </div>
                            </div>
                            <div class="col-6 text-center">
                                <button mat-fab class="hover-icon" (click)="gotoMakeOfferWithConditions()">
                                    <mat-icon>note_alt</mat-icon>
                                </button>
                                <div class="mt-4">
                                    <div class="heading-5">MAKE AN OFFER : ADD CONDITIONS</div>
                                    <p class="paragraph mt-3">Your offer may be subject to
                                        condition(s) e.g., another property
                                        that needs to be sold.</p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <mat-card class="text-center">
                        <div class="heading-3">
                            ANNEXURE 1
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <button mat-fab class="hover-icon">
                                    <mat-icon>photo_camera</mat-icon>
                                </button>
                                <div class="heading-5 mt-3">MAKE AN IMMEDIATE OFFER</div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-6">
                    <mat-card class="text-center">
                        <div class="heading-3">
                            SHARE THIS PROPERTY
                        </div>
                        <div class="row mt-3">
                            <div class="col-3 text-center">
                                <button mat-fab class="hover-icon">
                                    <mat-icon>mail</mat-icon>
                                </button>
                                <div class="heading-5 mt-3">MAIL</div>
                            </div>
                            <div class="col-3 text-center">
                                <button mat-fab class="hover-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-instagram" viewBox="0 0 16 16">
                                        <path
                                            d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                    </svg>
                                </button>
                                <div class="heading-5 mt-3">INSTAGRAM</div>
                            </div>
                            <div class="col-3 text-center">
                                <button mat-fab class="hover-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg>
                                </button>
                                <div class="heading-5 mt-3">FACEBOOK</div>
                            </div>
                            <div class="col-3 text-center">
                                <button mat-fab class="hover-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                        class="bi bi-instagram" viewBox="0 0 16 16">
                                        <path
                                            d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                    </svg>
                                </button>
                                <div class="heading-5 mt-3">TWITTER</div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

        </div>
    </main>
</mat-sidenav-container>