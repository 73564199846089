import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControlName } from '@angular/forms';
import { Observable, fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UrbanPlannerDetail } from 'src/app/models/registrationForms/urban-planner-detail';
import { Validators } from 'src/app/models/validation/validators';
import { FileUploader } from 'src/app/models/file-uploader';

@Component({
  selector: 'app-conveyancer-personal-company-details',
  templateUrl: './conveyancer-personal-company-details.component.html',
  styleUrls: ['./conveyancer-personal-company-details.component.scss']
})
export class ConveyancerPersonalCompanyDetailsComponent {
  files: any[] = [];
  urbanPlannerDetailModel = new UrbanPlannerDetail();
  validators = new Validators();
  fileUploader = new FileUploader();
  public registrationTypeSelection: string[] = ['Professional planner', 'Technical planner', 'Candidate planner'];

  constructor() { }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }
}