<div class="progress-tracker">
    <div *ngFor="let step of steps; let i = index" class="step"
        [ngClass]="{
           'medium-step': isMediumScreen,
           'small-step': isSmallScreen,
           'current-step': i === currentStep
         }">
        <a (click)="onStepClick(step.link)" class="step-container">
            {{ isMediumScreen ? 'STEP ' + (i + 1) : step.label }}
        </a>
    </div>
</div>