import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estate-agent-registration',
  templateUrl: './estate-agent-registration.component.html',
  styleUrls: ['./estate-agent-registration.component.scss']
})
export class EstateAgentRegistrationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  proceed() {
    this.router.navigateByUrl('/estate-agent-processes');
  }
}
