import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-valuer-sign-contract',
  templateUrl: './valuer-sign-contract.component.html',
  styleUrls: ['./valuer-sign-contract.component.scss']
})
export class ValuerSignContractComponent implements OnInit {

  constructor(private router: Router) { }

  submitForm() {
    this.router.navigateByUrl('');
  }

  ngOnInit(): void { }
}
