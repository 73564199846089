import { EstateAgentDto, EstateAgentPersonalInformationDto, EstateAgentCompanyInformationDto, EstateAgentDocumentDto } from 'src/app/services/property-matrixV2/models';
import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estate-agent-register-form',
  templateUrl: './estate-agent-register-form.component.html',
  styleUrls: ['./estate-agent-register-form.component.scss']
})
export class EstateAgentRegisterFormComponent implements OnInit {

  pageNumber: number = 1;
  loading: boolean = false;

  personalInformation!: EstateAgentPersonalInformationDto;
  companyInformation!: EstateAgentCompanyInformationDto;
  documents: EstateAgentDocumentDto[] = [];

  steps = [
    { label: 'PERSONAL INFORMATION', link: 1 },
    { label: 'COMPANY INFORMATION', link: 2 },
    { label: 'SUBMIT DOCUMENTS', link: 3 },
    { label: 'SIGN CONTRACT', link: 4 }
  ];

  constructor(
    private _notificationService: NotificationService,
    private _registrationService: RegistrationService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  selectPage(pageNumber: number) {
    this.pageNumber = pageNumber;
  }

  changePage(page: number) {
    this.pageNumber = page;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  onDocumentsSubmitted(): void {
    this.submit();
  }

  submit() {
    this.loading = true;
    const formState: EstateAgentDto = this._estateAgentRegistrationStateService.getFormState();

    this._registrationService.apiV1RegistrationRegisterEstateAgentPost({
      body: formState
    }).subscribe({
      next: () => {
        this._notificationService.showSuccessMessage('Success', 'Registration successful.');
        this.loading = false;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'An error occurred while registering.');
        this.loading = false;
      }
    });
  }
}
