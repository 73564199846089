import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ComingSoonDialogComponent } from '../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-feasibility-studies',
  templateUrl: './feasibility-studies.component.html',
  styleUrls: ['./feasibility-studies.component.scss']
})
export class FeasibilityStudiesComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.openDialog();
  }

  newColorButton1 = false;
  newColorButton2 = false;
  newColorButton3 = false;
  isMenuOpen = false;
  showLevel1 = false;
  showLevel2 = false;
  open = false;
  isShow = true;
  tabIndex = -1;

  toggleColorButton1(index: number) {
    this.newColorButton1 = !this.newColorButton1;
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorButton2(index: number) {
    this.newColorButton2 = !this.newColorButton2;
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    if (this.newColorButton1 == true) {
      this.newColorButton1 = false
    }
    if (this.newColorButton2 == true) {
      this.newColorButton2 = false
    }
  }
}
