<main class="dialog-container">
    <div class="dialog-content">
        <h2 class="heading-2 uppercase mb-3">Key:</h2>
        <div *ngFor="let key of keys" class="paragraph">
            <p [ngStyle]="{'color': key.color}">{{ key.name }}</p>
        </div>
    </div>
    <div class="button-group">
        <button class="footer-button background-coral" (click)="closeDialog()">Confirm</button>
    </div>
</main>