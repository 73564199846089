<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main class="container-fluid">
    <div class="heading-1 py-3 text-center">
        PROPERTY VALUATION
    </div>
    <div class="paragraph">
        <p>Thank you for your enquiry. Please provide your details below and one of our professional planners will be
            keen to give
            you a call within the time slots you prefer.</p>
        <div>
            Be assured that all information provided will be protected in terms of our <a class="privacy-policy-link"
                href="#">Privacy Policy</a>
        </div>
    </div>
    <form id="valuationForm" (ngSubmit)="submit(form)" novalidate #form="ngForm">
        <section>
            <div class="heading-2">
                Property details
            </div>
            <div class="heading-5 remove-row-gap shift-left">
                <span class="burnt-red">| </span>Is the property to be valued already listed on the Property Matrix
                website?
            </div>
            <div>
                <mat-radio-group [(ngModel)]="listedProperty" [ngModelOptions]="{standalone: true}"
                    (change)="setListedProperty()">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of listedPropertySelection"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </section>
        <div *ngIf="listedProperty == 1">
            <div class="paragraph py-2">
                Great. This means that Property Matrix has already done a planning investigation on this property as
                well as a market value assessment. Fees payable for a formal property valuation will therefore be
                reduced.
            </div>
        </div>
        <div *ngIf="listedProperty == 2">
            <div class="paragraph py-2">
                It will be extremely beneficial and time saving if we can do a quick desktop search to have a glance at
                the property at stake (location and surrounding infrastructure) prior to contacting you.
            </div>
            <div class="heading-5">
                Physical address of the property <span class="burnt-red">to be investigated:</span>
            </div>
            <section>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="buildingUnitNo">Building/ unit no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" class="paragraph not-required" id="buildingUnitNo"
                            [(ngModel)]="propertyDetails.unitNo" name="unitNo">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="estateComplexName">Estate/complex name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" class="paragraph not-required" id="estateComplexName"
                            [(ngModel)]="propertyDetails.complexName" name="complexName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="txtPropertyStreetNo" class="paragraph">Street
                            no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNo"
                            class="paragraph" [(ngModel)]="propertyDetails.streetNo" required #streetNo="ngModel"
                            [ngClass]="{'invalid-input': streetNo.invalid, 'valid-input': streetNo.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="txtPropertyStreetName" class="paragraph">Street
                            Name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                            class="paragraph" [(ngModel)]="propertyDetails.streetName" required #streetName="ngModel"
                            [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="txtPropertySuburb" class="paragraph">Suburb:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb"
                            class="paragraph" [(ngModel)]="propertyDetails.suburb" required #suburb="ngModel"
                            [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="txtPropertyCity" class="paragraph">City:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                            [(ngModel)]="propertyDetails.city" #city="ngModel" required
                            [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="txtPropertyDescription" class="paragraph">Property
                            description</label>
                    </div>
                    <div class="col-6">
                        <textarea id="txtPropertyDescription" name="propertyDescription" class="paragraph not-required"
                            placeholder="Erf 386 Meyerton | Holding 23 of Raslouw Agricultural Holdings | Portion 77 of the Farm Stoneybrooke 365-JR"
                            rows="3" [(ngModel)]="propertyDescription.propertyDescription"
                            #propertyDescription="ngModel"></textarea>
                        <div class="charcoal paragraph">
                            *indicated on your municipal rates account or your title deed
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="pb-3">
            <div class="heading-5 remove-row-gap shift-left">
                <span class="burnt-red">| </span>What is the main purpose of the property valuation?
            </div>
            <div *ngFor="let option of mainPurposes; let index = i" class="remove-row-gap">
                <label class="checkbox-container" (change)="isOtherSelected()">
                    <strong class="paragraph">{{option.value}}</strong>
                    <input type="checkbox" [(ngModel)]="option.checked" [name]="mainPurpose" #mainPurpose="ngModel"
                        required />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div *ngIf="otherCurrentMainUse == true">
                <textarea class="paragraph other" name="mainPurposeOther" id="mainPurposeOther"
                    [(ngModel)]="queryBody.mainPurposeOther"></textarea>
            </div>
        </section>
        <section>
            <div class="heading-5 shift-left remove-row-gap">
                <span class="burnt-red">| </span>What is the current main use of the property:
            </div>
            <div>
                <mat-radio-group name="radioCurrentMainUse" [(ngModel)]="queryBody.currentMainUse">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of currentMainUses"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="queryBody.currentMainUse == 6">
                <textarea class="paragraph other" name="currentMainUseOther" id="currentMainUseOther"
                    [(ngModel)]="queryBody.currentMainUseOther"></textarea>
            </div>
        </section>
        <section *ngIf="queryBody.currentMainUse != 1">
            <div class="heading-5 shift-left remove-row-gap">
                <span class="burnt-red">| </span>Type of <span *ngIf="queryBody.currentMainUse == 2">residential</span>
                <span *ngIf="queryBody.currentMainUse == 3">industrial</span>
                <span *ngIf="queryBody.currentMainUse == 4">office</span>
                <span *ngIf="queryBody.currentMainUse == 5">retail</span> property:
            </div>
            <div>
                <mat-radio-group name="radioPropertyType" [(ngModel)]="queryBody.propertyType">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of propertyTypes"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="queryBody.propertyType == 7">
                <textarea class="paragraph other" name="propertyTypeOther" id="propertyTypeOther"
                    [(ngModel)]="queryBody.propertyTypeOther"></textarea>
            </div>
        </section>
        <section>
            <div class="heading-5 shift-left remove-row-gap">
                <span class="burnt-red">| </span>What is the current occupation status of this property?
            </div>
            <div>
                <mat-radio-group name="radioCurrentOccupation" [(ngModel)]="queryBody.currentOccupation">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of currentOccupationSelections"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </section>
        <section>
            <div class="heading-5 remove-row-gap">
                Do you have copies available of approved building plans or "as built" plans?
            </div>
            <div>
                <mat-radio-group name="radioBuildingPlans" [(ngModel)]="queryBody.buildingPlans">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of buildingPlansSelections"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </section>
        <section>
            <div class="heading-2">
                Personal details
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                        [(ngModel)]="personalDetails.name" required #name="ngModel"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                        [(ngModel)]="personalDetails.surname" required #surname="ngModel"
                        [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalLandline" for="txtPersonalLandline" class="paragraph">Landline:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPersonalLandline" name="landLine" class="paragraph not-required"
                        [(ngModel)]="personalDetails.landline">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph"
                        class="paragraph">Mobile:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                        class="paragraph" [(ngModel)]="personalDetails.phoneNumber" required #phoneNumber="ngModel"
                        minlength="10"
                        [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                        *Mobile must contain at least the 10 numbers
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonAlternativemobile" for="txtPersonalAlternativeMobile"
                        class="paragraph">Alternative
                        Mobile:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPersonalAlternativeMobile" name="alternativeNumber"
                        class="paragraph not-required" [(ngModel)]="personalDetails.alternativeNumber">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                        Address:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="email"
                        class="paragraph" [(ngModel)]="personalDetails.emailAddress" required #email="ngModel"
                        [pattern]="emailPattern" [ngClass]="{'invalid-input': email.invalid, 
                                                            'valid-input': email.valid}">
                    <small class="form-control-feedback" *ngIf="email.errors && (email.dirty || email.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                        Email:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                        [(ngModel)]="personalDetails.confirmEmail" class="paragraph" required #confirmEmail="ngModel"
                        [pattern]="emailPattern" (change)="checkValidation(email, confirmEmail)"
                        [ngClass]="{'invalid-input': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                                'valid-input': (email.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                    <small class="form-control-feedback" [ngClass]="{'confirmEmail-invalid': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                                'confirmEmail-valid': email.value == confirmEmail.value}">
                        *Confirm Email does not match Email Address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">Identity/Passport
                        Number:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                        class="paragraph" [(ngModel)]="personalDetails.id_Number" required #id_Number="ngModel"
                        minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                            'valid-input': id_Number.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                        *Must must contain at least 9 characters
                    </small>
                </div>
            </div>
        </section>
        <section>
            <div class="row remove-row-gap">
                <div class="col-12 heading-3 shift-left">
                    <span class="burnt-red">| </span>TIME PREFERENCES:
                </div>
                <div class="col-12 heading-5">
                    Which times, within the next 3 working days, will be most suitable to give you a call?
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div *ngFor="let option of checkDate; let index = i" (click)="openDialog(option.id)">
                        <label class="checkbox-container">
                            <strong class="paragraph">{{option.value}}</strong>
                            <input type="checkbox" [checked]="option.checked" [(ngModel)]="option.checked"
                                [name]="timePreferenceVMs" #timePreferenceVMs="ngModel" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <p>
            </p>
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" class="button heading-4" type="submit"
                        [disabled]="form.invalid || validateTimePreference()">SUBMIT</button>
                </div>
            </div>
        </section>
    </form>
</main>