<mat-card class="mt-4 outlined-card">
    <div style="background-color: white; width: 100%; min-height: 38vh;">
      <div class="row">
        <div class="col-4">
          <div class="m-3" style="border: solid black 1px; width: 90%; height: 100%"></div>
        </div>
        <div class="col-8 mt-3 paragraph">
          <div class="row">
            <div class="col-4">
              <strong>Price:</strong>
            </div>
            <div class="col-8">
              <span>R4.5 m</span>
            </div>
            <div class="col-4">
              <strong>Parcel extent: </strong>
            </div>
            <div class="col-8">
              <span>4.3ha</span>
            </div>
            <div class="col-4">
              <strong>Current bulk (±):</strong>
            </div>
            <div class="col-8">
              <span>960m2</span>
            </div>
            <div class="col-4">
              <strong>Current zoning:</strong>
            </div>
            <div class="col-8">
              <span>Residential 2</span>
            </div>
            <div class="col-4">
              <strong>Current use:</strong>
            </div>
            <div class="col-8">
              <span>Multiple residential; Block of flats. Dae ma
                porporro dolo quam secat laborum orpostis.</span>
            </div>
            <div class="col-12">
              <strong>Current use:</strong>
            </div>
            <div class="col-12">
              <span>Rezone to Residential 3 and add 10 more units. Neque min
                nobisciam dolore evellauda net Ollupta volo.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</mat-card>