import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { PersonalDetailsVm } from 'src/app/services/property-matrix/models';
import { AdminService } from 'src/app/services/property-matrix/services';
import { AdminMyProfilePersonalDetailsEditDialogComponent } from './admin-my-profile-personal-details-edit-dialog/admin-my-profile-personal-details-edit-dialog.component';

@Component({
  selector: 'app-admin-my-profile-personal-details',
  templateUrl: './admin-my-profile-personal-details.component.html',
  styleUrls: ['./admin-my-profile-personal-details.component.scss']
})
export class AdminMyProfilePersonalDetailsComponent implements OnInit {

  myProfileDetails: any[] = [
    { key: 1, value: null, title: 'Personal Details' },
    { key: 2, value: null, title: 'Professional Details' },
    { key: 3, value: null, title: 'Qualitfications' },
    { key: 4, value: null, title: 'Physical Address' },
  ];

  constructor(private adminService: AdminService, private sharedService: PropertyMatrixSharedService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getPersonalDetails();
  }

  getPersonalDetails() {
    let userName = this.sharedService.getUsername();
    this.adminService.apiV1AdminGetPersonalDetailsGet$Response({ username: userName })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          let body = JSON.parse(result.body);
          this.myProfileDetails[0].value = body.personalDetails as PersonalDetailsVm;
          this.myProfileDetails[1].value = body.professionalDetails;
          this.myProfileDetails[2].value = body.qualifications;
          this.myProfileDetails[3].value = body.phyiscalAddress;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }

  editInfo(typeKey: number) {
    let data = this.myProfileDetails.find(f => f.key == typeKey);
    const dialogRef = this.dialog.open(AdminMyProfilePersonalDetailsEditDialogComponent, {
      width: '60vw',
      maxHeight: '80vh',
      data: { editType: typeKey, data: data } as AdminEditDialogModel,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        data.value = result.value
      } else {
        this.getPersonalDetails();
      }
    });
  }
}