import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SacplanRegistrationDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { Validators } from 'src/app/models/validation/validators';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

export const ROUTES = {
  WEBSITE_USER_REGISTRATION: 'website-user-registration',
  ESTATE_AGENT_REGISTRATION: 'estate-agent-registration',
  URBAN_PLANNER_REGISTRATION: 'planner-registration',
  CONVEYANCER_REGISTRATION: 'conveyancer-registration',
  PROPERTY_VALUER_REGISTRATION: 'property-valuer-registration',
  BOND_ORIGINATOR_REGISTRATION: 'bond-originator-registration',
  ARCHITECTURAL_TECHNOLOGIST_REGISTRATION: 'architectural-technologist-registration'
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../css/2-modules/_forms.scss'],
})
export class RegisterComponent {

  @Output() pageNumberEvent = new EventEmitter<number>();

  userType: string;
  registrationType: SacplanRegistrationDto;
  sacPlanRegNumber: string;
  validators = new Validators();
  passwordPattern: string = GlobalConstants.passwordPattern;
  emailPattern: string = GlobalConstants.emailPattern;
  confirmConsent: boolean = false;
  loading: boolean = false;
  isRsaResident: boolean = true;
  registrationSub: Subscription;

  form: UserDto = {
    confirmEmail: '',
    confirmMobile: '',
    email: '',
    idNumber: '',
    isSouthAfricanResident: this.isRsaResident,
    landLine: '',
    mobile: '',
    name: '',
    passportNumber: '',
    surname: '',
    userName: '',
    password: '',
    passwordConfirm: ''
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private keycloak: KeycloakService,
    private authService: UserManagementService,
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    this.receiveData();
  }

  onResidencyChange(event: any): void {
    this.isRsaResident = event.value;
    this.form.isSouthAfricanResident = this.isRsaResident
  }

  register() {
    if (this.userType === 'estate-agent') {
      this.loading = true;
      let userRegistrationForm: UserDto = { ...this.form };
      this.authService.apiV1UserManagementAddUserPost({
        body: userRegistrationForm
      }).subscribe({
        next: (result: any) => {
          if (result.success) {
            this.estateAgentRegistrationStateService.setUserId(result.userId);
            this.router.navigate([ROUTES.ESTATE_AGENT_REGISTRATION]);
            this.loading = false;
          } else {
            if (result.errorMessage.includes('Duplicate entry')) {
              this.notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
              this.loading = false;
            } else {
              this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
              this.loading = false;
            }
          }
        },
        error: (_error) => {
          this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
          this.loading = false;
        }
      });
    }
    else {
      this.loading = true;
      if (this.form.urbanPlanner?.sacPlanRegNumber != '' || this.form.urbanPlanner?.registrationType != undefined) {
        this.getUrbanPlannerDetails();
        this.router.navigate([ROUTES.URBAN_PLANNER_REGISTRATION]);
        GlobalMethods.scrollToTop();
      } else {
        let userRegistrationForm: UserDto = { ...this.form };
        delete userRegistrationForm.urbanPlanner;
        this.authService.apiV1UserManagementAddUserPost({
          body: userRegistrationForm
        }).subscribe({
          next: (result: any) => {
            if (result.success) {
              this.notificationService.showSuccessMessage('Success', 'You have successfully registered with Property Matrix. Keep an eye on your email inbox.');
              this.keycloak.login();
            } else {
              if (result.errorMessage.includes('Duplicate entry')) {
                this.notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
              } else {
                this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
              }
            }
            this.loading = false;
          },
          error: (_error) => {
            this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
            this.loading = false;
          }
        });
      }
    }
  }

  private receiveData() {
    this.registrationSub = this.registrationService.userData.subscribe(
      (urbanPlannerData) => {
        if (urbanPlannerData && urbanPlannerData.urbanPlanner) {
          this.form.urbanPlanner.sacPlanRegNumber = urbanPlannerData.urbanPlanner.sacPlanRegNumber || '';
          this.form.urbanPlanner.registrationType = urbanPlannerData.urbanPlanner.registrationType || null;
        }
      }
    );
  }

  private getUrbanPlannerDetails() {
    this.form.urbanPlanner.plannerName = this.form.name;
    this.form.urbanPlanner.plannerEmail = this.form.email;
    this.form.urbanPlanner.plannerSurname = this.form.surname;
    this.form.urbanPlanner.plannerMobile = this.form.mobile;
    this.registrationService.setUserEnrollmentData(this.form);
  }
}
