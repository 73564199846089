<div *ngFor="let type of myProfileDetails" class="mt-4">
    <mat-card *ngIf="type.value">
        <mat-card-content>
            <div>
                <div class="row">
                    <div class="col-11">
                        <div class="heading-2">{{type.title}}</div>
                    </div>
                    <div class="col-1 edit-button-margin"><button (click)="editInfo(type.key)"
                            mat-icon-button><mat-icon>edit</mat-icon></button></div>
                </div>
                <!-- Personal Details -->
                <div class="row pt-3" *ngIf="type.key == 1">
                    <div class="col-12"><b>Name:</b> {{type.value.name}}</div>
                    <div class="col-12"><b>Surname:</b> {{type.value.surname}}</div>
                    <div class="col-12"><b>Landline:</b> {{type.value.landline}}</div>
                    <div class="col-12"><b>Mobile:</b> {{type.value.phoneNumber}}</div>
                    <div class="col-12"><b>Email:</b> {{type.value.emailAddress}}</div>
                    <div class="col-12"><b>Identity/Passport number:</b> {{type.value.id_Number}}</div>
                </div>
                <!-- Professional Details -->
                <div class="row pt-3" *ngIf="type.key == 2">
                    <div *ngIf="type.value.status" class="col-12"><b>Status:</b> {{type.value.status}}</div>
                    <div *ngIf="type.value.fidelityFundCertificateNumber" class="col-12"><b>Fidelity Fund Certificate
                            number:</b>
                        {{type.value.fidelityFundCertificateNumber}}</div>
                    <div *ngIf="type.value.individualReferenceNumber" class="col-12"><b>Individual reference number:</b>
                        {{type.value.individualReferenceNumber}}
                    </div>
                    <div *ngIf="type.value.yearsOfExperience" class="col-12"><b>Years of experience:</b>
                        {{type.value.yearsOfExperience}}</div>
                    <div *ngIf="type.value.specialisingPropertySectors" class="col-12"><b>Specialising property
                            sectors:</b> {{type.value.specialisingPropertySectors}}
                    </div>
                    <div *ngIf="type.value.legalPracticeCOuncilNumber" class="col-12"><b>Legal Practice Council (LPC)
                            number:</b> {{type.value.legalPracticeCOuncilNumber}}</div>
                    <div *ngIf="type.value.financialInstitutionForPanelAccred" class="col-12"><b>Financial institutions
                            for panel accreditation:</b> {{type.value.financialInstitutionForPanelAccred}}</div>
                    <div *ngIf="type.value.plannerGraduationYear" class="col-12"><b>Graduated as a planner:</b>
                        {{type.value.plannerGraduationYear}}</div>
                    <div *ngIf="type.value.institution" class="col-12"><b>Institution:</b> {{type.value.institution}}
                    </div>
                    <div *ngIf="type.value.sacplan_RegistrationNumber" class="col-12"><b>Full SACPLAN registration
                            number:</b> {{type.value.sacplan_RegistrationNumber}}</div>
                    <div *ngIf="type.value.sacplan_RegistrationType" class="col-12"><b>SACPLAN registration type:</b>
                        {{type.value.sacplan_RegistrationType}}</div>
                    <div *ngIf="type.value.sacpvp_Number" class="col-12"><b>SACPVP Number:</b>
                        {{type.value.sacpvp_Number}}</div>
                    <div *ngIf="type.value.registrationType" class="col-12"><b>Registration type:</b>
                        {{type.value.registrationType}}</div>
                    <div *ngIf="type.value.valuationPermitted" class="col-12"><b>Valuation permitted:</b>
                        {{type.value.valuationPermitted}}</div>
                    <div *ngIf="type.value.experience" class="col-12"><b>Experience:</b> {{type.value.experience}}</div>
                </div>
                <!-- Qualitfications -->
                <div class="row pt-3" *ngIf="type.key == 3">
                    <div class="col-12"><b>Highest qualification:</b> {{type.value.highestQualification}}</div>
                    <div class="col-12"><b>Qualification details:</b> {{type.value.qualificationDetails}}</div>
                    <div class="col-12"><b>Additional skills:</b> {{type.value.additionalSkills}}</div>
                </div>
                <!-- Physical Address -->
                <div class="row pt-3" *ngIf="type.key == 4">
                    <div *ngIf="type.value.unitNo" class="col-12"><b>Building/unit no:</b> {{type.value.unitNo}}
                    </div>
                    <div *ngIf="type.value.complexName" class="col-12"><b>Building/complex name:</b>
                        {{type.value.complexName}}</div>
                    <div class="col-12"><b>Street no:</b> {{type.value.streetNo}}</div>
                    <div class="col-12"><b>Street name:</b> {{type.value.streetName}}</div>
                    <div class="col-12"><b>Suburb:</b> {{type.value.suburb}}</div>
                    <div class="col-12"><b>City:</b> {{type.value.city}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>