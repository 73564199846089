<div class="create-profession-dialog">
    <!--Personal Info-->
    <form id="professionalForm" novalidate #professionalForm="ngForm" style="display: block;">
        <section class="submission-form label-padding">
            <div class="heading-2">
                <div class="row">
                    <div class="col-12">
                        {{ dialogTitle }}
                    </div>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblEntityName" for="txtEntityName" class="paragraph">
                        Name :
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtEntityName" class="paragraph input-sizing"
                        name="professionalName" [(ngModel)]="professional.name" required #professionalname="ngModel"
                        [ngClass]="{'invalid-input': professionalname.invalid, 'valid-input': professionalname.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblProfessionalSurname" for="txtProfessionalSurname" class="paragraph">Surname:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtProfessionalSurname"
                        class="paragraph input-sizing" name="professionalSurname" [(ngModel)]="professional.surname"
                        required #professionalSurname="ngModel"
                        [ngClass]="{'invalid-input': professionalSurname.invalid, 'valid-input': professionalSurname.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblProfession" for="txtProfession" class="paragraph">Profession:</label>
                </div>
                <div class="col-7">
                    <select id="ddlProfession" name="Profession" class="paragraph input-sizing"
                        [(ngModel)]="professional.profession.id" required #Profession="ngModel">
                        <option *ngFor="let profession of professions" [value]="profession.id">{{profession.value}}
                        </option>
                    </select>
                </div>
                <div class="col-2 hide-profession-button">
                    <mat-button class="add-user-button" (click)="(openProfessionalsDialog())">
                        <mat-icon class="add-user-icon"
                            style="border: 1px solid black; border-radius: 15px;">add</mat-icon>
                    </mat-button>
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblCompanyName" for="txtcompanyName" class="paragraph">
                        Company Name:
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtcompanyName" name="companyName"
                        class="paragraph not-required input-sizing" [(ngModel)]="professional.companyName" required
                        #companyName="ngModel"
                        [ngClass]="{'invalid-input': companyName.invalid, 'valid-input': companyName.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblMobile" for="txtMobile" class="paragraph">
                        Mobile :
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtMobile" name="mobile" class="paragraph not-required input-sizing"
                        [(ngModel)]="professional.mobile" #mobile="ngModel"
                        [ngClass]="{'invalid-input': mobile.invalid, 'valid-input': mobile.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblEmail" for="txtEmail" class="paragraph">
                        Email:
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtEmail" name="email" class="paragraph not-required input-sizing"
                        [(ngModel)]="professional.email" #email="ngModel"
                        [ngClass]="{'invalid-input': email.invalid, 'valid-input': email.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-3">
                    <label id="lblLinkToWebsite" for="txtLinkToWebsite" class="paragraph">
                        Link to their website:
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtWebsiteLink" name="websiteLink"
                        class="paragraph not-required input-sizing" [(ngModel)]="professional.websiteLink"
                        #websiteLink="ngModel"
                        [ngClass]="{'invalid-input': websiteLink.invalid, 'valid-input': websiteLink.valid}">
                </div>
            </div>
            <div class="row row-spacing">
                <div class="col-5">
                    <label for="pleaseConfirmCheckbox" class="paragraph">
                        I have consent from this professional to provide Property Matrix with information on their
                        behalf.
                    </label>
                </div>
                <div class="col-7">
                    <input type="checkbox" [formControl]="pleaseConfirmCheckbox">
                </div>
            </div>
            <div class="row text-center mt-3 mb-3">
                <div class="col-12">
                    <button id="btnSavejuristicPerson"
                        [disabled]="!professionalForm.valid || !pleaseConfirmCheckbox.value" mat-button
                        class="button heading-4 mt-2" (click)="saveProfessional()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </section>
    </form>
</div>
