import { AdditionalPolicyPlanFigureDto, AreaAvailableDto, CostEstimatesDto, CurrentConstructionScopeDto, ExistingLandUseRightDto, ImportantFactorsToConsiderDto, ImprovementOptionDto, LandParcelDto, LandUseDto, PlanningReportDto, PlanningReportReferenceDto, PossibleDevelopmentScenarioDto, PotentialConstructionScopeDto, RiskscapeExistingLandUseAndFeatureDto, RiskscapePropertyInfoDto, SchemeDto, SpatialFrameworkAndPolicyDto, TitleConditionDto, WayForwardDto, ZoningDto } from 'src/app/services/property-matrixV2/models';
import { initialDeedsReportsText, initialDevelopmentChargesText, initialFutureRoadsText, initialHeritageText, initialHistoricApprovalsText } from 'src/app/constants/data/important-factors-initial-values';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { initialCostEstimatesText } from 'src/app/constants/data/cost-estimates-initial-data';
import { initialWayForwardText } from 'src/app/constants/data/way-forward-initial-data';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { Section } from 'src/app/common/global-models/section';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-planning-report',
  templateUrl: './planning-report.component.html',
  styleUrls: ['./planning-report.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class PlanningReportComponent implements OnInit, OnChanges {

  @Input() planningReportData: PlanningReportDto;
  @Input() listingId: string;
  @Input() multipleListingId: string;
  @Input() isMultiple: boolean;
  @Input() isGeneralInformation: boolean;

  @Output() tabNumberEvent = new EventEmitter<number>();

  planningReportId: string = '';

  sections: Section[];
  riskscapePropertyInfoData: RiskscapePropertyInfoDto;
  riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto;
  existingLandUseRightData: ExistingLandUseRightDto;
  spatialFrameworkAndPolicyData: SpatialFrameworkAndPolicyDto[];
  additionalPolicyPlanFigureData: AdditionalPolicyPlanFigureDto[];
  areaAvailableData: AreaAvailableDto;
  improvementOptionData: ImprovementOptionDto[];
  possibleDevelopmentScenarioData: PossibleDevelopmentScenarioDto;
  landParcelData: LandParcelDto[];
  currentConstructionScopeData: CurrentConstructionScopeDto;
  potentialConstructionScopeData: PotentialConstructionScopeDto;
  importantFactorsToConsiderData: ImportantFactorsToConsiderDto = {};
  costEstimatesData: CostEstimatesDto = {};
  titleConditionData: TitleConditionDto;
  wayForwardData: WayForwardDto;
  referenceData: PlanningReportReferenceDto[];

  selectedMetroDescription: string;

  localityMapFileId: string;
  zoningMapFileId: string;

  landUseData: LandUseDto[] = [];
  predominantLandUseData: LandUseDto[] = [];
  additionalLandUseData: LandUseDto[] = [];
  selectedPredominantLandUseId: string;
  selectedAdditionalLandUseId: string;

  schemeData: SchemeDto[] = [];
  selectedSchemeId: string;

  zoningData: ZoningDto[] = [];
  zoningFilteredData: ZoningDto[] = [];
  selectedZoningId: string;

  usesPermittedOnSiteData: LandUseDto[] = [];
  approvedAdditionalUseData: LandUseDto[] = [];
  usesPermittedOnSiteId: string = '5167BD5C-9F86-47A6-8FA3-7283FCF7597D';
  selectedUsesPermittedOnSiteIds: string[] = [];
  selectedApprovedAdditionalUseIds: string[] = [];

  selectedProposedZoningId: string;
  selectedProposedLandUseIds: string[] = [];
  selectedApprovedAdditionalUseLandParcelIds: string[] = [];

  proposedLandUseData: LandUseDto[] = [];

  listingType: string;
  zoningMapVisible: boolean = false;
  amountOfParcelsOption: number;
  maxImprovementOptions: number = 4;
  maxLandParcels: number = 3;

  potentialUsableProperty: number;

  loading: boolean = false;

  planningReportSections = [
    'Property Details',
    'Address Details',
    'Locality Map',
    'Existing Land Use',
    'Existing Land Use Rights',
    'Spatial Development Framework & Policies',
    'Additional Policies, Plans, Figures',
    'Development Potential - Area Available',
    'Improvement Options',
    'A Possible Development Scenario',
    'Quantified Extent (A Possible Development Scenario)',
    'Current Construction Scope',
    'Potential Construction Scope',
    'Important Factors To Consider',
    'Cost Estimates',
    'Way Forward',
    'References',
  ];

  generalInformationSections = [
    'Locality Map',
    'Existing Land Use',
    'Spatial Development Framework & Policies',
    'Additional Policies, Plans, Figures',
    'Development Potential - Consolidated Area Available',
    'Improvement Options',
    'A Possible Development Scenario',
    'Proposed Development Scenario - "Highest/Best" Right',
    'Current Construction Scope',
    'Potential Construction Scope',
    'Important Factors To Consider',
    'Cost Estimates',
    'Way Forward',
    'References',
  ];

  propertySpecificInformationSections = [
    'Property Details',
    'Address Details',
    'Existing Land Use Rights',
    'References',
  ];

  propertyTypes: KeyValuePair[] = [
    { key: 1, value: 'Erf' },
    { key: 2, value: 'Holding' },
    { key: 3, value: 'Farm' },
  ];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private planningReportService: PlanningReportService,
    private lookupService: LookupService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.planningReportId = this.planningReportData?.id || '';
    this.localityMapFileId = this.planningReportData?.localityMapFileId || '';
    this.zoningMapFileId = this.planningReportData?.existingLandUseRight?.zoningMapImageUrl || '';

    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });

    this.sections = this.generateSections();
    this.loadAllPlanningReportData();
    this.loadAllZoningMatrixData();

    if (this.improvementOptionData.length === 0) {
      this.addImprovementOption();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.planningReportData) {
      this.loadAllPlanningReportData();
    }
  }

  loadAllPlanningReportData() {
    this.riskscapePropertyInfoData = this.planningReportData?.riskscapePropertyInfo || {};
    this.riskscapeExistingLandUseAndFeatureData = this.planningReportData?.riskscapeExistingLandUseAndFeature || {};
    this.existingLandUseRightData = this.planningReportData?.existingLandUseRight || {};

    this.spatialFrameworkAndPolicyData = this.planningReportData?.spatialFrameworksAndPolicies || [];
    this.additionalPolicyPlanFigureData = this.planningReportData?.additionalPoliciesPlansFigures || [];
    this.areaAvailableData = this.planningReportData?.areaAvailable || {};
    this.improvementOptionData = this.planningReportData?.improvementOptions || [];
    this.possibleDevelopmentScenarioData = this.planningReportData?.possibleDevelopmentScenario || {};

    this.amountOfParcelsOption = this.possibleDevelopmentScenarioData?.amountOfParcels || 0;
    this.adjustLandParcels();

    this.landParcelData = this.planningReportData?.possibleDevelopmentScenario?.landParcels || [];
    this.currentConstructionScopeData = this.planningReportData?.currentConstructionScope || {};
    this.potentialConstructionScopeData = this.planningReportData?.potentialConstructionScope || {};
    this.importantFactorsToConsiderData = this.planningReportData?.importantFactorsToConsider || {};
    this.costEstimatesData = this.planningReportData?.costEstimates || {};
    this.titleConditionData = this.planningReportData?.titleCondition || {};
    this.wayForwardData = this.planningReportData?.wayForward || {};
    this.referenceData = this.planningReportData?.references || [];

    if (!this.importantFactorsToConsiderData.deedsReports) {
      this.importantFactorsToConsiderData.deedsReports = initialDeedsReportsText;
    }
    if (!this.importantFactorsToConsiderData.developmentCharges) {
      this.importantFactorsToConsiderData.developmentCharges = initialDevelopmentChargesText;
    }
    if (!this.importantFactorsToConsiderData.heritage) {
      this.importantFactorsToConsiderData.heritage = initialHeritageText;
    }
    if (!this.importantFactorsToConsiderData.historicApprovals) {
      this.importantFactorsToConsiderData.historicApprovals = initialHistoricApprovalsText;
    }
    if (!this.importantFactorsToConsiderData.futureRoads) {
      this.importantFactorsToConsiderData.futureRoads = initialFutureRoadsText;
    }
    if (!this.costEstimatesData.description) {
      this.costEstimatesData.description = initialCostEstimatesText;
    }
    if (!this.wayForwardData.description) {
      this.wayForwardData.description = initialWayForwardText;
    }
  }

  loadAllZoningMatrixData() {
    this.selectedMetroDescription = this.planningReportData.riskscapePropertyInfo.municipality;
    this.loadLandUseData();
    this.loadPredominantLandUseData();
    this.loadAdditionalLandUseData();
    this.loadSchemeData();
  }

  loadLandUseData() {
    this.lookupService.apiV1LookupGetLandUseDataGet().subscribe({
      next: (response: LandUseDto[]) => {
        this.landUseData = response.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load land use type data.');
      }
    });
  }

  loadPredominantLandUseData() {
    this.lookupService.apiV1LookupGetPredominantLandUseDataGet().subscribe({
      next: (response: LandUseDto[]) => {
        this.predominantLandUseData = response.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load predominant land use data.');
      }
    });
  }

  loadAdditionalLandUseData() {
    this.lookupService.apiV1LookupGetAdditionalLandUseDataGet().subscribe({
      next: (response: LandUseDto[]) => {
        this.additionalLandUseData = response.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load additional land use data.');
      }
    });
  }

  loadSchemeData() {
    this.lookupService.apiV1LookupGetSchemeDataGet({ metroDescription: this.selectedMetroDescription }).subscribe({
      next: (response: SchemeDto[]) => {
        this.schemeData = response.sort((a, b) => a.description.localeCompare(b.description));
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load scheme data.');
      }
    });
  }

  handlePropertyTypeChange(value: string) {
    this.riskscapePropertyInfoData.propertyType = value;
  }

  handlePredominantLandUseChange(value: string) {
    this.selectedPredominantLandUseId = value;
    this.existingLandUseRightData.predominantLandUse = this.landUseData.find(landUse => landUse.id === this.selectedPredominantLandUseId);
  }

  handleAdditionalLandUseChange(value: string) {
    this.selectedAdditionalLandUseId = value;
    this.existingLandUseRightData.additionalLandUse = this.landUseData.find(landUse => landUse.id === this.selectedAdditionalLandUseId);
  }

  handleSchemeChange(value: string) {
    this.selectedSchemeId = value;
    this.existingLandUseRightData.schemeName = this.schemeData.find(scheme => scheme.id === this.selectedSchemeId);
    this.loadZoningData();
    this.loadApprovedAdditionalUseData();
  }

  loadZoningData() {
    this.lookupService.apiV1LookupGetZoningDataGet({ schemeId: this.selectedSchemeId }).subscribe({
      next: (response: ZoningDto[]) => {
        this.zoningData = response.sort((a, b) => a.description.localeCompare(b.description));
      }
    });
  }

  handleCurrentZoningChange(value: string) {
    if (value != 'null') {
      this.selectedZoningId = value;
      this.existingLandUseRightData.currentZoning = this.zoningData.find(zoning => zoning.id === this.selectedZoningId);
      this.loadUsesPermittedOnSiteData();
    }
  }

  loadUsesPermittedOnSiteData() {
    this.lookupService.apiV1LookupGetUsesPermittedOnSiteDataGet({
      schemeId: this.selectedSchemeId,
      zoningId: this.selectedZoningId,
      useRightTypeId: this.usesPermittedOnSiteId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        this.usesPermittedOnSiteData = response.sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));
      }
    });
  }

  loadApprovedAdditionalUseData() {
    this.lookupService.apiV1LookupGetApprovedAdditionalUseDataGet({
      schemeId: this.selectedSchemeId,
      useRightTypeId: this.usesPermittedOnSiteId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        this.approvedAdditionalUseData = response.sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));
      }
    });
  }

  handleUsesPermittedOnSiteChange(value: string[]) {
    this.selectedUsesPermittedOnSiteIds = value;

    if (this.selectedUsesPermittedOnSiteIds && !this.selectedUsesPermittedOnSiteIds.includes('null')) {
      this.existingLandUseRightData.usesPermittedOnSite = this.usesPermittedOnSiteData.filter(landUse => this.selectedUsesPermittedOnSiteIds.includes(landUse.id));
    }
    else {
      this.existingLandUseRightData.usesPermittedOnSite = [];
    }
  }

  handleApprovedAdditionalUsesChange(value: string[]) {
    this.selectedApprovedAdditionalUseIds = value;

    if (this.selectedApprovedAdditionalUseIds && !this.selectedApprovedAdditionalUseIds.includes('null')) {
      this.existingLandUseRightData.additionalRights = this.approvedAdditionalUseData.filter(landUse => this.selectedApprovedAdditionalUseIds.includes(landUse.id));
    }
    else {
      this.existingLandUseRightData.additionalRights = [];
    }
  }

  handleProposedZoningChange(value: string) {
    this.selectedProposedZoningId = value;
    this.landParcelData.map(landParcel => landParcel.proposedZoningRight = this.zoningData.find(zoning => zoning.id === this.selectedProposedZoningId));
    this.loadProposedLandUseData();
  }

  loadProposedLandUseData() {
    this.lookupService.apiV1LookupGetUsesPermittedOnSiteDataGet({
      schemeId: this.selectedSchemeId,
      zoningId: this.selectedProposedZoningId,
      useRightTypeId: this.usesPermittedOnSiteId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        this.proposedLandUseData = response.sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));
      }
    });
  }

  handleProposedLandUsesChange(value: string[]) {
    this.selectedProposedLandUseIds = value;

    if (this.selectedProposedLandUseIds && !this.selectedProposedLandUseIds.includes('null')) {
      this.landParcelData.map(landParcel => landParcel.proposedLandUses = this.proposedLandUseData.filter(landUse => this.selectedProposedLandUseIds.includes(landUse.id)));
    }
    else {
      this.landParcelData.map(landParcel => landParcel.proposedLandUses = []);
    }
  }

  handleApprovedAdditionalUsesLandParcelChange(value: string[]) {
    this.selectedApprovedAdditionalUseLandParcelIds = value;

    if (this.selectedApprovedAdditionalUseLandParcelIds && !this.selectedApprovedAdditionalUseLandParcelIds.includes('null')) {
      this.landParcelData.map(landParcel => landParcel.proposedAdditionalRights = this.proposedLandUseData.filter(landUse => this.selectedApprovedAdditionalUseLandParcelIds.includes(landUse.id)));
    }
    else {
      this.landParcelData.map(landParcel => landParcel.proposedAdditionalRights = []);
    }
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  generateSections(): Section[] {
    let sections: string[] = [];

    if (this.isMultiple) {
      if (this.isGeneralInformation) {
        sections = this.generalInformationSections;
      }
      else {
        sections = this.propertySpecificInformationSections;
      }
    }
    else {
      sections = this.planningReportSections;
    }

    return sections.map((section, index) => {
      return {
        id: index + 1,
        value: section,
        checked: false
      };
    });
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
    }
  }

  handlePotentialUsableSpaceChange(newValue: number): void {
    this.potentialUsableProperty = newValue;
  }

  addImprovementOption(): void {
    if (this.improvementOptionData.length < this.maxImprovementOptions) {
      const newImprovementOption = {
        landUseApplication: '',
        possibleDevelopmentEnhancement: '',
        timeFrame: ''
      };
      this.addItemToArray(this.improvementOptionData, newImprovementOption);
    }
    else {
      this.notificationService.showWarningMessage('Improvement Option Limit Reached', 'You cannot add more than ' + this.maxImprovementOptions + ' improvement options.');
    }
  }

  removeImprovementOption(index: number): void {
    this.removeItemFromArray(this.improvementOptionData, index);
  }

  onLandParcelSelectionChange(value: number) {
    this.amountOfParcelsOption = value;
    this.adjustLandParcels();
  }

  createNewLandParcel() {
    return {
      proposedAdditionalRight: null,
      proposedCoverage: null,
      proposedDensity: null,
      proposedFloorAreaRatio: null,
      proposedHeight: null,
      proposedLandUse: null,
      proposedPotentialDevelopment: null,
      proposedZoningRight: null,
      sizeOfLandParcel: null,
    };
  }

  adjustLandParcels() {
    this.landParcelData = [];
    if (this.amountOfParcelsOption > 0 && this.amountOfParcelsOption <= this.maxLandParcels) {
      for (let i = 0; i < this.amountOfParcelsOption; i++) {
        this.landParcelData.push(this.createNewLandParcel());
      }
    }
  }

  updateImportantFactorsToConsider(data: ImportantFactorsToConsiderDto) {
    this.importantFactorsToConsiderData = data;
  }

  updateCostEstimates(data: CostEstimatesDto) {
    this.costEstimatesData = data;
  }

  updateWayForward(data: WayForwardDto) {
    this.wayForwardData = data;
  }

  goToListingOverview() {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  saveReportDetails() {
    if (!this.isMultiple) {
      this.savePlanningReportDetails();
    } else if (this.isGeneralInformation) {
      this.saveGeneralReportDetails();
    } else {
      this.savePropertySpecificReportDetails();
    }
  }

  savePlanningReportDetails() {
    this.planningReportData.existingLandUseRight = this.existingLandUseRightData;
    if (this.planningReportData.possibleDevelopmentScenario != null) {
      this.planningReportData.possibleDevelopmentScenario.landParcels = this.landParcelData;
    }
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdatePlanningReportPost({
      propertyListingId: this.listingId,
      body: this.planningReportData
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'Successfully saved planning report details.');
        this.loading = false;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not save planning report details.');
        this.loading = false;
      }
    });
  }

  savePropertySpecificReportDetails() {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost({
      propertyListingId: this.listingId,
      body: this.planningReportData
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'Successfully saved property specific report details.');
        this.loading = false;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not save property specific report details.');
        this.loading = false;
      }
    });
  }

  async saveGeneralReportDetails() {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdateGeneralReportPost({
      multipleListingId: this.multipleListingId,
      body: this.planningReportData
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'Successfully saved general report details.');
        this.loading = false;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not save general report details.');
        this.loading = false;
      }
    });
  }

  goToAddendumOne() {
    this.tabNumberEvent.emit(2);
  }

  handleSetLocalityMapFileId(event: string) {
    this.planningReportData.localityMapFileId = event;
  }

  handleSetZoningMapFileId(event: string) {
    this.planningReportData.existingLandUseRight.zoningMapImageUrl = event;
  }

  handleSpatialFrameworkChange(event: SpatialFrameworkAndPolicyDto[]) {
    this.planningReportData.spatialFrameworksAndPolicies = event;
  }

  handleAdditionalPolicyChange(event: AdditionalPolicyPlanFigureDto[]) {
    this.planningReportData.additionalPoliciesPlansFigures = event;
  }

  handleReferenceChange(event: PlanningReportReferenceDto[]) {
    this.planningReportData.references = event;
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }
}
