<div class="background-image container-fluid">
    <section class="content">
        <form (ngSubmit)="ChangePassword(changePassForm)" [formGroup]="changePassForm" novalidate autocomplete="false">
            <div class="container text-center">
                <div class="row">
                    <p class="heading-1">Change Password</p>
                </div>
                <div class="paragraph">
                    <div class="row">
                        <label id="lblNewPassword" for="txtNewPassword">New Password</label>
                        <input formControlName="password" type="password" class="textbox" id="txtNewPassword">
                    </div>
                    <div class="row">
                        <label id="lblConfirmPassword" for="txtConfirmPassword">Confirm Password</label>
                        <input formControlName="confirmPassword" type="password" class="textbox"
                            id="txtConfirmPassword">
                    </div>
                    <div class="row">
                        <button type="submit" class="button heading-3"
                            [disabled]="changePassForm.invalid">SUBMIT</button>
                    </div>
                </div>
            </div>
        </form>
    </section>
</div>