<div class="p-3">
  <div class="popup-heading popup-margins">
    Important Information
  </div>
  <div class="col-12 heading-5-light popup-margins">
    Why approved plans?
  </div>
  <div class="col-12">
    <ul class="paragraph light">
      <li>
        Approved plans are required by financial institutions should the purchaser need to secure a loan.
      </li>
      <li>
        It provides evidence that no alterations/additions have been carried out without municipal approval.
      </li>
      <li>
        Approval of building plans is dependent on planning rights being in place – our team investigates the matter holistically.
      </li>
    </ul>
    <div class="col-12 heading-5-light popup-margins">
      Property Matrix Requirements:
    </div>
    <div class="col-12">
      <ul class="paragraph light">
        <li>
          That all information pertaining to your property/properties be disclosed.
        </li>
        <li>
          Your pro-active cooperation to obtain approved building plans while we market your property.
        </li>
        <li>
          That our team manages the process to ensure that you are charged with market-related fees and the approval process not be delayed.
        </li>
      </ul>
      <div class="col-12 heading-5-light popup-margins">
        Our Offer:
      </div>
      <div class="col-12">
        <ul class="paragraph light">
          <li>
            Property Matrix will appoint an architectural technologist in our network to visit your property and investigate the matter.
          </li>
          <li>
            A fee of R1 500 is charged to your account for this initial investigation (higher initial fee may be charged for specialised or large scale properties).
          </li>
          <li>
            A formal quotation be provided once the scope and timelines are estimated.
          </li>
          <li>
            The initial investigation fee will be deducted from the total amount quoted.
          </li>
          <li>
            Our team continues with the planning investigation once the amount invoiced, or the deposit required, is settled.
          </li>
        </ul>
        <div class="text-center popup-margins">
          <button class="button-light heading-3-light" [disabled]="agreeBool" id="propertyOwnerInfoContinue" (click)="closeDialog()">
            Agree
          </button>
        </div>
        <div class="col-12">
          <label class="checkbox-container">
            <b class="paragraph light">I am not interested in this offer</b>
            <input type="checkbox" [(ngModel)]="agreeBool" name="sellMoreThanOneProperty" #sellMoreThanOneProperty="ngModel" id="sellMoreThanOneProperty" data-toggle="toggle" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div *ngIf="agreeBool">
          <div class="heading-5-light popup-margins" style="margin-top: 20px;">
            <span class="burnt-red">| </span>Kindly provide the reason for your decision:
          </div>
          <div class="row">
            <mat-radio-group name="reasonForNotInterested" [(ngModel)]="reasonForNotInterested">
              <div *ngFor="let selectionValue of ReasonForNotInterestedLookup">
                <mat-radio-button class="paragraph light popup-radio-button" [value]="selectionValue">
                  {{selectionValue.value}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <button class="button-light heading-3-light popup-margins" style="margin-top: 20px;" id="propertyOwnerInfoContinue" (click)="doNotAgreeEvent()" [disabled]="!reasonForNotInterested">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
