import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { AddPropertyValuationVm, AddressVm, CheckboxVm, PersonalDetailsVm, TimePreferenceVm } from 'src/app/services/property-matrix/models';
import { AdditionalServeService } from 'src/app/services/property-matrix/services';

@Component({
  selector: 'app-property-valuation-request',
  templateUrl: './property-valuation-request.component.html',
  styleUrls: ['../../../../../css/2-modules/_forms.scss']
})
export class PropertyValuationRequestComponent {

  emailPattern: string = GlobalConstants.emailPattern;

  loading: boolean = false;

  listedProperty: number;
  listedPropertySelection: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I need a valuation for a different property' }
  ]

  setListedProperty() {
    if (this.listedProperty == 1) {
      this.queryBody.propertyListed = true;
    } else if (this.listedProperty == 2) {
      this.queryBody.propertyListed = false;
    }
  }

  mainPurposes: CheckboxVm[] = [
    { id: 1, value: 'To determine the open market value of the property', checked: false },
    { id: 2, value: 'For security purposes as you require mortgage finance', checked: false },
    { id: 3, value: 'For auditing or tax purposes', checked: false },
    { id: 4, value: 'To determine values for merging or taking over a business', checked: false },
    { id: 5, value: 'For the division of family or business property assets', checked: false },
    { id: 6, value: 'To appeal a municipal valuation', checked: false },
    { id: 7, value: 'For insurance purposes', checked: false },
    { id: 8, value: 'Forced sale value for liquidation purposes', checked: false },
    { id: 9, value: 'Alternative use - related to a proposed zoning of the property', checked: false },
    { id: 10, value: 'Other – Please specify: ', checked: false }
  ]

  currentMainUses: KeyValuePair[] = [
    { key: 1, value: 'Vacant land' },
    { key: 2, value: 'Residential' },
    { key: 3, value: 'Industrial' },
    { key: 4, value: 'Offices' },
    { key: 5, value: 'Retail' },
    { key: 6, value: 'Other/ specialised property - Please describe:' }
  ]

  propertyTypes: KeyValuePair[] = [
    { key: 1, value: 'Single stand with one (or more) dwelling house(s) and/or outbuildings' },
    { key: 2, value: 'Residential smallholding (Agricultural holding/”Plot”)' },
    { key: 3, value: 'Small farm portion that is essentially used for residential purposes' },
    { key: 4, value: 'Sectional title unit (flat unit or group housing unit, where levies are typically payable)' },
    { key: 5, value: 'Group housing complex (complex as a whole)' },
    { key: 6, value: 'Block of flats (all flats in the block/building)' },
    { key: 7, value: 'Other – Please describe:' }
  ]

  currentOccupationSelections: KeyValuePair[] = [
    { key: 1, value: 'Unoccupied' },
    { key: 2, value: 'Owner occupied' },
    { key: 3, value: 'Tenanted' },
    { key: 4, value: 'Owner occupied and tenanted' }
  ]

  buildingPlansSelections: KeyValuePair[] = [
    { key: 1, value: 'Yes, in electronic format' },
    { key: 2, value: 'Yes, hard copy only' },
    { key: 3, value: 'No, not currently available to me' }
  ]

  today: Date = new Date();
  checkDate: CheckboxVm[] = [
    { id: 1, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 2, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 3, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
  ]

  timePreferences: TimePreferenceVm[] = [
    { date: this.checkDate.find(f => f.id == 1), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 2), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 3), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' }
  ]

  propertyDetails: AddressVm = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    propertyDescription: ''
  }

  personalDetails: PersonalDetailsVm = {
    name: '',
    surname: '',
    landline: '',
    phoneNumber: '',
    alternativeNumber: '',
    emailAddress: '',
    confirmEmail: '',
    id_Number: ''
  }

  queryBody: AddPropertyValuationVm = {
    propertyListed: null,
    propertyAddress: this.propertyDetails,
    mainPurpose: this.mainPurposes,
    mainPurposeOther: '',
    currentMainUse: null,
    currentMainUseOther: '',
    propertyType: null,
    propertyTypeOther: '',
    currentOccupation: null,
    buildingPlans: null,
    personalDetails: this.personalDetails,
    timePreferenceVMs: this.timePreferences
  }

  constructor(public dialog: MatDialog, private datePipe: DatePipe, private addService: AdditionalServeService) { }

  emailConfirmed: boolean;
  checkValidation(email: NgModel, confirmEmail: NgModel) {
    this.emailConfirmed = GlobalMethods.checkValidation(email, confirmEmail);
  }

  validateTimePreference(): boolean {
    return (this.checkDate.filter(f => f.checked == true).length == 0);
  }

  otherCurrentMainUse: boolean
  isOtherSelected() {
    this.otherCurrentMainUse = this.queryBody.mainPurpose.find(f => f.id == 10).checked
  }

  submit({ value, valid }: { value: AddPropertyValuationVm, valid: boolean }) {
    this.loading = true
    this.queryBody.timePreferenceVMs = this.timePreferences.filter(f => f.date.checked == true);
    this.queryBody.mainPurpose = this.mainPurposes.filter(f => f.checked == true);
    if (valid && this.emailConfirmed) {
      this.addService.apiV1AdditionalServeCapturePropertyValuationPost$Response({ body: this.queryBody }).pipe()
        .subscribe(Response => {
          this.loading = false
          GlobalMethods.tinySuccessAlert("Saved!", "Your details have been submitted!");
        },
          error => {
            this.loading = false
            GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.");
          }
        );
    } else {
      this.loading = false
      GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.")
    }
  }

  clicked: boolean = false;
  openDialog(id: number) {
    if (this.clicked == true) {
      this.clicked = false;
      return;
    }
    this.clicked = true;
    if (this.checkDate[id - 1].checked == false) {
      const dialogRef = this.dialog.open(TimePickerDialogValuation, {
        width: '25vw',
        height: '30vh',
        data: { timePreferences: this.timePreferences }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result == null || result.startTime == null || result.endTime == null) {
          GlobalMethods.tinyErrorAlert("Error", "Please select your preferred time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime > result.endTime) {
          GlobalMethods.tinyErrorAlert("Error", "Make sure your start time is before your end time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime < "09:00" || result.endTime > "16:00") {
          GlobalMethods.tinyErrorAlert("Error", "Please choose times between 09:00 and 16:00");
          this.checkDate[(id - 1)].checked = false;
        }
        else if (result) {
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).startTime = this.setDateForAPI(id, result.startTime);
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).endTime = this.setDateForAPI(id, result.endTime);
        }
      });
    }
  }

  setDateForAPI(id: number, time: string): string {
    return ((this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).date).value + 'T' + time + ':00.000Z');
  }
}

@Component({
  selector: 'time-picker-dialog-valuation',
  templateUrl: './time-picker-dialog-valuation.html'
})
export class TimePickerDialogValuation {

  constructor(public dialogRef: MatDialogRef<TimePickerDialogValuation>,
    @Inject(MAT_DIALOG_DATA) public data: TimePreferenceVm) { }

  closeDialog() {
    if (this.data.startTime == null) { this.dialogRef.close(null) }
    else { this.dialogRef.close(this.data); }
  }
}