<main>
    <mat-card>
        <div class="col-12">
            <span class="paragraph group-title">
                Development Opportunity
            </span>
        </div>
        <div class="col-12">
            <span class="paragraph group-subtitle">
                Update group information
            </span>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Group Name</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="groupName" />
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Cover Photo</label>
                <div class="cover-image-container mt-1">
                    <img [src]="coverImageUrl" alt="Group Cover Photo">
                </div>
            </div>
        </div>
        <div class="button-group mt-5">
            <button class="footer-button background-cloud" (click)="onCancel()">Cancel</button>
            <button class="footer-button background-coral" (click)="onSave()">Save</button>
        </div>
    </mat-card>
</main>