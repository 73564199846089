import { ExistingLandUseRightDto, LandUseDto, RiskscapeExistingLandUseAndFeatureDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-existing-land-use',
  templateUrl: './existing-land-use.component.html',
  styleUrls: ['./existing-land-use.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExistingLandUseComponent implements OnInit {

  @Input() predominantLandUseData: LandUseDto[] = [];
  @Input() additionalLandUseData: LandUseDto[] = [];
  @Input() existingLandUseRightData: ExistingLandUseRightDto;

  @Input() riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto;

  @Output() predominantLandUse = new EventEmitter<string>();
  @Output() additionalLandUse = new EventEmitter<string>();

  selectedPredominantLandUse: string | null = null;
  selectedAdditionalLandUse: string | null = null;

  constructor() { }

  ngOnInit(): void {
    if (this.existingLandUseRightData?.additionalLandUse != null) {
      this.selectedAdditionalLandUse = this.existingLandUseRightData?.additionalLandUse.id ?? null;
    }
    if (this.existingLandUseRightData?.predominantLandUse != null) {
      this.selectedPredominantLandUse = this.existingLandUseRightData?.predominantLandUse.id ?? null;
    }
  }

  onPredominantLandUseChange(event: MatSelectChange) {
    this.selectedPredominantLandUse = event.value === null ? null : event.value;
    this.predominantLandUse.emit(this.selectedPredominantLandUse ?? "");
  }

  onAdditionalLandUseChange(event: MatSelectChange) {
    this.selectedAdditionalLandUse = event.value === null ? null : event.value;
    this.additionalLandUse.emit(this.selectedAdditionalLandUse ?? "");
  }
}
