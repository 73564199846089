<form id="signContractForm" novalidate #signContractForm="ngForm" class="submission-form">
    <div class="heading-2 mt-4">STEP 4: SIGN CONTRACT</div>
    <div class="text-center mt-3 paragraph">
        <div class="row">
            <div class="col"></div>
            <div class="col-10">
                <p class="mt-4">Thank you for your time</p>
                <p>Please keep an eye on your inbox regarding the way forward. A link to the <strong>Property Matrix</strong> contract
                    will be provided for your signature on completion of a successful application.</p>
            </div>
            <div class="col"></div>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12 mt-3">
            <button id="btnSaveFinal" class="button heading-4" (click)="submitForm()">NOTED</button>
        </div>
    </div>
</form>