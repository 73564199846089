<div class="inner-block contract-details p-3">
  <div class="collaboration-border"> 
    <h1 class="heading-1 text-center mt-2">
        COLLABORATION
    </h1>
    <div class="m-3 mt-2">
        <div class="row">
            <div class="col-12">
                <strong class="paragraph">I further Agree to:</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="montserrat-bullets paragraph">
                    <li>
                        Disclose details of an estate agent if the agent introduced me to Property Matrix.
                    </li>
                    <li>
                        Sign the Commission Claim Form (CCF) if this is provided to me by the agent.
                    </li>
                    <li>
                        I will only sign one* CCF.
                    </li>
                    <li>
                        Allow the agent to visit my property and ensure easy access to all buildings (if applicable).
                    </li>
                    <li>
                        Allow the agent to take pictures (or a short video if required) of the property and interior and
                        exterior of all buildings (if applicable).
                    </li>
                    <li>
                        Ensure that my property is neat and "photo-ready" to make a good first impression and increase
                        the chances of selling.
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="paragraph">
                    * If you are approached by multiple brokers/agents, please sign a CCF for the broker/agent who
                    initially introduced you to Property Matrix. The onus is on our agents to draft a commission split
                    agreement if required.
                </span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button id="btnAgree" class="button heading-4" (click)="agree()">Agree</button>
            </div>
        </div>
    </div>
</div>
</div>