<mat-sidenav-container (backdropClick)="close()" [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 0}"
                (click)="toggleColorButton1(0)">LEVEL 1</button>
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 1}"
                (click)="toggleColorButton2(1)">LEVEL 2</button>
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 2}"
                (click)="toggleColorButton3(2)">LEVEL 3</button>
        </section>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel1">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    As part of the LEVEL 1 property investigation, Property Matrix offers to:
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white" (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Inspect the following:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Zoning of the property
                        </li>
                        <li>
                            Town Planning Scheme
                        </li>
                        <li>
                            S.G. Diagrams (to confirm exact boundaries and possible servitudes
                            regestered)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Review the following (where applicable):
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Strategic plans of the local council such as the Integrated Development Plan
                            (IDP) and
                            Spatial
                            Development Framework (SDF)
                        </li>
                        <li>
                            Precinct plan
                        </li>
                        <li>
                            Development policies
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Discuss and confirm our interpretation with Council regarding:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Allowable land uses in relation to your requirements
                        </li>
                        <li>
                            Possible actions, e.g., subdivision, rezoning, consent use, etc.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Give advice regarding:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Predicted costs and timeframes, should you wish to lodge a planning
                            application
                            for the
                            required land use
                            or action
                        </li>
                        <li>
                            Parking requirements, if applicable
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    All the information will be emailed to you once the investigation is completed:
                </div>
                <div class="col-12">
                    <p class="paragraph light">
                        <span class="heading-5-light"> Deliverable: </span>
                        A 1 to 2-page summary report
                        (email
                        format) <br>
                        <span class="heading-5-light">Timeframe: </span> 1 -
                        5 working days, depending on
                        availability of and feeback from officials at
                        the local
                        municipality <br>
                        <span class="heading-5-light">Fee:</span> We charge
                        a basic fee of R1 500 (excl. VAT)
                        for
                        this study
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    SPECIAL OFFER:
                </div>
            </div>
            <div class="row">
                <div class="col-12 ">
                    <p class="paragraph light">
                        In cases where this investigation leads to a planning application to obtain any of the
                        rights/”legal uses”
                        as proposed by the planner, the initial <b>R1500 will be deducted</b> from the professional
                        fees
                        payable to the urban planner.
                    </p>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12 ">
                    <button class="heading-3 button-light"
                        [routerLink]="['/additional-services/property-investigation/level-1-investigation/']">REQUEST</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    IMPORTANT NOTE:
                </div>
            </div>
            <div class="row">
                <div class="col-12 paragraph light">
                    <ol>
                        <li>
                            The information provided will be our professional opinion, based on a desktop study only.
                        </li>
                        <li>
                            The title deed will not be examined to determine possible restrictions on the property and
                            such influences on the proposed application or the estimated costs.
                        </li>
                        <li>
                            Should you require details regarding the steps involved in the proposed application, as well
                            as a cost-breakdown and applicable payment schedule, we suggest that you request a full
                            quotation for a planning application
                            <span>
                                <a href="#" class="here-light"
                                    [routerLink]="['/additional-services/town-planning-application']">
                                    here
                                </a>
                            </span>.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel2">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    As part of the LEVEL 2 property investigation, Property Matrix offers to:
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white " (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Inspect the following documents:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Title deed – to verify if any restrictions on the property are documented
                        </li>
                        <li>
                            S.G. diagram(s) – to confirm exact boundaries and possible servitudes
                            registered
                        </li>
                        <li>
                            Zoning of the property
                        </li>
                        <li>
                            Town Planning Scheme.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Review the following (where applicable):
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Strategic plans of the local council such as the Integrated Development Plan
                            (IDP) and
                            Spatial
                            Development Framework (SDF)
                        </li>
                        <li>
                            Precinct plan
                        </li>
                        <li>
                            Development policies
                        </li>
                        <li>
                            Municipal Road Master Plan (proposed future roads near the property);
                            railway
                            lines and distances from
                            stations – only where information is provided by the Municipal GIS system.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Discuss and confirm our interpretation with Council regarding:
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Allowable land uses in relation to your requirements
                        </li>
                        <li>
                            Planning parameters: maximum proposed zoning, density, height, etc
                        </li>
                        <li>
                            Possible restraints (building lines, servitudes registered, etc.)
                        </li>
                        <li>
                            Parking requirements (where applicable)
                        </li>
                        <li>
                            Whether certain planning applications will be required
                        </li>
                        <li>
                            The most suitable and cost-effective approach to meet your specific needs;
                            and
                        </li>
                        <li>
                            Predicted costs and timeframes if a planning application is required.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph light">
                        Our findings and recommendations will be made available to you once the the
                        investigation is completed:
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph light">
                        <span class="heading-5-light"> Deliverable: </span> A 2-3-page
                        report, setting out
                        the
                        restraints and potential of the property. <br>
                        <span class="heading-5-light">Timeframe: </span> 1-3 weeks,
                        depending on availability
                        of
                        and feedback from officials at the local council. <br>
                        <span class="heading-5-light">Fee:</span> We charge a basic
                        fee of R4 000 (excl VAT)
                        for
                        this investigation.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    SPECIAL OFFER:
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph light">
                        In cases where this investigation leads to a planning application to obtain any of the
                        rights/”legal uses”
                        as proposed by the planner, the initial <b>R4 000</b> will include a quotation for the
                        relevant
                        application and will be
                        <b>deducted</b> from the professional fees payable to the urban planner.
                    </p>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <button class="heading-3 button-light"
                        [routerLink]="['/additional-services/property-investigation/level-2-investigation/']">REQUEST</button>
                </div>
            </div>
            <div class=" row">
                <div class="col-12 heading-5-light">
                    IMPORTANT NOTE:
                </div>
                <div class="col-12 paragraph light">
                    Inspection of documents or studies such as the following, is NOT included in this level of
                    investigation:
                </div>
            </div>
            <div class="row ">
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Building plans (of existing structures on the property)
                        </li>
                        <li>
                            EIA – Environmental Impact Assessment
                        </li>
                        <li>
                            Civil Services Reports (availability of services: sewer, water, electricity
                        </li>
                        <li>
                            Geotechnical report (soil conditions)
                        </li>
                        <li>
                            Flood line determination (if near a water course)
                        </li>
                        <li>
                            Heritage Report (buildings over 60 years, graves/artifacts on site)
                        </li>
                        <li>
                            TIA – Traffic Impact Assessment
                        </li>
                        <li>
                            Access studies; etc.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph light">
                        Timeframes and costing of the above will be essential for budgeting purposes should you
                        consider
                        developing the property. This will, however, be considered as a Level 3 investigation, which
                        can
                        also be
                        accompanied by a feasibility study, where the most feasible option to develop the property,
                        as
                        well as your
                        expected return on investment (ROI) can be determined. Our fees for a Level 3 investigation
                        and/or feasibility
                        study depends on the type of development and is therefore project specific.
                    </p>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container " [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel3">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Our LEVEL 3 investigation generally incorporates the following
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white " (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph light">
                        Note: Every site and every development opportunity are unique and will be treated on its own
                        merits
                    </p>
                </div>
                <div class="col-12 heading-5-light">
                    Comprehensive property investigation:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Determining current and prospected zoning, acceptable future densities, exact
                            boundaries, servitudes,
                            building restrictions etc. to determine the potential as well as the possible
                            constraints of your property
                            and/or proposed development.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Market research:
                </div>
                <div class="col-12 paragraph light">
                    A comprehensive market survey of both existing developments as well as newly proposed
                    developments of
                    similar property types in the area to establish …
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            what is selling
                        </li>
                        <li>
                            for what price
                        </li>
                        <li>
                            at what pace; and
                        </li>
                        <li>
                            whether the price reflects the quality of the product and its
                            finishes/facilities
                            offered.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Design options:
                </div>
                <div class="col-12">
                    <p class="paragraph light">
                        Once the demand for what you plan to develop is confirmed, as well as market-related
                        costing
                        thereof, it
                        can be weighed against the planning parameters of the site to determine the most
                        viable
                        option, design,
                        and scope to develop the site. Depending on the desired level of detail, a massing
                        diagram
                        (drawings of
                        the exterior shape of the buildings and their positioning on the site) can be done
                        by an
                        architect to express
                        possible scenarios on how the site can be developed. Considering the parking
                        requirements,
                        shadows on
                        adjacent sites, privacy of neighbours, etc. recommendations will be made on the most
                        viable,
                        but also the
                        most aesthetic way of developing the site.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Development costs:
                </div>
                <div class="col-12 paragraph light">
                    Calculations of the expected capital expenses and potential operational expenses of the
                    proposed
                    development. The following calculations (amongst others) can now kick off:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Building cost of structures/units
                        </li>
                        <li>
                            Provision for parking
                        </li>
                        <li>
                            Security fences and equipment
                        </li>
                        <li>
                            Civil services (water, sewer)
                        </li>
                        <li>
                            Electrical and fire costs
                        </li>
                        <li>
                            Professional fees
                        </li>
                        <li>
                            Landscaping and irrigation
                        </li>
                        <li>
                            Plan scrutiny fees and all other administration costs
                        </li>
                        <li>
                            Land price; etc.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    … with the following controls:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Average selling rate per square metre
                        </li>
                        <li>
                            Total development rate per square metre (GBA)
                        </li>
                        <li>
                            Total improvement cost per square metre
                        </li>
                        <li>
                            Cost per opportunity
                        </li>
                        <li>
                            Average net income per opportunity
                        </li>
                        <li>
                            Profit per opportunity
                        </li>
                        <li>
                            Building cost as a percentage of total expenses
                        </li>
                        <li>
                            Expected Return on Investment (ROI)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Possible additional studies:
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>
                            Studies, such as the following, might be required, depending on the location of
                            the site, the depth of the
                            study and the time and costs allocated:
                        </li>
                        <li>
                            Reports by civil and electrical engineers – providing more detailed calculations
                            on what the expected
                            internal and external services for the development as well as the expected bulk
                            contributions to the
                            relevant council will comprise of.
                        </li>
                        <li>
                            Flood line study by a civil engineer if the property is adjacent to a water
                            course.
                        </li>
                        <li>
                            Access study by a traffic engineer if located next to a major road
                        </li>
                        <li>
                            Environmental Impact Assessment (EIA)
                        </li>
                        <li>
                            Traffic Impact Assessment (TIA) by a traffic engineer
                        </li>
                        <li>
                            Geotechnical report, checking soil conditions for construction, etc.
                        </li>
                    </ul>
                </div>

            </div>
            <div class="row ">
                <div class="col-12">
                    <p class="paragraph light">
                        You will be advised, however, should any conditions flashes a red light.
                    </p>
                </div>
                <div class="col-12">
                    <p class="paragraph light">
                        <span class="heading-5-light"> Deliverable: </span> A
                        10-20-page report, setting out the
                        development potential of the property as well as the
                        expected ROI (return on investment).
                    </p>
                    <p class="paragraph light">
                        <span class="heading-5-light"> Timeframe: </span> 4 – 6
                        weeks
                    </p>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light"
                            [routerLink]="['/additional-services/property-investigation/level-3-investigation/']">REQUEST</button>
                    </div>
                </div>
                <div class="col-12 heading-5-light">
                    IMPORTANT NOTE:
                </div>
                <div class="col-12 paragraph light">
                    Should you consider a development in an industry such as hospitality, leisure, commercial,
                    retail,
                    etc., you will
                    most probably need to add a socio-economic component, verifying the demographic composition of
                    your
                    target market, their purchasing power, and their expenditure patterns. These elements, versus
                    the
                    scope
                    of your direct competitors will directly influence the optimal viable size of your development
                    or
                    business
                    premises. Refer to our feasibility studies and market gap analysis for more information.
                </div>
            </div>
        </div>
    </mat-sidenav>
    <main class="background-image container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                PROPERTY INVESTIGATIONS
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="paragraph text-center">
                                If you need advice regarding a certain property, e.g., possible land uses, foreseeable
                                risks, or
                                its full potential – <b>Property Matrix</b> has a team of experienced planners as well
                                as
                                extended expertise on board, ready to launch an investigation. We will provide you with
                                information and options you can trust, empowering you to make clear decisions.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 heading-4 text-center pb-1">
                            We offer three levels of property investigations:
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="numbers-right-border">
                                    <div class="heading-3 text-center">
                                        LEVEL
                                    </div>
                                    <div class="numbers text-center">
                                        1
                                    </div>
                                    <div class="heading-5 align-height_2">
                                        Required if you need to know:
                                    </div>
                                    <ul class="montserrat-bullets paragraph align-height">
                                        <li>
                                            The exact boundaries and possible
                                            servitudes over the property
                                        </li>
                                        <li>
                                            Whether a specific use/business is likely
                                            to be permitted; or
                                        </li>
                                        <li>
                                            Whether a town planning application
                                            (e.g. subdivision, consent use or
                                            rezoning) will be favourably considered
                                            by authorities
                                        </li>
                                    </ul>
                                    <div class="text-center paragraph mt-1">
                                        R1 500 (desktop only)
                                    </div>
                                    <div class="text-center">
                                        <button class="small-button button" id="LearnMore1"
                                            (click)="toggleColorButton1(0)">LEARN
                                            MORE</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="numbers-right-border">
                                    <div class="heading-3 text-center">
                                        LEVEL
                                    </div>
                                    <div class="numbers text-center">
                                        2
                                    </div>
                                    <div class="heading-5 align-height_2">
                                        Required if you need to know:
                                    </div>
                                    <ul class="montserrat-bullets paragraph align-height">
                                        <li>
                                            What the planning parameters/potential
                                            of the property is: maximum proposed
                                            zoning, density, height, etc.
                                        </li>
                                        <li>
                                            The exact boundaries and possible
                                            servitudes over the property
                                        </li>
                                        <li>
                                            Whether certain planning applications will
                                            be required; and
                                        </li>
                                        <li>
                                            The most suitable and cost-effective
                                            approach to reach it's potential.
                                        </li>
                                    </ul>
                                    <div class="text-center paragraph mt-1">
                                        R4 000
                                    </div>
                                    <div class="text-center">
                                        <button class="small-button button" id="btn"
                                            (click)="toggleColorButton2(1)">LEARN
                                            MORE</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="heading-3 text-center">
                                    LEVEL
                                </div>
                                <div class="numbers text-center">
                                    3
                                </div>
                                <div class="heading-5 align-height_2">
                                    Required if you wish to develop the site:
                                </div>
                                <ul class="montserrat-bullets paragraph align-height">
                                    <li>
                                        Determine the building design and site layout
                                        that will make optimum use of the site
                                    </li>
                                    <li>
                                        Obtain an upfront indicator of whether your
                                        project will provide a satisfactory return on
                                        investment (ROI)
                                    </li>
                                    <li>
                                        Obtain inputs from external professionals
                                        (e.g. engineers, architects, environmental- or
                                        geotechnical experts) should any proposed
                                        risks need to be addressed.
                                    </li>
                                </ul>
                                <div class="text-center paragraph mt-1">
                                    R20k – R80k
                                </div>
                                <div class="text-center">
                                    <button class="small-button button" id="LearnMore3"
                                        (click)="toggleColorButton3(2)">LEARN
                                        MORE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</mat-sidenav-container>