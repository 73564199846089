<main>
    <div [hidden]="pageNumber != 1">
        <app-property-for-sale-search (pageNumberEvent)="changePage($event)"></app-property-for-sale-search>
    </div>
    <div [hidden]="pageNumber != 2">
        <app-property-for-sale-options (pageNumberEvent)="changePage($event)"></app-property-for-sale-options>
    </div>
    <div [hidden]="pageNumber != 3">
        <app-property-for-sale-checkout (pageNumberEvent)="changePage($event)"></app-property-for-sale-checkout>
    </div>
</main>