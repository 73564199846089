<main>
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                Agent
                            </h1>
                        </div>
                    </div>
                    <div class="heading-5 text-center mt-4">
                        Agents can register once your Principal is endorsed.
                    </div>
                    <div class="row mt-5">
                        <div class="col-6 text-center principal-option">
                            <div class="row">
                                <div class="col heading-3 bold uppercase">
                                    Principal
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col numbers bold">
                                    1
                                </div>
                            </div>
                            <div class="row">
                                <div class="col paragraph">
                                    I am a principal that wishes to register my office with <b>Property Matrix</b>.
                                </div>
                            </div>
                            <div class="row paragraph mt-4">
                                <div class="text-center">
                                    <button (click)="proceedAsPrincipal()" class="button" type="submit">PROCEED</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-center agent-option">
                            <div class="row">
                                <div class="col heading-3 bold uppercase">
                                    Agent
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col numbers bold">
                                    2
                                </div>
                            </div>
                            <div class="row">
                                <div class="col paragraph">
                                    I am an Estate Agent/Broker that wishes to partner with <b>Property Matrix</b>.
                                </div>
                            </div>
                            <div class="row paragraph mt-4">
                                <div class="text-center">
                                    <button (click)="proceedAsAgent()" class="button" type="submit">PROCEED</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>