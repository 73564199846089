<div class="formal-valuation-report">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10 header">
            <div class="text-center heading-2-light">
                Formal Valuation Reports
            </div>
        </div>
        <div class="col-1">
            <button class="close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b class="formal-valuation-report-orange">
                Please note:
            </b>
            Our national network of selected Professional Valuers all registered with the South African Council for the
            Property Valuers Profession (“SACPVP”) will provide this service at a competitive rate. Rest assured that in
            terms of the Professional Valuers code of conduct, all property valuations will be performed in an
            independent and objective manner.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b>Description of a Formal Valuation Report:</b>
            <br />
            This is a detailed valuation report and will include a site visit by the property valuer. The report will
            contain full details of market research and will be an accurate determination of the market value of the
            property. The report can also be used for negotiation purposes, tax and audit purposes, financing purposes,
            etc. It also provides a replacement value of the property improvements for insurance purposes.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b>Factors Considered:</b>
            <br />
            Property price, location, site specifics, land use (planning) rights, site improvements, building tenancy,
            market conditions, comparative analysis, financial, and more.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <div>
                <b>
                    DELIVERABLES AND TIME-FRAMES
                </b>
                <ul class="formal-valuation-report-paragraph">
                    <li>15 to 30-page PDF report</li>
                    <li>Site visit by professional valuer</li>
                    <li>Full market research and calculations</li>
                    <li>10 to 12 working days for delivery</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <b>
                Please note:
            </b>
            This report suits financing applications; however, banks often have specific templates. We can adapt the
            report for a fee, according to bank requirements.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b> FEE STRUCTURE (estimates only)</b>
            <br />
            Fees indicated in the table below exclude VAT and vary based on property value.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b class="formal-valuation-report-orange">Important:</b>
            This is a general indication of fees; each request for a valuation will be assessed individually. A
            quotation will be provided based on property specific characteristics.
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <div>
                <table class="formal-valuation-report-table">
                    <tr class="formal-valuation-report-table-th">
                        <td colspan=2 class="formal-valuation-report-center-table-text">FEE SCHEDULE: FORMAL VALUATION
                            REPORT
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td colspan=2 class="formal-valuation-report-table-td different-table-row-color">
                            Single Residential property (vacant or developed)
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value less than R5 million</td>
                        <td class="formal-valuation-report-table-td">R2 000 to R4 500</td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value between R5 million and R15 million</td>
                        <td class="formal-valuation-report-table-td">
                            R3 500 to R8 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value between R15 million and R30 million</td>
                        <td class="formal-valuation-report-table-td">
                            R7 500 to R14 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value between R30 million and R50 million</td>
                        <td class="formal-valuation-report-table-td">
                            R12 500 to R19 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value above R50 million</td>
                        <td class="formal-valuation-report-table-td">R17 500 +</td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td colspan=2 class="formal-valuation-report-table-td different-table-row-color">
                            Commercial property (vacant/industrial/offices/flats/retail/other)
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value less than R5 million</td>
                        <td class="formal-valuation-report-table-td">R3 500 to R7 500</td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value between R5 million and R15 million</td>
                        <td class="formal-valuation-report-table-td">
                            R7 500 to R15 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value between R15 million and R30 million</td>
                        <td class="formal-valuation-report-table-td">
                            R14 500 to R21 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td>Value between R30 million and R50 million</td>
                        <td class="formal-valuation-report-table-td">
                            R20 500 to R28 500
                        </td>
                    </tr>
                    <tr class="formal-valuation-report-table-th">
                        <td class="formal-valuation-report-table-td">Value above R50 million</td>
                        <td>R26 500 +</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-12">
            <b>QUOTE:</b>
            A quotation for a formal valuation of your property can be requested under “Additional Services”; “Property
            Valuation” (level 2 to be selected).
        </div>
    </div>
    <br />
    <div class="row text-center">
        <div class="col-12">
            <button class="heading-3 button-light" (click)="closeDialog()">GOT IT</button>
        </div>
    </div>
</div>