<main class="main-background" id="main">
    <div class="content content-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 heading-1 text-center pb-3">
                    OFFER TO PURCHASE WITH CONDITIONS
                </div>
                <div class="col-12 mt-2 paragraph text-center">
                    <div class="col-12 mt-2 paragraph text-center">Thank you for your offer to purchase this property.
                        The <b>Matrix</b> team has already investigated this property and is keen to assist you further
                        in the
                        formulation of any criteria which needs to be waived or fulfilled to ensure that your interests
                        are protected and that your exact property needs can be met. </div>
                    <div class="col-12 mt-2 paragraph text-center">It will take about 10-15 minutes to complete this
                        form within 3 easy steps:</div>
                    <div class="row mt-4">
                        <div class="col-4">
                            <div class="numbers-right-border">
                                <div class="heading-3 text-center">
                                    LEVEL
                                </div>
                                <div class="numbers text-center">
                                    1
                                </div>
                                <div class="align-height text-center">
                                    <div class="heading-5">Provide
                                        purchaser details
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="numbers-right-border">
                                <div class="heading-3 text-center">
                                    LEVEL
                                </div>
                                <div class="numbers text-center">
                                    2
                                </div>
                                <div class="align-height text-center">
                                    <div class="heading-5">Suggest/formulate
                                        conditions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="heading-3 text-center">
                                LEVEL
                            </div>
                            <div class="numbers text-center">
                                3
                            </div>
                            <div class="align-height text-center">
                                <div class="heading-5">Confirm
                                    purchase price
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        Please provide details as true and accurately as possible. Information provided as well as the
                        proposed conditions will be incorporated into a draft
                        version of an offer to purchase contract. You may still change or add conditions or anything
                        else within the contract. We will keep in touch until you
                        have peace of mind. The contract will not be forwarded to the Seller without your approval and
                        signatures
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 paragraph">
                        Be assured that all information provided will be protected in terms of our <u
                            class="coral-underline">privacy policy</u>.
                    </div>
                </div>
                <div class="row mt-4 paragraph">
                    <div class="col-12">
                        <b>Please confirm the following:</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                I have read the planning report on this property
                            </span>
                            <input type="checkbox" [(ngModel)]="confirmOne" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-12">
                        <label class="checkbox-container paragraph mt-1">
                            <span class="paragraph">
                                I am aware that the report is derived from a Matrix <u class="coral-underline">Level 2
                                    investigation</u>, based on information available at the time the report was
                                drafted.
                            </span>
                            <input type="checkbox" [(ngModel)]="confirmTwo" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="row mt-4 text-center">
                    <div class="col">
                        <button class="button heading-4"
                            [disabled]="confirmOne == false || confirmTwo == false" (click)="gotoForm()">PROCEED</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>