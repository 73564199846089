import { ResetPasswordInterface } from 'src/app/models/resetPassword';
import { ActivatedRoute } from '@angular/router';
import { GenericValidator } from 'src/app/services/generic-validator';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PasswordMatcher } from 'src/app/services/password-matcher';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public showSuccess: boolean;
  public showError: boolean;
  public errorMessage: string;

  private token: string;
  private email: string;

  constructor(private authService: PropertyMatrixSharedService, private passConfValidator: GenericValidator,
    private _route: ActivatedRoute, private fb: FormBuilder) { }



  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, { validator: PasswordMatcher.match });

    this.token = this._route.snapshot.queryParams['token'];
    this.email = this._route.snapshot.queryParams['email'];
  }

  public validateControl = (controlName: string) => {
    return this.resetPasswordForm.controls[controlName].invalid && this.resetPasswordForm.controls[controlName].touched
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.resetPasswordForm.controls[controlName].hasError(errorName)
  }

  public resetPassword = (resetPasswordFormValue) => {
    this.showError = this.showSuccess = false;

    const resetPass = { ...resetPasswordFormValue };
    const resetPassDto: ResetPasswordInterface = {
      password: resetPass.password,
      confirmPassword: resetPass.confirm,
      token: this.token,
      email: this.email
    }

    this.authService.resetPassword(resetPassDto)
      .subscribe(result => {
        this.showSuccess = true;
      },
        error => {
          this.showError = true;
          this.errorMessage = error;
        })
  }

}