import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { SpatialFrameworkAndPolicyDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-spatial-framework-and-policy',
  templateUrl: './spatial-framework-and-policy.component.html',
  styleUrls: ['./spatial-framework-and-policy.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class SpatialFrameworkAndPolicyComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() spatialFrameworkChange = new EventEmitter<SpatialFrameworkAndPolicyDto[]>();

  spatialFrameworkAndPolicyData: SpatialFrameworkAndPolicyDto[] = [];

  file: any;
  fileId: string = null;
  fileUrls: string[] = [];
  hideButtons: boolean = false;

  maxSpatialFrameworks: number = 9;

  constructor(
    private reportingService: ReportingService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadSpatialFrameworkDetails();
  }

  async setFile(event: { target: { files: any[]; }; }, index: number): Promise<void> {
    try {
      this.fileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.spatialFrameworkAndPolicyData[index].imageUrl = this.fileId;
      this.emitChanges();

      this.file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrls[index] = event.target.result;
        this.hideButtons = true;
      }

      reader.readAsDataURL(this.file);
    } catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  async loadFile(index: number): Promise<void> {
    const spatialFramework = this.spatialFrameworkAndPolicyData[index];
    if (spatialFramework.imageUrl) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(spatialFramework.imageUrl);
        this.fileUrls[index] = fileUrl;
        this.hideButtons = !!fileUrl;
        this.emitChanges();
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async loadAllFiles(): Promise<void> {
    for (let i = 0; i < this.spatialFrameworkAndPolicyData.length; i++) {
      await this.loadFile(i);
    }
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createSpatialFramework(): void {
    const newSpatialFramework: SpatialFrameworkAndPolicyDto = {
      id: uuidv4(),
      description: '',
      imageName: '',
      imageUrl: '',
      name: ''
    };
    this.addItemToArray(this.spatialFrameworkAndPolicyData, newSpatialFramework);
  }

  addSpatialFramework(): void {
    if (this.spatialFrameworkAndPolicyData.length < this.maxSpatialFrameworks) {
      this.createSpatialFramework();
    } else {
      this.notificationService.showWarningMessage('Spatial Framework Limit Reached', 'You cannot add more than ' + this.maxSpatialFrameworks + ' spatial frameworks.');
    }
  }

  removeSpatialFramework(index: number): void {
    this.removeItemFromArray(this.spatialFrameworkAndPolicyData, index);
    this.fileUrls.splice(index, 1);
  }

  loadSpatialFrameworkDetails(): void {
    this.planningReportService.apiV1PlanningReportGetSpatialFrameworksGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: async (response) => {
        this.spatialFrameworkAndPolicyData = response;
        if (this.spatialFrameworkAndPolicyData === null || this.spatialFrameworkAndPolicyData.length === 0) {
          this.createSpatialFramework();
        } else {
          await this.loadAllFiles();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load spatial frameworks.');
      }
    });
  }

  emitChanges(): void {
    this.spatialFrameworkChange.emit(this.spatialFrameworkAndPolicyData);
  }
}
