<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<form class="registerForm" *ngIf="page == 1" id="siodRegisterForm" (ngSubmit)="submitRegister(form)" novalidate
    #form="ngForm">
    <section class="pb-4">
        <div class="heading-2">PERSONAL DETAILS</div>
        <div class="paragraph">Be assured that all information provided will be protected in terms of our <a
                class="privacy-policy-link" href="#">Privacy Policy.</a></div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                    [(ngModel)]="personalDetails.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                    [(ngModel)]="personalDetails.surname" required #surname="ngModel"
                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber" class="paragraph"
                    [(ngModel)]="personalDetails.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalConfirmPhoneNumber" for="txtPersonalConfirmPhoneNumber" class="paragraph">Confirm
                    Mobile:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmPhoneNumber" name="confirmPhoneNumber"
                    class="paragraph" required #confirmPhoneNumber="ngModel"
                    [(ngModel)]="personalDetails.confirmPhoneNumber"
                    [ngClass]="{'invalid-input': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                            'valid-input': (phoneNumber.value == confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                            'confirm-valid': phoneNumber.value == confirmPhoneNumber.value}">
                    *Confirm Mobile does not match Mobile Number
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                    class="paragraph" [(ngModel)]="personalDetails.emailAddress" required #emailAddress="ngModel"
                    [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                            'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                    Email:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                    class="paragraph" required #confirmEmail="ngModel" [pattern]="emailPattern"
                    [(ngModel)]="personalDetails.confirmEmail"
                    [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                            'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                            'confirm-valid': emailAddress.value == confirmEmail.value}">
                    *Confirm Email does not match Email Address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">Identity/Passport
                    Number:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number" class="paragraph"
                    [(ngModel)]="personalDetails.id_Number" required #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                            'valid-input': id_Number.valid}">
                <small class="form-control-feedback"
                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                    *Must must contain at least 9 characters
                </small>
            </div>
        </div>
    </section>
    <section>
        <div class="heading-4">Log-in details</div>
        <div class="row">
            <div class="col-2">
                <label id="lblPassword" for="txtPassword" class="paragraph">Password:</label>
            </div>
            <div class="col-6">
                <input type="password" placeholder="Mandatory" id="txtPassword" name="password" class="paragraph"
                    [(ngModel)]="registerBody.loginDetails.password" required #password="ngModel"
                    [pattern]="passwordPattern"
                    [ngClass]="{'invalid-input': password.invalid, 'valid-input': password.valid}">
                <small class="form-control-feedback" *ngIf="password.errors && (password.dirty || password.touched)">
                    * Must contain minimum eight characters, at least one uppercase letter, one
                    lowercase letter, one
                    number and one special character
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <label id="lblPasswordConfirm" for="txtPasswordConfirm" class="paragraph">Confirm
                    Password:</label>
            </div>
            <div class="col-6">
                <input type="password" placeholder="Mandatory" id="txtPasswordConfirm" name="passwordConfirm"
                    class="paragraph" [(ngModel)]="registerBody.loginDetails.passwordConfirm" required
                    #passwordConfirm="ngModel"
                    [ngClass]="{'invalid-input': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                                                    'valid-input': (password.value == passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched)}">
                <small class="form-control-feedback"
                    [ngClass]="{'confirm-invalid': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                                                                    'confirm-valid': password.value == passwordConfirm.value}">
                    *Confirm Password does not match Password
                </small>
            </div>
        </div>
    </section>
    <div class="text-center">
        <div>
            <div class="burnt-red heading-4" *ngIf="form.invalid && form.dirty">Please check all madatory
                fields.
            </div>
            <button id="btnSubmit" class="button heading-4" [disabled]="form.invalid" type="submit">SUBMIT</button>
        </div>
    </div>
</form>
<form class="companyInfo" *ngIf="page == 2" (ngSubmit)="submitCompanyInfo(form)" novalidate #form="ngForm">
    <section class="heading-2">COMPANY INFORMATION</section>
    <section>
        <div class="heading-5 remove-row-gap shift-left">
            <span class="burnt-red">| </span>How do you operate within the property industry?
        </div>
        <mat-radio-group name="radioUserType" [(ngModel)]="siodRegisterBody.userType" required>
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of userTypeSelection"
                [value]="selectionValue.key" >
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
    </section>
    <section>
        <div class="heading-5 remove-row-gap shift-left">
            <span class="burnt-red">| </span>What describes your interest
            in property?
        </div>
        <div *ngFor="let option of propertyInterestTypes; let index = i" class="remove-row-gap">
            <label class="checkbox-container" (change)="isOtherSelected()">
                <strong class="paragraph">{{option.value}}</strong>
                <input type="checkbox" [(ngModel)]="option.checked" required [name]="propertyInterest"
                    #propertyInterest="ngModel" />
                <span class="checkmark"></span>
            </label>
        </div>
        <div *ngIf="otherPropertyInterest == true"><textarea name="propertyInterestOther" id="propertyInterestOther"
                class="paragraph other" [(ngModel)]="siodRegisterBody.propertyInterestOther" required></textarea></div>
    </section>
    <section class="pt-3">
        <div *ngIf="siodRegisterBody.userType == 1">
            <div for="OrgName" class="heading-5 shift-left">
                <span class="cloud">| </span>Name of your company/business/agency:
            </div>
            <textarea class="other paragraph" name="OrgName" id="OrgName"
                [(ngModel)]="siodRegisterBody.name"></textarea>
        </div>
        <div *ngIf="siodRegisterBody.userType == 2">
            <div for="OrgNameRequired" class="heading-5 shift-left">
                <span class="burnt-red">| </span>Name of your company/business/agency:
            </div>
            <textarea required class="other paragraph" name="OrgNameRequired" id="OrgNameRequired"
                [(ngModel)]="siodRegisterBody.name"></textarea>
        </div>
    </section>
    <section>
        <div class="heading-5 remove-row-gap">
            Business Address
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Unit</label>
            </div>
            <div class="col-6">
                <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                    class="paragraph not-required" [(ngModel)]="businessAddress.unitNo">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                    class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-6">
                <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                    class="paragraph not-required" [(ngModel)]="businessAddress.complexName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                    class="paragraph" [(ngModel)]="businessAddress.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                    class="paragraph" [(ngModel)]="businessAddress.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                    [(ngModel)]="businessAddress.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                    [(ngModel)]="businessAddress.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Postal code:</label>
            </div>
            <div class="col-6">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="postalCode" class="paragraph"
                    [(ngModel)]="businessAddress.postalCode" #postalCode="ngModel" required
                    [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
            </div>
        </div>
    </section>
    <div class="row center-btn">
        <div class="burnt-red heading-4 text-center" *ngIf="(form.invalid && form.dirty)">Please check all mandatory
            fields
        </div>
        <button class="button heading-4" type="submit" [disabled]="form.invalid">SUBMIT</button>
    </div>
</form>