import { MultiplePropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingGroupStateService {
  private selectedListingGroupSource = new BehaviorSubject<MultiplePropertyListingDto | undefined>(this.loadInitialListingGroup());
  selectedListingGroup$ = this.selectedListingGroupSource.asObservable();

  constructor() { }

  setSelectedListingGroup(group: MultiplePropertyListingDto): void {
    sessionStorage.setItem('selectedListingGroup', JSON.stringify(group));
    this.selectedListingGroupSource.next(group);
  }

  clearSelectedListingGroup(): void {
    sessionStorage.removeItem('selectedListingGroup');
    this.selectedListingGroupSource.next(undefined);
  }

  private loadInitialListingGroup(): MultiplePropertyListingDto | undefined {
    const group = sessionStorage.getItem('selectedListingGroup');
    return group ? JSON.parse(group) : undefined;
  }
}
