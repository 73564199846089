<mat-card>
    <div class="group-image-container">
        <img *ngIf="group?.coverImage" [src]="group?.coverImage">
        <div class="image-overlay-right">
            <div class="paragraph report-reference-number">
                {{ group?.reportReferenceNumber ?? 'Ref # Unavailable' }}
            </div>
            <div class="paragraph report-status">
                {{ group?.dueDate ?? 'State Unavailable' }}
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="paragraph group-card-footer row">
        <div class="icon-container col-2 p-0">
            <mat-icon class="user-icon">person</mat-icon>
            <div class="role-icon">
                {{ group?.currentUser ?? 'N' }}
            </div>
        </div>
        <div class="paragraph professional-title col-8">
            {{ group?.currentUser ?? 'No professional available' }}
        </div>
        <div class="circle-button-container col-2">
            <button class="center-content next-button" (click)="goToListingGroupOverview()">
                <mat-icon class="next-button-icon">keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
</mat-card>