import { UrbanPlannerDetailsVm, UrbanPlannerExtendedNetworkVm, UploadDocumentsFileReturnVm, UrbanPlannerFormVm } from 'src/app/services/property-matrix/models';
import { UrbanPlannerService, UserService } from 'src/app/services/property-matrix/services';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { UrbanPlannerDto } from 'src/app/services/property-matrixV2/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FileUploader } from 'src/app/models/file-uploader';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-planner-register-form',
  templateUrl: './planner-register-form.component.html',
  styleUrls: ['./planner-register-form.component.scss']
})
export class PlannerRegisterFormComponent implements OnInit {

  pageNumber: number = 1;
  loading: boolean = false;
  user: any = null;
  fileUploader: FileUploader = null;
  registrationSub: Subscription;

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'INFORMATION' },
    { key: 2, value: 'EXTENDED NETWORK' },
    { key: 3, value: 'SUBMIT DOCUMENTS' },
    { key: 4, value: 'SIGN CONTRACT' },
  ];

  formUrbanPlanner: UrbanPlannerDto = {
    graduationInstitution: '',
    graduationYear: 0,
    registrationType: null,
    yearsOfExperience: 0,
    sacPlanRegNumber: '',
  }

  form: UrbanPlannerFormVm = {
    urbanPlannerDetails: null,
    urbanPlannerExtendedNetwork: null,
    paths: null,
    username: ''
  }

  constructor(
    private router: Router,
    private urbanPlannerService: UrbanPlannerService,
    private sharedService: PropertyMatrixSharedService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
  }

  changePage($event: number) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  assignUrbanPlannerDetails($event: UrbanPlannerDetailsVm) {
    this.form.urbanPlannerDetails = $event;
  }

  assignUrbanPlannerExtendedNetwork($event: UrbanPlannerExtendedNetworkVm) {
    this.form.urbanPlannerExtendedNetwork = $event;
  }

  assignFiles($event: UploadDocumentsFileReturnVm[]) {
    this.form.paths = $event;
    this.addOrRemoveBackgroundImage();
    this.submitForm();
  }

  selectPage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  addOrRemoveBackgroundImage() {
    if (this.pageNumber == 4) {
      document.getElementById('main').classList.add('background-image');
    } else {
      document.getElementById('main').classList.remove('background-image');
    }
  }

  submitForm() {
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.urbanPlannerService.apiV1UrbanPlannerCaptureUrbanPlannerDetailsPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed')
              .then(result => { });
          }
          this.loading = false;
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed')
            .then(result => { })
          this.loading = false;
        }
      });
  }

  isNormalUser() {
    const userModel = JSON.parse(JSON.parse(this.user));
    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an urban planner')
              .then(result => {
                this.router.navigateByUrl('');
              });
          }
          this.loading = false;
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an urban planner')
            .then(result => {
              this.router.navigateByUrl('');
            });
          this.loading = false;
        }
      });
  }
}
