<main>
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">Report Reference Number</label>
                    <div class="input-button-wrapper">
                        <input type="text" [(ngModel)]="referenceNumber" placeholder="Reference Number" />
                        <button (click)="referenceNumber = generateReferenceNumber()">Generate</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">
                        If this is a single property, click on completed.
                    </label>
                    <label class="col-12 bold">
                        If this property forms part of a development opportunity, click on create group.
                    </label>
                    <label class="col-12">
                        1. Select the multiple tab and click on
                    </label>
                    <label class="col-12">
                        2. Name this group
                    </label>
                    <label class="col-12">
                        3. Go to the single tab. Click on the kebab menu (three dots) of the property to be added.
                    </label>
                    <div class="d-flex justify-content-center align-items-center pt-3">
                        <button class="paragraph center-content navigate-button"
                            (click)="navigateToPlanningReport()">Completed</button>
                        <button class="paragraph center-content toggle-button"
                            (click)="showNumberPropertiesSection()">Create Group</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12">
                        Drag and drop cards to change the order.
                        The properties will be automatically numbered for the planning report.
                    </label>
                </div>
            </div>
            <div class="listing-card-container">
                <app-listing-card class="listing-card" *ngFor="let listing of propertyListings" [listing]="listing"
                    (listingSelected)="goToListingOverview(listing)">
                </app-listing-card>
            </div>
        </div>
    </mat-card>
</main>