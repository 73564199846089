<div *ngFor="let type of myProfileWorkDetails" class="mt-4">
    <mat-card *ngIf="type.value">
        <mat-card-content>
            <div>
                <div class="row">
                    <div class="col-11">
                        <div class="heading-2">{{type.title}}</div>
                    </div>
                    <div class="col-1 edit-button-margin"><button (click)="editInfo(type.key)"
                            mat-icon-button><mat-icon>edit</mat-icon></button></div>
                </div>
                <!-- Company Information -->
                <div class="row pt-3" *ngIf="type.key == 1">
                    <div *ngIf="type.value.franchiseName" class="col-12"><b>Franchise name:</b>
                        {{type.value.franchiseName}}</div>
                    <div *ngIf="type.value.franchiseBranch" class="col-12"><b>Franchise branch:</b>
                        {{type.value.franchiseBranch}}</div>
                    <div *ngIf="type.value.name" class="col-12"><b>Company name:</b> {{type.value.name}}</div>
                    <div *ngIf="type.value.companyName" class="col-12"><b>Company name:</b> {{type.value.companyName}}
                    </div>
                    <div *ngIf="type.value.vat" class="col-12"><b>VAT number:</b> {{type.value.vat}}</div>
                    <div *ngIf="type.value.registrationNumber" class="col-12"><b>Registration Number:</b>
                        {{type.value.registrationNumber}}</div>
                    <div *ngIf="type.value.phoneNumber" class="col-12"><b>Mobile:</b> {{type.value.phoneNumber}}</div>
                    <div *ngIf="type.value.alternativeNumber" class="col-12"><b>Alternative Mobile:</b>
                        {{type.value.alternativeNumber}}</div>
                    <div *ngIf="type.value.emailAddress" class="col-12"><b>Email:</b> {{type.value.emailAddress}}</div>
                    <div *ngIf="type.value.alternativeEmailAddress" class="col-12"><b>Alternative email:</b>
                        {{type.value.alternativeEmailAddress}}</div>
                    <div *ngIf="type.value.relevantCouncils" class="col-12"><b>Relevant councils:</b>
                        {{type.value.relevantCouncils}}</div>
                    <div *ngIf="type.value.experience" class="col-12"><b>Experience:</b> {{type.value.experience}}</div>
                </div>
                <!-- Principal details -->
                <div class="row pt-3" *ngIf="type.key == 2">
                    <div class="col-12"><b>Name:</b> {{type.value.name}}</div>
                    <div class="col-12"><b>Surname:</b> {{type.value.surname}}</div>
                    <div class="col-12"><b>Landline:</b> {{type.value.landline}}</div>
                    <div class="col-12"><b>Mobile:</b> {{type.value.phoneNumber}}</div>
                    <div class="col-12"><b>Email:</b> {{type.value.emailAddress}}</div>
                </div>
                <!-- Company Address -->
                <div class="row pt-3" *ngIf="type.key == 3">
                    <div *ngIf="type.value.unitNo" class="col-12"><b>Building/unit no:</b> {{type.value.unitNo}}
                    </div>
                    <div *ngIf="type.value.complexName" class="col-12"><b>Building/complex name:</b>
                        {{type.value.complexName}}</div>
                    <div class="col-12"><b>Street no:</b> {{type.value.streetNo}}</div>
                    <div class="col-12"><b>Street name:</b> {{type.value.streetName}}</div>
                    <div class="col-12"><b>Suburb:</b> {{type.value.suburb}}</div>
                    <div class="col-12"><b>City:</b> {{type.value.city}}</div>
                </div>
                <!-- Account details -->
                <div class="row pt-3" *ngIf="type.key == 4">
                    <div class="col-12"><b>Account holder:</b> {{type.value.accountHolder}}</div>
                    <div class="col-12"><b>Bank:</b> {{type.value.bank}}</div>
                    <div class="col-12"><b>Branch name:</b> {{type.value.branchName}}</div>
                    <div class="col-12"><b>Branch number:</b> {{type.value.branchNumber}}</div>
                    <div class="col-12"><b>Type of account:</b> {{type.value.typeOfAccount}}</div>
                    <div class="col-12"><b>Account number:</b> {{type.value.accountNumber}}</div>
                </div>
                <!-- Supporting Staff Member: Town Planner -->
                <!-- Supporting Staff Member: Administrative -->
                <div class="row pt-3" *ngIf="type.key == 5 || type.key == 6">
                    <div class="col-12"><b>Name:</b> {{type.value.name}}</div>
                    <div class="col-12"><b>Surname:</b> {{type.value.surname}}</div>
                    <div class="col-12"><b>Position:</b> {{type.value.position}}</div>
                    <div class="col-12"><b>Landline:</b> {{type.value.landline}}</div>
                    <div class="col-12"><b>Mobile:</b> {{type.value.phoneNumber}}</div>
                    <div class="col-12"><b>Email:</b> {{type.value.emailAddress}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>