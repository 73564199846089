import { AddressDto, AllBuildingsDoneWithApprovedBuildingPlansDto, ApproximateBuildingAgeDto, ContactDetailDto, CopiesOfApprovedBuildingPlansDto, InvoiceIssuesToDto, JointPropertiesAreOwnedDto, MainPropertyUseDto, OccupationStatusDto, OrganizationInvoiceDetailsDto, PropertyDetailDto, ReasonForNotInterestedDto, SellingMyPropertyPreferredOptionDto, UploadFileDto, WishToSellDto } from 'src/app/services/property-matrixV2/models';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LookupService, MapsService, SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { IndividualInvoiceDetailsVm } from 'src/app/services/property-matrix/models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { animate, style, transition, trigger } from '@angular/animations';
import { GlobalMethods } from 'src/app/common/global-methods';
import { catchError, of } from 'rxjs';

export interface PropertyDetailsDialogData {
  schemeData: any[];
}

export interface JointPropertiesDialogComponent {
  dialogData: any;
}

export interface PropertyDetailsBuildingDialogData {
  dialogData: any;
}

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss', '../../../../css/2-modules/_forms.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class PropertyDetailsComponent implements OnInit, AfterViewInit {

  @Input() ownershipDetailsId: number = 0;

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() openSideNavEvent = new EventEmitter<number>();
  @Output() cadastreEvent = new EventEmitter<UploadFileDto>();
  @Output() propertyDetailsEvent = new EventEmitter<PropertyDetailDto>();

  scrollStrategy: ScrollStrategy;

  cadasterPicture: any = 1;
  addressLatLonData: any = null;
  addressGeocodeData: any = null;
  priceCombo: any[] = [{ val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }, { val: 0 }];

  loadingAddress: boolean = false;
  loadingCadaster: boolean = false;
  radioButtonSelected: boolean = false;
  buildingPlansApproved?: boolean = false;

  cityTown: string = '';
  unitNumber: string = '';
  suburbName: string = '';
  propertyKey: string = '';
  shortAddress: string = '';
  propertyType: string = '';
  erfOrPortionNumber: string = '';
  cadasterPictureBase64: string = '';

  issueToValue: number = 0;
  selectedButton: number = 0;
  wishToSellValue: number = 0;
  mainPropertyValue: number = 0;
  confirmPropertyDetail: number = 0;
  occupationStatusValue: number = 0;
  confirmCorrectAddress: number = 2;
  selectedBoundaryButton: number = 0;
  confirmPropertyBoundary: number = 0;
  hasImprovementPotential: number = 0;
  valueAlteringInfluences: number = 0;
  approximateBuildingValue: number = 0;
  approvedBuildingPlanValue: number = 0;
  copiesOfApprovedBuildingPlansNumber: number = 0;

  cadastre: UploadFileDto;
  addressSchemeData: PropertyDetailsDialogData;
  reasonForNotInterestedData: PropertyDetailsBuildingDialogData;

  wishToSellLookup: WishToSellDto[] = [];
  mainPropertyUseLookup: MainPropertyUseDto[] = [];
  invoiceIssuedToLookup: InvoiceIssuesToDto[] = [];
  occupationStatusLookup: OccupationStatusDto[] = [];
  jointPropertiesLookup: JointPropertiesAreOwnedDto[] = [];
  reasonForNotInterestedLookup: ReasonForNotInterestedDto[] = [];
  approximateBuildingAgeLookup: ApproximateBuildingAgeDto[] = [];
  sellingMyPropertyLookup: SellingMyPropertyPreferredOptionDto[] = [];
  copiesOfApprovedBuildingLookup: CopiesOfApprovedBuildingPlansDto[] = [];
  approvedBuildingPlansLookup: AllBuildingsDoneWithApprovedBuildingPlansDto[] = [];

  individualInvoiceDetails: IndividualInvoiceDetailsVm = {
    name: '',
    surname: '',
  }

  organizationInvoiceDetails: OrganizationInvoiceDetailsDto = {
    organizationName: '',
    companyRegistrationNumber: '',
    vatNumber: '',
  }

  contactInvoiceDetails: ContactDetailDto = {
    isTheContactDetailsToAppearOnInvoice: null,
    name: '',
    surname: '',
    landline: '',
    mobile: '',
    emailAddress: '',
    confirmEmailAddress: '',
  }

  physicalAddress: AddressDto = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    addressFull: '',
    propertyDescription: '',
  };

  form: PropertyDetailDto = {
    invoiceIssuedTo: null,
    isTheOrganizationRegisteredForVat: null,
    isTheContactDetailsToAppearOnInvoice: null,
    isTheAddressToAppearOnInvoice: null,
    propertyAddress: '',
    propertyBoundaryConcern: '',
    wishToSell: null,
    mainPropertyUse: null,
    approximateBuildingAge: null,
    reasonForNotInterested: null,
    hasCopiesOfApprovedBuildingPlans: null,
    jointProperties: null,
    hasAskingPrice: false,
    allBuildingsDoneWithApprovedBuildingPlans: null,
    sellMoreThanOneProperty: false,
    propertyDescription: '',
    confirmCorrectAddressDetails: false,
    mainPropertyUseOther: '',
    approximateBuildingAgeOther: 0,
    hasValueAlteringInfluences: false,
    valueAlteringInfluencesDescription: '',
    tenantedBuildingsAmount: 0,
    hasImprovementPotential: false,
    futurePotential: '',
    latitude: '',
    longitude: '',
    correctAddress: false,
    askingPrice: 0,
    physicalAddress: this.physicalAddress,
    contactInvoiceDetails: this.contactInvoiceDetails,
    organizationInvoiceDetails: this.organizationInvoiceDetails,
    individualInvoiceDetails: this.individualInvoiceDetails,
    riskscapeInfo: {
      neighbourhoodId: '',
      sectionalTitleUnitNumber: '',
      subplaceId: '',
      surveyorGeneralKey: ''
    },
    riskscapeAddressGeocode: {
      data: []
    },
    riskscapeAddress: {
      data: null
    },
    riskscapePositionLookup: {
      data: null
    },
    riskscapePropertyDetails: {
      data: null
    },
  };

  isTheAddressToAppearOnInvoiceRadio: KeyValuePair[] = [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' },
  ];

  isTheOrganizationRegisteredForVatRadio: KeyValuePair[] = [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' },
  ];

  isTheContactDetailsToAppearOnInvoiceRadio: KeyValuePair[] = [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' },
  ];

  constructor(
    public dialog: MatDialog,
    private _mapsService: MapsService,
    private _lookupService: LookupService,
    private readonly sso: ScrollStrategyOptions,
    private _notificationService: NotificationService,
    private _sharedService: PropertyMatrixSharedService,
    private _sellMyPropertyService: SellMyPropertyService
  ) {
    this.scrollStrategy = this.sso.noop();
  }

  ngOnInit(): void {
    this.loadWishToSellLookupData();
    this.loadInvoiceIssuesToLookupData();
    this.loadReasonForNotInterestedLookupData();
    this.loadApproximateBuildingAgeLookupData();
    this.loadOccupationStatusLookupLookupData();
    this.loadMainPropertyUseLookupData();
    this.loadSellingMyPropertyPreferredOptionLookupData();
    this.loadRequiredCadastre();
    this.loadApprovedBuildingPlans();
    this.loadApprovedBuildingPlansCopies();
    this.loadJointProperties();
  }

  ngAfterViewInit(): void { }

  handlePropertySelected(property: any): void {
    this.getAddressFromLatLon(property.position.lat, property.position.lon);
  }

  getLabelForGroup(index: number): string {
    switch (index) {
      case 0: return 'Million';
      case 1: return 'Thousand';
      case 2: return 'Hundred';
      default: return '';
    }
  }

  selectButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.confirmPropertyDetail = buttonNumber;
  }

  copiesOfApprovedBuildingPlansSelected(value: any) {
    this.form.hasCopiesOfApprovedBuildingPlans = this.copiesOfApprovedBuildingLookup.find(x => x.id == value.id);
    this.copiesOfApprovedBuildingPlansNumber = value.intValue;
  }

  submitForm() {
    try {
      this.form.hasImprovementPotential = this.hasImprovementPotential == 1;
      this.form.hasValueAlteringInfluences = this.valueAlteringInfluences == 1;
      this.form.correctAddress = this.confirmCorrectAddress == 1;
      this.form.imageBase64 = this.cadasterPictureBase64;
      this.form.physicalAddress = this.physicalAddress;
      this.form.askingPrice = this.form.hasAskingPrice ? this.form.askingPrice : 0;
      let priceStr = "";
      this.priceCombo.forEach((element) => {
        priceStr += element.val;
      });
      let price = Number(priceStr);
      this.form.askingPrice = price;
      this.form.riskscapeInfo.addressId = this.form.riskscapePropertyDetails.data.address_id;
      this.form.riskscapePositionLookup.data.attributes.address_id = this.form.riskscapePropertyDetails.data.address_id;
      this.form.riskscapePositionLookup.data.attributes.neighbourhood_id = this.form.riskscapePropertyDetails.data.neighbourhood_id;
      this.form.riskscapePositionLookup.data.attributes.subplace_id = this.form.riskscapePropertyDetails.data.subplace_id;
      this.form.riskscapePositionLookup.data.attributes.scheme_id = this.form.riskscapePropertyDetails.data.scheme_id;
      this.form.hasAskingPrice = this.form.hasAskingPrice ?? false;
      if (this.form.riskscapePositionLookup.data.attributes.property_key == null) {
        this.form.riskscapePositionLookup.data.attributes.property_key = this.propertyKey;
      }
      let selectedWishToSellOption = this.wishToSellLookup.find(x => x.intValue == this.wishToSellValue);
      this.form.wishToSell = selectedWishToSellOption;
      let selectedMainPropertyOption = this.mainPropertyUseLookup.find(x => x.intValue == this.mainPropertyValue);
      this.form.mainPropertyUse = selectedMainPropertyOption;
      let selectedInvoiceTo = this.invoiceIssuedToLookup.find(x => x.intValue == this.issueToValue);
      this.form.invoiceIssuedTo = selectedInvoiceTo;
      let selectedApproximateOption = this.approximateBuildingAgeLookup.find(x => x.intValue == this.approximateBuildingValue);
      this.form.approximateBuildingAge = selectedApproximateOption;
      let selectedOccupationOption = this.occupationStatusLookup.find(x => x.intValue == this.occupationStatusValue);
      this.form.occupationStatus = selectedOccupationOption;
      this.form.isTheOrganizationRegisteredForVat = this.form.isTheOrganizationRegisteredForVat ?? false;
      this.form.isTheContactDetailsToAppearOnInvoice = this.form.isTheContactDetailsToAppearOnInvoice ?? false;
      this.form.isTheAddressToAppearOnInvoice = this.form.isTheAddressToAppearOnInvoice ?? false;
      this.form.hasCopiesOfApprovedBuildingPlans = this.copiesOfApprovedBuildingLookup.find(x => x.intValue == this.copiesOfApprovedBuildingPlansNumber);
      this.form.allBuildingsDoneWithApprovedBuildingPlans = this.approvedBuildingPlansLookup.find(x => x.intValue == this.approvedBuildingPlanValue);
      this.form.contactInvoiceDetails.isTheContactDetailsToAppearOnInvoice = this.form.contactInvoiceDetails.isTheContactDetailsToAppearOnInvoice ?? false;
      if (this.form.riskscapeAddress.data == null) {
        this.getAddressDetails(this.addressGeocodeData.attributes.address_id);
        this.getPropertyDetails(this.addressGeocodeData.attributes.property_key, this.addressGeocodeData.attributes.unit_number);
        this.getAddressGeoCode();
      }
      this.gotoContractDetails();
      GlobalMethods.scrollToTop();
    } catch (_error: any) {
      this._notificationService.showWarningMessage('Warning', 'Riskscape Property Information could not be found. Please contact Property Matrix for support.');
    }
  }

  getAddressGeoCode() {
    this.loadingAddress = true;
    this.confirmCorrectAddress = 0;
    this._mapsService.apiV1MapsGetAddressGeocodePost$Response({ body: this.physicalAddress }).subscribe({
      next: (result: any) => {
        this.addressGeocodeData = JSON.parse(result.body);
        this.loadingAddress = false;
        if (this.addressGeocodeData.attributes.scheme_id) {
          this.getSchemeData(this.addressGeocodeData.attributes.scheme_id);
        } else {
          this.form.riskscapeAddressGeocode.data[0] = this.addressGeocodeData;
          this.getAddressImage(this.addressGeocodeData.attributes.property_key);
          this.form.riskscapeAddress.data = null;
          this.form.riskscapePositionLookup.data = null;
          this.form.riskscapePropertyDetails.data = null;
          this.form.riskscapeInfo.surveyorGeneralKey = this.addressGeocodeData.attributes.property_key;
          this.form.riskscapeInfo.sectionalTitleUnitNumber = this.addressGeocodeData.attributes.unit_number;
        }
        GlobalMethods.scrollToBottom();
      },
      error: (_error: any) => {
        this.loadingAddress = false;
        this._notificationService.showErrorMessage('Error', 'Could not find suburb layer.');
      }
    })
  }

  getAddressFromLatLon(lat: string, lon: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet$Response({ lat: lat, lon: lon }).subscribe({
      next: (result: any) => {
        this.form.riskscapeAddressGeocode.data[0] = null;
        this.form.riskscapePositionLookup.data = JSON.parse(result.body);
        this.addressLatLonData = this.form.riskscapePositionLookup.data;
        this.propertyType = this.addressLatLonData.type;
        if (this.addressLatLonData.type == 'scheme') {
          this.getSchemeData(this.addressLatLonData.attributes.scheme_id);
        }
        if (this.addressLatLonData.attributes.property_key) {
          this.getAddressDetails(this.addressLatLonData.attributes.address_id);
          this.getAddressImage(this.addressLatLonData.attributes.property_key);
          this.getPropertyDetails(this.addressLatLonData.attributes.property_key, "00000");
          this.form.riskscapeInfo.surveyorGeneralKey = this.addressLatLonData.attributes.property_key;
          this.form.riskscapeInfo.sectionalTitleUnitNumber = "00000";
        }
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not find address.');
      }
    });
  }

  getSchemeData(schemeId: string) {
    this._mapsService.apiV1MapsGetUnitsFromSchemeIdGet$Response({ schemeId: schemeId }).subscribe({
      next: (result: any) => {
        const resultJson = JSON.parse(result.body);
        this.addressSchemeData = {
          schemeData: resultJson
        };
        this.openSchemeOptionDialog();
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not find scheme.');
      }
    })
  }

  openSchemeOptionDialog() {
    const dialogRef = this.dialog.open(PropertyDetailsDialog, {
      width: '60vw',
      maxWidth: '300px',
      data: this.addressSchemeData,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetails(result.property_key, result.unit_number);
        this.getAddressImage(result.property_key);
        this.propertyKey = result.property_key;
        this.form.riskscapeInfo.surveyorGeneralKey = result.property_key;
        this.form.riskscapeInfo.sectionalTitleUnitNumber = result.unit_number;
      }
    });
  }

  getAddressDetails(addressId: string) {
    this._mapsService.apiV1MapsGetAddressDetailsGet$Response({ addressId: addressId }).subscribe({
      next: (result: any) => {
        this.form.riskscapeAddress.data = JSON.parse(result.body);
        this.form.latitude = this.form.riskscapeAddress.data.position.lat.toString();
        this.form.longitude = this.form.riskscapeAddress.data.position.lon.toString();
        this.physicalAddress = {
          unitNumber: '',
          complexName: this.form.riskscapeAddress.data.complex ?? '',
          streetNumber: this.form.riskscapeAddress.data.street_number,
          streetName: this.form.riskscapeAddress.data.street,
          suburb: this.form.riskscapeAddress.data.subplace,
          city: this.form.riskscapeAddress.data.city_town,
          postalCode: this.form.riskscapeAddress.data.postal_code,
        }
        this.unitNumber = this.physicalAddress.unitNumber;
        this.erfOrPortionNumber = result.body.erf_or_portion_nr;
        this.shortAddress = this.physicalAddress.streetNumber + ' ' + this.physicalAddress.streetName;
        this.suburbName = this.physicalAddress.suburb;
        this.cityTown = this.physicalAddress.city;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load address details.');
      }
    });
  }

  async getAddressImage(propertyKey: string) {
    this.loadingCadaster = true;
    let result = await this._sharedService.getAddressImage(propertyKey);
    if (result?.ok) {
      const resultJson = JSON.parse(result.body);
      this.cadasterPictureBase64 = resultJson.encoded;
      const base64Response = await fetch(`data:image/jpeg;base64,${this.cadasterPictureBase64}`);
      const blob = await base64Response.blob();
      let objectURL = URL.createObjectURL(blob);
      this.cadasterPicture = await this._sharedService.getImageFromDataUri(objectURL);
      this.cadastre.file = blob;
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not find address image.');
    }
    this.loadingCadaster = false;
  }

  async getPropertyDetails(propertyKey: string, unitNumber: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsGet$Response({ propertyKey, unitNumber }).subscribe({
      next: (result: any) => {
        this.form.riskscapePropertyDetails.data = JSON.parse(result.body);
        this.getAddressDetails(this.form.riskscapePropertyDetails.data.address_id);
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load property details.');
      }
    });
  }

  multipleOwnersEntities(intValue: number) {
    if (intValue == 2) {
      this.dialog.open(JointPropertiesDialogComponent, {
        width: '70%'
      });
    }
  }

  setValuesOfWishToSellRadioButtons(id: string) {
    let selectedOption = this.wishToSellLookup.find(x => x.id == id)
    this.form.wishToSell = selectedOption;
    if (selectedOption) {
      switch (selectedOption.intValue) {
        case 1:
          this.wishToSellValue = 1;
          break;
        case 2:
          this.wishToSellValue = 2;
          break;
        case 3:
          this.wishToSellValue = 3;
          break;
        default:
          break;
      }
    }
    this.radioButtonSelected = true;
  }

  setValuesOfMainPropertyUseRadioButtons(id: string) {
    let selectedOption = this.mainPropertyUseLookup.find(x => x.id == id)
    this.form.mainPropertyUse = selectedOption;
    if (selectedOption) {
      switch (selectedOption.intValue) {
        case 1:
          this.mainPropertyValue = 1;
          break;
        case 2:
          this.mainPropertyValue = 2;
          break;
        case 3:
          this.mainPropertyValue = 3;
          break;
        case 4:
          this.mainPropertyValue = 4;
          break;
        case 5:
          this.mainPropertyValue = 5;
          break;
        case 6:
          this.mainPropertyValue = 6;
          break;
        case 7:
          this.mainPropertyValue = 7;
          break;
        case 8:
          this.mainPropertyValue = 8;
          break;
        default:
          break;
      }
    }
  }

  setValuesOfApproximateBuildingAgeRadioButtons(value: number, id: string) {
    let selectedOption = this.approximateBuildingAgeLookup.find(x => x.id == id);
    this.form.approximateBuildingAge = selectedOption;
    if (selectedOption) {
      switch (selectedOption.intValue) {
        case 1:
          this.approximateBuildingValue = 1;
          break;
        case 2:
          this.approximateBuildingValue = 2;
          break;
        case 3:
          this.approximateBuildingValue = 3;
          break;
        case 4:
          this.approximateBuildingValue = 4;
          break;
        case 5:
          this.approximateBuildingValue = 5;
          break;
        case 6:
          this.approximateBuildingValue = 6;
          break;
        case 7:
          this.approximateBuildingValue = 7;
          break;
        case 8:
          this.approximateBuildingValue = 8;
          break;
        default:
          break;
      }
    }
  }

  setValuesOfOccupationStatusRadioButtons(id: string) {
    let selectedOption = this.occupationStatusLookup.find(x => x.id == id);
    this.form.occupationStatus = selectedOption;
    if (selectedOption) {
      switch (selectedOption.intValue) {
        case 1:
          this.occupationStatusValue = 1;
          break;
        case 2:
          this.occupationStatusValue = 2;
          break;
        case 3:
          this.occupationStatusValue = 3;
          break;
        default:
          break;
      }
    }
  }

  setValuesOfIssueToRadioButtons(id: string) {
    let selectedOption = this.invoiceIssuedToLookup.find(x => x.id == id);
    this.form.invoiceIssuedTo = selectedOption;
    if (selectedOption) {
      switch (selectedOption.intValue) {
        case 1:
          this.issueToValue = 1;
          break;
        case 2:
          this.issueToValue = 2;
          break;
        default:
          break;
      }
    }
  }

  openBuildingPlansOptionDialog(selectedValue: any) {
    this.approvedBuildingPlanValue = selectedValue.intValue;
    if (selectedValue.intValue != 1) {
      const dialogRef = this.dialog.open(PropertyDetailsBuildingDialog, {
        width: '70vw',
        height: '95vh',
        panelClass: ['dialogBackgroundColor', 'reason-for-not-interested-dialogue', 'square-dialog'],
        data: this.reasonForNotInterestedData,
        backdropClass: 'dialogBackgroundColor',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result != undefined) {
          this.buildingPlansApproved = result.agreeWithPopup;
          this.form.reasonForNotInterested = result.reasonForNotInterestedData;
        }
      });
    }
  }

  checkPriceInput(event: KeyboardEvent) {
    if (event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  }

  gotoContractDetails() {
    this.pageNumberEvent.emit(3);
    this.propertyDetailsEvent.emit(this.form);
    this.cadastreEvent.emit(this.cadastre);
  }

  openSideNav(page: number) {
    this.openSideNavEvent.emit(page);
  }

  selectBoundaryButton(buttonNumber: number) {
    this.selectedBoundaryButton = buttonNumber;
    this.confirmPropertyBoundary = buttonNumber;
  }

  selectInput(index: number) {
    this.form.hasAskingPrice;
    if (index != 0) {
      return;
    }
    const askingPriceElement: HTMLInputElement = document.querySelector('#askingPrice');
    setTimeout(() => {
      askingPriceElement.focus();
      askingPriceElement.select();
    }, 20);
  }

  loadRequiredCadastre() {
    this._sellMyPropertyService.apiV1SellMyPropertyLoadRequiredCadastreGet().subscribe({
      next: (res: UploadFileDto) => {
        this.cadastre = res;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load required cadastre.');
      }
    });
  }

  loadApprovedBuildingPlans() {
    this._lookupService.apiV1LookupGetAllBuildingsDoneWithApprovedBuildingPlansGet().subscribe({
      next: (res: AllBuildingsDoneWithApprovedBuildingPlansDto[]) => {
        this.approvedBuildingPlansLookup = res;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load copies of approved building plans.');
      }
    });
  }

  loadJointProperties() {
    this._lookupService.apiV1LookupGetJointPropertiesGet().subscribe({
      next: (res: JointPropertiesAreOwnedDto[]) => {
        this.jointPropertiesLookup = res;
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load joint properties.');
      }
    });
  }

  loadApprovedBuildingPlansCopies() {
    this._lookupService.apiV1LookupGetCopiesOfApprovedBuildingPlansGet().subscribe({
      next: (res: CopiesOfApprovedBuildingPlansDto[]) => {
        this.copiesOfApprovedBuildingLookup = res;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load copies of approved building plans.');
      }
    });
  }

  loadWishToSellLookupData() {
    this._lookupService.apiV1LookupGetIWishToSellLookupValuesGet().subscribe({
      next: (result: WishToSellDto[]) => {
        this.wishToSellLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load wish to sell lookup values.');
      }
    });
  }

  loadInvoiceIssuesToLookupData() {
    this._lookupService.apiV1LookupGetInvoiceIssuesLookupValuesGet().subscribe({
      next: (result: InvoiceIssuesToDto[]) => {
        this.invoiceIssuedToLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load invoice issues lookup values.');
      }
    });
  }

  loadReasonForNotInterestedLookupData() {
    this._lookupService.apiV1LookupGetReasonForNotInterestedLookupValuesGet().subscribe({
      next: (result: ReasonForNotInterestedDto[]) => {
        this.reasonForNotInterestedLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load reason for not interested lookup values.');
      }
    });
  }

  loadApproximateBuildingAgeLookupData() {
    this._lookupService.apiV1LookupGetApproximateBuildingAgeGet().subscribe({
      next: (result: ApproximateBuildingAgeDto[]) => {
        this.approximateBuildingAgeLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load approximate building age lookup values.');
      }
    });
  }

  loadOccupationStatusLookupLookupData() {
    this._lookupService.apiV1LookupGetOccupationStatusGet().subscribe({
      next: (result: OccupationStatusDto[]) => {
        this.occupationStatusLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load occupation status lookup values.');
      }
    });
  }

  loadMainPropertyUseLookupData() {
    this._lookupService.apiV1LookupGetGetMainPropertyGet().subscribe({
      next: (result: MainPropertyUseDto[]) => {
        this.mainPropertyUseLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load main property use lookup values.');
      }
    });
  }

  loadSellingMyPropertyPreferredOptionLookupData() {
    this._lookupService.apiV1LookupGetSellingMyPropertyPreferredOptionGet().subscribe({
      next: (result: SellingMyPropertyPreferredOptionDto[]) => {
        this.sellingMyPropertyLookup = result.sort((a, b) => a.intValue - b.intValue);
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load selling my property preferred option lookup values.');
      }
    });
  }
}

@Component({
  selector: 'property-details-dialog',
  templateUrl: './property-details-dialog.html',
  styleUrls: ['property-details.component.scss']
})
export class PropertyDetailsDialog implements OnInit {

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PropertyDetailsDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: PropertyDetailsDialogData
  ) { }

  ngOnInit(): void { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  selectProperty(property: any) {
    this.dialogRef.close(property);
  }
}

@Component({
  selector: 'property-details-building-dialog',
  templateUrl: './property-details-building-dialog.html',
  styleUrls: ['property-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PropertyDetailsBuildingDialog implements OnInit {

  agreeBool: boolean = false;
  reasonForNotInterested: any;
  ReasonForNotInterestedLookup: ReasonForNotInterestedDto[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService,
    public dialogRef: MatDialogRef<PropertyDetailsBuildingDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: PropertyDetailsBuildingDialogData,
  ) { }

  ngOnInit(): void {
    this.loadReasonForNotInterestedLookupData();
  }

  loadReasonForNotInterestedLookupData() {
    this.lookupService.apiV1LookupGetReasonForNotInterestedLookupValuesGet().subscribe({
      next: (result: ReasonForNotInterestedDto[]) => {
        this.ReasonForNotInterestedLookup = result;
      },
      error(_error: any) {
        this.notificationService.showErrorMessage('Error', 'Could not load reason for disinterest in lookup values.');
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close({ agreeWithPopup: this.agreeBool = true });
  }

  doNotAgreeEvent() {
    this.dialogRef.close({ reasonForNotInterestedData: this.reasonForNotInterested, agreeWithPopup: this.agreeBool = false });
  }
}

@Component({
  selector: 'app-joint-properties-dialog',
  templateUrl: './joint-properties-dialog.html',
  styleUrls: ['property-details.component.scss'],
})
export class JointPropertiesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<JointPropertiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JointPropertiesDialogComponent,
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
