import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { AddendumTwoReferenceDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-addendum-two-reference',
  templateUrl: './addendum-two-reference.component.html',
  styleUrls: ['./addendum-two-reference.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AddendumTwoReferenceComponent implements OnInit {

  @Input() addendumTwoId: string;
  @Output() referenceChange = new EventEmitter<AddendumTwoReferenceDto[]>();

  referenceData: AddendumTwoReferenceDto[] = [];
  maxReferences: number = 9;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadReferenceDetails();
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createReference(): void {
    const newReference: AddendumTwoReferenceDto = {
      id: uuidv4(),
      company: '',
      contactDetail: '',
      name: '',
      professional: '',
      website: ''
    };
    this.addItemToArray(this.referenceData, newReference);
  }

  addReference(): void {
    if (this.referenceData.length < this.maxReferences) {
      this.createReference();
    } else {
      this.notificationService.showWarningMessage('Reference Limit Reached', 'You cannot add more than ' + this.maxReferences + ' references.');
    }
  }

  removeReference(index: number): void {
    this.removeItemFromArray(this.referenceData, index);
  }

  loadReferenceDetails(): void {
    this.planningReportService.apiV1PlanningReportGetAddendumTwoReferencesGet({
      addendumTwoId: this.addendumTwoId
    }).subscribe({
      next: async (response) => {
        this.referenceData = response;
        if (this.referenceData === null || this.referenceData.length === 0) {
          this.createReference();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load references.');
      }
    });
  }

  emitChanges(): void {
    this.referenceChange.emit(this.referenceData);
  }
}
