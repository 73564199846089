<div class="p-3 popup-colors">
    <div class="row">
        <div class="col-11">
            <div class="heading-3-light">
                Required if advertising joint properties as one development opportunity
            </div>
        </div>
        <div class="col-1 exit-button">
            <button class="close-button" (click)="closeDialog()">X</button>
        </div>
    </div>
    <ol class="paragraph light">
        <li>
            A map or aerial photo, indicating all the properties involved and the exact boundaries.
        </li>
        <li>
            A Joint Sales Agreement, setting out the rules and general structure that your group will adhere to. The
            Property Matrix legal team can assist you in this matter.
        </li>
        <li>
            A resolution, signed by all owners, nominating a single representative (co-seller) who will liaise with
            Property Matrix.
        </li>
        <li>
            A resolution, signed by all owners, nominating a single conveyancer experienced in acting for groups. Our
            legal team specializes in these transactions.
        </li>
        <li>
            A resolution, signed by all owners, nominating a single commercial broker/estate agent registered with
            Property Matrix, who will act on behalf of all other commercial broker/estate agents involved.
        </li>
        <li>
            A Commission Split Agreement, drafted by the nominated agent, signed by all agents, owners, and the
            nominated conveyancer.
        </li>
    </ol>
    <div class="col-12 heading-5-light">
        Please note:
    </div>
    <div class="paragraph light">
        Each owner/seller involved will sign the sole and exclusive mandate contract, indicating the collective asking
        price as agreed upon. It is required that the Joint Sales Agreement (as an annexure to the contract), indicates
        the percentage split of the selling price between all parties once an offer is received. Property Matrix can
        assist in this matter: we have a national panel of SACPVP-registered valuers in our network and can refer a
        professional valuer who will objectively assess the open market value of each property involved.
    </div>
    <button class="button-light heading-3-light" style="margin: 20px auto; display: block;" (click)="closeDialog()">
        GOT IT
    </button>
</div>