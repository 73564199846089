import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';

export function InitializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak,
        realm: environment.keycloakrealm,
        clientId: environment.keycloakclient
      },
      initOptions: {
        onLoad: 'check-sso',
        redirectUri: window.location.origin,
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      },
      shouldAddToken: (request) => {
        const { url } = request;
        return url.startsWith(environment.api);
      }
    });
}
