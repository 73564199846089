import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { UserManagementService } from '../property-matrixV2/services';
import { RoleBasedAccessDto } from '../property-matrixV2/models';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private keycloakService: KeycloakService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService
  ) { }

  async setUserToken(path: string = '') {
    try {
      const isLoggedIn = await this.keycloakService.isLoggedIn();

      if (!isLoggedIn) {
        this.keycloakService.login({redirectUri: `${window.location.origin}/#${path}`});
        return;
      }

      if (!this.isUserTokenSet()) {
        const source = this.userManagementService.apiV1UserManagementGetUserTokenGet().pipe();
        const token: RoleBasedAccessDto | null = await lastValueFrom(source);
        if (token) {
          sessionStorage.setItem('userToken', btoa(JSON.stringify(token)));
        }
      }
    } catch (error: any) {
      alert(error.message);
      this.notificationService.showErrorMessage('Error', 'Could not set user token. Please try again later or contact your administrator');
      setTimeout(() => {
        this.keycloakService.logout();
      }, 2000);
    }
  }

  isUserTokenSet() {
    return sessionStorage.getItem('userToken') !== null;
  }

  async getDecodedUserToken() {
    try {
      let token: string | null = sessionStorage.getItem('userToken');
      if (token != null) {
        return atob(token);
      }
    } catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not decode user token. Please try again later or contact your administrator.');
    }
    return null;
  }

  async getUserFullName(): Promise<string> {
    const token: any = await this.getDecodedUserToken();

    if (token != null) {
      const decodedToken: RoleBasedAccessDto = JSON.parse(token);

      if (decodedToken.userFullName != null && decodedToken.userFullName !== undefined) {
        return decodedToken.userFullName;
      } else {
        return '';
      }
    }
    return '';
  }
}
