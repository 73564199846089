import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ComingSoonDialogComponent } from '../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-town-planning-applications',
  templateUrl: './town-planning-applications.component.html',
  styleUrls: ['./town-planning-applications.component.scss']
})
export class TownPlanningApplicationsComponent {

  @ViewChild('sidenav') sidenav: MatSidenav;

  tabIndex = -1;
  isShow = true;
  open = false;
  showLevel1 = false;
  showLevel2 = false;
  showLevel3 = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  }

  toggleSideNav(index: number) {
    this.sidenav.open();
    this.tabIndex = index;
    this.isShow = false;
    this.open = true;
    this.showLevel1 = index == 1;
    this.showLevel2 = index == 2;
    this.showLevel3 = index == 3;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }

  close() {
    this.isShow = true;
    this.sidenav.close();
    this.open = false;
  }
}
