<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Street Number</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.streetNumber" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Street Name</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.streetName" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Complex Name</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.complexName" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Suburb Name</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.suburbName" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Sub-Place Name</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.subPlaceName" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">City/Town</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.city" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Municipality</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.municipality" />
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Province</label>
            <input class="col-12 mt-1 riskscape-data" type="text" readonly
                [(ngModel)]="riskscapePropertyInfoData.province" />
        </div>
    </div>
</main>