import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

import { PropertyForSalePurchaseDocumentsDialogComponent } from './property-for-sale-purchase-documents-dialog/property-for-sale-purchase-documents-dialog.component';

@Component({
  selector: 'app-property-for-sale-options',
  templateUrl: './property-for-sale-options.component.html',
  styleUrls: ['./property-for-sale-options.component.scss']
})
export class PropertyForSaleOptionsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @ViewChild('sidenav') sidenav: MatSidenav;

  pdfSrc = `../../../../assets/pdf/test.pdf`

  purchasableDocuments: purchasableDocuments[] = [
    { id: 1, name: 'Planning Report', description: 'Refer to preview above', price: 50, information: null, enabled: true, addedToCart: true },
    { id: 2, name: 'ADDENDUM 1', description: 'Site attributes and access', price: 180, information: 'test', enabled: true, addedToCart: false },
    { id: 3, name: 'ADDENDUM 2', description: 'Refer to preview above', price: 180, information: 'test', enabled: true, addedToCart: false },
    { id: 4, name: 'ADDENDUM 3', description: 'Refer to preview above', price: null, information: 'test', enabled: false, addedToCart: false },
    { id: 5, name: 'ADDENDUM 4', description: 'Refer to preview above', price: 180, information: 'test', enabled: true, addedToCart: false },
    { id: 6, name: 'ADDENDUM 4', description: 'Refer to preview above', price: 180, information: 'test', enabled: true, addedToCart: false },
    { id: 7, name: 'ADDENDUM 4', description: 'Refer to preview above', price: 180, information: 'test', enabled: true, addedToCart: false },
  ]

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PropertyForSalePurchaseDocumentsDialogComponent, {
      width: '35vw',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openNav() {
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }

  backToMap() {
    this.pageNumberEvent.emit(1)
  }

  gotoContactEstateAgent() {
    GlobalMethods.gotoNewTabPage('contact-estate-agent')
  }

  gotoContactTownPlanner() {
    GlobalMethods.gotoNewTabPage('contact-town-planner')
  }

  gotoMakeOfferWithConditions() {
    GlobalMethods.gotoNewTabPage('property-for-sale/offer-to-purchase-with-conditions')
  }

  gotoMakeOffer() {
    GlobalMethods.gotoNewTabPage('property-for-sale/offer-to-purchase')
  }
}

export interface purchasableDocuments {
  id: number,
  name: string,
  description: string,
  price: number | null,
  information: string | null,
  enabled: boolean,
  addedToCart: boolean,
}
