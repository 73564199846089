import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-key-dialog',
  templateUrl: './report-key-dialog.component.html',
  styleUrls: ['./report-key-dialog.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ReportKeyDialogComponent implements OnInit {

  keys = [
    { name: 'Matrix Programming', color: '#f2825e' },
    { name: 'Riskscape Data', color: '#287baf' },
    { name: 'Proposed text', color: '#a7a8aa' },
    { name: 'Typing Text - editable text inserted by previous Planner.', color: '#040707' }
  ];

  constructor(public dialogRef: MatDialogRef<ReportKeyDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
