import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';

import { RoleBasedAccessDto } from '../services/property-matrixV2/models';
import { AccountService } from '../services/auth/account-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    private accountService: AccountService,
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authenticated) {
      sessionStorage.clear();
      await this.keycloak.login({
        redirectUri: window.location.href
      });
    }
    return true;
  }

  public override async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this.accountService.setUserToken(state.url);

    let decodedToken: RoleBasedAccessDto = {};
    await this.accountService.getDecodedUserToken().then((token: any) => {
      if (token != null) {
        decodedToken = JSON.parse(token);
      }
    });

    let requiredRoles: string[] = [];
    if (route.data?.roles) {
      requiredRoles = route.data['roles'];
    }

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.some((role) => decodedToken.roles?.includes(role));
  }
}
