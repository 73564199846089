<link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14=" crossorigin="" />
<section [hidden]="showAddSubscription">
    <div class="heading-1">SUBSCRIPTIONS</div>
    <div class="paragraph mb-3">Click on the plus sign to kick off with subscription one</div>
    <div class="fill-white text-center">
        <div class="add-subscribe-block" *ngFor="let subscription of subscriptions; let i = index"
        [ngClass]="{'clickable': subscription.showPlusIcon, 'black-backround': !subscription.showPlusIcon}" (click)="gotoAddSubscription(subscription.showPlusIcon)">
        {{subscription.showPlusIcon | json}}   
        <div *ngIf="subscription.showPlusIcon; else showPlusIcon">
                <mat-icon class="add-icon">add</mat-icon>
            </div>
            <ng-template #showPlusIcon>
                <div class="add-block-text paragraph light">
                    <span>SUBSCRIPTION</span>
                    <br>
                    <b>{{i + 1}}</b>
                    <br>
                    <span><button (click)="deleteSubscription(i)" mat-icon-button><mat-icon>delete</mat-icon></button></span>
                </div>
            </ng-template>
        </div>
        <section>
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" [disabled]="!containsSubscription" class="button heading-4"
                        (click)="submitForm()">SUBMIT</button>
                </div>
            </div>
        </section>
    </div>
</section>
<section [hidden]="!showAddSubscription" style="margin-left: -11vw;">
    <section class="page-1" [hidden]="page != 1">
        <div class="row">
            <div class="col-1">
                <div class="stepper-text">
                    <div class="heading-3 grey">SIZE</div>
                    <div class="heading-3 grey">PRICE</div>
                    <div class="heading-3 grey">TYPE</div>
                    <div class="heading-3 grey">LOCATION</div>
                    <div class="heading-3 coral-text">SECTOR</div>
                </div>
            </div>
            <div class="col-1">
                <div class="stepper-left">
                    <div class="sector-dot dot"></div>
                    <div class="location-dot dot"></div>
                    <div class="type-dot dot"></div>
                    <div class="price-dot dot"></div>
                    <div class="size-dot dot"></div>
                </div>
            </div>
            <div class="col-10">
                <div class="heading-1">SUBSCRIPTION</div>
                <div class="heading-2 py-2"><span class="burnt-red">| </span>Select a property sector.</div>
                <div class="center-content">
                    <button class="sector-button" (click)="developedClicked()">
                        <div class="heading-3 py-2" [ngClass]="{'coral-underline' : sectorOptions[0].checked}">DEVELOPED
                        </div>
                        <div class="heading-5">Property with existing buildings/facilities:</div>
                        <div class="paragraph">ready to be occupied, renovated, fitted out, or stocked</div>
                    </button>
                    <button class="sector-button" (click)="developableClicked()">
                        <div class="heading-3 py-2" [ngClass]="{'coral-underline' : sectorOptions[1].checked}">
                            DEVELOPABLE</div>
                        <div class="heading-5">Land/erven with development potential:</div>
                        <div class="paragraph">generally vaccant OR with structures that can be demolished</div>
                    </button>
                    <div class="paragraph text-center py-4"><u>Please note:</u> Only one sector can be selected per
                        subscription.
                        You will have the opportunity to
                        register for the additional sector once preferences for this subscription are finalised.
                    </div>
                    <button type="button" class="heading-4 button" (click)="sectorSubmit()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
    <section class="page-2" [hidden]="page != 2">
        <div class="row">
            <div class="col-1">
                <div class="stepper-text">
                    <div class="heading-3 grey">SIZE</div>
                    <div class="heading-3 grey">PRICE</div>
                    <div class="heading-3 grey">TYPE</div>
                    <div class="heading-3 coral-text">LOCATION</div>
                    <div class="heading-3 grey" (click)="goToPage(1)">SECTOR</div>
                </div>
            </div>
            <div class="col-1">
                <div class="stepper-left">
                    <div (click)="goToPage(1)" class="sector-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div class="location-dot dot"></div>
                    <div class="type-dot dot"></div>
                    <div class="price-dot dot"></div>
                    <div class="size-dot dot"></div>
                </div>
            </div>
            <div class="col-10">
                <div class="heading-1">SUBSCRIPTION</div>
                <div class="heading-2 py-2">
                    <span class="burnt-red">| </span>Indicate your location prefrences on the map.
                </div>
                <div class="map-container">
                    <div class="map-frame">
                        <!-- <div [hidden]="!loading" class="map-loader"></div> -->
                        <div id="siodMap"></div>
                    </div>
                    <div *ngIf="mapRendered" id="backButtonBlock">
                        <button *ngIf="showBackButton()" (click)="backButtonPress()" class="button" id="backButton">
                            <mat-icon>arrow_back_ios</mat-icon>
                        </button>
                    </div>
                    <div id="submitButtonBlock">
                        <div class="next-buttons">
                            <div *ngIf="showNonRefinableNote()" class="text-center">
                                <div class="coral-text">
                                    Please note
                                </div>
                                <span class="paragraph light">
                                    If you select more than one metro per subscription, you cannot refine regions or
                                    suburbs.
                                </span>
                                <br>
                                <button (click)="locationSubmit()" style="width: 40%" class="button heading-4">
                                    SUBMIT
                                </button>
                            </div>
                            <button *ngIf="metroSelected() && !showNonRefinableNote()" (click)="locationSubmit()" class="button heading-4 m-1">
                                SUBMIT
                            </button>
                            <span *ngIf="!moreThanOneMetroSelected()" class="coral-text"><b>OR</b></span>
                            <button *ngIf="!moreThanOneMetroSelected()" (click)="refineMetro()"
                                class="button heading-4 m-1">
                                Refine
                            </button>
                        </div>
                    </div>
                    <div class="submit-buttons" id="nextButtonBlock">
                        <button class="button heading-4 m-1" [hidden]="regionSelected()" (click)="zoomToSuburbLayer()">
                            Next
                        </button>
                        <button class="button heading-4 m-1" [hidden]="!regionSelected()" (click)="locationSubmit()">
                            Submit
                        </button>
                    </div>
                    <div class="submit-buttons" id="finalSubmitButtonBlock">
                        <button class="button heading-4 m-1" (click)="locationSubmit()" [hidden]="!subPlaceSelected()">
                            Submit
                        </button>
                        <button class="button heading-4 m-1" (click)="backButtonPress()" [hidden]="subPlaceSelected()">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="page-3" [hidden]="page != 3">
        <div class="row">
            <div class="col-1">
                <div class="stepper-text">
                    <div class="heading-3 grey">SIZE</div>
                    <div class="heading-3 grey">PRICE</div>
                    <div class="heading-3 coral-text">TYPE</div>
                    <div class="heading-3 grey" (click)="goToPage(2)">LOCATION</div>
                    <div class="heading-3 grey" (click)="goToPage(1)">SECTOR</div>
                </div>
            </div>
            <div class="col-1">
                <div class="stepper-left">
                    <div (click)="goToPage(1)" class="sector-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(2)" class="location-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div class="type-dot dot"></div>
                    <div class="price-dot dot"></div>
                    <div class="size-dot dot"></div>
                </div>
            </div>
            <div class="col-10">
                <div class="heading-1" style="margin-top: -5vh">SUBSCRIPTION</div>
                <div class="heading-2 py-2" *ngIf="sectorOptions[1].checked">
                    <span class="burnt-red">| </span>Which of the following uses/facilities do you intend to develop on
                    the site?
                    (only one sub-category per subscription)
                </div>
                <div class="property-type-block">
                    <div class="row g-0">
                        <div class="col-3">
                            <div *ngFor="let majorType of propertyTypes.slice(0, 1); let majorIndex = index"
                                class="paragraph m-1">
                                <table class="table-border">
                                    <tr>
                                        <th class="heading-5 text-center">{{majorType.name}}</th>
                                    </tr>
                                    <div class="bottom-border"
                                        *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                        <table>
                                            <tr>
                                                <td class="d-flex justify-content-between"
                                                    (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;">
                                                    <b>{{minorType.name}}</b>
                                                    <a>
                                                        <mat-icon class="expaned-false"
                                                            *ngIf="minorType.expanded === false">expand_more
                                                        </mat-icon>
                                                        <mat-icon class="expaned-true"
                                                            *ngIf="minorType.expanded === true">expand_less
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                <td *ngIf="minorType.expanded === true">
                                                    <div class="d-flex justify-content-between">
                                                        <label [for]="value.id"
                                                            class="value-name">{{value.name}}</label>
                                                        <input type="checkbox" [(ngModel)]="value.checked"
                                                            [id]="value.id" class="property-type-checkbox">
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div class="col-3">
                            <div *ngFor="let majorType of propertyTypes.slice(1, 4); let majorIndex = index"
                                class="paragraph m-1">
                                <table class="table-border">
                                    <tr>
                                        <th class="heading-5 text-center">{{majorType.name}}</th>
                                    </tr>
                                    <div class="bottom-border"
                                        *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                        <table>
                                            <tr>
                                                <td class="d-flex justify-content-between"
                                                    (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;">
                                                    <b>{{minorType.name}}</b>
                                                    <a>
                                                        <mat-icon class="expaned-false"
                                                            *ngIf="minorType.expanded === false">expand_more
                                                        </mat-icon>
                                                        <mat-icon class="expaned-true"
                                                            *ngIf="minorType.expanded === true">expand_less
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                <td [hidden]="minorType.expanded === false">
                                                    <div class="d-flex justify-content-between">
                                                        <label [for]="value.id"
                                                            class="value-name">{{value.name}}</label>
                                                        <input type="checkbox" [(ngModel)]="value.checked"
                                                            [id]="value.id" class="property-type-checkbox">
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div class="col-3">
                            <div *ngFor="let majorType of propertyTypes.slice(4, 6); let majorIndex = index"
                                class="paragraph m-1">
                                <table class="table-border">
                                    <tr>
                                        <th class="heading-5 text-center">{{majorType.name}}</th>
                                    </tr>
                                    <div class="bottom-border"
                                        *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                        <table>
                                            <tr>
                                                <td class="d-flex justify-content-between"
                                                    (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;">
                                                    <b>{{minorType.name}}</b>
                                                    <a>
                                                        <mat-icon class="expaned-false"
                                                            *ngIf="minorType.expanded === false">expand_more
                                                        </mat-icon>
                                                        <mat-icon class="expaned-true"
                                                            *ngIf="minorType.expanded === true">expand_less
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                <td *ngIf="minorType.expanded === true">
                                                    <div class="d-flex justify-content-between">
                                                        <label [for]="value.id"
                                                            class="value-name">{{value.name}}</label>
                                                        <input type="checkbox" [(ngModel)]="value.checked"
                                                            [id]="value.id" class="property-type-checkbox">
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div class="col-3">
                            <div *ngFor="let majorType of propertyTypes.slice(6, 8); let majorIndex = index"
                                class="paragraph m-1">
                                <table class="table-border">
                                    <tr>
                                        <th class="heading-5 text-center">{{majorType.name}}</th>
                                    </tr>
                                    <div class="bottom-border"
                                        *ngFor="let minorType of majorType.propertyTypeMinorCategories; let minorIndex = index">
                                        <table>
                                            <tr>
                                                <td class="d-flex justify-content-between"
                                                    (click)="minorType.expanded === true ? minorType.expanded = false : minorType.expanded = true;">
                                                    <b>{{minorType.name}}</b>
                                                    <a>
                                                        <mat-icon class="expaned-false"
                                                            *ngIf="minorType.expanded === false">expand_more
                                                        </mat-icon>
                                                        <mat-icon class="expaned-true"
                                                            *ngIf="minorType.expanded === true">expand_less
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let value of minorType.propertyTypeValues; let valueIndex = index">
                                                <td *ngIf="minorType.expanded === true">
                                                    <div class="d-flex justify-content-between">
                                                        <label [for]="value.id"
                                                            class="value-name">{{value.name}}</label>
                                                        <input type="checkbox" [(ngModel)]="value.checked"
                                                            [id]="value.id" class="property-type-checkbox">
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center-content">
                    <button type="button" class="heading-4 button my-2" (click)="typeSubmit()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
    <section class="page-4" [hidden]="page != 4">
        <div class="row">
            <div class="col-1">
                <div class="stepper-text">
                    <div class="heading-3 grey">SIZE</div>
                    <div class="heading-3 coral-text">PRICE</div>
                    <div class="heading-3 grey" (click)="goToPage(3)">TYPE</div>
                    <div class="heading-3 grey" (click)="goToPage(2)">LOCATION</div>
                    <div class="heading-3 grey" (click)="goToPage(1)">SECTOR</div>
                </div>
            </div>
            <div class="col-1">
                <div class="stepper-left">
                    <div (click)="goToPage(1)" class="sector-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(2)" class="location-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(3)" class="type-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div class="price-dot dot"></div>
                    <div class="size-dot dot"></div>
                </div>
            </div>
            <div class="col-10">
                <div class="heading-1">SUBSCRIPTION</div>
                <div class="heading-2 py-2">
                    <span class="burnt-red">| </span>Selling Price
                </div>
                <div *ngFor="let option of priceOptions; let index = i">
                    <label class="checkbox-container">
                        <strong class="paragraph">{{option.value}}</strong>
                        <input type="checkbox" [(ngModel)]="option.checked" [checked]="option.checked" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="center-content">
                    <button type="button" class="heading-4 button" (click)="priceSubmit()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
    <section class="page-5" [hidden]="page != 5">
        <div class="row">
            <div class="col-1">
                <div class="stepper-text">
                    <div class="heading-3 coral-text">SIZE</div>
                    <div class="heading-3 grey" (click)="goToPage(4)">PRICE</div>
                    <div class="heading-3 grey" (click)="goToPage(3)">TYPE</div>
                    <div class="heading-3 grey" (click)="goToPage(2)">LOCATION</div>
                    <div class="heading-3 grey" (click)="goToPage(1)">SECTOR</div>
                </div>
            </div>
            <div class="col-1">
                <div class="stepper-left">
                    <div (click)="goToPage(1)" class="sector-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(2)" class="location-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(3)" class="type-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div (click)="goToPage(4)" class="price-dot dot">
                        <mat-icon>check</mat-icon>
                    </div>
                    <div class="size-dot dot"></div>
                </div>
            </div>
            <div class="col-10">
                <div class="heading-1">SUBSCRIPTION</div>
                <div class="heading-2 pt-3 pb-2">
                    <span class="burnt-red">| </span>Size of land/erf
                </div>
                <div *ngFor="let option of standSizeOptions; let index = i">
                    <label class="checkbox-container">
                        <strong class="paragraph">{{option.value}}</strong>
                        <input type="checkbox" [(ngModel)]="option.checked" [checked]="option.checked" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div *ngIf="sectorOptions[0].checked && bedBathParkingChecked">
                    <div class="heading-2 pt-4 pb-2">
                        <span class="burnt-red">| </span>Size of residential/lodging facility
                    </div>
                    <div class="heading-5 py-1">Bedrooms</div>
                    <div *ngFor="let option of bedroomOptions; let index = i">
                        <label class="checkbox-container">
                            <strong class="paragraph">{{option.value}}</strong>
                            <input type="checkbox" [(ngModel)]="option.checked" [checked]="option.checked" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="heading-5 pt-3 pb-1">Bathrooms</div>
                    <div *ngFor="let option of bathroomOptions; let index = i">
                        <label class="checkbox-container">
                            <strong class="paragraph">{{option.value}}</strong>
                            <input type="checkbox" [(ngModel)]="option.checked" [checked]="option.checked" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="heading-5 pt-3 pb-1">Parking</div>
                    <div *ngFor="let option of parkingOptions; let index = i">
                        <label class="checkbox-container">
                            <strong class="paragraph">{{option.value}}</strong>
                            <input type="checkbox" [checked]="option.checked" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div *ngIf="sectorOptions[0].checked && squareMeterChecked">
                    <div class="heading-2 pt-4 pb-2">
                        <span class="burnt-red">| </span>Total size - all buildings on the site (bulk sqm)
                    </div>
                    <div *ngFor="let option of bulkSizeOptions; let index = i">
                        <label class="checkbox-container">
                            <strong class="paragraph">{{option.value}}</strong>
                            <input type="checkbox" [(ngModel)]="option.checked" [checked]="option.checked" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="center-content">
                    <button type="button" class="heading-4 button" (click)="setSubmit()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
</section>