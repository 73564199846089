<main>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Deeds Reports</label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="importantFactorsToConsiderData.deedsReports"
                (ngModelChange)="onInputChange()">
            </textarea>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Development Charges/Levies</label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="importantFactorsToConsiderData.developmentCharges"
                (ngModelChange)="onInputChange()">
            </textarea>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Heritage</label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="importantFactorsToConsiderData.heritage"
                (ngModelChange)="onInputChange()">
            </textarea>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Historic Approvals</label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="importantFactorsToConsiderData.historicApprovals"
                (ngModelChange)="onInputChange()">
            </textarea>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Future Roads</label>
            <textarea class="col-12 mt-1 text-area" [(ngModel)]="importantFactorsToConsiderData.futureRoads"
                (ngModelChange)="onInputChange()">
            </textarea>
        </div>
    </div>
</main>