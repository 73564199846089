import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComingSoonDialogComponent } from '../../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-valuer',
  templateUrl: './valuer.component.html',
  styleUrls: ['./valuer.component.scss']
})
export class ValuerComponent {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }
}
