import { AddressDto, DevelopmentSpecialityDto, LandDevelopmentTypeDto, MunicipalCouncilDto, SacplanRegistrationDto, UploadFileDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { LookupService, MapsService, SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { WizardDataService } from 'src/app/services/property-matrixV2/custom-services/wizard-data.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Subscription, catchError, finalize, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as L from 'leaflet';

import { PlannerPropertyDetailsDialogComponent } from './dialogs/planner-property-details-dialog/planner-property-details-dialog.component';

@Component({
  selector: 'app-planner-register-form-information',
  templateUrl: './planner-register-form-information.component.html',
  styleUrls: ['./planner-register-form-information.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class PlannerRegisterFormInformationComponent {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() cadastreEvent = new EventEmitter<UploadFileDto>();

  private map;
  propertySearchResults = [];
  loadingCadaster: boolean = false;
  subscription: Subscription;

  selectedButton: number = 0;
  selectedBoundaryButton: number = 0;
  confirmPropertyBoundary: number = 0;
  confirmPropertyDetail: number = 0;

  emailPattern: string = GlobalConstants.emailPattern;
  loaderHtml: string = `<div class="loader"></div>`;

  activeMarker;

  mediumMapIcon = L.icon({
    iconUrl: '../../assets/images/maps/icons/map-marker.png',
    iconSize: [54, 64],
  });

  physicalAddress: AddressDto = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    addressFull: '',
    propertyDescription: '',
  };

  checkboxForm: FormGroup;
  searchAmount: number;
  unitNumber: string;
  erfOrPortionNr: string;
  shortAddress: string;
  suburbName: string;
  cityTown: string;

  loadingAddress: boolean = false;
  cadastre: UploadFileDto;
  cadasterPictureBase64: string;
  cadasterPicture: any = 1;
  propertyKey: string = '';
  propertyType: string;
  riskscapePositionLookup: any = {};
  riskscapeAddress: any = {};
  riskscapeInfo: any = [];
  riskscapePropertyDetails: any = {};
  addressSchemeData: PlannerPropertyDetailsDialogData;
  municipalCouncilLookup: MunicipalCouncilDto[] = [];
  municipalCouncilChecked: MunicipalCouncilDto[] = [];
  developmentTypeChecked: DevelopmentSpecialityDto[] = [];
  landDevelopmentTypeChecked: LandDevelopmentTypeDto[] = [];

  developmentSpeciality: DevelopmentSpecialityDto[] = [];
  landDevelopmentType: LandDevelopmentTypeDto[] = [];
  registrationSub: Subscription;
  wizardDataSub: Subscription;
  registrationTypes: SacplanRegistrationDto[] = [];
  experienceInMarketResearch: boolean = false;

  form: UserDto = {
    confirmEmail: '',
    confirmMobile: '',
    email: '',
    idNumber: '',
    isSouthAfricanResident: null,
    landLine: '',
    mobile: '',
    name: '',
    passportNumber: '',
    surname: '',
    userName: '',
    password: '',
    passwordConfirm: '',
    urbanPlanner: {
      graduationInstitution: '',
      graduationYear: 0,
      registrationType: null,
      yearsOfExperience: 0,
      sacPlanRegNumber: '',
      companyDetail: {
        name: '',
        companyRegistrationNumber: '',
        companyVatNumber: '',
        propertyDetailsAddress: '',
        propertyBoundary: '',
        developmentSpeciality: null,
        developmentSpecialityOther: '',
        experienceInMarketResearch: false,
        landDevelopmentType: null,
        landDevelopmentTypeOther: '',
        municipalCouncilOther: '',
        municipalCouncil: null,
        addressDetail: this.physicalAddress,
        cadastre: null,
        propertyDetailsDescription: '',
        contactDetail: {
          confirmEmailAddress: '',
          emailAddress: '',
          isTheContactDetailsToAppearOnInvoice: false,
          surname: '',
          name: '',
          mobile: '',
          landline: '',
          position: ''
        }
      }
    }
  }

  validatedForm: FormGroup;

  constructor(
    private mapsService: MapsService,
    private sharedService: PropertyMatrixSharedService,
    public dialog: MatDialog,
    public lookupService: LookupService,
    private notificationService: NotificationService,
    private _sellMyPropertyService: SellMyPropertyService,
    private fb: FormBuilder,
    private _registrationService: RegistrationService,
    private _wizardDataService: WizardDataService,
  ) {
    this.validatedForm = this.fb.group({
      urbanPlanner: this.fb.group({
        graduationYear: ['', [Validators.required, this.yearValidator.bind(this)]]
      })
    });
  }

  ngOnInit(): void {
    this.LoadMunicipalityCouncilLookupData();
    this.LoadDevelopmentSpecialityLookupData();
    this.LoadLandDevelopmentLookupData();
    this.loadRequiredCadastre()
    this.initMap();
    this.loadRegistrationTypes();
    this.receiveData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  yearValidator(control: FormControl): { [key: string]: any } | null {
    const currentYear = new Date().getFullYear();
    const inputYear = Number(control.value);
    return inputYear > currentYear ? { 'yearInvalid': true } : null;
  }

  receiveData() {
    this.registrationSub = this._registrationService.userData.subscribe(userData => {
      this.form.confirmEmail = userData.confirmEmail;
      this.form.confirmMobile = userData.confirmMobile;
      this.form.email = userData.email;
      this.form.idNumber = userData.idNumber;
      this.form.isSouthAfricanResident = userData.isSouthAfricanResident;
      this.form.urbanPlanner.plannerEmail = userData.urbanPlanner.plannerEmail;
      this.form.urbanPlanner.plannerName = userData.urbanPlanner.plannerName;
      this.form.urbanPlanner.plannerSurname = userData.urbanPlanner.plannerSurname;
      this.form.urbanPlanner.plannerMobile = userData.urbanPlanner.plannerMobile;
      this.form.landLine = userData.landLine;
      this.form.mobile = userData.mobile;
      this.form.name = userData.name;
      this.form.passportNumber = userData.passportNumber;
      this.form.surname = userData.surname;
      this.form.userName = userData.userName;
      this.form.passwordConfirm = userData.passwordConfirm;
      this.form.password = userData.password;
      this.form.urbanPlanner.sacPlanRegNumber = userData.urbanPlanner.sacPlanRegNumber;
      this.form.urbanPlanner.registrationType = userData.urbanPlanner.registrationType;
    });
    this._registrationService.setUserEnrollmentData(this.form);
  }

  loadRegistrationTypes() {
    this.lookupService.apiV1LookupGetUrbanPlannerRegistrationTypesGet().subscribe({
      next: (result: SacplanRegistrationDto[]) => {
        this.registrationTypes = result;
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load registration types.');
      }
    })
  }

  getRegistrationType(registrationId: any) {
    this.form.urbanPlanner.registrationType = this.registrationTypes.find(x => x.id == registrationId)
  }

  municipalOtherCheck(option: MunicipalCouncilDto): boolean {
    return option.intValue == 6 && option.isChecked;
  }

  selectedMunicipalCouncil(municipalCouncil: MunicipalCouncilDto) {
    const selectedMunicipalCouncil = this.municipalCouncilLookup.find(x => x.id == municipalCouncil.id);
    if (selectedMunicipalCouncil) {
      if (selectedMunicipalCouncil.isChecked) {
        this.municipalCouncilChecked.push(selectedMunicipalCouncil);
      } else {
        const index = this.municipalCouncilChecked.findIndex(x => x.id == municipalCouncil.id);
        if (index !== -1) {
          this.municipalCouncilChecked.splice(index, 1);
        }
      }
    }
    this.form.urbanPlanner.companyDetail.municipalCouncil = this.municipalCouncilChecked;
    this.municipalOtherCheck(municipalCouncil);
  }

  selectedDevelopmentType(developmentType: DevelopmentSpecialityDto) {
    const selectedDevelopmentType = this.developmentSpeciality.find(x => x.id == developmentType.id);
    if (selectedDevelopmentType) {
      if (selectedDevelopmentType.isChecked) {
        this.developmentTypeChecked.push(selectedDevelopmentType);
      } else {
        const index = this.developmentTypeChecked.findIndex(x => x.id == developmentType.id);
        if (index !== -1) {
          this.developmentTypeChecked.splice(index, 1);
        }
      }
    }
    this.form.urbanPlanner.companyDetail.developmentSpeciality = this.developmentTypeChecked;
    this.developmentTypeOtherCheck(developmentType);
  }

  developmentTypeOtherCheck(option: DevelopmentSpecialityDto): boolean {
    return option.intValue == 9 && option.isChecked;
  }

  selectedLandDevelopmentType(landDevelopmentType: LandDevelopmentTypeDto) {
    const selectedLandDevelopmentType = this.landDevelopmentType.find(x => x.id == landDevelopmentType.id);
    if (selectedLandDevelopmentType) {
      if (selectedLandDevelopmentType.isChecked) {
        this.landDevelopmentTypeChecked.push(selectedLandDevelopmentType);
      } else {
        const index = this.landDevelopmentTypeChecked.findIndex(x => x.id == landDevelopmentType.id);
        if (index !== -1) {
          this.landDevelopmentTypeChecked.splice(index, 1);
        }
      }
    }
    this.form.urbanPlanner.companyDetail.landDevelopmentType = this.landDevelopmentTypeChecked;
    this.planningOtherCheck(landDevelopmentType);
  }

  planningOtherCheck(option: LandDevelopmentTypeDto): boolean {
    return option.intValue == 5 && option.isChecked;
  }

  LoadMunicipalityCouncilLookupData() {
    this.lookupService.apiV1LookupGetMunicipalLookupValuesGet().subscribe({
      next: (result: MunicipalCouncilDto[]) => {
        this.municipalCouncilLookup = result;
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not find municipality councils.');
      }
    })
  }

  LoadDevelopmentSpecialityLookupData() {
    this.lookupService.apiV1LookupGetDevelopmentSpecialityLookupValuesGet().subscribe({
      next: (result: DevelopmentSpecialityDto[]) => {
        this.developmentSpeciality = result;
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not fetch find development specialties.');
      }
    })
  }

  LoadLandDevelopmentLookupData() {
    this.lookupService.apiV1LookupGetLandDevelopmentLookupValuesGet().subscribe({
      next: (result: LandDevelopmentTypeDto[]) => {
        this.landDevelopmentType = result;
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not find land development data.');
      }
    })
  }

  async getAddressImage(propertyKey: string) {
    this.loadingCadaster = true;
    let result = await this.sharedService.getAddressImage(propertyKey);
    if (result?.ok) {
      const resultJson = JSON.parse(result.body);
      this.cadasterPictureBase64 = resultJson.encoded;
      const base64Response = await fetch(`data:image/jpeg;base64,${this.cadasterPictureBase64}`);
      const blob = await base64Response.blob();
      let objectURL = URL.createObjectURL(blob);
      this.cadasterPicture = await this.sharedService.getImageFromDataUri(objectURL)
      this.cadastre.file = blob;
    } else {
      GlobalMethods.tinyErrorAlert('Error', 'Problem finding image');
    }
    this.loadingCadaster = false;
  }

  setExperienceInMarketResearch(value: boolean) {
    this.form.urbanPlanner.companyDetail.experienceInMarketResearch = value;
  }

  getAddressFromLatLon(lat: string, lon: string) {
    this.mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet$Response({ lat: lat, lon: lon }).subscribe({
      next: (result: any) => {
        this.form.urbanPlanner.companyDetail.addressDetail = null;
        this.riskscapePositionLookup = JSON.parse(result.body);
        this.propertyType = this.riskscapePositionLookup.type;
        if (this.riskscapePositionLookup.type == 'scheme') {
          this.getSchemeData(this.riskscapePositionLookup.attributes.scheme_id)
        }
        if (this.riskscapePositionLookup.attributes.property_key) {
          this.getAddressDetails(this.riskscapePositionLookup.attributes.address_id)
          this.getAddressImage(this.riskscapePositionLookup.attributes.property_key);
          this.getPropertyDetails(this.riskscapePositionLookup.attributes.property_key, "00000")
        }
        this.getPropertyArea(lat, lon, this.propertyType);
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'Problem finding location');
      }
    })
  }

  getSchemeData(schemeId: string) {
    this.mapsService.apiV1MapsGetUnitsFromSchemeIdGet$Response({ schemeId: schemeId }).subscribe({
      next: (result: any) => {
        const resultJson = JSON.parse(result.body);
        this.addressSchemeData = {
          schemeData: resultJson
        };
        this.openSchemeOptionDialog()
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'Problem finding scheme');
      }
    })
  }

  async getPropertyDetails(propertyKey: string, unitNumber: string) {
    this.mapsService.apiV1MapsGetPropertyDetailsGet$Response({ propertyKey, unitNumber }).subscribe({
      next: (result: any) => {
        this.riskscapePropertyDetails.data = JSON.parse(result.body);
        this.getAddressDetails(this.riskscapePropertyDetails.data.address_id)
      },
      error: (error: any) => {

      }
    })
  }

  openSchemeOptionDialog() {
    const dialogRef = this.dialog.open(PlannerPropertyDetailsDialogComponent, {
      width: '60vw',
      maxWidth: '300px',
      data: this.addressSchemeData,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetails(result.property_key, result.unit_number);
        this.getAddressImage(result.property_key);
        this.propertyKey = result.property_key;
        this.riskscapeInfo.surveyorGeneralKey = result.property_key;
        this.riskscapeInfo.sectionalTitleUnitNumber = result.unit_number;
      }
    });
  }

  getAddressDetails(addressId: string) {
    this.mapsService.apiV1MapsGetAddressDetailsGet$Response({ addressId: addressId }).subscribe({
      next: (result: any) => {
        this.riskscapeAddress.data = JSON.parse(result.body);
        this.physicalAddress = {
          unitNumber: '',
          complexName: this.riskscapeAddress.data.complex ?? '',
          streetNumber: this.riskscapeAddress.data.street_number,
          streetName: this.riskscapeAddress.data.street,
          suburb: this.riskscapeAddress.data.subplace,
          city: this.riskscapeAddress.data.city_town,
          postalCode: this.riskscapeAddress.data.postal_code,
        }
        this.form.urbanPlanner.companyDetail.addressDetail = this.physicalAddress;
        this.unitNumber = this.physicalAddress.unitNumber
        this.erfOrPortionNr = result.body.erf_or_portion_nr
        this.shortAddress = this.physicalAddress.streetNumber + ' ' + this.physicalAddress.streetName
        this.suburbName = this.physicalAddress.suburb
        this.cityTown = this.physicalAddress.city
      },
      error: (error) => {

      }
    })
  }

  setMainMapView(lat: number, lon: number) {
    if (this.activeMarker) {
      this.map.removeLayer(this.activeMarker)
    }

    this.activeMarker = L.marker([lat, lon], {
      icon: this.mediumMapIcon
    })

    this.activeMarker.bindPopup(this.loaderHtml, {
      maxWidth: 1000
    }).openPopup();

    this.map.setView([lat, lon], 18.5);

    if (this.activeMarker) {
      this.map.removeLayer(this.activeMarker)
    }

    this.activeMarker = L.marker([lat, lon], {
      icon: this.mediumMapIcon
    })

    this.activeMarker.bindPopup(this.loaderHtml, {
      maxWidth: 1000
    }).openPopup();
    this.map.setView([lat, lon], 18.5);
  }

  selectButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.confirmPropertyDetail = buttonNumber;
  }

  selectBoundaryButton(buttonNumber: number) {
    this.selectedBoundaryButton = buttonNumber;
    this.confirmPropertyBoundary = buttonNumber;
  }

  initSearch() {
    let searchBox = L.control({ position: 'topleft' });
    searchBox.onAdd = function (map) {
      var div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
      div.style.width = '300px';
      div.style.backgroundColor = '#fff';
      div.style.borderRadius = '5px';
      div.innerHTML = '<input type="text" id="addressSearch" placeholder="Search Address" style="width: 100%; border: none; padding: 10px; box-sizing: border-box;" />';
      var dropdown = document.createElement('select');
      dropdown.id = 'suggestions';
      dropdown.style.width = '100%';
      dropdown.style.position = 'absolute';
      dropdown.style.top = '100%';
      dropdown.style.display = 'none';
      dropdown.style.boxSizing = 'border-box';
      dropdown.style.border = 'none';
      div.appendChild(dropdown);
      div.firstChild.onmousedown = div.firstChild.ondblclick = L.DomEvent.stopPropagation;
      div.firstChild.addEventListener('click', function (event) {
        event.stopPropagation();
        map.dragging.enable();
      });
      dropdown.addEventListener('click', function (event) {
        event.stopPropagation();
        map.dragging.disable();
      });
      return div;
    };
    searchBox.addTo(this.map);
    this.searchAmount = 0;
    document.getElementById('addressSearch').addEventListener('keydown', async (e) => {
      if (this.searchAmount <= 3) {
        if (e.keyCode !== 13) {
          return;
        }
        this.searchAmount += 1;
        const searchBox = (e.target as HTMLInputElement).value;
        if (searchBox == '') {
          return;
        }
        let result: any;
        this.propertySearchResults = [];
        this.mapsService.apiV1MapsGetAddressSearchGet({ SearchQuery: searchBox }).subscribe({
          next: (res: any) => {
            result = JSON.parse(res);
            if (result.data && result.data.length > 0) {
              this.propertySearchResults = result.data;
              let dropdown = document.getElementById('suggestions');
              dropdown.innerHTML = '';
              /*create a placeholder so you can select the first item in dropdown list. */
              let defaultOption = document.createElement('option');
              defaultOption.text = "Please select an option";
              dropdown.appendChild(defaultOption);
              this.propertySearchResults.forEach((item, index) => {
                let option = document.createElement('option');
                option.value = index.toString();
                option.text = item.candidate;
                dropdown.appendChild(option);
              });
              if (this.propertySearchResults.length > 1) {
                dropdown.style.display = 'block';
              } else {
                dropdown.style.display = 'none';
              }
              if (this.propertySearchResults.length === 1) {
                const selectedOption = this.propertySearchResults[0];
                this.getAddressFromLatLon(selectedOption.position.lat, selectedOption.position.lon);
                if (selectedOption.attributes.address_id) {
                  this.getAddressDetails(selectedOption.attributes.address_id);
                }
                this.setMainMapView(selectedOption.position.lat, selectedOption.position.lon);
                this.getPropertyArea(selectedOption.position.lat, selectedOption.position.lon, this.propertyType);
              }
            }
          },
          error(error: any) {
            this.notificationService.showErrorMessage('Error', 'Error searching address.');
          }
        });
      }
      else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: 'Maximum allowed attempts have been made',
          confirmButtonText: 'OK'
        });
      }
    });

    document.getElementById('suggestions').addEventListener('change', (e) => {
      const selectedIndex = parseInt((e.target as HTMLSelectElement).value);
      const selectedOption = this.propertySearchResults[selectedIndex];
      this.getAddressFromLatLon(selectedOption.position.lat, selectedOption.position.lon);
      if (selectedOption.attributes.address_id) {
        this.getAddressDetails(selectedOption.attributes.address_id);
      }
      this.setMainMapView(selectedOption.position.lat, selectedOption.position.lon);
      this.getPropertyArea(selectedOption.position.lat, selectedOption.position.lon, this.propertyType);
    });
  };

  getPropertyArea(lat: string, lon: string, type: string) {
    const latLon = `${lat},${lon}`;
    // this.mapsService.apiV1MapsPropertyAreaGet({ Type: type, LatLon: latLon }).subscribe({
    //   next: (response: any) => {
    //     const parsedResponse = JSON.parse(response);
    //     const coordinates = parsedResponse.data.geometry.coordinates[0];
    //     const latLngs = coordinates.map(coordinate => L.latLng(coordinate[1], coordinate[0]));
    //     const polygon = L.polygon(latLngs).addTo(this.map);
    //   }
    // })
  }

  loadRequiredCadastre() {
    this._sellMyPropertyService.apiV1SellMyPropertyLoadRequiredCadastreGet()
      .pipe(
        catchError((err) => {
          return of(
            this.notificationService.showErrorMessage(
              'Error',
              'Error loading File Types'
            )
          );
        }),
        finalize(() => {

        })
      )
      .subscribe((res) => {
        if (res != null) {
          this.cadastre = res as UploadFileDto
        }
      });
  }

  initMap() {
    let mapElement = document.getElementById('siodMap') as Element;
    mapElement = document.getElementById('pfsMap') as Element;
    if (mapElement) {
      mapElement.remove();
    }
    setTimeout(() => {
      if (!this.map) {
        this.map = L.map('sypMap', {
          scrollWheelZoom: false,
          zoom: 6,
          zoomControl: false,
        }).setView([-30.5595, 22.9375]);
        this.map.doubleClickZoom.disable();
        L.tileLayer('http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}', {
          maxZoom: 50,
          minZoom: 1,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(this.map);

        this.initSearch();

        this.map.on('click', function (e) {
          this.setSideMapView(e.latLng.lat, e.latLng.lng)
          let latStr = e.latLng.lat.toString()
          let lonStr = e.latLng.lng.toString()
          if (latStr.length > 9) {
            latStr = latStr.substring(0, 9)
          }
          if (lonStr.length > 9) {
            lonStr = lonStr.substring(0, 9)
          }
          this.getAddressFromLatLon(latStr, lonStr);
        }.bind(this))
      }
    }, 50);
  }

  setWizardData() {
    this._wizardDataService.setUrbanPlannerInfoEnrollmentData(this.form);
  }

  submitForm() {
    if (this.form.urbanPlanner.companyDetail.experienceInMarketResearch &&
      (this.form.urbanPlanner.companyDetail.developmentSpeciality == null ||
        this.form.urbanPlanner.companyDetail.developmentSpeciality.length == 0)) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a Development Speciality is selected').then(result => {
      });
      return;
    }
    if (this.form.urbanPlanner.companyDetail.landDevelopmentType == null || this.form.urbanPlanner.companyDetail.landDevelopmentType.length == 0) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a land Development type is selected').then(result => {
      });
      return;
    }
    if (this.form.urbanPlanner.companyDetail.municipalCouncil == null || this.form.urbanPlanner.companyDetail.municipalCouncil.length == 0) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a Development Speciality is selected').then(result => {
      });
      return;
    }
    this.getRegistrationType(this.form.urbanPlanner.registrationType);
    this.form.urbanPlanner.graduationYear = this.validatedForm.get('urbanPlanner').get('graduationYear').value;
    this.setWizardData();
    this.pageNumberEvent.emit(2);
    this.cadastreEvent.emit(this.cadastre);
  }
}

export interface PlannerPropertyDetailsDialogData {
  schemeData: any[]
}
