import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaAvailableDto } from 'src/app/services/property-matrixV2/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';

@Component({
  selector: 'app-area-available',
  templateUrl: './area-available.component.html',
  styleUrls: ['./area-available.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AreaAvailableComponent implements OnInit {

  @Input() areaAvailableData: AreaAvailableDto;
  @Output() potentialUsableSpaceChange = new EventEmitter<number>();
  @Output() potentialUsableSpaceInHectaresChange = new EventEmitter<number>();
  @Output() potentialUsableSpaceInSquareMetersChange = new EventEmitter<number>();

  metricUnits: KeyValuePair[] = [
    { key: 'm²', value: 'Square Meters (m²)' },
    { key: 'ha', value: 'Hectares (ha)' },
  ];

  previousMetricUnit: string;

  constructor() { }

  ngOnInit(): void {
    if (this.areaAvailableData.metricUnit) {
      this.previousMetricUnit = this.areaAvailableData.metricUnit;
    } else {
      this.areaAvailableData.metricUnit = 'ha';
      this.previousMetricUnit = 'ha';
    }
    this.calculatePotentialUsableSpace();
  }

  onMetricUnitChange(newUnit: string): void {
    this.areaAvailableData.propertySize = this.convertArea(this.areaAvailableData.propertySize, this.previousMetricUnit, newUnit);
    this.areaAvailableData.unusableSpace = this.convertArea(this.areaAvailableData.unusableSpace, this.previousMetricUnit, newUnit);

    this.previousMetricUnit = newUnit;
    this.areaAvailableData.metricUnit = newUnit;

    this.calculatePotentialUsableSpace();
  }

  onPropertySizeChange(): void {
    this.calculatePotentialUsableSpace();
  }

  onUnusableSpaceChange(): void {
    this.calculatePotentialUsableSpace();
  }

  calculatePotentialUsableSpace(): void {
    const potentialUsableSpace = this.areaAvailableData.propertySize - this.areaAvailableData.unusableSpace;
    this.areaAvailableData.potentialUsableSpace = this.roundToFixed(potentialUsableSpace, 2);
    this.potentialUsableSpaceChange.emit(this.areaAvailableData.potentialUsableSpace);

    const potentialUsableSpaceInHectares = this.convertArea(this.areaAvailableData.potentialUsableSpace, this.areaAvailableData.metricUnit, 'ha');
    this.potentialUsableSpaceInHectaresChange.emit(this.roundToFixed(potentialUsableSpaceInHectares, 2));

    const potentialUsableSpaceInSquareMeters = this.convertArea(this.areaAvailableData.potentialUsableSpace, this.areaAvailableData.metricUnit, 'm²');
    this.potentialUsableSpaceInSquareMetersChange.emit(this.roundToFixed(potentialUsableSpaceInSquareMeters, 2));
  }

  roundToFixed(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }

  convertArea(area: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === 'ha' && toUnit === 'm²') {
      return area * 10000;
    } else if (fromUnit === 'm²' && toUnit === 'ha') {
      return area / 10000;
    } else {
      return area;
    }
  }
}
