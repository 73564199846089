<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main style="background-color: white" id="main">
    <div class="content content-padding">
        <div class="container">
            <!--Form section block-->
            <section>
                <div class="row mt-3 no-gutters">
                    <div class="col-3 px-1" *ngFor="let header of formHeaders">
                        <button class="form-section form-section-button active-form-section"
                            [ngClass]="{ 'active-form-section': pageNumber >= header.key }"
                            [disabled]="pageNumber <= header.key || pageNumber == 4"
                            (click)="selectPage(header.key)">
                            <div class="heading-3-light">{{header.value}}</div>
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <div [hidden]="pageNumber != 1">
                    <app-conveyancer-personal-information (pageNumberEvent)="changePage($event)" (ConveyancerPersonalInformationEvent)="assignConveyancerPersonalInformation($event)">
                    </app-conveyancer-personal-information>
                </div>
                <div [hidden]="pageNumber != 2">
                    <app-conveyancer-firm-information (pageNumberEvent)="changePage($event)" (ConveyancerFirmInformationEvent)="assignConveyancerInformation($event)">
                    </app-conveyancer-firm-information>
                </div>
                <div [hidden]="pageNumber != 3">
                    <app-conveyancer-submit-documents (pageNumberEvent)="changePage($event)" (uploadFilesEvent)="assignFiles($event)">
                    </app-conveyancer-submit-documents>
                </div>
                <div [hidden]="pageNumber != 4">
                    <app-conveyancer-sign-contract></app-conveyancer-sign-contract>
                </div>
            </section>
        </div>
    </div>
</main>