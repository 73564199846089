import { RiskscapePropertyInfoDto } from 'src/app/services/property-matrixV2/models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AddressDetailComponent implements OnInit {

  @Input() riskscapePropertyInfoData: RiskscapePropertyInfoDto;

  constructor() { }

  ngOnInit(): void { }
}
