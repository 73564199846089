<main class="main-container">
    <section class="content">
        <div class="content-padding">
            <div class="container mt-4">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading-1 text-center">
                            Registration: Brokers & Estate Agents
                        </h1>
                    </div>
                </div>
                <div class="text-center">
                    <p class="paragraph  mt-2">
                        Congratulations on the smart decision to join the <b>Property Matrix</b> network.
                    </p>
                    <div class="heading-5 mt-5">
                        Registration can be done through 4 easy steps:
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col heading-5 bold">
                                Provide personal information
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col heading-5 bold">
                                Provide work-related information
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col heading-5 bold">
                                Watch instruction video
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col heading-5 bold">
                                Upload documents & signed contract
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paragraph m-5">
                    <p>
                        Once vetted and approved, you will receive an email with links to our training courses.
                    </p>
                    <b>
                        For STEP 4, please have the following ready to submit in PDF format:
                    </b>
                    <ul class="montserrat-bullets paragraph align-height paragraph">
                        <li>Copy of your identification document</li>
                        <li>Up to date EAAB Fidelity Fund Certificate</li>
                        <li>A photo of yourself to be used by Property Matrix for marketing purposes</li>
                    </ul>
                    <b>
                        Where applicable:
                    </b>
                    <ul class="montserrat-bullets paragraph align-height paragraph">
                        <li>Company VAT certificate</li>
                        <li>
                            Certified copy(ies), of professional qualifications and/or designations awarded to you
                        </li>
                    </ul>
                    <p>
                        <b>Please note:</b> You may provide a photo where the name of the franchise you are
                        affiliated with is visible - <b>Property Matrix</b> is inclusive in this regard.
                    </p>
                </div>
                <div class="button-container">
                    <button mat-button (click)="proceed()" class="button-proceed heading-4">PROCEED</button>
                </div>
            </div>
        </div>
    </section>
</main>