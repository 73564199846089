import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MultiplePropertyListingDto, PropertyListingDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-reference-and-group',
  templateUrl: './reference-and-group.component.html',
  styleUrls: ['./reference-and-group.component.scss']
})
export class ReferenceAndGroupComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto[];
  @Input() isMultiple: boolean;
  @Input() createdDate: string;

  amountOfProperties: number;
  isListingOrdered: boolean;
  isAdditionalServiceOrdered: boolean;
  metroName: string;
  reportNumber: number;
  referenceNumber: string;

  sections = [
    { key: 1, value: 'Generate Reference Number', checked: true },
    { key: 2, value: 'Property Grouping Instructions', checked: true },
    { key: 3, value: 'Number Properties', checked: false }
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.amountOfProperties = 6;
    this.isListingOrdered = true;
    this.isAdditionalServiceOrdered = false;
    this.metroName = 'CT';
    this.reportNumber = 1;
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  generateReferenceNumber(): string {
    let serviceOrdered = '';
    if (this.isListingOrdered) {
      serviceOrdered = 'LIS';
    } else if (this.isAdditionalServiceOrdered) {
      serviceOrdered = 'ADD';
    }

    const multiplePrefix = this.isMultiple ? `M${this.amountOfProperties}/` : '';
    const yearMonth = this.createdDate.substring(2, 4) + this.createdDate.substring(5, 7);
    const reportNumberPadded = this.reportNumber.toString().padStart(3, '0');

    const referenceNumber = `${multiplePrefix}${serviceOrdered}/${this.metroName}/${yearMonth}/${reportNumberPadded}`;

    this.reportNumber += 1;

    // TODO: Add logic to save the generated report number to the DB.

    return referenceNumber;
  }

  navigateToPlanningReport() {
    this.router.navigate(['admin/listings/listing-overview']);
  }

  showNumberPropertiesSection(): void {
    const numberPropertiesSection = this.sections.find(section => section.key === 3);
    if (numberPropertiesSection) {
      numberPropertiesSection.checked = !numberPropertiesSection.checked;
    }
  }
}
