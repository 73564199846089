<div class="container-fluid main-container-height background-img">
    <div class="container-fluid container-style">
        <div class="content-border">
            <div class="row">
                <div class="col"></div>
                <div class="col-6">
                    <div class="heading-1 text-center collaboration-heading">
                        COLLABORATION AGREEMENT
                    </div>
                </div>
                <div class="col"></div>
            </div>
            <div class="inner-content paragraph mt-3">
                <div class="row">
                    <div class="col-12">
                        <strong>Thank you for your interest. It is essential, however, that you have a good idea of what you are
                        signing up for before
                        you jump into our registration process. Hence the following summary of the Property Matrix model:</strong>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <ul class="montserrat-bullets">
                            <li>
                                Once you are approved as a <strong>Property Matrix</strong> 'associate planner' we will refer potential
                                clients in your area/metro to you.
                            </li>
                            <li>
                                Our in-house planners will prepare a baseline report with typical planning data such as
                                surveyor general diagrams for boundaries
                                and servitudes, zoning, strategic plans, etc. (70-80% completion).
                            </li>
                            <li>
                                As registered planner you are expected to confirm the existing land use rights and offer an
                                opinion on the potential land use
                                rights in terms of the municipal policy.
                            </li>
                            <li>
                                If the client continues with any land use management (LUMS) work, the client will be
                                referred to you, whereupon you may act
                                as an independent consultant in your own firm and not in the capacity of an associate of
                                <b>Property Matrix</b>
                            </li>
                            <li>
                                We will provide a guideline in terms of professional planning fees on our website and these
                                figures might be higher than what
                                is charged by unregistered planners in the open market. We believe that the incorrect
                                tariffs charged in the market could be
                                corrected and that planners should not discount more than 30% to our advertised tariff
                                guidelines. Should you do this, we would
                                like to have a discussion with you regarding this practice in your area/metro
                            </li>
                            <li>
                                The investigation report is seen as a 'quote' and will therefore mainly be completed on risk
                                in return for the potential statutory
                                work. We do realise that an appointment to do the work does not always materialise.
                                <b>Property Matrix</b> therefore agrees to pay
                                R10 000 plus VAT for every planning report that assisted us in facilitating a property
                                transaction
                            </li>
                            <li>
                                In cases where clients are not interested in a property transaction, they will have the
                                opportunity to pay R4 000 for this service.
                                <b>Property Matrix</b> agrees to pay you the amount of R2 000 for every paid planning report
                                you contributed to.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col paragraph paragraph-spacing row-width">
                        A 20-minute training video or online training session will be scheduled after registration to
                        provide more details on the above
                        summary and our workflow procedures
                    </div>
                </div>
                <div class="row">
                    <div class="col  paragraph bold registration-process">
                        If you would like to continue to the registration process, click <a class="privacy-policy-link" routerLink="/planner-registration">here</a>.
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>