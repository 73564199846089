import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';

@Component({
  selector: 'app-planner-registration',
  templateUrl: './planner-registration.component.html',
  styleUrls: ['./planner-registration.component.scss']
})
export class PlannerRegistrationComponent implements OnInit {
  registrationSub: Subscription;

  constructor(private router: Router, private _registrationService: RegistrationService) { }

  ngOnInit(): void {
  }

  Proceed(){
    this.router.navigateByUrl('/planner-register-form');  
  }
}
