import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ComingSoonDialogComponent } from 'src/app/shared/components/coming-soon-dialog/coming-soon-dialog.component';

@Component({
  selector: 'app-share-in-our-database',
  templateUrl: './share-in-our-database.component.html',
  styleUrls: ['./share-in-our-database.component.scss']
})
export class ShareInOurDatabaseComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  page = 1;

  constructor(private route: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openNav() {
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }

  continueToForm() {
    this.route.navigateByUrl('/share-in-our-database/capture');
  }
}
