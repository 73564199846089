<div class="table-popup-color">
    <section *ngIf="data.showDelete == true">
        <main class="dialog-container">
            <div class="dialog-content">
                <div>
                    <span class="paragraph bold user-dialog-heading">Remove Juristic Person</span>
                    <div class="row-container pt-1 pb-1">
                        <span class="paragraph user-dialog-subheading">       
                            Are you sure you want to delete this Juristic Person?
                        </span>
                    </div>
                </div>
            </div>
            <div class="button-group">
                <button class="footer-button background-cloud" (click)="onNoClick()">Cancel</button>
                <button class="footer-button background-coral" (click)="deleteJuristicPerson()">Confirm</button>
            </div>
        </main>
    </section>
    <section *ngIf="data.showDelete == false" class="juristic-person-details">
        <form id="juristicOwnershipForm" novalidate
            #juristicOwnershipForm="ngForm">
            <section class="submission-form">
                <div class="heading-2">
                    <div class="row">
                        <div class="col-12 pb-3 text-center">
                            ADD JURISTIC PERSON (ENTITY)
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblEntityName" for="txtEntityName"
                            class="paragraph">
                            Full name of juristic person (entity):
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" placeholder="Mandatory"
                            id="txtEntityName" class="paragraph"
                            name="juristicPersonName"
                            [(ngModel)]="data.juristicPerson.fullName" required
                            #juristicPersonEntityName="ngModel"
                            [ngClass]="{'invalid-input': juristicPersonEntityName.invalid, 'valid-input': juristicPersonEntityName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonPersonalSurname"
                            for="txtJuristicPersonPersonalSurname"
                            class="paragraph">
                            Registration number of the entity:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" placeholder="Mandatory"
                            id="txtJuristicPersonPersonalSurname"
                            class="paragraph" name="juristicPersonSurname"
                            [(ngModel)]="data.juristicPerson.registrationNumberOfEntity"
                            required
                            #juristicPersonSurname="ngModel"
                            [ngClass]="{'invalid-input': juristicPersonSurname.invalid, 'valid-input': juristicPersonSurname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonWorkPhone"
                            for="txtJuristicPersonWorkPhone"
                            class="paragraph">
                            If applicable, VAT number of the entity:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonVat"
                            name="JuristicPersonVat"
                            class="paragraph not-required"
                            [(ngModel)]="data.juristicPerson.vatNumber"
                            #JuristicPersonVat="ngModel"
                            [ngClass]="{'invalid-input': JuristicPersonVat.invalid, 'valid-input': JuristicPersonVat.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="heading-5">
                            <span class="burnt-red">|</span>
                            Are you the legal representative of this entity?
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-radio-group
                            name="radioJuristicPersonLegalRepresentative"
                            #radioJuristicPersonLegalRepresentative="ngModel"
                            [(ngModel)]="data.juristicPerson.areYouLegalRepresentative">
                            <mat-radio-button class="paragraph"
                                *ngFor="let selectionValue of legalRepresentativeRadio"
                                (change)="changeLegalRepresentativeYesNo(selectionValue.key)"
                                [value]="selectionValue.key">
                                {{selectionValue.value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </section>
            <section *ngIf="data.showDelete == false">
                <div class="heading-5">
                    <span>
                        <span>
                            Details of the
                        </span>
                        <span class="coral">
                            legal representative
                        </span>
                        <span>
                            for contract purposes:
                        </span>
                    </span>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonTitle"
                            for="txtJuristicPersonTitle"
                            class="paragraph">
                            Title:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonTitle"
                            name="juristicPersonTitle"
                            #juristicPersonTitle="ngModel" class="paragraph"
                            [(ngModel)]="data.juristicPerson.legalRepresentative.title"
                            required
                            [ngClass]="{'invalid-input': juristicPersonTitle.invalid, 'valid-input': juristicPersonTitle.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonFullName"
                            for="txtJuristicPersonFullName"
                            class="paragraph">
                            Full names:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonFullName"
                            name="juristicPersonFullName"
                            #juristicPersonFullName="ngModel" class="paragraph "
                            [(ngModel)]="data.juristicPerson.legalRepresentative.fullNames"
                            required
                            [ngClass]="{'invalid-input': juristicPersonFullName.invalid, 'valid-input': juristicPersonFullName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonSurname"
                            for="txtJuristicPersonSurname"
                            class="paragraph">
                            Surname:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text"
                            id="txtJuristicPersonLegalRepresentativeSurname"
                            name="juristicPersonLegalRepresentativeSurname"
                            class="paragraph"
                            [(ngModel)]="data.juristicPerson.legalRepresentative.surname"
                            #juristicPersonLegalRepresentativeSurname="ngModel"
                            required
                            [ngClass]="{'invalid-input': juristicPersonLegalRepresentativeSurname.invalid,
                            'valid-input': juristicPersonLegalRepresentativeSurname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonWorkPhone"
                            for="txtJuristicPersonWorkPhone"
                            class="paragraph">
                            Work phone:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonWorkPhone"
                            name="juristicPersonWorkPhone"
                            class="paragraph"
                            [(ngModel)]="data.juristicPerson.legalRepresentative.workPhoneNumber"
                            #juristicPersonWorkPhone="ngModel" required
                            [ngClass]="{'invalid-input': juristicPersonWorkPhone.invalid, 'valid-input': juristicPersonWorkPhone.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(juristicPersonWorkPhone.errors &&
                            (juristicPersonWorkPhone.dirty || juristicPersonWorkPhone.touched)) &&
                            juristicPersonWorkPhone.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonMobile"
                            for="txtJuristicPersonMobile"
                            class="paragraph">
                            Mobile:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonMobile"
                            name="juristicPersonMobile"
                            #juristicPersonMobile="ngModel"
                            [ngClass]="{'invalid-input': juristicPersonMobile.invalid, 'valid-input': juristicPersonMobile.valid}"
                            class="paragraph" required
                            [(ngModel)]="data.juristicPerson.legalRepresentative.mobilePhoneNumber">
                        <small class="form-control-feedback"
                            *ngIf="(juristicPersonMobile.errors && (juristicPersonMobile.dirty || juristicPersonMobile.touched)) && juristicPersonMobile.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonEmail"
                            for="txtJuristicPersonEmail"
                            class="paragraph">
                            Email:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonEmail"
                            name="juristicPersonEmail"
                            #juristicPersonEmail="ngModel"
                            [ngClass]="{'invalid-input': juristicPersonEmail.invalid, 'valid-input': juristicPersonEmail.valid}"
                            class="paragraph" required
                            [(ngModel)]="data.juristicPerson.legalRepresentative.emailAddress">
                        <small class="form-control-feedback"
                            *ngIf="juristicPersonEmail.errors && (juristicPersonEmail.dirty || juristicPersonEmail.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonConfirmEmail"
                            for="txtJuristicPersonConfirmEmail"
                            class="paragraph">
                            Confirm email:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonConfirmEmail"
                            name="JuristicPersonSpouseEmailAddress"
                            [(ngModel)]="data.juristicPerson.legalRepresentative.confirmEmail"
                            class="paragraph"
                            #JuristicPersonSpouseEmailAddress="ngModel" required
                            [ngClass]="{'invalid-input': JuristicPersonSpouseEmailAddress.invalid,
                        'valid-input': JuristicPersonSpouseEmailAddress.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label id="lblJuristicPersonIdentityNumber"
                            for="txtJuristicPersonIdentityNumber"
                            class="paragraph">
                            Identity Number:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtJuristicPersonIdentityNumber"
                            name="juristicPersonIdentityNumber"
                            #juristicPersonIdentityNumber="ngModel"
                            class="paragraph" required
                            [(ngModel)]="data.juristicPerson.legalRepresentative.identityNumber"
                            [ngClass]="{'invalid-input': juristicPersonIdentityNumber.invalid, 'valid-input': juristicPersonIdentityNumber.valid}">
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <div class="row" *ngIf="!data.juristicPerson.areYouLegalRepresentative">
                    <div class="heading-5">
                        <u>Please confirm</u>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="checkbox-container">
                                <span class="paragraph">
                                    I have consent from this owner to fill in
                                    this form and to provide Property Matrix
                                    with information on their behalf. I can
                                    confirm that this owner agrees and give
                                    permission to sell this property
                                </span>
                                <input type="checkbox"
                                    [(ngModel)]="data.juristicPerson.legalRepresentative.confirmedConsent"
                                    name="juristicPersonConfirmedConsent"
                                    #juristicPersonConfirmedConsent="ngModel"
                                    id="cbjuristicPersonConfirmPermission"
                                    data-toggle="toggle"
                                    (click)="scroll('btnSaveCoOwner')" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row text-center mt-3 mb-3">
                    <div class="col-12">
                        <button id="btnSavejuristicPerson" mat-button
                            class="button heading-4 mt-2"
                            [disabled]="!juristicOwnershipForm.valid ||
                            (!data.juristicPerson.areYouLegalRepresentative && !data.juristicPerson.legalRepresentative.confirmedConsent)"
                            [mat-dialog-close]="data"
                            (click)="saveJuristicPerson(juristicOwnershipForm.valid)">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </section>
        </form>
    </section>
</div>