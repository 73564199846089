<main style="background-color: white">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <form id="registerForm" novalidate #registerForm="ngForm" class="submission-form">
                        <div class="row text-center">
                            <div class="col-12">
                                <h1 class="heading-1">
                                    REGISTER
                                </h1>
                            </div>
                            <div class="col-12 mt-4 paragraph">
                                <p>Several property valuers were identified and have received emails with log in details. If you were not contacted but
                                    you are interested in joining the <strong>Property Matrix</strong> valuer network in the future (once we expand), please feel free
                                    to provide your details below and to upload the required documents and we will keep in touch.</p>
                            </div>
                        </div>
                        <div class="heading-4">Personal details</div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph"
                                    name="name" [(ngModel)]="form.personalDetails.name" required #name="ngModel"
                                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalSurname" for="txtPersonalSurname"
                                    class="paragraph">Surname:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph"
                                    name="surname" [(ngModel)]="form.personalDetails.surname" required
                                    #surname="ngModel"
                                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblLandline" for="txtLandline" class="paragraph">Landline:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" id="txtLandline" name="landline" class="paragraph not-required"
                                    [(ngModel)]="form.personalDetails.landline">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                                    class="paragraph" [(ngModel)]="form.personalDetails.phoneNumber" required
                                    #phoneNumber="ngModel" minlength="10"
                                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                                    *Mobile number must contain at least the 10 numbers
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalConfirmPhoneNumber" for="txtPersonalConfirmPhoneNumber"
                                    class="paragraph">Confirm
                                    Mobile:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmPhoneNumber"
                                    name="confirmPhoneNumber" class="paragraph" required #confirmPhoneNumber="ngModel"
                                    [(ngModel)]="form.personalDetails.confirmPhoneNumber"
                                    [ngClass]="{'invalid-input': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                    'valid-input': (phoneNumber.value == confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                    'confirm-valid': phoneNumber.value == confirmPhoneNumber.value}">
                                    *Confirm Mobile does not match Mobile Number
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress"
                                    class="paragraph">Email
                                    Address:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress"
                                    name="emailAddress" class="paragraph"
                                    [(ngModel)]="form.personalDetails.emailAddress" required #emailAddress="ngModel"
                                    [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                    'valid-input': emailAddress.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                                    *Must be a valid email address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail"
                                    class="paragraph">Confirm
                                    Email:</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail"
                                    name="confirmEmail" class="paragraph" required #confirmEmail="ngModel"
                                    [pattern]="emailPattern" [(ngModel)]="form.personalDetails.confirmEmail"
                                    [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                    'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                    'confirm-valid': emailAddress.value == confirmEmail.value}">
                                    *Confirm Email does not match Email Address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                                    Resident of RSA: ID nr
                                    <br>
                                    Non-resident: Passport nr.
                                </label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                                    class="paragraph" [(ngModel)]="form.personalDetails.id_Number" required
                                    #id_Number="ngModel" minlength="9"
                                    [ngClass]="{'invalid-input': id_Number.invalid, 'valid-input': id_Number.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                                    *Must must contain at least 9 characters
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblUsername" for="txtUsername" class="paragraph">Username(Email
                                    Address):</label>
                            </div>
                            <div class="col-7">
                                <input type="text" placeholder="Mandatory" id="txtUsername" name="username"
                                    class="paragraph" [(ngModel)]="form.loginDetails.email" required #username="ngModel"
                                    [pattern]="emailPattern"
                                    [ngClass]="{'invalid-input': username.invalid, 'valid-input': username.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="username.errors && (username.dirty || username.touched)">
                                    *Must be a valid email address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPassword" for="txtPassword" class="paragraph">Password:</label>
                            </div>
                            <div class="col-7">
                                <input type="password" placeholder="Mandatory" id="txtPassword" name="password"
                                    class="paragraph" [(ngModel)]="form.loginDetails.password" required
                                    #password="ngModel" [pattern]="passwordPattern"
                                    [ngClass]="{'invalid-input': password.invalid, 'valid-input': password.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="password.errors && (password.dirty || password.touched)">
                                    * Must contain minimum eight characters, at least one uppercase letter, one
                                    lowercase letter, one
                                    number and one special character
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPasswordConfirm" for="txtPasswordConfirm" class="paragraph">Confirm
                                    Password:</label>
                            </div>
                            <div class="col-7">
                                <input type="password" placeholder="Mandatory" id="txtPasswordConfirm"
                                    name="passwordConfirm" class="paragraph"
                                    [(ngModel)]="form.loginDetails.passwordConfirm" required #passwordConfirm="ngModel"
                                    [ngClass]="{'invalid-input': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                    'valid-input': (password.value == passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                                    'confirm-valid': password.value == passwordConfirm.value}">
                                    *Confirm Password does not match Password
                                </small>
                            </div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col-12">
                                <button id="btnRegister" (click)="register()" [disabled]="registerForm.invalid"
                                    mat-button class="button heading-4 mt-2">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</main>