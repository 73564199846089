<mat-card>
    <div class="phase-header-container">
        <div class="d-flex justify-content-between align-items-center pb-2">
            <span class="paragraph phase-number">
                Phase {{ phase | numberToWords }}
            </span>
            <span class="paragraph date-received">
                Received: {{ receivedDate ?? 'No date available' }}
            </span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="paragraph phase-description">
                Draft Planning Report is for the purpose of a value assessment.
            </span>
            <button class="paragraph center-content due-date-button">
                <span class="due-date-label">Select Due Date</span>
                <mat-icon class="down-icon">expand_more</mat-icon>
            </button>
        </div>
    </div>
    <div class="phase-table-container">
        <mat-table [dataSource]="dataSource" class="task-table" matSort>
            <mat-header-row class="paragraph" *matHeaderRowDef="displayedColumns">
            </mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)">
            </mat-row>
            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                <mat-cell *matCellDef="let task">
                    {{ task.number }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="task">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Task</mat-header-cell>
                <mat-cell *matCellDef="let task">
                    {{ task.task }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Professional</mat-header-cell>
                <mat-cell *matCellDef="let task">
                    <div class="user-container">
                        <div class="icon-container">
                            <mat-icon class="user-icon" [ngClass]="{'has-profile-pic': task.user.profilePic}">
                                person
                            </mat-icon>
                            <div class="role-icon" [ngStyle]="{'background-color': task.roleColor}">
                                {{ task.roleInitial }}
                            </div>
                        </div>
                        {{ task.user }}
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="notes">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Notes</mat-header-cell>
                <mat-cell *matCellDef="let task">
                    {{ task.notes }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let task">
                    <span class="status-label"
                        [ngClass]="{
                            'status-done': task.status === 'Done',
                            'status-in-progress': task.status === 'In Progress',
                            'status-awaiting': task.status === 'Awaiting'
                        }">
                        {{ task.status }}
                    </span>
                </mat-cell>
            </ng-container>
        </mat-table>
    </div>
</mat-card>