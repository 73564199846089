import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { EstateAgentCompanyInformationDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-estate-agent-company-information',
  templateUrl: './estate-agent-company-information.component.html',
  styleUrls: ['./estate-agent-company-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class EstateAgentCompanyInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();

  companyInfoForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    const currentCompanyInfo = this._estateAgentRegistrationStateService.getFormState().companyInformation || {};

    this.companyInfoForm = this._formBuilder.group({
      fidelityFundCertificateNumber: new FormControl(null),
      individualReferenceNumber: new FormControl(null),
      isPrincipal: new FormControl(currentCompanyInfo.isPrincipal || false),
      isAffiliatedWithAgency: new FormControl(currentCompanyInfo.isAffiliatedWithAgency || false),
      agencyName: new FormControl(null),
      agencyBranch: new FormControl(null),
      agencyPinNumber: new FormControl(null),
      unitNumber: new FormControl(null),
      complexName: new FormControl(null),
      streetNumber: new FormControl(null),
      streetName: new FormControl(null),
      suburb: new FormControl(null),
      city: new FormControl(null),
      accountHolderName: new FormControl(null),
      bankName: new FormControl(null),
      branchName: new FormControl(null),
      branchNumber: new FormControl(null),
      accountType: new FormControl(null),
      accountNumber: new FormControl(null),
      vatNumber: new FormControl(null),
      principalName: new FormControl(null),
      principalSurname: new FormControl(null)
    });
  }

  get isPrincipal() {
    return this.companyInfoForm.get('isPrincipal').value;
  }

  get isAffiliatedWithAgency() {
    return this.companyInfoForm.get('isAffiliatedWithAgency').value;
  }

  onSubmit() {
    const formValue: EstateAgentCompanyInformationDto = { ...this.companyInfoForm.value };
    this._estateAgentRegistrationStateService.updateCompanyInformation(formValue);
    this.pageNumberEvent.emit(3);
  }
}
