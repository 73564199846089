import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { WizardDataService } from 'src/app/services/property-matrixV2/custom-services/wizard-data.service';
import { UrbanPlannerService } from 'src/app/services/property-matrixV2/services/urban-planner.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UploadDocumentsFileReturnVm } from 'src/app/services/property-matrix/models';
import { UploadFileDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription, catchError, finalize, of } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FileUploader } from 'src/app/models/file-uploader';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-planner-register-form-documents',
  templateUrl: './planner-register-form-documents.component.html',
  styleUrls: ['./planner-register-form-documents.component.scss']
})
export class PlannerRegisterFormDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() uploadFilesEvent = new EventEmitter<Array<UploadDocumentsFileReturnVm>>();
  @Output() uploadDocumentDetailsEvent = new EventEmitter<UploadFileDto[]>();

  fileUploader = new FileUploader();
  loading: boolean = false;

  files: UploadFileDto[] = [];
  cadastre: UploadFileDto;
  registrationSub: Subscription;
  wizardDataSub: Subscription;
  userDto: UserDto = {};
  uploadBlocks: UploadFileDto[] = [];
  uploadBlocksAfterAgree: UploadFileDto[] = [];

  requiredKeys = [1, 2, 3, 4, 5];

  constructor(
    private _registrationService: RegistrationService,
    private _notificationService: NotificationService,
    private _wizardDataService: WizardDataService,
    private _urbanPlannerService: UrbanPlannerService,
    private _httpClient: HttpClient
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.loadRequiredDocs();
  }

  assignCadastre($event: UploadFileDto) {
    this.cadastre = $event
  }

  assignUploadDocumentDetails($event: UploadFileDto[]) {
    this.files = $event;
    this.submitForm();
  }

  receiveData() {
    this.registrationSub = this._registrationService.userData.subscribe(userData => {
      this.userDto = userData;
    });
    this._registrationService.setUserEnrollmentData(this.userDto);
  }

  receiveWizardData() {
    this.wizardDataSub = this._wizardDataService.urbanPlannerInfoData.subscribe(data => {
      this.userDto = data;
    });
    this.wizardDataSub = this._wizardDataService.urbanPlannerExtendedNetwork.subscribe(data => {
      this.userDto.urbanPlanner.professionals = data;
    });
  }

  uploadFiles(urbanPlannerId: string) {
    const formData = new FormData();

    this.files?.forEach(file => {
      formData.append(`${urbanPlannerId}|${file.fileType.id}|${file.fileType.category}`, file.file);
    });

    this._httpClient.post(environment.api + '/api/v1/UrbanPlanner/UploadUrbanPlannerFiles', formData)
      .pipe(
        catchError((error) => {
          return of(
            GlobalMethods.tinyErrorAlert("Error", `Failed to upload `)
          );
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        if (res.value) {
          this.pageNumberEvent.emit(4);
        }
        else {
          this._notificationService.showErrorMessage('Error', 'Failed to upload.')
        }
      });
  }

  submitForm() {
    this.loading = true;
    this.receiveData();
    this.receiveWizardData();
    this._urbanPlannerService.apiV1UrbanPlannerAddUrbanPlannerUserAsyncPost({ body: this.userDto })
      .pipe(
        catchError((error) => {
          return of(
            false
          );
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.uploadFiles(res);
        }
        else {
          this._notificationService.showErrorMessage('Error', 'Failed to upload.')
        }
      });
  }

  selectUpload(files: any[], id: any) {
    this.updateShownBlocks(files[0], id);
    this.updateFiles(id);
  }

  dropUpload($event: any[], id: any) {
    this.updateShownBlocks($event[0], id);
    this.updateFiles(id);
  }

  updateFiles(id: string | number) {
    const index = this.files.findIndex(file => file.fileType.id === id);
    if (index !== -1) {
      this.files[index] = this.uploadBlocks[Number(id) - 1];
    } else {
      this.files.push(this.uploadBlocks[Number(id) - 1]);
    }
  }

  deleteFile(block: any, blockIndex: string | number) {
    this.uploadBlocks[blockIndex].file = null;
  }

  updateShownBlocks(file: Blob, id: number) {
    this.uploadBlocks[id - 1].file = file;
  }

  loadRequiredDocs() {
    this._urbanPlannerService.apiV1UrbanPlannerLoadRequiredDocumentsGet()
      .pipe(
        catchError((error) => {
          return of(
            this._notificationService.showErrorMessage('Error', 'Error loading File Types')
          );
        }),
        finalize(() => { })
      )
      .subscribe((res) => {
        if (res != null) {
          this.uploadBlocks = res[0] as UploadFileDto[];
          this.uploadBlocksAfterAgree = res[1] as UploadFileDto[];
          this.fileUploadOrder();
        }
      });
  }

  fileUploadOrder() {
    let keyMapping = {
      'Copy Of Your ID (RSA resident) or Passport(non-resident)': 1,
      'Up To Date Registration Certificate With SACPLAN': 2,
      'Certified Copy: Town And Regional Planning Degree Certificate': 3,
      'Photo Of Yourself For Office And Admin Purposes': 4,
      'Logo Of Your Company For Marketing Purposes': 5,
      'Certified Copy Of Qualification 1': 6,
      'Certified Copy Of Qualification 2': 7,
      'Certified Copy Of Qualification 3': 8,
    };

    this.uploadBlocks.forEach(uploadFileDto => {
      let key = keyMapping[uploadFileDto.fileType.value];
      if (key !== undefined) {
        uploadFileDto.key = key;
      }
    })

    this.uploadBlocks.sort((a, b) => a.key - b.key);
  }

  isFormValid() {
    return this.uploadBlocks.every(block => !this.requiredKeys.includes(block.key) || block.file !== null);
  }
}
