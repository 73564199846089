<div class="p-3">
  <div class="heading-3">
    Please select your unit:
  </div>
  <div class="scroll-container">
    <div *ngFor="let property of data.schemeData" class="pt-1 ps-2 pe-2 pb-1">
      <button (click)="selectProperty(property)" class="button unit-button">
        Unit {{ property.unit_number | removeLeadingZeros }}
      </button>
    </div>
  </div>
</div>