import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ConveyancerFirmInformationVm } from 'src/app/services/property-matrix/models';

@Component({
  selector: 'app-conveyancer-firm-information',
  templateUrl: './conveyancer-firm-information.component.html',
  styleUrls: ['./conveyancer-firm-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class ConveyancerFirmInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() ConveyancerFirmInformationEvent = new EventEmitter<ConveyancerFirmInformationVm>();

  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern : string = GlobalConstants.passwordPattern;

  form: ConveyancerFirmInformationVm = {
    physicalAddress: {
      city: '',
      suburb: '',
      complexName: '',
      streetName: '',
      streetNumber: '',
      unitNumber: '',
    },
    alternativeEmail: '',
    alternativeTelephoneNumber: '',
    confirmEmail: '',
    email: '',
    name: '',
    registrationNumber: '',
    telephoneNumber: '',
  }

  constructor() { }

  submitForm(){
    this.pageNumberEvent.emit(3);
    this.ConveyancerFirmInformationEvent.emit(this.form);
  }

  ngOnInit(): void {
  }

}
