<main style="background-color: white">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                CONVEYANCER
                            </h1>
                        </div>
                    </div>
                    <div class="heading-5 text-center mt-4">
                        Please select the option that applies to you:
                    </div>
                    <div class="row mt-4">
                        <div class="col-6 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    OPTION
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col numbers bold">
                                    1
                                </div>
                            </div>
                            <div class="row">
                                <div class="col paragraph">
                                    I already received an email from
                                    <strong>Property Matrix</strong> with a passcode
                                    and I am ready to register
                                </div>
                            </div>
                            <div class="row mt-4 text-center">
                                <div class="col">
                                    <button class="button heading-4" [routerLink]="['/login-conveyancer']">PROCEED</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    OPTION
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col numbers bold">
                                    2
                                </div>
                            </div>
                            <div class="row">
                                <div class="col paragraph">
                                    I haven't received an email, but
                                    I am a conveyancer and would
                                    like to offer my services to
                                    <strong>Property Matrix</strong>
                                </div>
                            </div>
                            <div class="row mt-4 text-center">
                                <div class="col">
                                    <button class="button heading-4" [routerLink]="['/conveyancer-register-details']">PROCEED</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>