<div class="row paragraph justify-content-center pb-2 mx-auto">Please choose times between 09:00 and 16:00</div>
<div class="row justify-content-center">
    <label class="col-6" for="startTime">Start Time: </label>
    <input class="col-6" id="startTime" type="time" [(ngModel)]="data.startTime">
</div>
<div class="row justify-content-center py-2">
    <label class="col-6" for="endTime">End Time: </label>
    <input class="col-6" id="endTime" type="time" [(ngModel)]="data.endTime">

</div>
<div class="row justify-content-center py-2">
    <button class="button text-center" [mat-dialog-close]="data">Set</button>
</div>