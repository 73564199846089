import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { AddressVm, CheckboxVm, PersonalDetailsVm, RegisterUserVm, ShareInOurDatabaseFormVm, SiodRegisterVm, UserTypeEnum } from 'src/app/services/property-matrix/models';
import { AuthenticateService, ShareInOurDatabaseService } from 'src/app/services/property-matrix/services';

@Component({
  selector: 'app-siod-register',
  templateUrl: './siod-register.component.html',
  styleUrls: ['./siod-register.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class SiodRegisterComponent implements OnInit {

  emailPattern = GlobalConstants.emailPattern;
  passwordPattern = GlobalConstants.passwordPattern;
  loading: boolean = false;
  page: Number = 1;
  siodForm: ShareInOurDatabaseFormVm = {
    siodRegisterVM: null,
    subscriptionVM: []
  }


  @Output() pageNumberEvent = new EventEmitter<number>();
  _subscription_siodForm: Subscription;

  constructor(private siodService: ShareInOurDatabaseService, private authService: AuthenticateService, private sharedService: PropertyMatrixSharedService, private router: Router) {
    this._subscription_siodForm = this.sharedService.siodFormSubject.subscribe((value) =>{
      this.siodForm = value;
    })
   }

  ngOnInit(): void {    
    const user = this.sharedService.getUserModel();
    if (user) {
      this.page = 2;
    }
  }

  personalDetails: PersonalDetailsVm = {
    name: null,
    surname: null,
    phoneNumber: null,
    confirmPhoneNumber: null,
    emailAddress: null,
    confirmEmail: null,
    id_Number: null
  }

  registerBody: RegisterUserVm = {
    personalDetails: this.personalDetails,
    loginDetails: {
      email: '',
      password: '',
      passwordConfirm: ''
    }
  }

  submitRegister({ value, valid }: { value: RegisterUserVm, valid: boolean }) {
    this.loading = true
    this.registerBody.loginDetails.email = this.personalDetails.emailAddress;
    if (valid) {
      this.authService.apiV1AuthenticateRegisterUserPost$Response({ body: this.registerBody })
        .subscribe({
          next: (result) => {
            if (result.status == HttpStatusCode.Conflict) {
              GlobalMethods.tinyErrorAlert('Error', 'User already exists');
              return;
            }
            GlobalMethods.tinySuccessAlert('Welcome', 'Please login. After signing in, you will be redirected back to this page.').then(result => {
              GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/share-in-our-database/capture', this.router)
              this.page = 2;
            });
            this.loading = false;
          }
          , error: (error) => {
            GlobalMethods.tinyErrorAlert('Error', 'User already exists')
            this.loading = false;
          }
        })
    } else {
      GlobalMethods.tinyErrorAlert('Error', 'Please complete all mandatory fields.')
    }
  }

  userTypeSelection: KeyValuePair[] = [
    { key: 1, value: 'as an individual/natural person (speculating with property)' },
    { key: 2, value: 'as part of an entity (e.g., company/real estate agency)' }
  ]

  propertyInterestTypes: CheckboxVm[] = [
    { id: 1, value: 'I am a property developer', checked: false },
    { id: 2, value: 'I work for developer(s): Investigate and recommend development opportunities', checked: false },
    { id: 3, value: "I am a smaller scale property entrepreneur ('speculating' with property)", checked: false },
    { id: 4, value: 'I am a property investor', checked: false },
    { id: 5, value: 'I am an owner of a business - now to related to property', checked: false },
    { id: 6, value: 'I am an estate agent/property broker', checked: false },
    { id: 7, value: 'Other, please specify', checked: false },
  ]

  businessAddress: AddressVm = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: ''
  }

  siodRegisterBody: SiodRegisterVm = {
    userType: null,
    propertyInterest: this.propertyInterestTypes,
    propertyInterestOther: '',
    name: '',
    businessAddress: this.businessAddress
  }

  otherPropertyInterest: boolean = false;
  isOtherSelected() {
    this.otherPropertyInterest = this.siodRegisterBody.propertyInterest.find(f => f.id == 7).checked
  }

  submitCompanyInfo({ value, valid }: { value: SiodRegisterVm, valid: boolean }) {
    this.siodRegisterBody.propertyInterest = this.propertyInterestTypes.filter(f => f.checked == true);
    if (valid) {
      this.siodForm.siodRegisterVM = this.siodRegisterBody
      this.sharedService.setSiodForm(this.siodForm)
      this.pageNumberEvent.emit(2);
    }
  }
}