import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostEstimatesDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-cost-estimates',
  templateUrl: './cost-estimates.component.html',
  styleUrls: ['./cost-estimates.component.scss']
})
export class CostEstimatesComponent implements OnInit {

  @Input() costEstimatesData: CostEstimatesDto;
  @Output() costEstimatesDataChange = new EventEmitter<CostEstimatesDto>();

  constructor() { }

  ngOnInit(): void { }

  onInputChange() {
    this.costEstimatesDataChange.emit(this.costEstimatesData);
  }
}
