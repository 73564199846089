import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, UrbanPlannerService } from 'src/app/services/property-matrixV2/services';
import { ProfessionDto } from 'src/app/services/property-matrixV2/models';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profession-add-dialog',
  templateUrl: './profession-add-dialog.component.html',
  styleUrls: ['./profession-add-dialog.component.scss', '../urban-planner-professional-dialog/urban-planner-professional-dialog.component.scss']
})
export class ProfessionAddDialogComponent implements OnInit {
  newProfession: ProfessionDto = {
    order: 0,
    value: '',
  };
  professions: ProfessionDto[] = [];
  @Output() professionAdded = new EventEmitter<ProfessionDto>();

  constructor(
    public dialog: MatDialog,
    private lookupService: LookupService,
    private professionalService: UrbanPlannerService,
    public dialogRef: MatDialogRef<ProfessionAddDialogComponent>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.loadExistingProfessions();
  }

  loadExistingProfessions() {
    this.lookupService.apiV1LookupGetProfessionsGet().subscribe((response) => {
      this.professions = response;
    });
  }

  saveProfession() {
    const maxOrder = this.professions.reduce((max, p) => p.order > max ? p.order : max, 0);
    this.newProfession.order = maxOrder + 1;
    this.professionalService.apiV1UrbanPlannerAddProfessionAsyncPost({ body: this.newProfession }).subscribe((response) => {
      if (response) {
        this.dialogRef.close(response);
      } else {
        this.notificationService.showErrorMessage('Error', 'Could not save profession.');
      }
    });
  }
}