import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-conveyancer-registration',
  templateUrl: './conveyancer-registration.component.html',
  styleUrls: ['./conveyancer-registration.component.scss']
})
export class ConveyancerRegistrationComponent {

  constructor(public dialog: MatDialog) { }
  
}