/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseService } from '../property-matrix-base-service';
import { PropertyMatrixConfiguration } from '../property-matrix-configuration';
import { PropertyMatrixStrictHttpResponse } from '../property-matrix-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ContactAgentQueryVm } from '../models/contact-agent-query-vm';
import { OfferToPurchaseFormVm } from '../models/offer-to-purchase-form-vm';

@Injectable({
  providedIn: 'root',
})
export class PropertyForSaleService extends PropertyMatrixBaseService {
  constructor(
    config: PropertyMatrixConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1PropertyForSaleGetPropertyTypesGet
   */
  static readonly ApiV1PropertyForSaleGetPropertyTypesGetPath = '/api/v1/PropertyForSale/GetPropertyTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyForSaleGetPropertyTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyForSaleGetPropertyTypesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyForSaleService.ApiV1PropertyForSaleGetPropertyTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyForSaleGetPropertyTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyForSaleGetPropertyTypesGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyForSaleGetPropertyTypesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyForSaleSendEstateAgentQueryPost
   */
  static readonly ApiV1PropertyForSaleSendEstateAgentQueryPostPath = '/api/v1/PropertyForSale/SendEstateAgentQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyForSaleSendEstateAgentQueryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSendEstateAgentQueryPost$Response(params?: {
    context?: HttpContext
    body?: ContactAgentQueryVm
  }
): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyForSaleService.ApiV1PropertyForSaleSendEstateAgentQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyForSaleSendEstateAgentQueryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSendEstateAgentQueryPost(params?: {
    context?: HttpContext
    body?: ContactAgentQueryVm
  }
): Observable<void> {

    return this.apiV1PropertyForSaleSendEstateAgentQueryPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyForSaleSendTownPlannerQueryPost
   */
  static readonly ApiV1PropertyForSaleSendTownPlannerQueryPostPath = '/api/v1/PropertyForSale/SendTownPlannerQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyForSaleSendTownPlannerQueryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSendTownPlannerQueryPost$Response(params?: {
    context?: HttpContext
    body?: ContactAgentQueryVm
  }
): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyForSaleService.ApiV1PropertyForSaleSendTownPlannerQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyForSaleSendTownPlannerQueryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSendTownPlannerQueryPost(params?: {
    context?: HttpContext
    body?: ContactAgentQueryVm
  }
): Observable<void> {

    return this.apiV1PropertyForSaleSendTownPlannerQueryPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyForSaleSaveOfferToPurchaseFormPost
   */
  static readonly ApiV1PropertyForSaleSaveOfferToPurchaseFormPostPath = '/api/v1/PropertyForSale/SaveOfferToPurchaseForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyForSaleSaveOfferToPurchaseFormPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSaveOfferToPurchaseFormPost$Response(params?: {
    context?: HttpContext
    body?: OfferToPurchaseFormVm
  }
): Observable<PropertyMatrixStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyForSaleService.ApiV1PropertyForSaleSaveOfferToPurchaseFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyForSaleSaveOfferToPurchaseFormPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyForSaleSaveOfferToPurchaseFormPost(params?: {
    context?: HttpContext
    body?: OfferToPurchaseFormVm
  }
): Observable<void> {

    return this.apiV1PropertyForSaleSaveOfferToPurchaseFormPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponse<void>) => r.body as void)
    );
  }

}
