<form id="conditionsForm" novalidate #conditionsForm="ngForm" class="submission-form">
    <section>
        <div class="heading-2 mt-1">STEP 2: CONDITIONS</div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>Will your offer to buy this property be subject to another property that
            needs to be sold?
        </div>
        <mat-radio-group name="radioAwaitingCurrentPropertySale" [(ngModel)]="awaitingCurrentPropertySale">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo" [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="awaitingCurrentPropertySale > 0">
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>Would you like Property Matrix to assist you in selling the other
                property?
            </div>
            <mat-radio-group name="radioPm_AssistWithSellingOtherProperty"
                [(ngModel)]="pm_AssistWithSellingOtherProperty">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="pm_AssistWithSellingOtherProperty == 1" class="submission-form">
            <span class="paragraph"><strong>Physical/street address of the property <u>to be sold</u>:</strong></span>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                        unit
                        no:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                        class="paragraph not-required" [(ngModel)]="form.physicalAddress.unitNo">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                        class="paragraph">Estate/complex
                        name:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                        class="paragraph not-required" [(ngModel)]="form.physicalAddress.complexName">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                        no:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                        class="paragraph" [(ngModel)]="form.physicalAddress.streetNo" required #streetNumber="ngModel"
                        [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                        Name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                        class="paragraph" [(ngModel)]="form.physicalAddress.streetName" required #streetName="ngModel"
                        [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                        [(ngModel)]="form.physicalAddress.suburb" required #suburb="ngModel"
                        [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                        [(ngModel)]="form.physicalAddress.city" #city="ngModel" required
                        [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Postal code:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="postalCode" class="paragraph"
                        [(ngModel)]="form.physicalAddress.postalCode" #postalCode="ngModel" required
                        [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
                </div>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>Please provide the property description as indicated on your municipal
                account (except for eThekwini) or on your title deed.
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Property description:</label>
                </div>
                <div class="col-6">
                    <textarea id="txtPropertyDescription" name="propertyDescription" class="paragraph" rows="3"
                        [(ngModel)]="form.propertyDescription" #propertyDescription="ngModel"></textarea>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-6">
                            <strong class="paragraph">Examples: </strong>
                            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                                <li>Erf 386 Meyerton; </li>
                                <li>Holding 23 of Raslouw Agricultural Holdings</li>
                                <li>Portion 77 of the Farm Stoneybrooke 365-JR] </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>What timeframe will you require to sell the other property?
            </div>
            <div class="row">
                <mat-radio-group name="radiotimeFrame" [(ngModel)]="form.timeFrame">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of propertySaleTimeframeRadioOptions"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="form.timeFrame == 5">
                    <div class="row">
                        <div class="col-8">
                            <textarea class="paragraph other" class="remove-textarea-height" name="timeFrameReason"
                                id="timeFrameReason" [(ngModel)]="form.timeFrameReason"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="formWithConditions && pm_AssistWithSellingOtherProperty == 2" class="submission-form">
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>Do you know which condition(s) you would like to include in your offer to purchase?
            </div>
            <mat-radio-group name="radioknowConditionsToInclude" [(ngModel)]="knowConditionsToInclude">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of knowConditionsToIncludeRadioOptions"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="knowConditionsToInclude == 1">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>Please describe the condition(s) you would like to add.
                </div>
                <div class="row">
                    <div class="col-8">
                        <textarea class="paragraph other" required class="remove-textarea-height" name="timeFrameReason"
                        id="timeFrameReason" rows="3" [(ngModel)]="form.conditionsDespcription"></textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="knowConditionsToInclude == 2">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>Please explain your concerns and any possible conditions you wish to add the best you can in
                    your own words.
                </div>
                <div class="paragraph mt-2">
                    <b>Note</b>: It will be extremely helpful if you can indicate what exactly you would like to use the property for.
                    Please provide as much detail as possible, e.g., number of people expected to work/live on the property,
                    any parking requirements, what activities will take place and where applicable: materials/supplies
                    involved, storage thereof, etc.
                </div>
                <div class="row">
                    <div class="col-8">
                        <textarea class="paragraph other" required class="remove-textarea-height" name="timeFrameReason"
                        id="timeFrameReason" rows="3" [(ngModel)]="form.conditionConcernsDescription"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" *ngIf="pm_AssistWithSellingOtherProperty > 0">
            <div class="col-12">
                <button id="btnSaveFinal" class="button heading-4" [disabled]="conditionsForm.invalid || (knowConditionsToInclude == 0 && formWithConditions)
                || (form.timeFrame == 0 && pm_AssistWithSellingOtherProperty == 1)"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>