import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags-key-dialog',
  templateUrl: './tags-key-dialog.component.html',
  styleUrls: ['./tags-key-dialog.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class TagsKeyDialogComponent implements OnInit {

  tags = [
    { name: 'Seller', description: 'Listed a property and signed the exclusive mandate', backgroundColor: '#618fd5' },
    { name: 'Bid-Buyer', description: 'Placed an offer to purchase or option to buy', backgroundColor: '#8fd2d7' },
    { name: 'Purchaser', description: 'Bought a property on our website', backgroundColor: '#b3d78f' },
    { name: 'Client', description: 'Ordered additional services', backgroundColor: '#a685dd' },
    { name: 'Subscribed', description: 'Subscribed to the database', backgroundColor: '#feadcf' },
    { name: 'Browser', description: 'Starred 5 or more properties the past 3 months', backgroundColor: '#edbf94' },
    { name: 'Spender', description: 'Made a deposited in their cash basket this month', backgroundColor: '#ede494' }
  ];

  constructor(public dialogRef: MatDialogRef<TagsKeyDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
