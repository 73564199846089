import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private httpClient: HttpClient) { }

  async uploadFile(file: any): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    return new Promise<string>((resolve, reject) => {
      this.httpClient.post(environment.api + '/api/v1/FileManagement/UploadFile', formData)
        .subscribe({
          next: (result) => {
            var res = result.toString();
            resolve(res);
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  async getFileUrl(fileId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.httpClient.get(environment.api + '/api/v1/FileManagement/GetFile/' + fileId)
        .subscribe({
          next: (result: any) => {
            resolve(result);
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }
}
