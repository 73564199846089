import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { CheckboxVm } from 'src/app/services/property-matrix/models';
import { ValuerInformationVm } from 'src/app/services/property-matrix/models/valuer-information-vm';

@Component({
  selector: 'app-valuer-information',
  templateUrl: './valuer-information.component.html',
  styleUrls: ['./valuer-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class ValuerInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() ValuerInformationEvent = new EventEmitter<ValuerInformationVm>();

  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern : string = GlobalConstants.passwordPattern;

  form: ValuerInformationVm = {
    addressDetails: {
      city: '',
      suburb: '',
      complexName: '',
      streetName: '',
      streetNumber: '',
      unitNumber: '',
    },
    clustersOfValutations: '',
    financialInstitutions: null,
    financialInstitutionsOther: '',
    propertyExperienceType: null,
    propertyExperienceTypeOther: '',
    sacpvp_RegistrationNumber: '',
    valuerType: null,
    yearsWorkingAsConveyancer: null,
    yearsWorkingInValuationIndustry: null,
    companyName: '',
    metroRegions: null,
  }

  propertyExperienceTypeCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Vacant land', checked: false },
    { id: 2, value: 'Single residential', checked: false },
    { id: 3, value: 'Group housing complexes/ Blocks of flats', checked: false },
    { id: 4, value: 'Commercial – Offices ', checked: false },
    { id: 5, value: 'Commercial – Retail', checked: false },
    { id: 6, value: 'Commercial – Industrial', checked: false },
    { id: 7, value: 'Specialised – Guest houses/ hotels/ lodges/ other hospitality facilities', checked: false },
    { id: 8, value: 'Specialised – Schools', checked: false },
    { id: 9, value: 'Specialised – Churches', checked: false },
    { id: 10, value: 'Specialised - Mining', checked: false },
    { id: 11, value: 'Agricultural farms', checked: false },
    { id: 12, value: 'Other', checked: false },
  ]

  financialInstitutionsPanelCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'ABSA Bank', checked: false },
    { id: 2, value: 'Capitec Bank', checked: false },
    { id: 3, value: 'First Rand Bank (FNB)', checked: false },
    { id: 4, value: 'Investec', checked: false },
    { id: 5, value: 'Mercantile Bank', checked: false },
    { id: 6, value: 'Nedbank', checked: false },
    { id: 7, value: 'Standard Bank', checked: false },
    { id: 8, value: 'Other', checked: false },
  ]

  metroRegionsCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Tshwane', checked: false },
    { id: 2, value: 'Cape Town', checked: false },
    { id: 3, value: 'Joburg', checked: false },
    { id: 4, value: 'Ekurhuleni', checked: false },
    { id: 5, value: 'Durban', checked: false },
  ]

  valuerTypeRadioOptions: KeyValuePair[] = [
    {key: 1, value: 'Professional valuer'},
    {key: 2, value: 'Professional associated valuer'},
    {key: 3, value: 'Candidate valuer'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  submitForm() {
    this.form.financialInstitutions = this.financialInstitutionsPanelCheckBoxOptions.filter(f => f.checked == true);
    this.form.propertyExperienceType = this.propertyExperienceTypeCheckBoxOptions.filter(f => f.checked == true);;
    this.form.metroRegions = this.metroRegionsCheckBoxOptions.filter(f => f.checked == true);;
    this.pageNumberEvent.emit(2);
    this.ValuerInformationEvent.emit(this.form);
  }

  financialInstitutionsPanelOtherCheck(option: CheckboxVm) : boolean{
    return option.id == 8 && option.checked;
  }

  propertyExperienceTypeOtherCheck(option: CheckboxVm) : boolean{
    return option.id == 12 && option.checked;
  }

}
