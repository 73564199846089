<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let additionalPolicy of additionalPolicyPlanFigureData; let i = index">
        <button *ngIf="i > 0" (click)="removeAdditionalPolicy(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Additional Policy {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Policy Name</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="additionalPolicy.name"
                            placeholder="SANITATION AND STORM-WATER NETWORK" (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Interpretation</label>
                        <textarea class="col-12 mt-1 text-area" [(ngModel)]="additionalPolicy.description"
                            placeholder="Figure 3 indicates"
                            (ngModelChange)="emitChanges()">
                        </textarea>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (load)="loadFile(i)" (change)="setFile($event, i)"
                                    class="upload-block-inner" multiple accept="image/*" id="input" placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Drag & drop or click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="fileUrls[i]">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="fileUrls[i]" alt="Image Preview" id="preview"
                                                class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="hideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="fileUrl"
                            [download]="file?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral" (click)="replace()">
                            Replace
                        </button>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Figure Name</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="additionalPolicy.imageName"
                            placeholder="Figure 3: Sanitation and storm-water network" (ngModelChange)="emitChanges()">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addAdditionalPolicy()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Add figure</span>
        </button>
    </div>
</div>