<div class="heading-2">
    <div class="row">
        <div class="col-10">Edit {{data.data.title}}</div>
        <div class="col-2 text-end"><button mat-icon-button
                (click)="closeDialogWithNoChanges()"><mat-icon>close</mat-icon></button></div>
    </div>
</div>
<!-- Company Information -->
<div *ngIf="data.editType == 1">
    <section class="submission-form" *ngIf="userRole == userRoles.estateAgent">

        <div class="row">
            <div class="col-3">
                <label class="paragraph">Franchise branch:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="franchiseBranch"
                    [(ngModel)]="data.data.value.franchiseBranch" required #franchiseBranch="ngModel"
                    [ngClass]="{'invalid-input': franchiseBranch.invalid, 'valid-input': franchiseBranch.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Franchise name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="franchiseName"
                    [(ngModel)]="data.data.value.franchiseName" required #franchiseName="ngModel"
                    [ngClass]="{'invalid-input': franchiseName.invalid, 'valid-input': franchiseName.valid}">
            </div>
        </div>
    </section>
    <section class="submission-form" *ngIf="userRole == userRoles.conveyancer">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Company name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="companyName"
                    [(ngModel)]="data.data.value.companyName" required #companyName="ngModel"
                    [ngClass]="{'invalid-input': companyName.invalid, 'valid-input': companyName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Registration Number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="registrationNumber"
                    [(ngModel)]="data.data.value.registrationNumber" required #registrationNumber="ngModel"
                    [ngClass]="{'invalid-input': registrationNumber.invalid, 'valid-input': registrationNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="phoneNumber"
                    [(ngModel)]="data.data.value.phoneNumber" required #phoneNumber="ngModel"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="emailAddress" class="paragraph"
                    [(ngModel)]="data.data.value.emailAddress" required #emailAddress="ngModel" [pattern]="emailPattern"
                    [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Alternative email:</label>
            </div>
            <div class="col-7">
                <input type="text" class="paragraph" name="alternativeEmailAddress"
                    [(ngModel)]="data.data.value.alternativeEmailAddress" #alternativeEmailAddress="ngModel">
            </div>
        </div>
    </section>
    <section class="submission-form" *ngIf="userRole == userRoles.urbanPlanner">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">VAT Number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="vat"
                    [(ngModel)]="data.data.value.vat" required #vat="ngModel"
                    [ngClass]="{'invalid-input': vat.invalid, 'valid-input': vat.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Registration Number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="registrationNumber"
                    [(ngModel)]="data.data.value.registrationNumber" required #registrationNumber="ngModel"
                    [ngClass]="{'invalid-input': registrationNumber.invalid, 'valid-input': registrationNumber.valid}">
            </div>
        </div>
        <div class="heading-5">
            <span class="burnt-red">| </span>At which municipal council(s) do you mostly submit planning
            applications?
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngFor="let option of municipalCouncilsMostlySubmittedToOptions; let index = i">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="paragraph">
                            {{option.value}}<span
                                [ngClass]="{'specify-text': data.data.value.relevantCouncilsOther.length == 0}"
                                *ngIf="otherCheckboxSelected(option, 6)"> – Please specify</span>
                        </span>
                        <input type="checkbox" [(ngModel)]="option.checked"
                            [name]="municipalCouncilsMostlySubmittedToOptions"
                            #municipalCouncilsMostlySubmittedToOptions="ngModel" />
                        <span class="checkmark"></span>
                    </label>
                    <div class="mt-2" *ngIf="otherCheckboxSelected(option, 6)">
                        <div class="row mt-2">
                            <div class="col-10">
                                <input name="relevantCouncilsOther" type="text"
                                    class="paragraph mat-input-section mat-input-section-other"
                                    [(ngModel)]="data.data.value.relevantCouncilsOther" required
                                    #relevantCouncilsOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="heading-5">
            <span class="burnt-red">| </span>Indicate any type of planning applications in which you have extensive
            experience and knowledge:
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngFor="let option of planningApplicationsExperienceAndKnowledgeOptions; let index = i">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="paragraph">
                            {{option.value}}<span
                                [ngClass]="{'specify-text': data.data.value.experienceOther.length == 0}"
                                *ngIf="otherCheckboxSelected(option, 5)"> – Please specify</span>
                        </span>
                        <input type="checkbox" [(ngModel)]="option.checked"
                            [name]="planningApplicationsExperienceAndKnowledgeOptions"
                            #planningApplicationsExperienceAndKnowledgeOptions="ngModel" />
                        <span class="checkmark"></span>
                    </label>
                    <div class="mt-2" *ngIf="otherCheckboxSelected(option, 5)">
                        <div class="row mt-2">
                            <div class="col-10">
                                <input name="experienceOther" type="text"
                                    class="paragraph mat-input-section mat-input-section-other"
                                    [(ngModel)]="data.data.value.experienceOther" required #experienceOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Principal details -->
<div *ngIf="data.editType == 2">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="name"
                    [(ngModel)]="data.data.value.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="surname"
                    [(ngModel)]="data.data.value.surname" required #surname="ngModel"
                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Work Phone:</label>
            </div>
            <div class="col-7">
                <input type="text" name="workPhone" class="paragraph not-required"
                    [(ngModel)]="data.data.value.alternativeMobile">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="phoneNumber" class="paragraph"
                    [(ngModel)]="data.data.value.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="emailAddress" class="paragraph"
                    [(ngModel)]="data.data.value.emailAddress" required #emailAddress="ngModel" [pattern]="emailPattern"
                    [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">
                    <span>Identity Number/Passport Number:</span>
                </label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="id_Number" class="paragraph"
                    [(ngModel)]="data.data.value.id_Number" required #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                'valid-input': id_Number.valid}">
                <small class="form-control-feedback"
                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                    *Must must contain at least 9 characters
                </small>
            </div>
        </div>
    </section>
</div>
<!-- Company Address -->
<div *ngIf="data.editType == 3">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" name="buildingUnitNumber" class="paragraph not-required"
                    [(ngModel)]="data.data.value.unitNo" #buildingUnitNumber="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" name="estateComplexName" class="paragraph not-required"
                    [(ngModel)]="data.data.value.complexName" #estateComplexName="ngModel">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetNumber" class="paragraph"
                    [(ngModel)]="data.data.value.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="streetName" class="paragraph"
                    [(ngModel)]="data.data.value.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="suburb" class="paragraph"
                    [(ngModel)]="data.data.value.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="city" class="paragraph"
                    [(ngModel)]="data.data.value.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
    </section>
</div>
<!-- Account details -->
<div *ngIf="data.editType == 4">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Account holder:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAccountHolder" class="paragraph" name="accountHolder"
                    [(ngModel)]="data.data.value.accountHolder" required #accountHolder="ngModel"
                    [ngClass]="{'invalid-input': accountHolder.invalid, 'valid-input': accountHolder.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Bank:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtBank" class="paragraph" name="bank"
                    [(ngModel)]="data.data.value.bank" required #bank="ngModel"
                    [ngClass]="{'invalid-input': bank.invalid, 'valid-input': bank.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Branch name:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtBranchName" name="landline" class="paragraph not-required"
                    [(ngModel)]="data.data.value.branchName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Branch number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtBranchNumber" class="paragraph" name="branchNumber"
                    [(ngModel)]="data.data.value.branchNumber" required #branchNumber="ngModel"
                    [ngClass]="{'invalid-input': branchNumber.invalid, 'valid-input': branchNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Type of account:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtTypeOfAccount" class="paragraph" name="typeOfAccount"
                    [(ngModel)]="data.data.value.typeOfAccount" required #typeOfAccount="ngModel"
                    [ngClass]="{'invalid-input': typeOfAccount.invalid, 'valid-input': typeOfAccount.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Account number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAccountNumber" class="paragraph" name="accountNumber"
                    [(ngModel)]="data.data.value.accountNumber" required #accountNumber="ngModel"
                    [ngClass]="{'invalid-input': accountNumber.invalid, 'valid-input': accountNumber.valid}">
            </div>
        </div>
    </section>
</div>
<!-- Supporting Staff Member: Town Planner -->
<!-- Supporting Staff Member: Administrative -->
<div *ngIf="data.editType == 5 || data.editType == 6">
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="name"
                    [(ngModel)]="data.data.value.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" class="paragraph" name="surname"
                    [(ngModel)]="data.data.value.surname" required #surname="ngModel"
                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Position:</label>
            </div>
            <div class="col-7">
                <input type="text" name="position" class="paragraph not-required"
                    [(ngModel)]="data.data.value.position">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="phoneNumber" class="paragraph"
                    [(ngModel)]="data.data.value.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="emailAddress" class="paragraph"
                    [(ngModel)]="data.data.value.emailAddress" required #emailAddress="ngModel" [pattern]="emailPattern"
                    [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">
                    <span>Identity Number/Passport Number:</span>
                </label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" name="id_Number" class="paragraph"
                    [(ngModel)]="data.data.value.id_Number" required #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                'valid-input': id_Number.valid}">
                <small class="form-control-feedback"
                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                    *Must must contain at least 9 characters
                </small>
            </div>
        </div>
    </section>
</div>
<div class="text-center">
    <button (click)="saveInfo()" class="button">Save</button>
</div>