import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlannerPropertyDetailsDialogData } from '../../planner-register-form-information.component';

@Component({
  selector: 'app-planner-property-details-dialog',
  templateUrl: './planner-property-details-dialog.component.html',
  styleUrls: ['./planner-property-details-dialog.component.scss']
})
export class PlannerPropertyDetailsDialogComponent implements OnInit {

  constructor(
     public dialogRef: MatDialogRef<PlannerPropertyDetailsDialogData>,
     private cdr: ChangeDetectorRef,
     @Inject(MAT_DIALOG_DATA) public data: PlannerPropertyDetailsDialogData,
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  selectProperty(property: any) {
    this.dialogRef.close(property);
  }

}
