import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { CheckboxVm, ContactAgentQueryVm, TimePreferenceVm } from 'src/app/services/property-matrix/models';
import { PropertyForSaleService } from 'src/app/services/property-matrix/services';
import { TimePickerDialogInvestigation } from '../../property-investigations/level3-investigations/level3-investigations.component';

@Component({
  selector: 'app-contact-town-planner',
  templateUrl: './contact-town-planner.component.html',
  styleUrls: ['./contact-town-planner.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class ContactTownPlannerComponent implements OnInit {

  purchasedAndReadPlanningReport = 0;

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  today: Date = new Date();
  checkDate: CheckboxVm[] = [
    { id: 1, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 2, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
    { id: 3, value: this.datePipe.transform(this.today.setDate(this.today.getDate() + 1), 'yyyy-MM-dd'), checked: false },
  ]

  timePreferences: TimePreferenceVm[] = [
    { date: this.checkDate.find(f => f.id == 1), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 2), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' },
    { date: this.checkDate.find(f => f.id == 3), startTime: '2022-10-12T12:12:11.134Z', endTime: '2022-10-12T12:12:11.134Z' }
  ]

  contactForm: ContactAgentQueryVm = {
    hasPurchasedAndReadPlanningReport: false,
    queryOne: '',
    queryTwo: '',
    timePreferences: null
  }

  constructor(public dialog: MatDialog, private datePipe: DatePipe, private propertyForSale: PropertyForSaleService) { }

  ngOnInit(): void {
  }

  submitForm() {
    this.contactForm.hasPurchasedAndReadPlanningReport = this.purchasedAndReadPlanningReport == 1;
    this.contactForm.timePreferences = this.timePreferences.filter(f => f.date.checked == true);
    this.propertyForSale.apiV1PropertyForSaleSendTownPlannerQueryPost$Response({ body: this.contactForm })
      .pipe(map(m => { return m })).subscribe({
        next: (result) => {

        }
        , error: (error) => {

        }
      })
  }

  clicked: boolean = false;
  openDialog(id: number) {
    if (this.clicked == true) {
      this.clicked = false;
      return;
    }
    this.clicked = true;
    if (this.checkDate[id - 1].checked == false) {
      const dialogRef = this.dialog.open(TimePickerDialogInvestigation, {
        width: '25vw',
        height: '30vh',
        data: { timePreferences: this.timePreferences }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result == null || result.startTime == null || result.endTime == null) {
          GlobalMethods.tinyErrorAlert("Error", "Please select your preferred time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime > result.endTime) {
          GlobalMethods.tinyErrorAlert("Error", "Make sure your start time is before your end time");
          this.checkDate[(id - 1)].checked = false;
        } else if (result.startTime < "09:00" || result.endTime > "16:00") {
          GlobalMethods.tinyErrorAlert("Error", "Please choose times between 09:00 and 16:00");
          this.checkDate[(id - 1)].checked = false;
        }
        else if (result) {
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).startTime = this.setDateForAPI(id, result.startTime);
          this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).endTime = this.setDateForAPI(id, result.endTime);
        }
      });
    }
  }

  setDateForAPI(id: number, time: string): string {
    return ((this.timePreferences.find(f => f.date == this.checkDate.find(f => f.id == id)).date).value + 'T' + time + ':00.000Z');
  }
}
