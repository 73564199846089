import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WayForwardDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-way-forward',
  templateUrl: './way-forward.component.html',
  styleUrls: ['./way-forward.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class WayForwardComponent implements OnInit {

  @Input() wayForwardData: WayForwardDto;
  @Output() wayForwardDataChange = new EventEmitter<WayForwardDto>();

  constructor() { }

  ngOnInit(): void { }

  onInputChange() {
    this.wayForwardDataChange.emit(this.wayForwardData);
  }
}
