export class Validators {

  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public mobileNumber: string = '';
  public confirmMobileNumber: string = '';
  public confirmEmail: string = '';
  public identitiyPassportNumber: string = '';
  public identityNumber: string = '';
  public graduateYear: string = '';
  public graduateinstitution: string = '';
  public yearsOfExperience: string = '';
  public nameOfCompanyOperation: string = '';
  public streetNumber: string = '';
  public streetName: string = '';
  public suburb: string = '';
  public city: string = '';
  public createPassword: string = '';
  public confirmPassword: string = '';
  public nameOfCertificate: string = '';
  public numberOfYearsRegistered: string = '';
  public eaabFidelityFund: string = '';
  public nameOfAgency: string = '';
  public nameOfBranch: string = '';
  public nameOfOrganisation: string = '';
  public companyRegistrationNumber: string = '';
  public vatNumber:string = '';

  get vatNumberValidationMessage() {
    return this.vatNumber;
  }

  set vatNumberValidationMessage(value) {
    this.vatNumber = value; 
  }

  get companyRegistrationNumberValidationMessage() {
    return this.companyRegistrationNumber;
  }

  set companyRegistrationNumberValidationMessage(value) {
    this.companyRegistrationNumber = value;
  }

  get nameOfOrganisationValidationMessage() {
    return this.nameOfOrganisation;
  }

  set nameOfOrganisationValidationMessage(value) {
    this.nameOfOrganisation = value;
  }

  get emailValidationMessage() {
    return this.email;
  }

  set emailValidationMessage(value) {
    this.email = value;
  }

  get identityNumberValidationMessage() {
    return this.identityNumber;
  }

  set identityNumberValidationMessage(value) {
    this.identityNumber = value;
  }

  get firstNameValidationMessage() {
    return this.firstName;
  }

  set firstNameValidationMessage(value) {
    this.firstName = value;
  }

  get lastNameValidationMessage() {
    return this.lastName;
  }

  set lastNameValidationMessage(value) {
    this.lastName = value;
  }


  get mobileNumberValidationMessage() {
    return this.mobileNumber;
  }

  set mobileNumberValidationMessage(value) {
    this.mobileNumber = value;
  }

  get confirmMobileNumberValidationMessage() {
    return this.confirmMobileNumber;
  }

  set confirmMobileNumberValidationMessage(value) {
    this.confirmMobileNumber = value;
  }

  get confirmEmailValidationMessage() {
    return this.confirmEmail;
  }

  set confirmEmailValidationMessage(value) {
    this.confirmEmail = value;
  }

  get confirmIdentitiyPassportNumberValidationMessage() {
    return this.identitiyPassportNumber;
  }

  set confirmIdentitiyPassportNumberValidationMessage(value) {
    this.identitiyPassportNumber = value;
  }

  get GraduateYearValidationMessage() {
    return this.graduateYear;
  }

  set GraduateYearValidationMessage(value){
    this.graduateYear = value;
  }

  get GraduateinstitutionValidationMessage() {
    return this.graduateinstitution;
  }

  set GraduateinstitutionValidationMessage(value){
    this.graduateinstitution = value;
  }

  get yearsOfExperianceValidationMessage() {
    return this.yearsOfExperience;
  }

  set yearsOfExperianceValidationMessage(value){
    this.yearsOfExperience = value;
  }

  get nameOfCompanyValidationMessage() {
    return this.nameOfCompanyOperation;
  }

  set nameOfCompanyValidationMessage(value){
    this.nameOfCompanyOperation = value;
  }

  get nameOfAgencyValidationMessage() {
    return this.nameOfAgency;
  }

  set nameOfAgencyValidationMessage(value){
    this.nameOfAgency = value;
  }

  get nameOfBranchValidationMessage() {
    return this.nameOfBranch;
  }

  set nameOfBranchValidationMessage(value){
    this.nameOfBranch = value;
  }

  get streetNumberValidationMessage() {
    return this.streetNumber;
  }

  set streetNumberValidationMessage(value){
    this.streetNumber = value;
  }

  get streetNameValidationMessage() {
    return this.streetName;
  }

  set streetNameValidationMessage(value){
    this.streetName = value;
  }

  get suburbValidationMessage() {
    return this.suburb;
  }

  set suburbValidationMessage(value){
    this.suburb = value;
  }

  get cityValidationMessage() {
    return this.city;
  }

  set cityValidationMessage(value){
    this.city = value;
  }

  get createPasswordValidationMessage() {
    return this.createPassword;
  }

  set createPasswordValidationMessage(value){
    this.createPassword = value;
  }

  get confirmPasswordValidationMessage() {
    return this.confirmPassword;
  }

  set confirmPasswordValidationMessage(value){
    this.confirmPassword = value;
  }
  get nameOfCertificateValidationMessage() {
    return this.nameOfCertificate;
  }

  set nameOfCertificateValidationMessage(value){
    this.nameOfCertificate = value;
  }

  set numberOfYearsRegisteredValidationMessage(value){
    this.numberOfYearsRegistered = value;
  }

  get numberOfYearsRegisteredValidationMessage() {
    return this.numberOfYearsRegistered;
  }
  set eaabFidelityFundRegisteredValidationMessage(value){
    this.eaabFidelityFund = value;
  }

  get eaabFidelityFundRegisteredValidationMessage() {
    return this.eaabFidelityFund;
  }


  emailValidation(ctrl, ctrl2) {
    let isValid = true;

    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.emailValidationMessage = "Email is required";
      isValid = false;
    }
    else if (ctrl.model != '' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ctrl.model) && (ctrl.dirty || ctrl.touched)) {
      this.emailValidationMessage = "Email is invalid";
      isValid = false;
    }
    return isValid;
  }

  confirmMEmailValidation(ctrl, ctrl2) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.confirmEmailValidationMessage = "Please confirm email address.";
      isValid = false;
    } 
    else if (ctrl.model != '' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ctrl.model) && (ctrl.dirty || ctrl.touched)) {
      this.confirmEmailValidationMessage = "Email is invalid";
      isValid = false;
    } 
    else if (ctrl.model != '' && ctrl.model != ctrl2.model && (ctrl.dirty || ctrl.touched)) {
      this.confirmEmailValidationMessage = "Emails do not match";
      isValid = false;     
    }
    return isValid;
  }

  vatNumberValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.vatNumberValidationMessage = "VAT number is required";
      isValid = false;
    }
    return isValid;
  }

  nameOfOrganisationValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.nameOfOrganisationValidationMessage = "Name of organisation is required";
      isValid = false;
    }
    return isValid;
  }

  companyRegistrationNumberValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.companyRegistrationNumberValidationMessage = "Company registration number is required";
      isValid = false;
    }
    return isValid;
  }

  firstNameValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.firstNameValidationMessage = "First name is required";
      isValid = false;
    }
    return isValid;
  }

  lastNameValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.lastNameValidationMessage = "Last name is required";
      isValid = false;
    }
    return isValid;
  }

  mobileNumberValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.mobileNumberValidationMessage = "Mobile number is required.";
      isValid = false;
    } 
    if (ctrl.model == '' && !/^\d+$/.test(ctrl.model) && (ctrl.dirty || ctrl.touched)) {
      this.mobileNumberValidationMessage = "Mobile number is invalid.";
      isValid = false;
    }
    if (ctrl.model == '' && ctrl.model.length != 10 && (ctrl.dirty || ctrl.touched)) {
      this.mobileNumberValidationMessage = "Mobile number is invalid.";
      isValid = false;
    }
    return isValid;
  }

  confirmMobileNumberValidation(ctrl, ctrl2) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.confirmMobileNumberValidationMessage = "Please confirm mobile number.";
      isValid = false;

    } else if (!/^\d+$/.test(ctrl.model) && (ctrl.dirty || ctrl.touched)) {
      this.confirmMobileNumberValidationMessage = "Mobile number is invalid.";
      isValid = false;

    } else if (ctrl.model != ctrl2.model && (ctrl.dirty || ctrl.touched)) {
      this.confirmMobileNumberValidationMessage = "Mobile numbers dont match";
      isValid = false;

    }

    return isValid;
  }


  confirmMIdentitiyPassportNumberValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.confirmIdentitiyPassportNumberValidationMessage = "Identity/Passport number is required";
      isValid = false;
    }
    return isValid;
  }

  identityNumberValidation(ctrl) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.identityNumberValidationMessage = "Identity number is required";
      isValid = false;
    }
    return isValid;
  }

  graduateYearValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.GraduateYearValidationMessage = "Graduate year is required";
      isValid = false;

    }
    return isValid;
  }

  institutionValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.GraduateinstitutionValidationMessage = "institution of graduation is required";
      isValid = false;

    }
    return isValid;
  }

  yearsOfExperienceValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.yearsOfExperience = "Years of experience is required";
      isValid = false;

    }
    return isValid;
  }

  nameOfCompanyValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.nameOfCompanyValidationMessage = "Name of the company is required";
      isValid = false;

    }
    return isValid;
  }

  nameOfAgencyValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.nameOfAgencyValidationMessage = "Name of the agency is required";
      isValid = false;

    }
    return isValid;
  }
  
  nameOfBranchValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.nameOfBranchValidationMessage = "Name of the branch is required";
      isValid = false;

    }
    return isValid;
  }

  streetNumberValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.streetNumberValidationMessage = "Street number is required";
      isValid = false;

    }
    return isValid;
  }

  streetNameValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.streetNameValidationMessage = "Street name is required";
      isValid = false;

    }
    return isValid;
  }

  suburbValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.suburbValidationMessage = "Suburb is required";
      isValid = false;

    }
    return isValid;
  }

  cityValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.cityValidationMessage = "City is required";
      isValid = false;

    }
    return isValid;
  }

  createPasswordValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.createPassword = "Password is required";
      isValid = false;

    }
    return isValid;
  }

  confirmPasswordValidation(ctrl, ctrl2) {
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.confirmPasswordValidationMessage = "Please confirm Password.";
      isValid = false;

    } else if (ctrl.model != ctrl2.model && (ctrl.dirty || ctrl.touched)) {
      this.confirmPasswordValidationMessage = "Passwords dont match";
      isValid = false;

    }
    return isValid;
  }

  certificateValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.nameOfCertificateValidationMessage = "Name of the certificate is required";
      isValid = false;

    }
    return isValid;
  }

  numberOfYearsRegisteredValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.numberOfYearsRegisteredValidationMessage = "Number of years  is required";
      isValid = false;

    }
    return isValid;
  }
  
  eaabFidelityFundValidation(ctrl){
    let isValid = true;
    if (ctrl.model == '' && (ctrl.dirty || ctrl.touched)) {
      this.eaabFidelityFundRegisteredValidationMessage = "EAAB Fidelity Fund Certificate number is required";
      isValid = false;

    }
    return isValid;
  }
}