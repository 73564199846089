/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { File } from '../models/file';

@Injectable({
  providedIn: 'root',
})
export class ReportGeneratorService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelOneSingleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelOneSingleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelOneSingleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Any$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Any(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Any$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Json$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Json(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Json$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelOneMultipleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelOneMultipleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelOneMultipleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Any$Response(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Any(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Any$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Json$Response(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Json(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Json$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelTwoSingleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelTwoSingleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelTwoSingleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Any$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Any(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Any$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Json$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Json(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Json$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelTwoMultipleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelTwoMultipleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Any$Response(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Any(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Any$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Json$Response(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<File>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<File>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Json(params?: {
    multipleListingId?: string;
    context?: HttpContext
  }
): Observable<File> {

    return this.apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Json$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<File>) => r.body as File)
    );
  }

}
