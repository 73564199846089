import { RiskscapeExistingLandUseAndFeatureDto, RiskscapePropertyInfoDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyDetailComponent implements OnInit {

  @Input() propertyTypes: KeyValuePair[];
  @Input() riskscapePropertyInfoData: RiskscapePropertyInfoDto;
  @Input() riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto;

  @Output() propertyType = new EventEmitter<string>();

  selectedPropertyType: string | null = null;

  constructor() { }

  ngOnInit(): void {
    switch (this.riskscapePropertyInfoData?.propertyType) {
      case "E":
        this.selectedPropertyType = "Erf";
        break;
      case "H":
        this.selectedPropertyType = "Holding";
        break;
      case "F":
        this.selectedPropertyType = "Farm";
        break;
    }
  }

  onPropertyTypeChange(event: MatSelectChange) {
    this.selectedPropertyType = event.value === null ? null : event.value;
    this.propertyType.emit(this.selectedPropertyType ?? "");
  }
}
