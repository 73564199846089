<div class="background-image container-fluid">
  <div class="container">
    <form class="form-signin" (ngSubmit)="login()" [formGroup]="loginForm" novalidate autocomplete="false">
      <div class="row content">
        <div class="col-12">
          <h1 class="heading-1">LOG-IN FOR VALUERS</h1>

          <div class="form-styling">
            <label id="lblPasscode" class="heading-3" for="txtPasscode">Please provide the passcode received via
              email:</label>
            <input [ngClass]="{'is-danger': displayMessage.Username}" id="txtPasscode" formControlName="passcode"
              class="form-control" type="password" placeholder="Enter the passcode">
            <div *ngIf="displayMessage.email" class="alert alert-danger error-message-padding">
              {{ displayMessage.email }}
            </div>
            <div class="col-6">
              <button [routerLink]="['/register']" class="button heading-3">Register</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>