<form id="firmInformationForm" novalidate #firmInformationForm="ngForm" class="submission-form">
    <div class="heading-2 mt-1">STEP 2: FIRM INFORMATION</div>
    <section class="submission-form">
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                    [(ngModel)]="form.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Firm's Registration
                    Number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtRegistrationNumber" class="paragraph"
                    name="registrationNumber" [(ngModel)]="form.registrationNumber" required
                    #registrationNumber="ngModel"
                    [ngClass]="{'invalid-input': registrationNumber.invalid, 'valid-input': registrationNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber" class="paragraph"
                    [(ngModel)]="form.telephoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Alternative Telephone
                    Number:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtAlternativeTelephoneNumber" name="alternativeTelephoneNumber"
                    class="paragraph not-required" [(ngModel)]="form.alternativeTelephoneNumber">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Firm Email:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtEmail" #emailAddress="ngModel" name="emailAddress"
                    class="paragraph not-required" [(ngModel)]="form.email">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                    Email:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                    class="paragraph" #confirmEmail="ngModel" [pattern]="emailPattern"
                    [(ngModel)]="form.confirmEmail"
                    [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                'confirm-valid': emailAddress.value == confirmEmail.value}">
                    *Confirm Email does not match Email Address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Alternative Email:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAlternativeEmail" name="alternativeEmail"
                    class="paragraph" [(ngModel)]="form.alternativeEmail" required #alternativeEmail="ngModel"
                    [pattern]="emailPattern" [ngClass]="{'invalid-input': alternativeEmail.invalid, 
                                                'valid-input': alternativeEmail.valid}">
                <small class="form-control-feedback"
                    *ngIf="alternativeEmail.errors && (alternativeEmail.dirty || alternativeEmail.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
    </section>
    <section class="submission-form">
        <div class="heading-5 mt-4">Firm's physical address:</div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                    class="paragraph not-required" [(ngModel)]="form.physicalAddress.unitNumber">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                    class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                    class="paragraph not-required" [(ngModel)]="form.physicalAddress.complexName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                    class="paragraph" [(ngModel)]="form.physicalAddress.streetNumber" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                    class="paragraph" [(ngModel)]="form.physicalAddress.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                    [(ngModel)]="form.physicalAddress.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                    [(ngModel)]="form.physicalAddress.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
    </section>
    <section class="submission-form">
        <div class="row text-center">
            <div class="col-10">
                <button id="btnSaveFinal" class="button heading-4" [disabled]="firmInformationForm.invalid"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>