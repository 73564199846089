<mat-sidenav-container (backdropClick)="close()" [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <div *ngIf="showButtons == true">
            <section class="rotate-buttons" [hidden]="isShow">
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 0}"
                    (click)="openSideNav(0, true)">
                    LEVEL 1
                </button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 1}"
                    (click)="openSideNav(1, true)">
                    LEVEL 2
                </button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 2}"
                    (click)="openSideNav(2, true)">
                    LEVEL 3
                </button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 3}"
                    (click)="openSideNav(3, true)">
                    LEVEL 4
                </button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 4}"
                    (click)="openSideNav(4, true)">
                    LEVEL 5
                </button>
            </section>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel1">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10 heading-4-light py-3 text-center">
                        Property Matrix offers to:
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()">
                        </button>
                    </div>
                </div>
                <ul class="paragraph light">
                    <li>
                        <strong>Inspect</strong> your property's zoning, Town Planning Scheme, and Surveyor-General
                        diagrams (for boundaries and possible servitudes).
                    </li>
                    <li>
                        <strong>Review</strong> local council's strategic plans like the Integrated Development Plan
                        (IDP) and Spatial Development Framework (SDF), along with development policies.
                    </li>
                    <li>
                        <strong>Interpret</strong> allowable land uses, possible restraints, potential future uses and
                        planning parameters: maximum proposed zoning, density, height, etc.
                    </li>
                    <li>
                        <strong>Provide insights</strong> on development or expansion scope and the need for planning
                        applications.
                    </li>
                    <li>
                        <strong>Compile</strong> a comprehensive planning report (Level 2) with findings,
                        recommendations, and potential property value enhancement strategies.
                    </li>
                </ul>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel2">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10 heading-4-light py-3 text-center">
                        Factors considered for Value Assessment:
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                    </div>
                </div>
                <div class="row paragraph light">
                    <p>
                        Be assured that your property will be objectively assessed and valued in terms of a thorough
                        investigation. The list below is by no means exhaustive.
                    </p>
                    <p>
                        Our value assessment team generally considers the following factors to determine your property's
                        open market value:
                    </p>
                </div>
                <ul class="paragraph light">
                    <li>
                        <b>Property price</b>, including previous purchase prices and dates, as well as the current
                        municipal valuation.
                    </li>
                    <li>
                        <b>Location factors</b> such as macro- and micro-location, accessibility, area developments,
                        nearby properties and amenities.
                    </li>
                    <li>
                        <b>Site particulars</b>, including property size and shape, planning specifications (current and
                        potential zoning, density, height, coverage, FAR, building lines), and servitudes.
                    </li>
                    <li>
                        <b>Property improvements</b> like building type, quality, size, grade, age, parking, and
                        security.
                    </li>
                    <li>
                        <b>Building tenancy</b>, whether owner-occupied or tenanted, tenant details, lease terms,
                        renewals or tenant defaults.
                    </li>
                    <li>
                        <b>Overall market conditions</b> encompassing macro-economic factors, supply and demand
                        dynamics, and if relevant, area rental rates and vacancy trends.
                    </li>
                    <li>
                        <b>Comparative Market Analysis</b> (if required), featuring nearby comparable sales or sales in
                        similar investor nodes, similar rentals, and property saleability.
                    </li>
                </ul>
                <div class="row">
                    <p class="paragraph light">
                        If your property's land use is uncommon or specialized in nature, you might be requested to
                        provide us with a formal valuation.
                    </p>
                </div>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel3">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10 heading-4-light py-3 text-center">
                        Deeds Reports
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                    </div>
                </div>
                <div class="row paragraph light">
                    <p class="text-center">
                        The Property Matrix legal team will order and examine your property's title deed and draft a
                        basic deeds report. This will empower potential buyers to make an informed decision about
                        purchasing your property as it mitigates potential risks. Conducting a title deed search upfront
                        promotes faster and transparent property sales.
                    </p>
                </div>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel4">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10 heading-4-light py-3 text-center">
                        Advertisement on our Website
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                    </div>
                </div>
                <div class="row paragraph light">
                    <p class="text-center">
                        Property Matrix stands apart from other websites advertising property. Our property adverts are
                        bolstered by professional planning reports, supporting documents, and relevant specialized
                        reports and maps where applicable. We offer expert advice and showcase your property's
                        potential. To view examples of how we present properties to potential buyers, explore listings
                        in the"Property for Sale" section on our website.
                    </p>
                </div>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel5">
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10 heading-4-light py-3 text-center">
                        Trace targeted buyers
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                    </div>
                </div>
                <div class="row paragraph light">
                    <p class="text-center">
                        The Property Matrix database is designed to link the supply (your property) with the demand:
                        buyers who indicated their precise property needs via our extensive and unique property 'menu'.
                    </p>
                    <p class="text-center">
                        Once we have done our investigation and identified the potential of your property, the
                        Matrix-'matching' can be done instantly. We are fully geared to facilitate faster and
                        transparent property sales.
                    </p>
                </div>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel6">
                <div class="row">
                    <div class="col-11 heading-4-light py-3">
                        Required if advertising joint properties as one development opportunity:
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                    </div>
                </div>
                <div class="row paragraph light">
                    <div class="col-12">
                        <ul class="montserrat-bullets">
                            <li>
                                A map or aerial photo, indicating all the properties involved and the exact boundaries
                            </li>
                            <li>
                                A Joint Sales Agreement, setting out the rules and general structure that your group
                                will adhere to. The Property Matrix legal team can assist you in this matter
                            </li>
                            <li>
                                A resolution, signed by all owners, nominating a single representative (co-seller) who
                                will liaise with Property Matrix.
                            </li>
                            <li>
                                A resolution, signed by all owners, nominating a single conveyancer experienced in
                                acting for groups. Our legal team specializes in these transactions.
                            </li>
                            <li>
                                A resolution, signed by all owners, nominating a single commercial broker/estate agent,
                                registered with Property Matrix, who will act on behalf of all other commercial
                                broker/estate agents involved
                            </li>
                            <li>
                                A Commission Split Agreement, drafted by the nominated agent, signed by all agents,
                                owners, and the nominated conveyancer
                            </li>
                        </ul>
                    </div>
                    <div class="row paragraph light">
                        <div class="col-12 heading-5-light">
                            Please Note:
                        </div>
                        <div class="col-12">
                            <p>
                                Each owner/seller involved will sign the sole and exclusive mandate contract, indicating
                                the collective asking price as agreed upon. It is required that the Joint Sales
                                Agreement (as an annexure to the contract), indicates the percentage split of the
                                selling price between all parties once an offer is received. Property Matrix can assist
                                in this matter: we have a national panel of SACPVP-registered valuers in our network and
                                can refer a professional valuer who will objectively assess the open market value of
                                each property involved
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row text-center p-3">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showButtons == false" class="container-side sidenav-container container"
            [ngClass]="{'container-overflow' : addOverflow}" fxLayout="column" fxLayoutAlign="space-between start">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Formal Valuation Reports
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white" (click)="close()"></button>
                </div>
            </div>
            <div class="row">
                <p class="col-12 heading-5-light">
                    <span class="coral-text">Please note:</span>
                    Our national panel of selected Professional Valuers - all registered with the South African Council
                    for the Property Valuers Profession (“SACPVP”) - will provide this service at a competitive rate. Be
                    assured that in terms of the Professional Valuers code of conduct, all valuations will be performed
                    in an independent and objective manner.
                </p>
                <br />
                <div class="col-12 heading-5-light">
                    Description of a Formal Valuation Report:
                </div>
                <div class="col-12">
                    <div class="paragraph light">
                        This is a detailed report and will also include a site visit by the property valuer. The report
                        will contain full details of market research and all relevant information and will be a more
                        accurate determination of the market value of the property. This type of report can also be used
                        for negotiation purposes, tax and audit purposes, financing purposes, etc. The report also
                        provides a replacement value of the property improvements for insurance purposes.
                    </div>
                </div>
                <br />
            </div>
            <div class="row">
                <div class="col-12 paragraph light">
                    <p>
                        There are various methods commonly used for determining the market value of real estate. These
                        methods of valuation comprise:
                    </p>
                    <ul class="montserrat-bullets">
                        <li>Direct comparable sales approach</li>
                        <li>Cost approach</li>
                        <li>Income approach</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 paragraph light">
                    <p>
                        Every property is unique and will be valued according to the type of property and improvements
                        there-on. Our valuation reports generally incorporate the following phases:
                    </p>
                    <ul class="montserrat-bullets">
                        <li>Phase 1: Data collection</li>
                        <li>Phase 2: Site inspection</li>
                        <li>Phase 3: Market research</li>
                        <li>Phase 4: Analysis and calculations</li>
                        <li>Phase 5: Report writing and conclusion</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    DELIVERABLES AND TIME-FRAMES
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph light">
                        <li>15 to 20-page report in PDF format</li>
                        <li>Includes site visit</li>
                        <li>Provides full detail of all market research and calculations</li>
                        <li>10 to 12 working days for delivery</li>
                        <li>
                            <strong>Please note:</strong> This type of report will provide all the relevant information
                            required for a financing application however most financial institutions / banks have their
                            own templates and requirements. We will be able to transfer the information to the relevant
                            template, however this will incur a small additional fee and the instruction will have to be
                            issued through the specific bank.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    FEE STRUCTURE (estimates only)
                </div>
                <div class="col-12">
                    <p class="paragraph light">
                        All fees as indicated in the table below exclude VAT. <strong>Important:</strong> This is only a
                        general indication of fees; each request for a valuation will be assessed individually and a
                        property specific quotation will be provided.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="fee-schedule-table">
                        <table id="fee-schedule-table">
                            <thead>
                                <tr class="heading-3-light text-center">
                                    <th colspan="2">FEE SCHEDULE: FORMAL VALUATION REPORT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="paragraph table-row-color">
                                    <td colspan="2">
                                        <span>
                                            <strong>
                                                Vacant land
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Vacant land (single zoning)
                                        </span>
                                    </td>
                                    <td class="text-center">R4 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Vacant land with township rights
                                        </span>
                                    </td>
                                    <td class="text-center">Depends on size of development</td>
                                </tr>
                                <tr class="paragraph table-row-color">
                                    <td colspan="2">
                                        <span>
                                            <strong>
                                                Land with built improvements
                                                (residential/industrial/offices/flats/retail/other)
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Single land use; value less than R5-million</span></td>
                                    <td class="text-center">R3 500 to R5 000</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Single land use; value between R5-million and R20-million</span></td>
                                    <td class="text-center">R5 500 to R8 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Single land use; value between R20-million and R50-million</span></td>
                                    <td class="text-center">R9 500 to R12 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Single land use; value above R50-million</span></td>
                                    <td class="text-center">R17 500 +</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Multiple land uses; value less than R5-million</span></td>
                                    <td class="text-center">R5 000 to R6 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Multiple land uses; value between R5-million and R20-million</span></td>
                                    <td class="text-center">R7 500 to R11 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Multiple land uses; value between R20-million and R50-million</span></td>
                                    <td class="text-center"><span>R11 500 to R15 500</span></td>
                                </tr>
                                <tr class="paragraph light">
                                    <td><span>Multiple land uses; value above R50-million</span></td>
                                    <td class="text-center"><span>R22 500 +</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <p class="paragraph light">
                        <span class="heading-5-light">OBTAIN QUOTE:</span> A quotation for a formal valuation of your
                        property can be requested under “Additional Services”; “Property Valuation” (level 2 to be
                        selected).
                    </p>
                </div>
            </div>
            <div class="row text-center p-3">
                <div class="col-12">
                    <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <main class="background-white">
        <div class="container-fluid" *ngIf="page == 1">
            <section class="content">
                <div class="content-padding">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h1 class="heading-1 text-center">
                                    SELLING MY PROPERTY
                                </h1>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-4 heading-4 text-center pb-2 mb-3 bottom-border">
                                        OUR OFFER INCLUDES:
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                            <div class="row mt-3 offer-margin-bottom">
                                <div class="col-3 column-width numbers-right-border" *ngFor="let office of officeArray">
                                    <div class="paragraph">
                                        <div class="numbers center-numbers">{{ office.number }}</div>
                                        <div class="heading-5">{{ office.header }}</div>
                                        <br />
                                        <p class="paragraph">
                                            {{ office.description }}
                                        </p>
                                    </div>
                                    <div class="offer-button">
                                        <div class="offer-margin-bottom paragraph">
                                            <div class="offer-margin-bottom paragraph free-of-charge-text">
                                                <strong>
                                                    Free of charge
                                                </strong>
                                            </div>
                                            <strong>{{ office.worthAmount }}</strong>
                                        </div>
                                        <br>
                                        <div class="offer-margin-bottom">
                                            <button class="small-button learn-more-button"
                                                (click)="openSideNav(office.id, true)">
                                                LEARN MORE
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="paragraph small">
                                        * Not applicable for properties that cannot be developed, extended, or improved
                                        (e.g., a single sectional title residential unit)
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="paragraph small">
                                        ** The report can be completed for a single property or for joint properties
                                        adjacent to your property, forming one development opportunity. Click
                                        <button class="text-button" [ngClass]="{'open': open, 'active': tabIndex === 5}"
                                            (click)="openSideOtherNav(5, true)">
                                            <span class="paragraph small here">here</span>
                                        </button>
                                        to view T&Cs for a multiple property report
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="paragraph small">
                                        *** Not applicable to all land-use typologies (e.g. properties with specialized
                                        or uncommon land uses).
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center mt-3">
                                <div class="col-12 text-center">
                                    <button class="button paragraph uppercase" id="continue" (click)="continue(2)">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="page == 2">
            <section class="other-content">
                <div class="content-padding">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                WHAT'S EXPECTED FROM YOU AS A SELLER?
                            </h1>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <h5 class="heading-5 text-center">
                                All the professional services listed - worth between R15k and R20k - are FREE of charge
                                when you partner with us.
                            </h5>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-12">
                            <div class="row">
                                <div class="col"></div>
                                <h4 class="col-6 heading-4 text-center pb-2 mt-1 bottom-border">
                                    To partner with Property Matrix, we will require:
                                </h4>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <div @fade class="side-image your-time-image"></div>
                        </div>
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR TIME
                            </h2>
                            <p class="paragraph">
                                The Property Matrix team invests significant time, experience, and professional
                                expertise in the investigation of your property without charge. We will require a period
                                in which our network can complete the investigation and market your property
                                exclusively. The opportunity cannot be shared with other agents/agencies, who do not
                                form part of our network.
                            </p>
                            <p class="paragraph">
                                To ensure the most competent sale, an exclusive mandate is essential for Property Matrix
                                to thoroughly market your property to the right buyer. A period of 10 months is required
                                to fulfil efficient investigation and marketing, and to validate our investment.
                            </p>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR TRUST
                            </h2>
                            <p class="paragraph">
                                To expedite the sale of your property, it needs to reach the target market with a
                                realistic selling price. This will require an objective assessment, determining the
                                worth (open market value) of your property.
                            </p>
                            <p class="paragraph">
                                To facilitate this process and provide you with peace of mind, Property Matrix
                                established a value assessment team, assisted by our development economist and our
                                national network of professional valuers, to expertly oversee this process.
                            </p>
                            <p class="paragraph">
                                We ask for your trust in our professional team when they propose an appropriate, market
                                related asking price for your property.
                            </p>
                        </div>
                        <div class="col-6">
                            <div @fade class="side-image your-trust-image"></div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <div @fade class="side-image your-collaboration-image"></div>
                        </div>
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR COLLABORATION
                            </h2>
                            <p class="paragraph">
                                Property Matrix is unique in the sense that our commercial brokers/estate agents are
                                trained and equipped to acquire specialized information on your property, ensuring that
                                we market it to its full potential. There will always be a broker/agent involved and
                                commission payable if you partner with us to sell your property. The sole income we
                                generate in return for our risk based professional input is the commission payable once
                                your property is sold.
                            </p>
                            <div class="paragraph">
                                <strong>We are confident that you will:</strong>
                            </div>
                            <ul class="montserrat-bullets paragraph">
                                <li>
                                    Honour our common structure.
                                </li>
                                <li>
                                    Accredit the broker or agent who introduced you to Property Matrix.
                                </li>
                                <li>
                                    Provide our agents with all the information required (professional reports, maps,
                                    building plans, tenant rolls, etc.).
                                </li>
                            </ul>
                        </div>
                    </div>
                    <br />
                    <div class="row mt-5">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                ROAD AHEAD
                            </h1>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12">
                            <div class="row">
                                <div class="col"></div>
                                <h5 class="col-6 heading-4 text-center pb-2 mt-1 bottom-border">
                                    We are excited to launch your journey as outlined below:
                                </h5>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <table>
                        <tr>
                            <td class="step-1">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 1*</h2>
                                        <p class="paragraph">
                                            You provide us with your personal/legal entity details, property
                                            information, preferred selling price, and relevant documents.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 2</h2>
                                        <p class="paragraph">
                                            We verify the information received and launch an initial planning
                                            investigation on your property.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-3">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 3</h2>
                                        <p class="paragraph">
                                            One of our partnering estate agents contact you to arrange a site visit.
                                            Your “own agent” can also register with Property Matrix if he/she meets our
                                            criteria.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-4">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 4</h2>
                                        <p class="paragraph">
                                            A proper investigation to determine whether your asking price is market
                                            related. Click
                                            <button class="text-button" (click)="openValueAssessmentFactorsDialog()">
                                                <span class="paragraph here">here</span>
                                            </button>
                                            for details.
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="step-8">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 8***</h2>
                                        <p class="paragraph">
                                            The content of our investigation - both the planning report and the
                                            prospective advertisement - will be forwarded to you for your approval.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-7">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 7</h2>
                                        <p class="paragraph">
                                            Once signed: The planning investigation and the report are finalized, and
                                            the property advertisement is designed. The title deed is ordered and a
                                            deeds report is drafted by our legal team.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 6</h2>
                                        <p class="paragraph">
                                            Once a market-related selling price has been agreed on, our office provides
                                            you with an exclusive mandate contract for your perusal and signature.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-5">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 5**</h2>
                                        <p class="paragraph">
                                            If your asking price and our proposed selling price differ by more than 20%,
                                            you have the opportunity to provide us with a formal valuation report to
                                            demonstrate that the asking price is correct.
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="step-9">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 9</h2>
                                        <p class="paragraph">
                                            If in agreement, you will sign off on the information and the proposed
                                            advert. Please bear in mind: we focus on the full potential of your
                                            property, but all property information will be disclosed.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="step-10">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="heading-2 coral-text">STEP 10</h2>
                                        <p class="paragraph">
                                            Your property is advertised on our website and all interested buyers with
                                            the propensity to buy into what your property offers, will receive direct
                                            notifications (of your property being in the market) and free access to the
                                            planning report.
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="row">
                        <div class="col-12">
                            <div class="paragraph small">
                                <span>
                                    * Information provided will enable Property Matrix to generate an exclusive mandate
                                    for your signature.
                                </span>
                                <br />
                                <span>
                                    ** The valuation report needs to be completed by an independent, professional
                                    property valuer, registered with the South African Council for the Property Valuers
                                    Profession (“SACPVP”), at your personal expense. We can offer this service at
                                    reasonable rates. Click
                                    <a (click)="openFormalValuationReportDialog()" class="here">here</a>
                                    for our fee schedule.
                                </span>
                                <br />
                                <span>
                                    *** If a market-related selling price could not be agreed upon, your property will
                                    unfortunately not be advertised on our website. We will, however, provide you with
                                    the opportunity to buy the property report (R9k). You will not be obligated to buy
                                    any of our reports. Property Matrix bears the risk in doing this work.
                                </span>
                            </div>
                            <br>
                            <div
                                class="text-center formal-valuation-report-padding formal-valuation-report-padding-bottom">
                                <strong class="paragraph small">
                                    If you are ready to partner with us, continue to kick off with <u>Step 1</u>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center mt-3">
                        <div class="col-12 text-center">
                            <button class="button paragraph uppercase" id="continue" (click)="continue(3)">
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="page == 3">
            <section class="other-content">
                <div class="content-padding">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h1 class="heading-1 text-center">
                                    PROPERTY AND OWNER INFORMATION
                                </h1>
                            </div>
                            <div class="col-12">
                                <p class="paragraph text-center">
                                    Thank you for choosing <strong>Property Matrix</strong> to contribute to your
                                    journey. Your assistance to provide us with reliable information will ensure a
                                    speedy kick-off.
                                </p>
                            </div>
                            <div class="col-12">
                                <p class="paragraph text-center">
                                    <strong>
                                        It will take about 15-20 minutes to complete this form within 4 easy steps:
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-1"></div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                1
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Ownership
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                2
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Property
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                3
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Contract
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="heading-3">
                                            STEP
                                        </div>
                                        <div class="numbers">
                                            4
                                        </div>
                                        <div class="heading-5 mt-2">
                                            Upload
                                            <br />
                                            documents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-1"></div>
                            <div class="col-10">
                                <p class="paragraph">
                                    <strong>
                                        For step 4, please have the following ready in PDF format:
                                    </strong>
                                </p>
                                <ul class="montserrat-bullets paragraph">
                                    <li>
                                        If the owner(s)/representative is/are residents of RSA, clear copy of identity
                                        document (ID)
                                    </li>
                                    <li>
                                        If the owner(s)/representative is/are not residents of RSA, clear copy of
                                        passport
                                    </li>
                                    <li>
                                        If the owner(s) is/are married in community, a copy of spouse's ID/passport
                                    </li>
                                    <li>
                                        If the property is registered in the name of a juristic person (company/cc/NPO):
                                        registration certificate, or trust: letter of authority
                                    </li>
                                    <li>
                                        If juristic person (entity), a copy of a resolution wherein you are authorized
                                        by the applicable persons to act on behalf of such juristic person.
                                    </li>
                                    <li>
                                        If the property forms part of a development opportunity, to be advertised with
                                        adjacent properties, a map (or image), indicating all properties and boundaries.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col-12 text-center">
                                <button class="button paragraph uppercase" (click)="continueToForms()">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</mat-sidenav-container>