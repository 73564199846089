<main class="main-container">
    <div class="content-container">
        <div class="content paragraph">
            <span class="title">COLLABORATION AGREEMENT</span>
            <div>
                <p>
                    Before proceeding with the registration process, it's crucial to understand what you're committing
                    to. After successful registration you will receive a formal contract outlining the terms.
                </p>
                <p>
                    Here's a summary of the <b>Property Matrix</b> model:
                </p>
            </div>
            <div class="summary-list">
                <ul>
                    <li>
                        Upon registration, you will receive log-in details to the Property Matrix platform and a
                        service-level agreement contract for your signature.
                    </li>
                    <li>
                        Once endorsed, you will receive access to our complimentary training courses.
                    </li>
                    <li>
                        Registration is open to principals and full estate agents with at least two years of experience
                        in the specified metropolitan areas. If you are an intern with relevant qualifications, you can
                        register, and we will assess your qualifications and experience.
                    </li>
                    <li>
                        There are no registration costs, planning report drafting fees, or charges for placing an
                        advertisement on our website.
                    </li>
                    <li>
                        Property owners (Sellers) also incur no costs, as Property Matrix invests substantial time and
                        professional expertise in investigating and marketing their property at our expense.
                    </li>
                    <li>
                        We offer these services on a risk-sharing basis to facilitate exclusive mandates.
                    </li>
                    <li>
                        As compensation for our initial professional inputs and marketing on a risk basis, Property
                        Matrix will charge a professional fee, payable only upon the successful sale of a property.
                    </li>
                    <li>
                        The professional fee to Property Matrix will be a percentage of the total commission amount paid
                        by the Seller.
                    </li>
                </ul>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Selling Price</th>
                        <th>Total Commission*</th>
                        <th>Professional Fee**</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>R4 000 001 - R10m</td>
                        <td>6,5%</td>
                        <td>19%</td>
                    </tr>
                    <tr>
                        <td>R10 000 001 - R20m</td>
                        <td>6%</td>
                        <td>18%</td>
                    </tr>
                    <tr>
                        <td>R20 000 001 - R40m</td>
                        <td>5,5%</td>
                        <td>17%</td>
                    </tr>
                    <tr>
                        <td>R40 000 001 - R70m</td>
                        <td>5%</td>
                        <td>16%</td>
                    </tr>
                    <tr>
                        <td>R70 000 001 plus</td>
                        <td>4%</td>
                        <td>15%</td>
                    </tr>
                </tbody>
            </table>
            <div class="table-notes">
                <ul>
                    <li>
                        *Calculated as a percentage (as indicated) of the total Selling Price
                    </li>
                    <li>
                        **Calculated as a percentage (as indicated) of the total Commission Amount
                    </li>
                </ul>
            </div>
            <div class="register-list">
                <ul>
                    <li>
                        Agents registered with Property Matrix who complete value assessments and successful sales
                        through our platform earn commissions, calculated as the total commission amount minus the
                        professional fee.
                    </li>
                    <li>
                        To maintain professionalism and prevent conflicts within our network and with our clients,
                        commissions and professional fees are non-negotiable. Sellers will also endorse the commission
                        structure when signing the dual exclusive mandate.
                    </li>
                    <li>
                        When listing a property with us, you'll receive a questionnaire as part of our value assessment
                        process. It's essential to visit the property, provide feedback on improvements, fixtures, and
                        tenant details, and conduct a comparative market analysis (if required) to propose a
                        market-related asking price.
                    </li>
                    <li>
                        Your insights into the property, its amenities, tenant contracts, and market trends in the area
                        are integral to our model. Detailed guidance will be provided.
                    </li>
                    <li>
                        If a property is listed directly by a Seller, we'll assign an agent from our network for the
                        value assessment, and the same commission structure applies!
                    </li>
                    <li>
                        Once your inputs and professional advice are received, we can determine and negotiate a
                        realistic asking price with the Seller.
                    </li>
                    <li>
                        Drafting of the exclusive mandate contract begins upon agreeing on the asking price.
                    </li>
                    <li>
                        The planning report is finalized when all parties sign the exclusive mandate.
                    </li>
                    <li>
                        The property is advertised on our website, and agent-related queries are directed to you with
                        email notifications.
                    </li>
                    <li>
                        Our specialized conveyancers - if nominated by the Seller - provide legal advice, guide any
                        conditions that need to be drafted, ensure effective communication in drafting an OTP, and
                        monitor the transfer process.
                    </li>
                    <li>
                        The commission, after deducting the professional fee, will be paid out by the transferring
                        attorneys once the property is registered at the offices of the Registrar of Deeds.
                    </li>
                </ul>
            </div>
            <div class="join-our-network">
                <p class="bold">Join our network:</p>
                <p>
                    Registration takes approximately 15-20 minutes, offering you access to new opportunities in the
                    property industry.
                </p>
            </div>
            <div class="button-container">
                <button mat-button (click)="proceed()" class="button-proceed heading-4">PROCEED</button>
            </div>
        </div>
    </div>
</main>