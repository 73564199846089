import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

import { ComingSoonDialogComponent } from '../../shared/components/coming-soon-dialog/coming-soon-dialog.component';

const PROPERTY_VALUATION_REQUEST_PATH = '/additional-services/property-valuation/property-valuation-request/';

@Component({
  selector: 'app-property-valuations',
  templateUrl: './property-valuations.component.html',
  styleUrls: ['./property-valuations.component.scss']
})
export class PropertyValuationsComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  isMenuOpen = false;
  addOverflow = false;
  showLevel1 = false;
  showLevel2 = false;
  isShow = true;
  open = false;
  tabIndex = -1;

  propertyValuationRequestPath: string = PROPERTY_VALUATION_REQUEST_PATH;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.openDialog();
  }

  toggleColorButton1(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorButton2(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openDialog(): void {
    this.dialog.open(ComingSoonDialogComponent, {
      width: '35vw',
      height: '35vh'
    });
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
  }
}
