import { AddressOfCompanyOffice } from 'src/app/models/registrationForms/address-of-company-office';
import { AdministrativeQueries } from 'src/app/models/registrationForms/administrative-queries';
import { PersonalCompanyInfo } from 'src/app/models/registrationForms/personal-company-info';
import { TownPlanningQueries } from 'src/app/models/registrationForms/town-planning-queries';
import { SpecificEmployee } from 'src/app/models/registrationForms/specific-employee';
import { EmployeeOther } from 'src/app/models/registrationForms/employee-other';
import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { GenericValidator } from 'src/app/services/generic-validator';
import { Validators } from 'src/app/models/validation/validators';
import { FormControlName, FormGroup } from '@angular/forms';
import { FileUploader } from 'src/app/models/file-uploader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conveyancer-personal-info',
  templateUrl: './conveyancer-personal-info.component.html',
  styleUrls: ['./conveyancer-personal-info.component.css']
})
export class ConveyancerPersonalInfoComponent implements OnInit {

  personalCompanyInfoModel = new PersonalCompanyInfo();
  addressOfCompanyOfficeModel = new AddressOfCompanyOffice();
  townPlanningQueriesModel = new TownPlanningQueries();
  administrativeQueriesModel = new AdministrativeQueries();
  civilEngineerModel = new SpecificEmployee();
  electricalEngineerModel = new SpecificEmployee();
  environmentalSpecialistModel = new SpecificEmployee();
  geotechnicalEngineerModel = new SpecificEmployee();
  landSurveyorModel = new SpecificEmployee();
  quantitySurveyorModel = new SpecificEmployee();
  TrafficEngineerModel = new SpecificEmployee();
  propertyValuerModel = new SpecificEmployee();
  architectModel = new SpecificEmployee();
  architecturalModel = new SpecificEmployee();
  fileUploader = new FileUploader();
  validators = new Validators();

  // Access every form input fields in our login html file
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  submitDocument: FormGroup;

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  additionalInfo1: boolean = true;
  additionalInfo2: boolean = false;
  additionalInfo3: boolean = false;
  additionalInfo4: boolean = false;
  additionalInfo5: boolean = false;

  // below variables will be used to switch between different tabs mentioned above the page
  stepOneExpand: boolean = true;
  stepTwoExpand: boolean = false;
  stepThreeExpand: boolean = false;
  stepFourExpand: boolean = false;

  collapseStep1: string = 'collapse show';
  collapseStep2: string = 'collapse';
  collapseStep3: string = 'collapse';
  collapseStep4: string = 'collapse';

  mainContainer: string = 'container-fluid container-style';

  // 'progress' will be used to identify the current active step
  progress: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void { }

  step1() {
    this.progress = '0';
    this.stepTwoExpand = false;
    this.stepThreeExpand = false;
    this.stepFourExpand = false;
    this.collapseStep1 = 'collapse show';
    this.collapseStep2 = 'collapse';
    this.collapseStep3 = 'collapse';
    this.collapseStep4 = 'collapse';
    this.mainContainer = 'container-fluid container-style'
  };

  step2() {
    this.progress = '25';
    this.stepTwoExpand = true;
    this.stepThreeExpand = false;
    this.stepFourExpand = false;
    this.collapseStep1 = 'collapse';
    this.collapseStep2 = 'collapse show';
    this.collapseStep3 = 'collapse';
    this.collapseStep4 = 'collapse';
    this.mainContainer = 'container-fluid container-style'
  };

  step3() {
    this.progress = '50';
    this.stepThreeExpand = true;
    this.stepTwoExpand = true;
    this.stepFourExpand = false;
    this.collapseStep1 = 'collapse';
    this.collapseStep2 = 'collapse';
    this.collapseStep3 = 'collapse show';
    this.collapseStep4 = 'collapse';
    this.mainContainer = 'container-fluid container-style'
  };

  step4() {
    this.progress = '100';
    this.stepTwoExpand = true;
    this.stepTwoExpand = true;
    this.stepThreeExpand = true;
    this.stepFourExpand = true;
    this.collapseStep1 = 'collapse';
    this.collapseStep2 = 'collapse';
    this.collapseStep3 = 'collapse';
    this.collapseStep4 = 'collapse show';
    this.mainContainer = 'container-fluid container-style background-img'
  };

  ExpandAdditionalDetails1() {
    this.additionalInfo1 = !this.additionalInfo1
  }

  ExpandAdditionalDetails2() {
    this.additionalInfo2 = !this.additionalInfo2
  }

  ExpandAdditionalDetails3() {
    this.additionalInfo3 = !this.additionalInfo3
  }

  ExpandAdditionalDetails4() {
    this.additionalInfo4 = !this.additionalInfo4
  }

  ExpandAdditionalDetails5() {
    this.additionalInfo5 = !this.additionalInfo5
  }

  submitForm() {
    this.router.navigateByUrl('');
  }
}
