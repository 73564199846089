import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formal-valuation-report-dialog',
  templateUrl: './formal-valuation-report-dialog.component.html',
  styleUrls: ['./formal-valuation-report-dialog.component.scss']
})
export class FormalValuationReportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FormalValuationReportDialogComponent>) { }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
