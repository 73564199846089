<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let servitude of servitudeData; let i = index">
        <button *ngIf="i > 0" (click)="removeServitude(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Servitude {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <input class="col-12 mt-1" type="text" placeholder="2m Storm Water Servitude: Eastern Boundary"
                            [(ngModel)]="servitude.description" (ngModelChange)="emitChanges()">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addServitude()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Add servitude</span>
        </button>
    </div>
</div>