import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserDto } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private _urbanPlannerData$ = new BehaviorSubject<UserDto>({});
  userData = this._urbanPlannerData$.asObservable();

  constructor() { }

  setUserEnrollmentData(val: UserDto) {
    this._urbanPlannerData$.next(val);
  }
}
