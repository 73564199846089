<section class="submission-form">
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Profession:</label>
        </div>
        <div class="col-7">
            <input type="text" name="profession" class="paragraph not-required"
                [(ngModel)]="data.data.profession">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Name:</label>
        </div>
        <div class="col-7">
            <input type="text" class="paragraph not-required" name="name"
                [(ngModel)]="data.data.personalDetails.name" #name="ngModel">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Surname:</label>
        </div>
        <div class="col-7">
            <input type="text" class="paragraph not-required" name="surname"
                [(ngModel)]="data.data.personalDetails.surname" #surname="ngModel">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Company name:</label>
        </div>
        <div class="col-7">
            <input type="text" class="paragraph not-required" name="companyName"
                [(ngModel)]="data.data.personalDetails.companyName" #companyName="ngModel">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Email:</label>
        </div>
        <div class="col-7">
            <input type="text" name="emailAddress" class="paragraph not-required"
                [(ngModel)]="data.data.personalDetails.emailAddress">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Mobile:</label>
        </div>
        <div class="col-7">
            <input type="text" name="phoneNumber" class="paragraph not-required"
                [(ngModel)]="data.data.personalDetails.phoneNumber">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Link to their website:</label>
        </div>
        <div class="col-7">
            <input type="text" name="linkToWebsite" class="paragraph not-required"
                [(ngModel)]="data.data.linkToWebsite">
        </div>
    </div>
</section>
<div class="text-center">
    <button (click)="saveInfo()" class="button">Save</button>
</div>