import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';
import { UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { CheckboxVm } from 'src/app/services/property-matrix/models';
import { AdminService } from 'src/app/services/property-matrix/services';

@Component({
  selector: 'app-admin-my-profile-personal-details-edit-dialog',
  templateUrl: './admin-my-profile-personal-details-edit-dialog.component.html',
  styleUrls: ['./admin-my-profile-personal-details-edit-dialog.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AdminMyProfilePersonalDetailsEditDialogComponent implements OnInit {

  specialisedSectorsCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Residential', checked: false },
    { id: 2, value: 'Commercial', checked: false },
    { id: 3, value: 'Business - retail', checked: false },
    { id: 4, value: 'Business - offices', checked: false },
    { id: 5, value: 'Industrial', checked: false },
    { id: 6, value: 'Other', checked: false },
  ]

  estateAgentStatusOptions: KeyValuePair[] = [
    { key: 1, value: 'Principal' },
    { key: 2, value: 'Full status agent' },
    { key: 3, value: 'Intern' }
  ];

  highestQualificationsOptions: KeyValuePair[] = [
    { key: 1, value: 'Matric (National Certificate)' },
    { key: 2, value: 'Higher certificate' },
    { key: 3, value: 'Diploma & advanced certificate' },
    { key: 4, value: 'Bachelor\'s degree & advanced diploma' },
    { key: 5, value: 'Honours degree & postgraduate diploma' },
    { key: 6, value: 'Master\'s degree' },
    { key: 7, value: 'Doctoral degree' },
  ];

  financialInstitutionsPanelCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'ABSA Bank', checked: false },
    { id: 2, value: 'Capitec Bank', checked: false },
    { id: 3, value: 'First Rand Bank (FNB)', checked: false },
    { id: 4, value: 'Investec', checked: false },
    { id: 5, value: 'Mercantile Bank', checked: false },
    { id: 6, value: 'Nedbank', checked: false },
    { id: 7, value: 'Standard Bank', checked: false },
    { id: 8, value: 'Other', checked: false },
  ];

  propertyExperienceTypeCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Vacant land', checked: false },
    { id: 2, value: 'Single residential', checked: false },
    { id: 3, value: 'Group housing complexes/ Blocks of flats', checked: false },
    { id: 4, value: 'Commercial – Offices ', checked: false },
    { id: 5, value: 'Commercial – Retail', checked: false },
    { id: 6, value: 'Commercial – Industrial', checked: false },
    { id: 7, value: 'Specialised – Guest houses/ hotels/ lodges/ other hospitality facilities', checked: false },
    { id: 8, value: 'Specialised – Schools', checked: false },
    { id: 9, value: 'Specialised – Churches', checked: false },
    { id: 10, value: 'Specialised - Mining', checked: false },
    { id: 11, value: 'Agricultural farms', checked: false },
    { id: 12, value: 'Other', checked: false },
  ]

  sacplanRegistrationTypes: KeyValuePair[] = [
    { key: 1, value: 'Professional planner' },
    { key: 2, value: 'Technical planner' },
    { key: 3, value: 'Candidate planner' },
  ]

  valuerTypeRadioOptions: KeyValuePair[] = [
    {key: 1, value: 'Professional valuer'},
    {key: 2, value: 'Professional associated valuer'},
    {key: 3, value: 'Candidate valuer'},
  ]

  uneditedData: any = {};
  userRole: string;
  userRoles = UserRoles;

  constructor(public dialogRef: MatDialogRef<AdminMyProfilePersonalDetailsEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminEditDialogModel, private adminService: AdminService, private sharedService: PropertyMatrixSharedService) { }

  ngOnInit(): void {
    this.getUserRole();
    this.shallowCopyUneditedData();
    this.assignCheckboxData();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  assignCheckboxData() {
    if (this.data.editType == 2) {
      if (this.userRole == UserRoles.estateAgent) {
        for (let index = 0; index < this.specialisedSectorsCheckBoxOptions.length; index++) {
          let element = this.specialisedSectorsCheckBoxOptions[index];
          if (this.data.data.value.specialisingPropertySectorIds.includes(element.id)) {
            element.checked = true;
          }
        }
      }
      else if (this.userRole == UserRoles.conveyancer) {
        for (let index = 0; index < this.financialInstitutionsPanelCheckBoxOptions.length; index++) {
          let element = this.financialInstitutionsPanelCheckBoxOptions[index];
          if (this.data.data.value.financialInstitutionForPanelAccredIds.includes(element.id)) {
            element.checked = true;
          }
        }
      }
      else if (this.userRole == UserRoles.valuer) {
        for (let index = 0; index < this.financialInstitutionsPanelCheckBoxOptions.length; index++) {
          let element = this.financialInstitutionsPanelCheckBoxOptions[index];
          if (this.data.data.value.valuationPermittedIds.includes(element.id)) {
            element.checked = true;
          }
        }
        for (let index = 0; index < this.propertyExperienceTypeCheckBoxOptions.length; index++) {
          let element = this.propertyExperienceTypeCheckBoxOptions[index];
          if (this.data.data.value.experienceIds.includes(element.id)) {
            element.checked = true;
          }
        }
      }
    }
  }

  shallowCopyUneditedData() {
    const jsonData = JSON.stringify(this.data);
    this.uneditedData = JSON.parse(jsonData);
  }

  otherCheckboxSelected(option: CheckboxVm, otherKey: number): boolean {
    return option.id == otherKey && option.checked;
  }

  saveInfo() {
    let userName = this.sharedService.getUsername();
    if (this.data.editType == 2) {
      switch (this.userRole) {
        case UserRoles.estateAgent:
          this.data.data.value.specialisingPropertySectorIds = this.specialisedSectorsCheckBoxOptions.filter(f => f.checked == true).map(m => m.id);
          break;
        case UserRoles.conveyancer:
          this.data.data.value.financialInstitutionForPanelAccredIds = this.financialInstitutionsPanelCheckBoxOptions.filter(f => f.checked == true).map(m => m.id);
          break;
          case UserRoles.valuer:
            this.data.data.value.financialInstitutionForPanelAccredIds = this.financialInstitutionsPanelCheckBoxOptions.filter(f => f.checked == true).map(m => m.id);
            this.data.data.value.experienceIds = this.propertyExperienceTypeCheckBoxOptions.filter(f => f.checked == true).map(m => m.id);
      }
    }
    this.adminService.apiV1AdminUpdatePersonalDetailsPost$Response({ username: userName, typeId: this.data.editType, jsonData: JSON.stringify(this.data.data.value) })
      .pipe(map(m => { return m })).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error updating data");
        }
      })
  }

  closeDialogWithNoChanges() {
    this.dialogRef.close(this.uneditedData.data);
  }

  getUserRole() {
    let user = this.sharedService.getUserModel();
    this.userRole = user.userRole;
  }
}