import { NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { ScrollingMethods } from "./scrolling/scrolling-methods";

export class GlobalMethods {

  private static scrolling = new ScrollingMethods();

  public static scrollToId(elementId: string) {
    let element: HTMLElement = document.getElementById(elementId);
    this.scrolling.scrollToHTMLElement(element);
  }

  public static scrollToFirstClass(elementClass: string) {
    let element: Element = document.getElementsByClassName(elementClass)[0];
    this.scrolling.scrollToElement(element);
  }

  public static scrollToFirstName(elementName: string) {
    let element: Element = document.getElementsByClassName(elementName)[0];
    this.scrolling.scrollToElement(element);
  }

  public static scrollToTop() {
    this.scrolling.scrollToTop();
  }

  public static scrollToBottom() {
    this.scrolling.scrollToBottom();
  }

  public static tinyErrorAlert(title: string, message: string): Promise<any> {
    return Swal.fire({
      icon: 'error',
      title: title,
      text: message
    });
  }

  public static tinySuccessAlert(title: string, message: string): Promise<any> {
    return Swal.fire({
      icon: 'success',
      title: title,
      text: message
    });
  }

  public static navigateToLoginWithRouteStored(key: string, url: string, router: Router) {
    sessionStorage.setItem(key, router.url);
    router.navigateByUrl(url);
  }

  public static checkValidation(email: NgModel, confirmEmail: NgModel): boolean {
    return (email.model == confirmEmail.model) ? true : false;
  }

  public static gotoNewTabPage(page: string) {
    window.open(page, "_blank");
  }
}
