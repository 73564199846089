import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { AddressVm } from 'src/app/services/property-matrix/models';
import { AdminService } from 'src/app/services/property-matrix/services';
import { AdminMyProfileBillingEditDialogComponent } from './admin-my-profile-billing-edit-dialog/admin-my-profile-billing-edit-dialog.component';

@Component({
  selector: 'app-admin-my-profile-billing',
  templateUrl: './admin-my-profile-billing.component.html',
  styleUrls: ['./admin-my-profile-billing.component.scss']
})
export class AdminMyProfileBillingComponent implements OnInit {

  orderHistory: any[] = [
    {orderNumber: '35235', date: '2023-01-13', item: 'Report', status: 'Delivered'}
  ];
  orderHistoryColumns: string[] = [
    'orderNumber',
    'date',
    'item',
    'status'
  ]

  billingDetails: any[] = [
    { key: 1, value: null, title: 'Billing Address' },
  ];

  emptyAddress: AddressVm = {
    city: '',
    complexName: '',
    currentLandUse: '',
    postalCode: '',
    streetName: '',
    streetNumber: '',
    unitNumber: '',
    suburb: ''
  }

  constructor(private adminService: AdminService, private sharedService: PropertyMatrixSharedService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getBillingDetails();
  }

  editInfo(typeKey: number) {
    let data = this.billingDetails.find(f => f.key == typeKey);
    const dialogRef = this.dialog.open(AdminMyProfileBillingEditDialogComponent, {
      width: '60vw',
      maxHeight: '80vh',
      data: { editType: typeKey, data: data } as AdminEditDialogModel,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        data.value = result.value
      } else {
        this.getBillingDetails();
      }
    });
  }

  getBillingDetails() {
    let userName = this.sharedService.getUsername();
    this.adminService.apiV1AdminGetBillingDetailsGet$Response({ username: userName })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          let body = JSON.parse(result.body);
          this.billingDetails[0].value = body.billingAddress ?? this.emptyAddress;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }
}
