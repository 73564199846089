import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-agent',
  templateUrl: './estate-agent.component.html',
  styleUrls: ['./estate-agent.component.scss']
})
export class EstateAgentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void { }

  proceed() {
    this.router.navigateByUrl('/estate-agent-agreement');
    GlobalMethods.scrollToTop();
  }
}
