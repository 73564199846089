<div class="row mt-3">
    <div class="col-9"></div>
    <div class="col-3 text-end"><button (click)="addProfessional()" class="button">Add</button></div>
</div>
<div *ngFor="let professional of professionals" class="mt-4">
    <mat-card>
        <mat-card-content>
            <div>
                <div class="row">
                    <div class="col-11">
                        <div class="heading-2">{{professional.profession}}</div>
                    </div>
                    <div class="col-1">
                        <div class="row edit-button-margin my-4">
                            <div class="col-6">
                                <button (click)="editProfessional(professional)"
                                    mat-icon-button><mat-icon>edit</mat-icon></button>
                            </div>
                            <div class="col-6">
                                <button [matMenuTriggerFor]="deleteMenu" color="warn"
                                    mat-icon-button><mat-icon>delete</mat-icon></button>
                                <mat-menu #deleteMenu="matMenu" xPosition="before">
                                    <!-- <div class="">
                                        Do you want to delete this?
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <div ><button class="text-right" mat-stroked-button>Yes</button></div>
                                                <div class="text-right"><button mat-stroked-button>No</button></div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="m-2">
                                    <div>Are you sure you want to delete?</div>
                                    <div class="mt-3 delete-confirm-buttons">
                                        <button (click)="deleteProfessional(professional.id)" mat-button
                                            color="warn">Yes</button>
                                        <button mat-button>No</button>
                                    </div>
                                </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-12"><b>Name:</b> {{professional.personalDetails.name}}</div>
                    <div class="col-12"><b>Surname:</b> {{professional.personalDetails.surname}}</div>
                    <div class="col-12"><b>Company name:</b> {{professional.personalDetails.companyName}}</div>
                    <div class="col-12"><b>Mobile:</b> {{professional.personalDetails.phoneNumber}}</div>
                    <div class="col-12"><b>Email:</b> {{professional.personalDetails.emailAddress}}</div>
                    <div class="col-12"><b>Link to their website:</b>&nbsp;
                        <a target="_blank" href="//{{professional.linkToWebsite}}">{{professional.linkToWebsite}}</a>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>