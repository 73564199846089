import { AddendumThreeDto, RiskscapeDeedsInformationDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-addendum-three',
  templateUrl: './addendum-three.component.html',
  styleUrls: ['./addendum-three.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AddendumThreeComponent implements OnInit, OnChanges {

  @Input() addendumThreeData: AddendumThreeDto;
  @Input() listingId: string;

  @Output() tabNumberEvent = new EventEmitter<number>();

  riskscapeDeedsInformationData: RiskscapeDeedsInformationDto;
  riskscapeDeedsCurrentOwnerInfoData: RiskscapeDeedsInformationDto;
  riskscapeDeedsTransferHistoryData: RiskscapeDeedsInformationDto;
  riskscapeDeedsOwnerHistoryData: RiskscapeDeedsInformationDto;
  riskscapeDeedsEndorsementData: RiskscapeDeedsInformationDto;
  riskscapeDeedsBondData: RiskscapeDeedsInformationDto;
  riskscapeDeedsConsolidationData: RiskscapeDeedsInformationDto;
  riskscapeDeedsSubdivisionData: RiskscapeDeedsInformationDto;

  sections = [
    { id: 1, value: 'Deeds Information', checked: false },
    { id: 2, value: 'Current Owner Information', checked: false },
    { id: 3, value: 'Transfer History', checked: false },
    { id: 4, value: 'Owner History', checked: false },
    { id: 5, value: 'Endorsements', checked: false },
    { id: 6, value: 'Consolidations', checked: false },
    { id: 7, value: 'Subdivisions', checked: false }
  ]

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadAllAddendumThreeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addendumThreeData) {
      this.loadAllAddendumThreeData();
    }
  }

  loadAllAddendumThreeData() {
    this.riskscapeDeedsInformationData = this.addendumThreeData?.riskscapeDeedsInformation || {};
    this.riskscapeDeedsCurrentOwnerInfoData = this.addendumThreeData?.riskscapeDeedsCurrentOwnerInfo || {};
    this.riskscapeDeedsTransferHistoryData = this.addendumThreeData?.riskscapeDeedsTransferHistory || {};
    this.riskscapeDeedsOwnerHistoryData = this.addendumThreeData?.riskscapeDeedsOwnerHistory || {};
    this.riskscapeDeedsEndorsementData = this.addendumThreeData?.riskscapeDeedsEndorsement || {};
    this.riskscapeDeedsBondData = this.addendumThreeData?.riskscapeDeedsBond || {};
    this.riskscapeDeedsConsolidationData = this.addendumThreeData?.riskscapeDeedsConsolidation || {};
    this.riskscapeDeedsSubdivisionData = this.addendumThreeData?.riskscapeDeedsSubdivision || {};
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  goToAddendumTwo() {
    this.tabNumberEvent.emit(3);
  }

  goToExportReport() {
    this.tabNumberEvent.emit(5);
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }
}
