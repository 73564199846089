<mat-card>
    <li class="side-menu-item" *ngFor="let item of sideMenuItems">
        <button class="side-menu-button" [ngClass]="{'selected-menu-item': item.key == sideMenuIndex}"
            (click)="sideMenuChange(item.key)">
            <mat-icon class="link material-icons-outlined icon-circle" [matTooltip]="item.value"
                matTooltipPosition="right" [matTooltipShowDelay]="1000">
                {{item.icon}}
            </mat-icon>
        </button>
    </li>
</mat-card>