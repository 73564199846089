import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiplePropertyListingDto } from '../../../../services/property-matrixV2/models';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit {

  @Input() group: MultiplePropertyListingDto;
  @Input() currentUserIndex: number = 0;

  @Output() groupClicked = new EventEmitter<MultiplePropertyListingDto>();

  selected: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  goToListingGroupOverview(): void {
    this.groupClicked.emit(this.group);
  }
}
