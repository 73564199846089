import { Component, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-property-for-sale-purchase-documents-dialog',
  templateUrl: './property-for-sale-purchase-documents-dialog.component.html',
  styleUrls: ['./property-for-sale-purchase-documents-dialog.component.scss']
})
export class PropertyForSalePurchaseDocumentsDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
