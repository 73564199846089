import { ExistingLandUseRightDto, LandUseDto, SchemeDto, ZoningDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-existing-land-use-right',
  templateUrl: './existing-land-use-right.component.html',
  styleUrls: ['./existing-land-use-right.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExistingLandUseRightComponent implements OnInit {

  @Input() schemeData: SchemeDto[] = [];
  @Input() zoningData: ZoningDto[] = [];
  @Input() usesPermittedOnSiteData: LandUseDto[] = [];
  @Input() approvedAdditionalUseData: LandUseDto[] = [];
  @Input() existingLandUseRightData: ExistingLandUseRightDto;

  @Output() schemeName = new EventEmitter<string>();
  @Output() currentZoning = new EventEmitter<string>();
  @Output() usesPermittedOnSite = new EventEmitter<string[]>();
  @Output() approvedAdditionalUses = new EventEmitter<string[]>();

  selectedSchemeName: string | null = null;
  selectedCurrentZoning: string | null = null;
  selectedUsesPermittedOnSite: string[] = [];
  selectedApprovedAdditionalUses: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.selectedSchemeName = null;
    if (this.existingLandUseRightData?.schemeName != null) {
      this.selectedSchemeName = this.existingLandUseRightData?.schemeName.id ?? null;
    }

    if (this.selectedSchemeName != null) {
      this.schemeName.emit(this.selectedSchemeName);

      for (const use of this.existingLandUseRightData?.additionalRights) {
        this.selectedApprovedAdditionalUses.push(use.id);
      }

      this.selectedCurrentZoning = this.existingLandUseRightData?.currentZoning.id ?? null;

      if (this.selectedCurrentZoning != null) {
        this.currentZoning.emit(this.selectedCurrentZoning);

        for (const use of this.existingLandUseRightData?.usesPermittedOnSite) {
          this.selectedUsesPermittedOnSite.push(use.id);
        }
      }
    }
  }

  onSchemeNameChange(event: MatSelectChange) {
    this.selectedSchemeName = event.value === null ? null : event.value;
    this.schemeName.emit(this.selectedSchemeName ?? "");

    if (this.selectedSchemeName === null) {
      this.resetOtherSelects();
    }
  }

  onCurrentZoningChange(event: MatSelectChange) {
    this.selectedCurrentZoning = event.value === null ? null : event.value;
    this.currentZoning.emit(this.selectedCurrentZoning ?? "");
  }

  onUsesPermittedOnSiteChange(event: MatSelectChange) {
    this.selectedUsesPermittedOnSite = event.value.includes(null) ? [] : event.value.filter((value: null) => value !== null);
    this.usesPermittedOnSite.emit(this.selectedUsesPermittedOnSite);
  }

  onApprovedAdditionalUsesChange(event: MatSelectChange) {
    this.selectedApprovedAdditionalUses = event.value.includes(null) ? [] : event.value.filter((value: null) => value !== null);
    this.approvedAdditionalUses.emit(this.selectedApprovedAdditionalUses);
  }

  private resetOtherSelects() {
    this.selectedCurrentZoning = null;
    this.selectedUsesPermittedOnSite = [];
    this.selectedApprovedAdditionalUses = [];
    this.currentZoning.emit(null);
    this.usesPermittedOnSite.emit([]);
    this.approvedAdditionalUses.emit([]);
  }
}
