import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { OwnershipDetailDto, UploadFileDto } from 'src/app/services/property-matrixV2/models';
import { ReportDialogData } from 'src/app/models/sellingMyProperty/ReportDialogData';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { UploadDocumentDetailsVm } from 'src/app/services/property-matrix/models';
import { ReportFile } from 'src/app/models/sellingMyProperty/ReportFile';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FileUploader } from 'src/app/models/file-uploader';
import { ComponentType } from '@angular/cdk/portal';
import { HttpHeaders } from '@angular/common/http';
import { MatTable } from '@angular/material/table';
import { catchError, of } from 'rxjs';

import { UploadDocumentsExamplesDialogComponent } from './upload-documents-examples-dialog/upload-documents-examples-dialog.component';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class UploadDocumentsComponent {

  @ViewChild(MatTable) table: MatTable<any>;

  @Input() loadUploadDocumentBlocks: boolean;
  @Input() ownershipDetails: OwnershipDetailDto;
  @Input() isMultipleProperty: boolean;
  @Input() isMultipleTenants: boolean;

  @Output() openSideNavEvent = new EventEmitter<number>();
  @Output() uploadDocumentDetailsEvent = new EventEmitter<UploadFileDto[]>();

  constructor(
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private _SellMyPropertyService: SellMyPropertyService
  ) { }

  fileUploader = new FileUploader();
  emailPattern: string = GlobalConstants.emailPattern;

  clicked: boolean = false;
  loading: boolean = false;

  form: UploadDocumentDetailsVm = {
    troubleUploadingCopies: false,
    reports: [],
    paths: [],
  }

  files: UploadFileDto[] = [];
  uploadBlocks: UploadFileDto[] = []
  uploadBlocksAfterAgree: UploadFileDto[] = []
  uploadReports: any[] = []

  ngOnInit(): void {
    this.loadRequiredDocs();
  }

  submitForm() {
    this.uploadBlocks.forEach(fileObj => {
      this.files.push(fileObj);
    });

    this.uploadBlocksAfterAgree.forEach(fileObj => {
      this.files.push(fileObj);
    });

    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)
    this.uploadDocumentDetailsEvent.emit(this.files);
  }

  selectUpload(files: any[], id: any) {
    this.updateShownBlocks(files[0], id);
  }

  dropUpload($event: any[], id: any) {
    this.updateShownBlocks($event[0], id);
  }

  deleteFile(block: any, blockIndex: string | number) {
    this.uploadBlocks[blockIndex].file = null;
  }

  updateShownBlocks(file: Blob, id: number) {
    this.uploadBlocks[id - 1].file = file;
  }

  selectUploadSecond(files: any[], id: any) {
    this.updateShownBlocksSecond(files[0], id);
  }

  dropUploadSecond($event: any[], id: any) {
    this.updateShownBlocksSecond($event[0], id);
  }

  deleteFileSecond(block: any, blockIndex: string | number) {
    this.uploadBlocksAfterAgree[blockIndex].file = null;
  }

  updateShownBlocksSecond(file: Blob, id: number) {
    this.uploadBlocksAfterAgree[id - 1].file = file;
  }

  openDialog<T>(dialogComponent: ComponentType<T>, width: string, height: string, data: object) {
    return this.dialog.open(dialogComponent, {
      width: width,
      height: height,
      data: data
    });
  }

  openDocumentsExampleDialog() {
    const dialogRef = this.dialog.open(UploadDocumentsExamplesDialogComponent, {
      width: '75vw',
      height: '90vh',
      panelClass: 'custom-dialog-container'
    });
  }

  loadRequiredDocs() {
    this._SellMyPropertyService.apiV1SellMyPropertyLoadRequiredDocumentsGet()
      .pipe(
        catchError((error: any) => {
          return of(
            this.notificationService.showErrorMessage('Error', 'Could not load required documents.')
          );
        })
      )
      .subscribe((result) => {
        if (result != null) {
          this.uploadBlocks = result[0] as UploadFileDto[];
          this.uploadBlocksAfterAgree = result[1] as UploadFileDto[];
        }
      });
  }
}

@Component({
  selector: 'upload-documents-report-dialog',
  templateUrl: './upload-documents-report-dialog.html',
  styleUrls: ['./upload-documents.component.scss', '../../../../css/2-modules/_forms.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ]
})
export class UploadDocumentsReportDialog {
  constructor(
    public dialogRef: MatDialogRef<UploadDocumentsReportDialog>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ReportDialogData
  ) { }

  selectUpload(files: any[]) {
    this.updateShownBlocks(files[0]);
  }

  dropUpload($event: any[]) {
    this.updateShownBlocks($event[0]);
  }

  updateShownBlocks(file: any) {
    var report: ReportFile = { key: 1, value: 'Report ' + 1, file: file };
    this.data.uploadReports = report;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitReport() {
    this.dialogRef.close({ report: this.data.report, reportFiles: this.data.uploadReports });
  }

  deleteReport() {
    this.data.deleteReport = true;
    this.dialogRef.close(this.data);
  }
}
