<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="container">
        <form id="registerForm" novalidate #registerForm="ngForm" class="submission-form">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading-1">
                        Registration For Log-In:
                    </h1>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="heading-4">
                        Contact Details
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Name:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="name" class="paragraph" placeholder="Mandatory" #name="ngModel"
                        [(ngModel)]="form.name"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}" />
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Surname:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="surname" class="paragraph" placeholder="Mandatory"
                        #surname="ngModel" [(ngModel)]="form.surname"
                        [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}" />
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Landline:</label>
                </div>
                <div class="col-8">
                    <input type="text" name="landline" class="paragraph not-required" [(ngModel)]="form.landLine" />
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Mobile:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="mobile" class="paragraph" placeholder="Mandatory"
                        #mobile="ngModel" minlength="10" [(ngModel)]="form.mobile"
                        [ngClass]="{'invalid-input': mobile.invalid, 'valid-input': mobile.valid}" />
                    <small class="form-control-feedback"
                        *ngIf="(mobile.errors && (mobile.dirty || mobile.touched)) && mobile.errors.minlength">
                        *Mobile number must contain at least 10 digits.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Confirm Mobile:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="confirmMobile" class="paragraph" placeholder="Mandatory"
                        #confirmMobile="ngModel" [(ngModel)]="form.confirmMobile"
                        [ngClass]="{
                            'invalid-input': (mobile.value != confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched),
                            'valid-input': (mobile.value == confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched)}" />
                    <small class="form-control-feedback" [ngClass]="{
                            'confirm-invalid': (mobile.value != confirmMobile.value) && (confirmMobile.dirty || confirmMobile.touched),
                            'confirm-valid': mobile.value == confirmMobile.value}">
                        *Mobile numbers do not match.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Email:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="email" class="paragraph" placeholder="Mandatory" #email="ngModel"
                        [pattern]="emailPattern" [(ngModel)]="form.email" [ngClass]="{
                            'invalid-input': email.invalid,
                            'valid-input': email.valid}" />
                    <small class="form-control-feedback" *ngIf="email.errors && (email.dirty || email.touched)">
                        *Must be a valid email address.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Confirm Email:</label>
                </div>
                <div class="col-8">
                    <input required type="text" name="confirmEmail" class="paragraph" placeholder="Mandatory"
                        #confirmEmail="ngModel" [pattern]="emailPattern" [(ngModel)]="form.confirmEmail"
                        [ngClass]="{
                            'invalid-input': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched),
                            'valid-input': (email.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}" />
                    <small class="form-control-feedback" [ngClass]="{
                            'confirm-invalid': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched),
                            'confirm-valid': email.value == confirmEmail.value}">
                        *Emails do not match.
                    </small>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="heading-5">
                        Are you a resident of the Republic of South Africa?
                    </div>
                    <div class="radio-button">
                        <input type="radio" id="yes" name="isRsaResident" [(ngModel)]="isRsaResident" [value]="true">
                        <label class="paragraph" for="yes">Yes</label>
                    </div>
                    <div class="radio-button">
                        <input type="radio" id="no" name="isRsaResident" [(ngModel)]="isRsaResident" [value]="false">
                        <label class="paragraph" for="no">No</label>
                    </div>
                </div>
            </div>
            <div *ngIf="isRsaResident" class="row">
                <div class="col-4">
                    <label class="paragraph" for="idNumber">Your ID number:</label>
                </div>
                <div class="col-8">
                    <input required class="paragraph" type="text" id="idNumber" name="idNumber" placeholder="Mandatory"
                        #idNumber="ngModel" pattern="^\d{13}$" [(ngModel)]="form.idNumber" [ngClass]="{
                            'invalid-input': idNumber.invalid,
                            'valid-input': idNumber.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(idNumber.errors && (idNumber.dirty || idNumber.touched)) && idNumber.errors.pattern">
                        *Must contain exactly 13 digits.
                    </small>
                </div>
            </div>
            <div *ngIf="!isRsaResident" class="row">
                <div class="col-4">
                    <label class="paragraph" for="passportNumber">Your passport number:</label>
                </div>
                <div class="col-8">
                    <input required class="paragraph" type="text" id="passportNumber" name="passportNumber"
                        placeholder="Mandatory" #passportNumber="ngModel" pattern="^\d{9}$"
                        [(ngModel)]="form.passportNumber" [ngClass]="{
                            'invalid-input': passportNumber.invalid,
                            'valid-input': passportNumber.valid}" />
                    <small class="form-control-feedback"
                        *ngIf="(passportNumber.errors && (passportNumber.dirty || passportNumber.touched)) && passportNumber.errors.pattern">
                        *Must contain exactly 9 digits.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-8">
                    <p class="paragraph">
                        <strong>Please check spelling and digits carefully.</strong>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 class="heading-4 mt-2">
                        Log In Details
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">
                        Username:
                    </label>
                </div>
                <div class="col-8">
                    <input required type="text" name="username" class="paragraph" placeholder="Mandatory"
                        #username="ngModel" [(ngModel)]="form.userName" />
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Password:</label>
                </div>
                <div class="col-8">
                    <input required type="password" name="password" class="paragraph" placeholder="Mandatory"
                        #password="ngModel" [pattern]="passwordPattern" [(ngModel)]="form.password" [ngClass]="{
                            'invalid-input': password.invalid,
                            'valid-input': password.valid}" />
                    <small class="form-control-feedback"
                        *ngIf="password.errors && (password.dirty || password.touched)">
                        *Must contain minimum eight characters, at least one uppercase letter, one lowercase
                        letter, one number and one special character.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label class="paragraph">Confirm Password:</label>
                </div>
                <div class="col-8">
                    <input required type="password" name="confirmPassword" class="paragraph" placeholder="Mandatory"
                        #confirmPassword="ngModel" [pattern]="passwordPattern" [(ngModel)]="form.passwordConfirm"
                        [ngClass]="{
                            'invalid-input': (password.value != confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched),
                            'valid-input': (password.value == confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched)}" />
                    <small class="form-control-feedback" [ngClass]="{
                            'confirm-invalid': (password.value != confirmPassword.value) && (confirmPassword.dirty || confirmPassword.touched),
                            'confirm-valid': password.value == confirmPassword.value}">
                        *Passwords do not match.
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="paragraph">
                        By clicking submit, you agree to the Property Matrix
                        <span class="underline">Terms & Conditions</span>,
                        <span class="underline">Privacy Policy</span> and
                        <span class="underline">Cookie Preferences</span>
                        and consent for your personal information to be processed according to the POPI Act.
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="checkbox-container">
                        <span class="paragraph">
                            I agree to receive marketing communications via email & SMS
                        </span>
                        <input type="checkbox" name="confirmConsentMarketing" #cbConfirmConsentMarketing="ngModel"
                            data-toggle="toggle" [(ngModel)]="confirmConsent" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="row text-center mt-3">
                <div class="col-12">
                    <button mat-button class="button-proceed heading-4 mt-2" (click)="register()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </form>
    </div>
</main>