<div class="container" *ngIf="currentUser; else loggedOut">
  <header class="jumbotron">
    <h3>
      Welcome <b>{{ currentUser.userName }}</b> to your profile
    </h3>
  </header>
  <p>
    <strong>Email:</strong>
    {{ currentUser.userEmail }}
  </p>

  <p>
    <strong>Role:</strong>
    {{ currentUser.userRole }}
  </p>

  <p>
    <a href="adminPortal" routerLink="/adminPortal">Admin Portal</a>
  </p>
</div>

<ng-template #loggedOut>
  <div class="col-md-3 mx-auto">
    <h1>Please login.</h1>
  </div>
</ng-template>
