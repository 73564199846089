import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { UrbanPlannerService } from 'src/app/services/property-matrixV2/services';
import { UrbanPlannerDto, UrbanPlannerProfessionalDto } from 'src/app/services/property-matrixV2/models';
import { UrbanPlannerProfessionalDialogComponent } from '../urban-planner-professional-dialog/urban-planner-professional-dialog/urban-planner-professional-dialog.component';
import Swal from 'sweetalert2';
import { WizardDataService } from 'src/app/services/property-matrixV2/custom-services/wizard-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-planner-register-form-extended-network',
  templateUrl: './planner-register-form-extended-network.component.html',
  styleUrls: ['./planner-register-form-extended-network.component.scss']
})
export class PlannerRegisterFormExtendedNetworkComponent implements OnInit {

  @ViewChild(MatTable) professionalTable: MatTable<any>;
  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() UrbanPlannerExtendedNetworkEvent = new EventEmitter<UrbanPlannerDto>();

  dataSource: any;
  displayedColumns: string[] = ['number', 'name', 'profession', 'edit', 'delete'];
  wizardDataSub: Subscription;
  professionals: UrbanPlannerProfessionalDto[] = [];

  professional: UrbanPlannerProfessionalDto = {
    companyName: '',
    email: '',
    id: '',
    mobile: '',
    name: '',
    profession: null,
    surname: '',
    websiteLink: '',
  }

  constructor(
    public dialog: MatDialog,
    private urbanPlannerService: UrbanPlannerService,
    private _wizardDataService: WizardDataService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  wizardPassData() {
    this._wizardDataService.setUrbanPlannerProfessionalData(this.professionals);
  }

  submitForm() {
    this.wizardPassData();
    this.pageNumberEvent.emit(3);
  }

  addProfessional() {
    const dialogRef = this.openDialog(UrbanPlannerProfessionalDialogComponent, { professional: this.professional, showDelete: false, mode: 'Add professional' });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.professionals.push(result);
        this.professionalTable.dataSource = this.professionals;
        this.professionalTable.renderRows();
        this.cdr.detectChanges();
      }
    });
  }

  editProfessionalItem(data: UrbanPlannerProfessionalDto) {
    const dialogRef = this.dialog.open(UrbanPlannerProfessionalDialogComponent, {
      data: {
        dataKey: data,
        mode: 'Edit professional'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        this.professionalTable.renderRows();
      }
    });
  }

  deleteProfessionalItem(data: UrbanPlannerProfessionalDto) {
    Swal.fire({
      title: `Delete professional ` + data.name + `?`,
      text: 'Are you sure you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#FCE0D7',
      cancelButtonColor: '#103637',
      customClass: {
        confirmButton: 'custom-confirm',
        cancelButton: 'custom-cancel'
      }
    }).then((buttonClick) => {
      if (buttonClick.isConfirmed) {
        this.urbanPlannerService.apiV1UrbanPlannerDeleteUrbanPlannerProfessionalAsyncDelete({ body: data }).pipe(
        ).subscribe(res => {
        });
        this.professionals.splice(this.professionals.indexOf(data), 1);
        this.professionalTable.renderRows();
      }
    });
  }

  openDialog<T>(dialogComponent: ComponentType<T>, data: object) {
    return this.dialog.open(dialogComponent, {
      data: data
    });
  }
}